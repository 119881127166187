import styled from 'styled-components';
import { AXSBlock, AXSButton } from '@axs/axs-ui';
import { parseThemePath } from '@axs/axs-ui/dist/utils';

export const SessionCardButton = styled(AXSButton)`
  border: solid 1px #4a6694;
  color: ${({ theme }) => parseThemePath('axsui.text.secondary', theme, '#bbc7dc') };
  font-size: 14px;
  font-weight : bold;
`;

export const SessionCardSubTitles = styled(AXSBlock)`
  border-top: solid 1px #1d3570;
  font-size: 20px;
  height: 24px;
  padding-top: 15px;
  width: 100%;
`;
export const SectionHeader = styled(AXSBlock)`
  font-size: 20px;
  height: 24px;
  margin-bottom: 15px;
  width: 100%;
`;

export const CloseButton = styled(AXSButton)`
  height: 16px;
  width: 100%;
  color: #4a6694;
  margin-bottom: 40px;
  margin-top: 24px;
  font-size: 14px;
`;
export const MOPItem = styled.span`
  color: ${({ theme }) => parseThemePath('axsui.text.h2.color', theme, '#798daa') };
  font-size: 14px;
`;
export const PageTitle = styled(AXSBlock)`
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
`;

export const PrintSessionCardButton = styled(AXSButton)`
  height: 48px;
  width: 360px;
  margin: 0 px;
  display: inline-block;
`;
