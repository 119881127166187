import {
  ApplicationStateActions,
  ApplicationStateTypes,
  IApplicationState,
  IFetchThemeSuccess,
  ISetNotification,
  ISetThemeMode,
} from '../types/appTypes';
import { themeTypes } from '../theme';

export const initialState: IApplicationState = {
  overlayEnabled: false,
  restoredFromLocalStorage: false,
  restoredFromSessionStorage: false,
  themeMode: themeTypes.dark,
  themes: [],
};

const ACTION_HANDLERS = {
  [ApplicationStateTypes.GET_THEMES]: (state: IApplicationState) => {
    return state;
  },
  [ApplicationStateTypes.SET_THEME_MODE]: (state: IApplicationState, { mode }: ISetThemeMode) => {
    return Object.assign({}, state, { themeMode: mode });
  },
  [ApplicationStateTypes.FETCH_THEME_BEGIN]: (state: IApplicationState) => {
    return state;
  },
  [ApplicationStateTypes.FETCH_THEME_SUCCESS]: (state: IApplicationState, { skin }: IFetchThemeSuccess) => {
    const themes = [...state.themes];
    themes.push(skin);
    return Object.assign({}, state, { themes });
  },
  [ApplicationStateTypes.TOGGLE_OVERLAY]: (state: IApplicationState) => {
    return { ...state, overlayEnabled: !state.overlayEnabled };
  },
  [ApplicationStateTypes.SET_NOTIFICATION]: (state: IApplicationState, { notification }: ISetNotification) => {
    return Object.assign({}, state, { notification });
  },
  [ApplicationStateTypes.CLEAR_NOTIFICATION]: (state: IApplicationState) => {
    return Object.assign({}, state, { notification: undefined });
  },
  [ApplicationStateTypes.RESTORE_LOCAL_STORAGE]: (state: IApplicationState) => {
    return Object.assign({}, state, { restoredFromLocalStorage: true });
  },
  [ApplicationStateTypes.RESTORE_SESSION_STORAGE]: (state: IApplicationState) => {
    return Object.assign({}, state, { restoredFromSessionStorage: true });
  },
};

export default function reducer(
    state: IApplicationState = initialState,
    action: ApplicationStateActions,
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
