import StorageBase from '../StorageBase/StorageBase';
import { IExpiration } from '../../types/common';
import { connect } from 'react-redux';
import { ISettings, IWorkStation } from '../../types/userTypes';
import { IOnBehalfOfInfo } from '../../api/types/fanAccount/responseTypes';
import {
    APP_REDUCER_KEY,
    ACCOUNT_REDUCER_KEY,
    CARTING_REDUCER_KEY,
    FEATURES_REDUCER_KEY,
    USER_REDUCER_KEY,
} from '../../store/store.types';
import { ICustomer } from '../../mfe/carting/Carting/types/apiRequestTypes';
import { ICart, IOrderingResult } from '../../mfe/carting/Carting/types/apiResponseTypes';
import { IFeatures } from '../../types/featuresTypes';
import { storeSettings, loginResolutionUserSuccess, setWorkstation } from '../../actions/userActions';
import { getUserTokenRestore } from '../../actions/accountActions';
import { setThemeMode, restoreFromLocalStorage } from '../../actions/appActions';
import { restoreCarting } from '../../mfe/carting/Carting/actions/cartingActions';
import { setFeatures, setClient } from '../../actions/featuresActions';
import { ILocalStorageProps, IStorageMap } from '../../types/storageTypes';

type orderingResultTypes = IOrderingResult | null;

class LocalStorage extends StorageBase<ILocalStorageProps> {
  constructor(public localStorageProps: ILocalStorageProps) {
    super(localStorageProps);
    this.storageTarget = localStorage;
  }

  public componentDidMount() {
    const { restoreFromLocalStorage, restoredFromLocalStorage } = this.props;
    this.componentDidMountStorageBase(restoreFromLocalStorage, restoredFromLocalStorage);
  }

  public componentDidUpdate(prevProps: ILocalStorageProps) {
    this.componentDidUpdateStorageBase(prevProps);
  }

  public getStorageMap(): IStorageMap {
    const {
      oboAccessToken,
      oboRefreshToken,
      oboUserEmail,
      onBehalfOfInfo,
      cashTendered,
      client,
      customer,
      features,
      lastOrderingResult,
      loginResolutionUserSuccess,
      lookUpEmailRestore,
      order,
      orderAutoPrinted,
      orderCheckedOut,
      restoreCarting,
      setClient,
      setFeatures,
      setThemeMode,
      setWorkstation,
      settings,
      storeSettings,
      themeMode,
      workstation,
    } = this.props;
    return {
      carting: {
        dependsOn: ['cashTendered', 'customer', 'lastOrderingResult', 'order', 'orderAutoPrinted', 'orderCheckedOut'],
        setter: ({ cashTendered, customer, lastOrderingResult, order, orderAutoPrinted, orderCheckedOut }) =>
            restoreCarting(cashTendered, customer, lastOrderingResult, order, orderAutoPrinted, orderCheckedOut),
        value: { cashTendered, customer, lastOrderingResult, order, orderAutoPrinted, orderCheckedOut },
      },
      client: {
        setter: setClient,
        value: client,
      },
      features: {
        setter: setFeatures,
        value: features,
      },
      onBehalfOfInfo: {
        dependsOn: ['onBehalfOfInfo'],
        setter: data => lookUpEmailRestore(data),
        value: onBehalfOfInfo,
      },
      resolutionUser: {
        dependsOn: ['oboAccessToken', 'oboRefreshToken', 'oboUserEmail'],
        setter: ({ oboAccessToken, oboRefreshToken, oboUserEmail }) => {
          loginResolutionUserSuccess(oboAccessToken, oboRefreshToken, oboUserEmail);
        },
        value: { oboAccessToken, oboRefreshToken, oboUserEmail },
      },
      settings: {
        setter: storeSettings,
        value: settings,
      },
      themeMode: {
        setter: setThemeMode,
        value: themeMode,
      },
      workstation: {
        setter: ({ data, expireAt }: IExpiration<IWorkStation>) => setWorkstation(data, expireAt),
        value: workstation,
      },
    };
  }
}

const mapStateToProps = (state: any) => {
  return {
    cashTendered: state[CARTING_REDUCER_KEY].cashTendered,
    client: state[FEATURES_REDUCER_KEY].client,
    customer: state[CARTING_REDUCER_KEY].customer,
    features: state[FEATURES_REDUCER_KEY].features || null,
    lastOrderingResult: state[CARTING_REDUCER_KEY].lastOrderingResult,
    oboAccessToken: state[USER_REDUCER_KEY].accessToken,
    oboRefreshToken: state[USER_REDUCER_KEY].refreshToken,
    oboUserEmail: state[USER_REDUCER_KEY].oboUserEmail,
    onBehalfOfInfo: state[ACCOUNT_REDUCER_KEY].onBehalfOfInfo,
    order: state[CARTING_REDUCER_KEY].order,
    orderAutoPrinted: state[CARTING_REDUCER_KEY].orderAutoPrinted,
    orderCheckedOut: state[CARTING_REDUCER_KEY].orderCheckedOut,
    restoredFromLocalStorage: state[APP_REDUCER_KEY].restoredFromLocalStorage,
    settings: state[USER_REDUCER_KEY].settings,
    themeMode: state[APP_REDUCER_KEY].themeMode,
    workstation: state[USER_REDUCER_KEY].workstation,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loginResolutionUserSuccess: (accessToken: string, refreshToken: string, email: string) =>
        dispatch(loginResolutionUserSuccess(accessToken, refreshToken, email)),
    lookUpEmailRestore: (data: { [contextId: number]: { [email: string]: IOnBehalfOfInfo } }) =>
        dispatch(getUserTokenRestore(data)),
    restoreCarting: (cashTendered: number | null, customer: ICustomer | null, lastOrderingResult: orderingResultTypes,
                     order: ICart | null, orderAutoPrinted: boolean, orderCheckedOut: boolean) =>
        dispatch(restoreCarting(cashTendered, customer, lastOrderingResult, order, orderAutoPrinted, orderCheckedOut)),
    restoreFromLocalStorage: () => dispatch(restoreFromLocalStorage()),
    setClient: (client: string | null) => dispatch(setClient(client)),
    setFeatures: (features: IFeatures | null) => dispatch(setFeatures(features)),
    setThemeMode: (mode: string) => dispatch(setThemeMode(mode)),
    setWorkstation: (workstation?: IWorkStation, expireAt?: number) => dispatch(setWorkstation(workstation, expireAt)),
    storeSettings: (settings: ISettings) => dispatch(storeSettings(settings)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalStorage);

