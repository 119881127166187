import * as React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import PageLayout from '../layouts/PageLayout';

const SearchResultsContainer = Loadable({
  loader: () => import('../containers/search'),
  loading: () => null,
});

export default function (store: any, props: any) {
  const match = props.match;
  return (
    <Switch>
      <PageLayout>
        <Route path={ match.url } render={ () => <SearchResultsContainer { ...store } { ...props } /> } />
      </PageLayout>
    </Switch>
  );
}
