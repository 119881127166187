import styled from 'styled-components';
import { AXSIcon, AXSLabel } from '@axs/axs-ui';

export const AXSPill = styled(AXSLabel)`
`;

export const Expander = styled(AXSIcon)`
  margin-left: 3px;
`;

export const CartStatus = styled.div`
  cursor: pointer;
`;
