export enum TAGS {
  Script = 'script',
}

/**
 * Removes all specified tags from html string.
 * @param content
 * @param tag
 */
export const removeHtmlTag = (content: string, tag: string) => {
  const sanitizeHtml = require('sanitize-html');
  const dirtyHtml = content;
  return sanitizeHtml(dirtyHtml, {
    allowedAttributes: false,
    allowedTags: false,
    exclusiveFilter: (frame: any) => {
      return frame.tag === tag;
    },
  });
};
