import { IAddress } from '../api/types/fanAccount/responseTypes';

/**
 * Concatenates street fields of the specified address.
 */
const getStreetLine = ({ line1, line2 }: { line1?: string, line2?: string }) => {
  return [line1, line2].filter(x => x).join(' ');
};

/**
 * Concatenates city fields of the specified address.
 */
const getCityLine = ({
  city,
  state,
  zip,
  country,
}: {
  city?: string;
  country?: string;
  state?: string;
  zip?: string;
}) => {
  const cityState = [city, state].filter(x => x).join(' ');
  return [cityState, zip, country].filter(x => x).join(', ');
};

const compareByPrimaryAndCity = (address1: IAddress, address2: IAddress): number => {
  const EQUALS = 0;
  const primaryComparison = +address2.isPrimaryAddress - +address1.isPrimaryAddress;
  if (primaryComparison !== EQUALS) {
    return primaryComparison;
  }

  const city1 = address1.city || '';
  const city2 = address2.city || '';
  let cityComparison = city1.localeCompare(city2);
  if (!city1 || !city2) {
    // it is more convenient to have empty strings at the end
    // which is 'reverse' to normal string comparison
    // that's why we reverse the value
    cityComparison = -cityComparison;
  }
  if (cityComparison !== EQUALS) {
    return cityComparison;
  }

  const id1 = +address1.addressID || 0;
  const id2 = +address2.addressID || 0;
  return id2 - id1;
};

export { compareByPrimaryAndCity, getStreetLine, getCityLine };
