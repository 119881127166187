import React from 'react';
import { matchPath } from 'react-router';
import Loadable from 'react-loadable';
import { get } from 'lodash';
import { Route, Switch } from 'react-router-dom';
import { pages } from '../constants/routes';
import PageLayout from '../layouts/PageLayout';

const ChangeModPageContainer = Loadable({
  loader: () => import('../containers/changeMod'),
  loading: () => null,
});

export default function (store: any, props: any) {
  const { match } = props;
  const pageParamsOrder = matchPath(match.url, {
    exact: true,
    path: pages.changeModOrder(':email', ':orderId', ':eventId'),
    strict: false,
  });
  const pageParamsTicket = matchPath(match.url, {
    exact: true,
    path: pages.changeModTicket(':email', ':orderId', ':eventId', ':eventZoneId', ':seatGroupId', ':seatId'),
    strict: false,
  });
  const extendedProps = {
    ...props,
    customerEmail: get(pageParamsOrder, 'params.email') || get(pageParamsTicket, 'params.email'),
    eventId: get(pageParamsOrder, 'params.eventId') || get(pageParamsTicket, 'params.eventId'),
    eventZoneId: get(pageParamsTicket, 'params.eventZoneId'),
    orderId: get(pageParamsOrder, 'params.orderId') || get(pageParamsTicket, 'params.orderId'),
    seatGroupId: get(pageParamsTicket, 'params.seatGroupId'),
    seatId: get(pageParamsTicket, 'params.seatId'),
  };

  return (
    <Switch>
      <PageLayout>
        <Route
          path={ match.url }
          render={ () => <ChangeModPageContainer { ...store } { ...extendedProps } /> }
        />
      </PageLayout>
    </Switch>
  );
}
