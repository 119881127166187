import React from 'react';
import { AXSBlock } from '@axs/axs-ui';

/**
 * A component that will auto-resize and clip text per UX specs.
 *
 * @param align
 * @param children
 * @param className
 * @constructor
 */
export const EventDetailSupportingTitle = ({ align, children, fontSize, color, margin }: any) =>
  (
    <AXSBlock margin={ margin } textAlign={ align } color={ color } fontSize={ fontSize }>
      { children }
    </AXSBlock>
  );
