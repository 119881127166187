import Venue from '../Venue';

class CVenue extends Venue {
  public venueId?: any;
  public title?: string;
  public city?: any;
  public state?: any;
}

export default CVenue;
