import {
  IInventoryState,
  InventoryStateActions,
  InventoryStateTypes,
} from '../types/inventoryTypes';
import { logStyles } from '../constants/logStyles';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import filter from 'lodash/filter';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

import min from 'lodash/min';
import max from 'lodash/max';

import OfferPriceChart from '../common/PriceChart/OfferPriceChart';
import PriceChart from '../common/PriceChart/PriceChart';
import PriceLevel from '../common/PriceLevel';
import PriceCode from '../common/PriceCode';
import Price from '../common/Price';
import Seat from '../common/Seat/Seat';
import Section from '../common/Section/Section';
import { Currency } from '../common/Price/Currency';
import { IOfferInventory, ISeat, IInventoryPrices } from '../api/types/unifiedapi/responseTypes';
import OfferInventory from '../common/Offer/OfferInventory';
import ResalePrice from '../common/Price/ResalePrice';
import { cookDynamicPriceRanges, cookDynamicPrices, getDynamicPriceLevelMinMax } from './utils/dynamicPriceUtils';
import DynamicPrice from '../common/Price/DynamicPrice';
import { IDynamicPriceRanges, IMinMax } from '../common/Price/DynamicPriceRange';
import { OfferType } from '../common/Offer/OfferType';


export const initialState: IInventoryState = {
  fetchingInventory: false,
  fetchingOfferPricing: false,
  offerInventories: [],
  offerPrices: [],
  seatInventory: [],
  sectionInventory: [],
};

const ACTION_HANDLERS = {
  [InventoryStateTypes.ADD_OFFER_INVENTORY_SEATS]: (state: IInventoryState, action: { seats: Seat[] }) => {
    const newState = { ...state };
    const newSeatInventory = newState.seatInventory;
    action.seats.forEach((seat) => {
      // tslint:disable-next-line: max-line-length
      const existingSeat = find(newState.seatInventory, { description: seat.description, rowLabel: seat.rowLabel, sectionLabel: seat.sectionLabel });
      if (!existingSeat) {
        newSeatInventory.push(seat);
      }
    });

    return Object.assign(newState, { seatInventory: newSeatInventory });
  },
  [InventoryStateTypes.GET_OFFER_INVENTORY_BEGIN]: (state: IInventoryState) => {
    const newState = { ...state };
    return Object.assign(newState, { fetchingInventory: true });
  },
  // tslint:disable-next-line: max-line-length
  [InventoryStateTypes.GET_OFFER_INVENTORY_SUCCESS]: (state: IInventoryState, action: { data: { offers: IOfferInventory[] } }) => {
    const newState = { ...state };
    const newOfferInventories = newState.offerInventories;
    const offers: IOfferInventory[] = get(action, 'data.offers');
    const contextId = get(action, 'data.contextId');
    const offerType = get(action, 'data.offerType') as OfferType;
    const originalOfferId: number = get(action, 'data.offerId');
    const existingOfferInventoryIndex = findIndex(newOfferInventories, { offerId: originalOfferId });
    const newOfferInventory: OfferInventory = new OfferInventory(
      originalOfferId,
      0,
      contextId,
      [],
      0,
      [],
    );
    // since we requested 1 offer, but we might get multiple back (related Offers, resale Offers)
    // We ll merge seats into the main one
    offers.forEach((offerInventoryData: IOfferInventory) => {
      const newSeatInventory: Seat[] = [];
      const newSectionInventory: Section[] = [];
      const productId = offerInventoryData.productID;
      const offerId = offerInventoryData.offerID;
      const eventId = offerInventoryData.eventID;
      newOfferInventory.eventId = parseInt(eventId);
      offerInventoryData.items.forEach((s: ISeat) => {
        const seat = new Seat(
          parseInt(s.id),
          s.number,
          parseInt(s.priceLevelID),
          parseInt(s.displayOrder),
          parseInt(offerId),
          parseInt(productId),
          parseInt(s.sectionID),
          s.sectionLabel,
          parseInt(s.rowID),
          s.rowLabel,
          s.seatType,
          s.info1,
          s.info2);
        newSeatInventory.push(seat);
        // If new Section, Add it
        if (!newSectionInventory.find(s => s.id === seat.sectionId)) {
          const section: Section = new Section(seat.sectionId, seat.sectionLabel);
          newSectionInventory.push(section);
        }
      });
      Array.prototype.push.apply(newOfferInventory.seats, newSeatInventory);
      Array.prototype.push.apply(newOfferInventory.sections, newSectionInventory);
      newOfferInventory.seatCount = newOfferInventory.seats.length;

      // Feed the Possible Seat price into each seat object (for filtering and seat Tool tip)
      if (newState.offerPrices) {
        // Find the corresponding offerPrice Object
        const offerPriceChart: OfferPriceChart | undefined =
        find(newState.offerPrices, { offerId: originalOfferId });
        if (offerPriceChart && offerPriceChart.priceCharts) {
          newSeatInventory.forEach((seat: Seat) => {
            seat.prices = offerPriceChart.getSeatPrices(seat, offerType);
          });
        }
      }
    });

    if (existingOfferInventoryIndex !== -1) {
      newOfferInventories.splice(existingOfferInventoryIndex, 1);
    }
    newOfferInventories.push(newOfferInventory);
    return Object.assign(newState, {
      fetchingInventory: false,
      offerInventories: newOfferInventories,
    });
  },
  [InventoryStateTypes.GET_OFFER_INVENTORY_FAILURE]: (state: IInventoryState) => {
    const newState = { ...state };
    return Object.assign(newState, { fetchingInventory: false });
  },
  [InventoryStateTypes.GET_OFFER_PRICING_BEGIN]: (state: IInventoryState) => {
    const newState = { ...state };
    return Object.assign(newState, { fetchingOfferPricing: true });
  },
  [InventoryStateTypes.GET_OFFER_PRICING_SUCCESS]: (state: IInventoryState, action: { data: IInventoryPrices }) => {
    const newState =  cloneDeep(state);
    console.log(`%cLoaded ${action.data.offerPrices.length} Offer Prices`, logStyles);

    const offerPrices: OfferPriceChart[] = [...newState.offerPrices];
    const inventoryPriceData: IInventoryPrices = action.data;
    const currency: Currency = get(inventoryPriceData, 'currency');
    forEach(inventoryPriceData.offerPrices, (data) => {
      const priceCharts: PriceChart[] = [];
      forEach(data.zonePrices, (zone) => {
        // Build the priceLevel List and the price list
        const priceLevels: PriceLevel[] = [];
        const prices: Price[] = [];
        forEach(zone.priceLevels, (priceLevelData) => {
          const priceLevel: PriceLevel = new PriceLevel(
            parseInt(priceLevelData.priceLevelID),
            priceLevelData.label,
            priceLevelData.order,
          );
          priceLevels.push(priceLevel);

          // Fetching prices
          forEach(priceLevelData.prices, (priceData) => {
            const price: Price = new Price(
              priceData.base,
              parseInt(priceLevelData.priceLevelID),
              parseInt(priceData.priceTypeID));
            prices.push(price);
          });

          // Fetching Availability
          priceLevel.availability = get(priceLevelData, 'availability.amount');
        });

        // Build the price code List
        const priceCodes: PriceCode[] = [];
        forEach(zone.priceTypes, (priceTypeData) => {
          const priceCode: PriceCode = new PriceCode(
            parseInt(priceTypeData.priceTypeID),
            priceTypeData.label,
            priceTypeData.maxQty,
            priceTypeData.minQty,
            priceTypeData.sortOrder,
            priceTypeData.pricingMode,
          );
          priceCodes.push(priceCode);
        });

        // Build the resale Price List
        const resalePrices: ResalePrice[] = [];
        if (zone.resalePrices) {
          forEach(Object.keys(zone.resalePrices), (sectionLabel: string) => {
            const resalePriceData: any = zone.resalePrices[sectionLabel];
            forEach(Object.keys(resalePriceData), (offerListingId) => {
              const priceData: number = resalePriceData[offerListingId];
              const resalePrice: ResalePrice = new ResalePrice(
                sectionLabel,
                parseInt(offerListingId),
                priceData,
              );
              resalePrices.push(resalePrice);
            });
          });
        }

        // Dynamic Price
        let dynamicPrices: DynamicPrice[] = [];
        let dynamicPriceRanges: IDynamicPriceRanges = {};
        if (zone.rawDynamicPriceRanges) {
          dynamicPriceRanges = cookDynamicPriceRanges(zone.rawDynamicPriceRanges);
        }

        if (zone.rawDynamicPrices) {
          dynamicPrices = cookDynamicPrices(zone.rawDynamicPrices);
        }

        // Set min max for the price level
        forEach(priceLevels, (priceLevel: PriceLevel) => {
          const plPrices = filter(prices, { priceLevelId: priceLevel.id });
          const values = plPrices.map(item => item.value);
          let minPrice = values.length > 0 ? min(values.filter(value => value > 0)) : min(values);
          let maxPrice = max(values);

          // Find out if there is any price for this specific Price Level
          const dynamicPriceRange: IMinMax | undefined = getDynamicPriceLevelMinMax(dynamicPriceRanges, priceLevel.id);
          if (dynamicPriceRange) {
            if (dynamicPriceRange.min !== undefined && minPrice) {
              minPrice = Math.min(minPrice, dynamicPriceRange.min);
            }
            if (dynamicPriceRange.max !== undefined && maxPrice) {
              maxPrice = Math.max(maxPrice, dynamicPriceRange.max);
            }
          }
          priceLevel.setPriceRange(minPrice, maxPrice);
        });

        // Generate an "All" price level
        let priceLevelCount = 0;
        let minPrice = Number.MAX_SAFE_INTEGER;
        let maxPrice = 0;

        if (priceLevels.length === 1) {
          const prices = priceLevels[0].getPriceRange();
          minPrice = prices.min || 0;
          maxPrice = prices.max || 0;
          priceLevelCount = priceLevels[0].availability || 0;
        } else {
          forEach(priceLevels, (item: PriceLevel) => {
            priceLevelCount = priceLevelCount += item.availability || 0;
            const { min, max } = item.getPriceRange();
            minPrice = min && min < minPrice && min !== 0 ? minPrice = min : minPrice;
            maxPrice = max && max > maxPrice ? maxPrice = max : maxPrice;
          });
        }

        const allPriceLevel = new PriceLevel(0, 'All', 0, minPrice, maxPrice, priceLevelCount);
        priceLevels.unshift(allPriceLevel);

        // Building the price chart for the zone
        const zonePriceChart: PriceChart = new PriceChart(
          zone.zoneID,
          parseInt(zone.eventID),
          zone.productID,
          priceLevels,
          priceCodes,
          prices,
          resalePrices,
          dynamicPrices,
          dynamicPriceRanges,
          zone.isSoldOut,
        );
        priceCharts.push(zonePriceChart);
      });
      const offerPriceChart = new OfferPriceChart(
        parseInt(data.offerID),
        parseInt(data.offerGroupID),
        priceCharts,
        currency);
      // Add or Replace
      const existingOfferPriceChartIndex: number = findIndex(offerPrices, { offerId: offerPriceChart.offerId });
      if (existingOfferPriceChartIndex === -1) {
        offerPrices.push(offerPriceChart);
      } else {
        offerPrices[existingOfferPriceChartIndex] = offerPriceChart;
      }

    });

    return Object.assign(newState, { offerPrices, fetchingOfferPricing: false });
  },
  [InventoryStateTypes.GET_OFFER_PRICING_FAILURE]: (state: IInventoryState) => {
    const newState = { ...state };
    return Object.assign(newState, { fetchingOfferPricing: false });
  },
};

export default function reducer(
  state: IInventoryState = initialState,
  action: InventoryStateActions,
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state; // @todo fix any crutch here.
}
