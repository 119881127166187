import { flatMap, get } from 'lodash';
import moment from 'moment';
import { IDeliveryMethod, IPrimaryOrderSeat } from '../api/types/fanAccount/responseTypes';
import { ORDER_NUMBER_FLASH, TICKET_STATUSES } from '../constants/ticketStatus';
import { getTicketStatus } from './ticketStatus';

export const getTicketMODs = (deliveryMethodIds: number[]) => {
  const ticketMODs = new Set();
  deliveryMethodIds.forEach((deliveryMethodId: number) => ticketMODs.add(deliveryMethodId));
  return ticketMODs;
};

/**
 * Defines whether Method of Delivery of the tickets can be changed.
 * @param ticket - the ticket to check
 */
const isChangeModAllowed = (ticket: IPrimaryOrderSeat): boolean => {

  const endDate = get(ticket, 'parent.endDate');
  if (moment().isAfter(moment(endDate))) {
    // event is in the past
    return false;
  }

  const offer = get(ticket, 'parent');
  const flashTicket = get(ticket, 'flashTicket', null);
  const ticketStatus = getTicketStatus(offer, ticket, flashTicket);

  if (
    ticketStatus === TICKET_STATUSES.CANCELLED ||
    ticketStatus === TICKET_STATUSES.TRANSFERRED ||
    ticketStatus === TICKET_STATUSES.TRANSFER_WAITING ||
    ticketStatus === TICKET_STATUSES.TRANSFERRED_REDEEMED ||
    ticketStatus === TICKET_STATUSES.LISTED ||
    ticketStatus === TICKET_STATUSES.SOLD ||
    ticketStatus === TICKET_STATUSES.SOLD_REDEEMED ||
    ticketStatus === TICKET_STATUSES.RESOLD ||
    ticketStatus === TICKET_STATUSES.REDEEMED ||
    ticketStatus === TICKET_STATUSES.NOT_REDEEMED ||
    ticketStatus === TICKET_STATUSES.FORWARDED
  ) {
    return false;
  }

  if (
    ticket.parent.parent.orderNumber === ORDER_NUMBER_FLASH &&
    ticketStatus === TICKET_STATUSES.RECEIVED
  ) {
    return false;
  }

  return true;
};

/**
 * Defines whether the Method of Delivery of the tickets can be changed.
 * @param tickets - the tickets to check
 */
export const getChangeModAllowed = (tickets: IPrimaryOrderSeat[]): boolean => {
  return tickets.some(isChangeModAllowed);
};

export const getAvailableMods = (deliveryMethods: IDeliveryMethod[]) => {
  return flatMap(deliveryMethods, modSet => modSet)
    .filter((mod, index, mods) => mods.findIndex(item => mod.id === item.id) === index);
};
