import { AnyAction } from 'redux';
import { Moment } from 'moment';
import { VTCustomer } from '../common/Customer';

export interface ICustomerFilterDate {
  status: string;
  range: Moment[];
}

export interface ICustomerStateOrderFilterSettings {
  date: ICustomerFilterDate;
  orderStatuses: string[];
  paymentStatuses: string[];
}

export interface ICustomerStateEventFilterSettings {
  date: ICustomerFilterDate;
  ticketStatuses: string[];
}

export interface ICustomersState {
  customer: VTCustomer | null;
  fetchingCustomers: boolean;
  fetchingCustomersError: boolean;
  filterSettingsOrder: ICustomerStateOrderFilterSettings;
  filterSettingsEvent: ICustomerStateEventFilterSettings;
}

export enum CustomersStateTypes {
  RESET_CUSTOMER_STATE = 'RESET_CUSTOMER_STATE',
  SET_CUSTOMER_FILTER_ORDER_SETTINGS = 'SET_CUSTOMER_FILTER_ORDER_SETTINGS',
  SET_CUSTOMER_FILTER_EVENT_SETTINGS = 'SET_CUSTOMER_FILTER_EVENT_SETTINGS',
}

export interface IResetCustomerState extends AnyAction {
  type: CustomersStateTypes.RESET_CUSTOMER_STATE;
}

export interface ISetCustomerFilterOrderSettings extends AnyAction {
  type: CustomersStateTypes.SET_CUSTOMER_FILTER_ORDER_SETTINGS;
  settings: ICustomerStateOrderFilterSettings;
}

export interface ISetCustomerFilterEventSettings extends AnyAction {
  type: CustomersStateTypes.SET_CUSTOMER_FILTER_EVENT_SETTINGS;
  settings: ICustomerStateEventFilterSettings;
}

export type CustomersStateActions =
  IResetCustomerState |
  ISetCustomerFilterOrderSettings |
  ISetCustomerFilterEventSettings;
