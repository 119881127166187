import {
  IUserState,
  UserStateActions,
  UserStateTypes,
  ISetSelectedEventId,
  ISetSelectedFlowType,
  ISetSelectedOfferId,
  ILoginFailure,
  IStoreSettings,
  ISetSetting,
  PaymentMethods,
  ISettings,
  ILoginSuccess,
  ILoginResolutionUserSuccess,
  ISetOutlets,
  ISetSession,
  ISetWorkstation,
  IWorkStation,
} from '../types/userTypes';
import { findIndex, sumBy } from 'lodash';
import { FlowType } from '../common/Offer/FlowType';
import { CartingActionTypes, CartingActions } from '../mfe/carting/Carting/types/cartingTypes';
import { ICartingMessage } from '../mfe/carting/Carting/types/apiResponseTypes';
import config from '../config';
import { IOutlet } from '../api/types/eventtool/responseTypes';
import { OutletType } from '../common/Outlet/OutletType';
import { getExpiration } from '../utils/common';
import { IExpiration } from '../types/common';
import { GUEST_CHECKOUT_MODE } from '../constants/guestCheckoutMode';

export const initialSettings: ISettings = {
  cartTimer: { label: 'Default', value: '0' },
  event: { label: 'No Event Selected', value: '' },
  guestCheckoutMode: { label: 'Off', value: GUEST_CHECKOUT_MODE.OFF },
  location: { label: 'Window 1', value: 'window_one' },
  mode: { label: 'Auto', value: 'auto' },
  offer: { label: 'No Offer Selected', value: '' },
  outlet: {},
  printer: {},
  receipt: { label: 'Quick Sell', value: 'quick_sell' },
  stock: { label: 'None', value: '' },
  topCard: { label: 'Auto', value: 'auto' },
  type: { label: 'Boca', value: '3' },
};

export const initialState: IUserState = {
  accessToken: null,
  authToken: null,
  authenticatedMessage: null,
  contextId: null,
  creatingSession: false,
  isAuthenticating: false,
  oboUserEmail: null,
  refreshToken: null,
  selectedEventId: null,
  selectedFlowType: FlowType.BEST_AVAILABLE,
  selectedOfferId: null,
  selectedPaymentMethods: [],
  selectedPriceCodes: [],
  selectedPriceLevels: [Number(0)],
  sessionExpiration: null,
  sessionId: null,
  settings: initialSettings,
  totalSelectedTicketCount: 0,
  userId: null,
  workstation: null,
};

if (config.outletTypes === undefined) { config.outletTypes = OutletType.BoxOffice; }
let AllowedOutletTypes = Object.keys(OutletType).filter(type =>
  config.outletTypes.split(',').some(configType => configType.match(new RegExp(type, 'i'))));

if (AllowedOutletTypes.length === 0) { AllowedOutletTypes = [OutletType.BoxOffice]; }

const ACTION_HANDLERS = {
  [UserStateTypes.TOGGLE_PRICE_CODE]: (state: IUserState, action: { priceCodeId: number, count: number }) => {
    const newState = { ...state };
    const index = findIndex(state.selectedPriceCodes, { id: action.priceCodeId });
    const codes = [...newState.selectedPriceCodes || []];
    index === -1 ? codes.push({ id: action.priceCodeId, count: action.count }) :
      action.count === 0
        ? codes[index] = { id: action.priceCodeId, count: 0 }
        : codes[index] = { id: action.priceCodeId, count: action.count };
    // New count
    const totalSelectedTicketCount = sumBy(codes, 'count');
    return Object.assign(newState, { selectedPriceCodes: codes, totalSelectedTicketCount });
  },
  [UserStateTypes.TOGGLE_PRICE_LEVEL]: (state: IUserState, action: { priceLevelId: number }) => {
    const newState = { ...state };
    const index = findIndex(state.selectedPriceLevels, plId => plId === action.priceLevelId);
    const levels = [...newState.selectedPriceLevels || []];
    index === -1 ? levels[0] = action.priceLevelId : levels[0] = null;
    return Object.assign(newState, { selectedPriceLevels: levels });
  },
  [UserStateTypes.RESET_SEARCH_CRITERIA]: (state: IUserState) => {
    const newState = { ...state };
    return Object.assign(newState, { selectedPriceLevels: [0], selectedPriceCodes: [], totalSelectedTicketCount: 0 });
  },
  [UserStateTypes.TOGGLE_PAYMENT_METHOD]: (state: IUserState, action: { method: PaymentMethods }) => {
    const newState = { ...state };
    const methods = [...newState.selectedPaymentMethods || []];
    methods[0] = action.method;
    return Object.assign(newState, { selectedPaymentMethods: methods });
  },
  [UserStateTypes.SET_SELECTED_EVENT_ID]: (state: IUserState, { id }: ISetSelectedEventId) => {
    const newState = { selectedEventId: parseInt(id) };
    return Object.assign({}, state, newState);
  },
  [UserStateTypes.SET_SELECTED_FLOW_TYPE]: (state: IUserState, { flowType }: ISetSelectedFlowType) => {
    const newState = { selectedFlowType: flowType };
    return Object.assign({}, state, newState);
  },
  [UserStateTypes.SET_SELECTED_OFFER_ID]: (state: IUserState, { id }: ISetSelectedOfferId) => {
    const newState = { selectedOfferId: id };
    return Object.assign({}, state, newState);
  },
  [UserStateTypes.LOGIN_BEGIN]: (state: IUserState) => {
    return Object.assign({ ...state }, { authenticatedMessage: null, isAuthenticating: true });
  },
  [UserStateTypes.LOGIN_SUCCESS]: (state: IUserState, { authToken, contextId, userId }: ILoginSuccess) => {
    return Object.assign({}, state, {
      authToken,
      authenticatedMessage: null,
      contextId,
      isAuthenticating: false,
      sessionInfo: undefined,
      userId,
    });
  },
  [UserStateTypes.LOGIN_FAILURE]: (state: IUserState, { errorMsg }: ILoginFailure) => {
    return Object.assign({}, state, {
      authToken: null,
      authenticatedMessage: errorMsg,
      isAuthenticating: false,
      userId: null,
    });
  },
  [UserStateTypes.LOGIN_RESOLUTION_SUCCESS]: (state: IUserState, args: ILoginResolutionUserSuccess) => {
    return Object.assign({}, state, {
      accessToken: args.accessToken,
      oboUserEmail: args.oboUserEmail,
      refreshToken: args.refreshToken,
    });
  },
  [UserStateTypes.LOGOUT]: () => {
    return Object.assign({}, initialState);
  },
  [UserStateTypes.CLOSE_NOTIFICATION]: (state: IUserState) => {
    return Object.assign({ ...state }, { authenticatedMessage: null });
  },
  [UserStateTypes.SET_SETTING]: (state: IUserState, { key, setting }: ISetSetting) => {
    const settings = { ...state.settings };
    settings[key] = setting;
    return Object.assign({}, state, { settings });
  },
  [UserStateTypes.STORE_SETTINGS]: (state: IUserState, { settings }: IStoreSettings) => {
    return Object.assign({}, state, { settings });
  },
  [UserStateTypes.CREATING_SESSION]: (state: IUserState) => {
    return Object.assign({}, state, { creatingSession: true });
  },
  [UserStateTypes.SET_SESSION]: (state: IUserState, { sessionExpiration, sessionId }: ISetSession) => {
    return Object.assign({}, state, { creatingSession: false, sessionExpiration, sessionId });
  },
  [CartingActionTypes.CARTING_END]: (state: IUserState, { payload }: CartingActions) => {
    const { sessionExpireAt } = payload as ICartingMessage;
    const { userId } = state;
    const newState: Partial<IUserState> = {};
    if (sessionExpireAt && userId) { newState.sessionExpiration = sessionExpireAt; }
    return Object.assign({}, state, newState);
  },
  [UserStateTypes.CLEAR_SESSION_INFO]: (state: IUserState, { failure }: UserStateActions) => {
    return Object.assign({}, state, { sessionInfo: failure ? null : undefined });
  },
  [UserStateTypes.GET_SESSION_INFO_SUCCESS]: (state: IUserState, { sessionInfo }: UserStateActions) => {
    return Object.assign({}, state , { sessionInfo });
  },
  [UserStateTypes.SET_OUTLETS]: (state: IUserState, { outlets }: ISetOutlets) => {
    const newOutlets: IOutlet[] = outlets.filter(outlet =>
      AllowedOutletTypes.some(allowedType => outlet.Type === allowedType));
    return Object.assign({}, state, { outlets: newOutlets });
  },
  [UserStateTypes.SET_WORKSTATION]: (state: IUserState, { workstation: data }: ISetWorkstation) => {
    const expireAt = getExpiration('general');
    const workstation: IExpiration<IWorkStation> = { data, expireAt };
    return Object.assign({}, state, { workstation });
  },
  [UserStateTypes.GET_WORKSTATION_BEGIN]: (state: IUserState) => {
    const expireAt = getExpiration('general');
    const workstation: IExpiration<IWorkStation> = { expireAt };
    return Object.assign({}, state, { workstation });
  },
};

export default function reducer(
  state: IUserState = initialState,
  action: UserStateActions,
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state; // @todo fix any crutch here.
}
