import Offer from '../../common/Offer/Offer';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import Product from '../../common/Product/Product';
import OfferGroup from '../../common/Offer/OfferGroup';
import { FlowType } from '../../common/Offer/FlowType';
import { IOffer } from '../../api/types/eventtool/responseTypes';
import { OfferType } from '../../common/Offer/OfferType';
import { getExpiration } from '../../utils/common';
import { IAvailableOffers, IOfferState } from '../../types/offerTypes';
import { OFFER_SHELF_LIFE } from '../offersReducer';
import { IOnsaleInformation } from '../../api/types/unifiedapi/responseTypes';

/**
 * Adds an offer to the availableOffers in the offer store.
 *
 * @param data
 * @param eventId
 */
export const addOffer = (data: IOffer, eventId: number): Offer => {
  const offer: Offer = new Offer(
    data.Id,
    data.LookupId,
    eventId,
    [],
    data.OfferType,
    data.IsMMCEnabled,
    data.IsMmc3D,
    data.OfferName,
    data.Description,
    data.OnSaleDate,
    data.OffSaleDate,
    [],
    data.MmcMapId);
  offer.seatCount = data.SeatCount;
  offer.isMMCEnabled = data.IsMMCEnabled;
  if (data.MmcMapId) {
    if (data.IsMMCEnabled) {
      offer.flowType = FlowType.PICK_A_SEAT_3D;
    } else if (data.AllowPublicSeatChange) {
      offer.flowType = FlowType.PICK_A_SEAT_2D;
    }
  } else {
    offer.flowType = FlowType.BEST_AVAILABLE;
  }
  return offer;
};

/**
 * Merges in the additional offer data to the offers within availableOffers in offer store.
 *
 * @param offer
 * @param onsaleInformation
 */
export const addOfferDetails = (offer: Offer, data: IOnsaleInformation) => {
  const offerMinQty = get(data, 'onsaleInformation.ticketSearchCriteria.min', 0);
  const offerMaxQty = get(data, 'onsaleInformation.ticketSearchCriteria.max', 0);
  const isMmc3D = get(data, 'onsaleInformation.isMmc3D', false);
  offer.maxQty = parseInt(offerMaxQty);
  offer.minQty = parseInt(offerMinQty);
  // Flag does not come back. Resale support was cancelled for WBO
  // Re-open PR https://github.com/aegaxs/unified-api/pull/1227 to add it to uAPI
  offer.isResaleEnabled = get(data, 'onsaleInformation.isOfferResaleEnabled', false);
  offer.isMmc3D = isMmc3D;

  const offerGroups = data.onsaleInformation.groups;
  if (offerGroups && offerGroups.length > 0) {
    offer.offerGroups = [];
    forEach(offerGroups, (offerGroupData) => {
      const products: Product[] = [];
      forEach(offerGroupData.products, (productData) => {
        products.push(
          new Product(
            parseInt(productData.id),
            productData.description,
            productData.eventID,
            productData.required,
            productData.zoneDescription,
          ),
        );
      });
      const offerGroup = new OfferGroup(
        parseInt(offerGroupData.offerGroupID),
        offerGroupData.name,
        [],
        [],
        parseInt(get(offerGroupData, 'ticketSearchCriteria.max', 0)),
        parseInt(get(offerGroupData, 'ticketSearchCriteria.min', 0)),
        parseInt(get(offerGroupData, 'ticketSearchCriteria.step', 0)),
        offer,
        products);
      offerGroup.venueMapImage = offerGroupData.venueMapImage;
      offer.offerGroups.push(offerGroup);
    });
  }
  return offer;
};

/**
 * Formats Offer data for storage in state
 * @param data - response from fetch_event_offers
 * @param state - initialState in offer reducer
 * @param eventId
 */

export const formatOfferData = (data: any, state: IOfferState, eventId: number) => {
  const availableOffers: IAvailableOffers = { ...state.availableOffers };
  if (data && data.Offers && data.Offers.length > 0) {
    forEach(data.Offers, (o) => {
      const offer: Offer = addOffer(o, eventId);
      if (offer && (offer.offerType === OfferType.SINGLE || offer.offerType === OfferType.BUNDLE)) {
        availableOffers[offer.id.toString()] = {
          data: offer,
          expireAt: getExpiration(OFFER_SHELF_LIFE),
        };
      }
    });
  }
  return availableOffers;
};
