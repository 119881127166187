import * as React from 'react';
import { animated, config, useSpring } from 'react-spring';
import classnames from 'classnames';
import { AXSNotification, AXSBlock } from '@axs/axs-ui';
import styled from 'styled-components';

const Wrapper = styled(AXSBlock)<any>`
  left: 0;
  overflow: hidden;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 20;
`;

const Notification = ({ handleClose, location, active, errorMessage }: any) => {

  const animationProps = useSpring({
    config: config.default,
    from: { transform: 'translate3d(0, -64px, 0)', transition: 'all 0s' },
    transform: 'translate3d(0, 0, 0)',
  });

  return (
      <Wrapper
        location={ location }
        className={ classnames([{ 'is-active': active }]) }
      >
        <animated.div style={ animationProps }>
        <AXSNotification
          handleClose={ handleClose }
          type="danger"
        >{ errorMessage }
        </AXSNotification>
        </animated.div>
      </Wrapper>
  );
};

export default Notification;
