import { AnyAction } from 'redux';
import { FlowType } from '../common/Offer/FlowType';
import { IOutlet } from '../api/types/eventtool/responseTypes';
import { IExpiration } from './common';

export interface ISelectedPriceCodes {
  count: number;
  id: number;
}

export interface ISetting {
  label?: string;
  value?: string;
}

export interface ISettings {
  cartTimer: ISetting;
  location: ISetting;
  mode: ISetting;
  event: ISetting;
  guestCheckoutMode: ISetting;
  offer: ISetting;
  outlet: ISetting;
  printer: ISetting;
  receipt: ISetting;
  stock: ISetting;
  type: ISetting;
  topCard: ISetting;
}

export interface ISessionItem {
  mopLabel: string;
  amount: number;
  count?: number;
}

export interface ISessionItemFromAPI {
  MopLabel: string;
  Amount: number;
  Count?: number;
}

export interface ISessionInfo {
  items: ISessionItem[];
  grandTotal: number;
  totalCount: number;
}

export interface ILoginUser {
  Meta: {
    Context: string;
  };
  Password: string;
  UserName: string;
}

export interface IPrinter {
  displayName: string;
  name: string;
}

export interface IStockOrType {
  id: number;
  name: string;
}

export interface IWorkStation {
  machineName: string;
  printers: IPrinter[];
  printerStocks: IStockOrType[];
  printerTypes: IStockOrType[];
}

export interface IUserState {
  accessToken: string | null;
  authToken: string | null;
  authenticatedMessage: string | null;
  contextId: number | null;
  creatingSession: boolean;
  isAuthenticating: boolean;
  oboUserEmail: string | null;
  outlets?: IOutlet[];
  refreshToken: string | null;
  selectedEventId: number | null;
  selectedFlowType: FlowType;
  selectedOfferId: number | null;
  selectedPaymentMethods?: PaymentMethods[];
  selectedPriceCodes: ISelectedPriceCodes[];
  selectedPriceLevels?: any[];
  sessionExpiration: number | null;
  sessionId: string | null;
  sessionInfo?: ISessionInfo | null;
  settings: ISettings;
  totalSelectedTicketCount: number;
  userId: string | null;
  workstation: IExpiration<IWorkStation> | null;
}

export enum PaymentMethods {
  CASH = 'Cash',
  CREDIT = 'Credit',
}

export enum UserStateTypes {
  CLEAR_SESSION_INFO = 'CLEAR_SESSION_INFO',
  CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION',
  CREATING_SESSION = 'CREATING_SESSION',
  EXTEND_SESSION = 'EXTEND_SESSION',
  GET_PRINTER_SETTINGS_BEGIN = 'GET_PRINTER_SETTINGS_BEGIN',
  GET_PRINTER_SETTINGS_FAILURE = 'GET_PRINTER_SETTINGS_FAILURE',
  GET_PRINTER_SETTINGS_SUCCESS = 'GET_PRINTER_SETTINGS_SUCCESS',
  GET_SESSION_INFO_BEGIN = 'GET_SESSION_INFO_BEGIN',
  GET_SESSION_INFO_SUCCESS = 'GET_SESSION_INFO_SUCCESS',
  GET_WORKSTATION_BEGIN = 'GET_WORKSTATION_BEGIN',
  LOGIN_BEGIN = 'LOGIN_BEGIN',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_RESOLUTION_SUCCESS = 'LOGIN_RESOLUTION_SUCCESS',
  LOGOUT = 'LOGOUT',
  LOGOUT_USER = 'LOGOUT_USER',
  RESET_SEARCH_CRITERIA = 'RESET_SEARCH_CRITERIA',
  RESET_WORKSTATION = 'RESET_WORKSTATION',
  SET_OUTLETS = 'SET_OUTLETS',
  SET_SELECTED_EVENT_ID = 'SET_SELECTED_EVENT_ID',
  SET_SELECTED_FLOW_TYPE = 'SET_SELECTED_FLOW_TYPE',
  SET_SELECTED_OFFER_ID = 'SET_SELECTED_OFFER_ID',
  SET_SESSION = 'SET_SESSION',
  SET_SETTING = 'SET_SETTING',
  SET_WORKSTATION = 'SET_WORKSTATION',
  STORE_SETTINGS = 'STORE_SETTINGS',
  TOGGLE_PAYMENT_METHOD = 'TOGGLE_PAYMENT_METHOD',
  TOGGLE_PRICE_CODE = 'TOGGLE_PRICE_CODE',
  TOGGLE_PRICE_LEVEL = 'TOGGLE_PRICE_LEVEL',
}

export interface ICloseNotification extends AnyAction {
  type: UserStateTypes.CLOSE_NOTIFICATION;
}

export interface ISetSelectedPriceCodes extends AnyAction {
  type: UserStateTypes.TOGGLE_PRICE_CODE; priceCodeId: number; count: number;
}

export interface ITogglePriceLevel extends AnyAction {
  type: UserStateTypes.TOGGLE_PRICE_LEVEL; priceLevelId: number;
}

export interface IResetSearchCriteria extends AnyAction {
  type: UserStateTypes.RESET_SEARCH_CRITERIA;
}

export interface ITogglePaymentMethod extends AnyAction {
  type: UserStateTypes.TOGGLE_PAYMENT_METHOD; method: PaymentMethods;
}

export interface ISetSelectedEventId extends AnyAction {
  type: UserStateTypes.SET_SELECTED_EVENT_ID;
}

export interface ISetSelectedFlowType extends AnyAction {
  type: UserStateTypes.SET_SELECTED_FLOW_TYPE;
}

export interface ISetSelectedOfferId extends AnyAction {
  type: UserStateTypes.SET_SELECTED_OFFER_ID;
}

export interface ILoginBegin extends AnyAction {
  type: UserStateTypes.LOGIN_BEGIN;
}

export interface ILoginSuccess extends AnyAction {
  type: UserStateTypes.LOGIN_SUCCESS; authToken: string; contextId: number; userId: string;
}

export interface ILoginFailure extends AnyAction {
  type: UserStateTypes.LOGIN_FAILURE; errorMsg: string;
}

export interface ILoginResolutionUserSuccess extends AnyAction {
  accessToken: string;
  oboUserEmail: string;
  refreshToken: string;
  type: UserStateTypes.LOGIN_RESOLUTION_SUCCESS;
}

export interface ILogout extends AnyAction {
  type: UserStateTypes.LOGOUT;
}

export interface ISetSetting extends AnyAction {
  key: string; setting: ISetting; type: UserStateTypes.SET_SETTING;
}

export interface IStoreSettings extends AnyAction {
  settings: ISettings;
  type: UserStateTypes.STORE_SETTINGS;
}

export interface ICreatingSession extends AnyAction {
  type: UserStateTypes.CREATING_SESSION;
}

export interface ISetSession extends AnyAction {
  sessionId: string | null;
  sessionExpiration?: number;
  type: UserStateTypes.SET_SESSION;
}

export interface IExtendSession extends AnyAction {
  type: UserStateTypes.EXTEND_SESSION;
}

export interface IGetSessionInfoBegin extends AnyAction {
  type: UserStateTypes.GET_SESSION_INFO_BEGIN;
}

export interface IGetSessionInfoSuccess extends AnyAction {
  type: UserStateTypes.GET_SESSION_INFO_SUCCESS; sessionInfo: ISessionInfo;
}

export interface IClearSessionInfo extends AnyAction {
  failure: boolean;
  type: UserStateTypes.CLEAR_SESSION_INFO;
}

export interface ISetOutlets extends AnyAction {
  outlets: IOutlet[];
  type: UserStateTypes.SET_OUTLETS;
}

export interface ISetWorkstation extends AnyAction {
  expireAt?: number;
  type: UserStateTypes.SET_WORKSTATION;
  workstation?: IWorkStation;
}

export interface IGetWorkstationBegin extends AnyAction {
  type: UserStateTypes.GET_WORKSTATION_BEGIN;
}

export type UserStateActions =
  IClearSessionInfo |
  ICloseNotification |
  IExtendSession |
  IGetSessionInfoBegin |
  IGetSessionInfoSuccess |
  IGetWorkstationBegin |
  ILoginBegin |
  ILoginFailure |
  ILoginSuccess |
  ILogout |
  IResetSearchCriteria |
  ISetOutlets |
  ISetSelectedEventId |
  ISetSelectedFlowType |
  ISetSelectedOfferId |
  ISetSelectedPriceCodes |
  ISetSession |
  ISetSetting |
  ISetWorkstation |
  IStoreSettings |
  ITogglePaymentMethod |
  ITogglePriceLevel;
