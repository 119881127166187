import { PricingMode } from './PricingMode';

class PriceCode {

  constructor(
    public id: number,
    public description: string,
    public maxQty: number,
    public minQty: number,
    public sortOrder: number,
    public pricingMode: PricingMode,
  ) {
  }

  public toString() {
    return `PriceCode: ${this.id} - ${this.description}`;
  }
}

export default PriceCode;
