import {
  VTCustomer,
  CustomerBaseGlobalSearch,
  CustomerAddress,
  CustomerCreditCard,
  CustomerOrder,
  CEvent,
  CVenue,
} from '../../common/Customer';

import {
  ICustomerAddress,
  ICustomerCreditCard,
  ICustomerEvent,
  ICustomerFullInfo,
  ICustomerOrder,
  IGlobalSearchCustomerInfo,
  ICustomerVenue,
} from '../../api/types/fanAccount/mockResponseTypes';

const mapCustomerAddress = (addressData: ICustomerAddress): CustomerAddress => {
  return new CustomerAddress(
    addressData.id,
    addressData.firstName,
    addressData.lastName,
    addressData.address1,
    addressData.address2,
    addressData.address3,
    addressData.city,
    addressData.zip,
    addressData.country,
    addressData.state,
  );
};

export const mapCustomerCreditCard = (creditCardData: ICustomerCreditCard): CustomerCreditCard => {
  return new CustomerCreditCard(
    mapCustomerAddress(creditCardData.billingAddress),
    creditCardData.cardNumber,
    creditCardData.expireMonth,
    creditCardData.expireYear,
    creditCardData.nameOnCard,
    creditCardData.paymentID,
    creditCardData.code,
    creditCardData.source,
  );
};

export const mapCustomerBaseGlobalSearch = (customerData: IGlobalSearchCustomerInfo): CustomerBaseGlobalSearch => {
  return new CustomerBaseGlobalSearch(
    customerData.vxId,
    customerData.contextId,
    customerData.accountNumber,
    customerData.firstName,
    customerData.lastName,
    customerData.email,
    customerData.addressLine1,
    customerData.city,
    customerData.state,
    customerData.country,
    customerData.zip,
    customerData.sinceDate,
    customerData.phone1,
    customerData.shipTo,
    customerData.suffix,
    customerData.prefix,
    customerData.phone1Type,
    customerData.phone2,
    customerData.phone3,
    customerData.phone3Type,
    customerData.contextAlias,
    customerData.alternateName,
    customerData.addressLine2,
  );
};


export const mapCustomerOrder = (orderData: ICustomerOrder): CustomerOrder => {
  return new CustomerOrder(
    orderData.contextId,
    orderData.eventName,
    orderData.orderId,
    orderData.orderDate,
    orderData.orderStatus,
    orderData.orderStatusText,
    orderData.paymentStatus,
    orderData.price,
  );
};

export const mapCustomerVenue = (venueData: ICustomerVenue): CVenue => {
  return new CVenue(
    venueData.id,
    venueData.name ? venueData.name : venueData.title,
    venueData.address ? mapCustomerAddress(venueData.address) : undefined,
    venueData.homePageUrl,
    venueData.notes,
  );
};

export const mapCustomerEvent = (eventData: ICustomerEvent): CEvent => {
  return new CEvent(
    eventData.id,
    eventData.text,
    eventData.time,
    mapCustomerVenue(eventData.venue),
    eventData.offers,
    eventData.expires,
    eventData.orderNo,
    eventData.image,
  );
};

export const mapCustomer = (customerData: ICustomerFullInfo): VTCustomer => {
  return new VTCustomer(
    customerData.id,
    customerData.firstName,
    customerData.lastName,
    customerData.email,
    customerData.primaryPhoneNumber,
    mapCustomerAddress(customerData.primaryAddress),
    customerData.dateOfBirth,
    customerData.driverLicense,
    customerData.additionalPhoneNumbers,
    customerData.backOfficeAddresses.map(mapCustomerAddress),
    customerData.flashAddresses.map(mapCustomerAddress),
    customerData.createdDate,
    customerData.paymentMethods.map(mapCustomerCreditCard),
    customerData.contextId,
    customerData.orders.map(mapCustomerOrder),
    customerData.events.map(mapCustomerEvent),
  );
};
