import {
  IGetOrderFailure,
  IGetOrderSuccess,
  IGetSingleOrderFailure,
  IGetSingleOrderSuccess,
  IOrderState,
  ISetExistingOrder,
  OrderStateActions,
  OrderStateTypes,
} from '../types/orderTypes';
import {
  appendCustomerEmail,
  appendParents,
  appendFlashTicket,
  sortPaymentActivity,
} from './utils/accountMapper';

export const initialState: IOrderState = {
  error: '',
  isFetchingOrder: false,
  order: null,
  orderDetails: {
    ContextDescription: '',
    ContextId: 0,
    Customer: {
      AccountNumber: '',
      CreditCards: [],
      Email: '',
      FirstName: '',
      FullName: '',
      LastName: '',
      PrimaryAddress: {
        City: '',
        Line1: '',
        Line2: '',
        State: '',
        Zip: '',
      },
    },
    Date: '',
    ETicketEmail: '',
    FeeTotal: 0,
    Paid: 0,
    PaymentHistories:[],
    SeatItems: [],
    Total: 0,
  },
};

const ACTION_HANDLERS = {
  [OrderStateTypes.GET_ORDER_BEGIN]: (state: IOrderState) => {
    return Object.assign({}, state, { isFetchingOrder: true });
  },
  [OrderStateTypes.GET_ORDER_SUCCESS]: (state: IOrderState, action: IGetOrderSuccess) => {
    const newState = {
      isFetchingOrder: false,
      orderDetails: action.orderDetails,
    };
    return Object.assign({}, state, newState);
  },
  [OrderStateTypes.GET_ORDER_FAILURE]: (state: IOrderState, action: IGetOrderFailure) => {
    const newState = {
      error: action.error,
      isFetchingOrder: false,
    };
    return Object.assign({}, state, newState);
  },
  [OrderStateTypes.RESET_ORDER_DETAILS]: () => {
    return Object.assign({}, initialState);
  },

  [OrderStateTypes.GET_SINGLE_ORDER_BEGIN]: (state: IOrderState) => {
    const newData = {
      error: initialState.error,
      isFetchingOrder: true,
      order: null,
    };
    return { ...state, ...newData };
  },
  [OrderStateTypes.GET_SINGLE_ORDER_SUCCESS]: (
    state: IOrderState,
    { contextId, customerEmail, primaryOrder, flashTickets }: IGetSingleOrderSuccess,
  ) => {
    appendParents([primaryOrder]);
    appendFlashTicket(contextId, [primaryOrder], flashTickets);
    appendCustomerEmail([primaryOrder], customerEmail);
    sortPaymentActivity([primaryOrder]);
    const newData = {
      isFetchingOrder: false,
      order: primaryOrder,
    };
    return { ...state, ...newData };
  },
  [OrderStateTypes.GET_SINGLE_ORDER_FAILURE]: (state: IOrderState, action: IGetSingleOrderFailure) => {
    const newData = {
      error: action.error,
      isFetchingOrder: false,
      order: null,
    };
    return { ...state, ...newData };
  },
  [OrderStateTypes.SET_EXISTING_ORDER]: (
    state: IOrderState, { customerEmail, primaryOrder }: ISetExistingOrder,
  ) => ({ ...state, order: primaryOrder }),
};

export default function reducer(
  state: IOrderState = initialState,
  action: OrderStateActions,
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state;
}
