class Address {

  constructor(
      public address1: string,
      public address2: string = '',
      public address3: string = '',
      public city: string,
      public zip: string,
      public country: string,
      public state?: string,
    ) {
  }

  public toString() {
    const { address1, address2, address3, city, state, zip, country } = this;
    return `Address: ${address1} ${address2} ${address3} ${city} ${state} ${zip} ${country}`;
  }
}

export default Address;
