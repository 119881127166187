import { AnyAction } from 'redux';
import { IOrderDetails } from '../common/OrderDetail';
import { IFlashTickets, IPrimaryOrder } from '../api/types/fanAccount/responseTypes';

export interface IOrderState {
  order: IPrimaryOrder | null;
  orderDetails?: IOrderDetails;
  isFetchingOrder: boolean;
  error: string;
}

export enum OrderStateTypes {
  GET_SINGLE_ORDER_BEGIN = 'GET_SINGLE_ORDER_BEGIN',
  GET_SINGLE_ORDER_SUCCESS = 'GET_SINGLE_ORDER_SUCCESS',
  GET_SINGLE_ORDER_FAILURE = 'GET_SINGLE_ORDER_FAILURE',
  GET_ORDER_BEGIN = 'GET_ORDER_BEGIN',
  GET_ORDER_FAILURE = 'GET_ORDER_FAILURE',
  GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS',
  RESET_ORDER_DETAILS = 'RESET_ORDER_DETAILS',
  SET_EXISTING_ORDER = 'SET_EXISTING_ORDER',
}

export interface IGetSingleOrderBegin extends AnyAction {
  type: OrderStateTypes.GET_SINGLE_ORDER_BEGIN;
}

export interface IGetSingleOrderSuccess extends AnyAction {
  type: OrderStateTypes.GET_SINGLE_ORDER_SUCCESS;
  contextId: number;
  customerEmail: string;
  primaryOrder: IPrimaryOrder;
  flashTickets: IFlashTickets[] | null;
}

export interface IGetSingleOrderFailure extends AnyAction {
  type: OrderStateTypes.GET_SINGLE_ORDER_FAILURE;
}

export interface IGetOrderBegin extends AnyAction {
  type: OrderStateTypes.GET_ORDER_BEGIN;
}

export interface IGetOrderFailure extends AnyAction {
  type: OrderStateTypes.GET_ORDER_FAILURE;
}

export interface IGetOrderSuccess extends AnyAction {
  type: OrderStateTypes.GET_ORDER_SUCCESS;
}

export interface IResetOrderDetails extends AnyAction {
  type: OrderStateTypes.RESET_ORDER_DETAILS;
}

export interface ISetExistingOrder extends AnyAction {
  type: OrderStateTypes.SET_EXISTING_ORDER;
  customerEmail: string;
  primaryOrder: IPrimaryOrder;
}

export type OrderStateActions =
  IGetOrderBegin |
  IGetOrderFailure |
  IGetOrderSuccess |
  IResetOrderDetails |
  IGetSingleOrderBegin |
  IGetSingleOrderSuccess |
  IGetSingleOrderFailure;
