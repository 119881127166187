import {
  IOfferState,
  OfferStateActions,
  OfferStateTypes,
  IFetchEventOffersSuccess,
  IUpdateOfferSeatCounts,
  IAvailableOffers,
  IGetOfferSuccess,
} from '../types/offerTypes';
import { clone, forEach, get } from 'lodash';
import { addOfferDetails, formatOfferData } from './utils/offerUtils';
import {  isExpired } from '../utils/common';

export const OFFER_SHELF_LIFE = 'general';

export const initialState: IOfferState = {
  availableOffers: {},
  fetchingDefaultOffers: false,
  fetchingMissingOffers: false,
  fetchingOffer: false,
  fetchingOffers: false,
  filterPanelActive: false,
  mapFiltersActive: false,
  precartPanelActive: false,
};

const ACTION_HANDLERS = {
  [OfferStateTypes.FETCH_EVENT_OFFERS_BEGIN]: (state: IOfferState) => {
    return Object.assign({ ...state }, { fetchingOffers: true });
  },
  [OfferStateTypes.FETCH_EVENT_OFFERS_FAILURE]: (state: IOfferState) => {
    return Object.assign({ ...state }, { fetchingOffers: false });
  },
  // tslint:disable-next-line: max-line-length
  [OfferStateTypes.FETCH_EVENT_OFFERS_SUCCESS]: (state: IOfferState, { data, eventId }: IFetchEventOffersSuccess) => {
    const availableOffers = formatOfferData(data, state, eventId);
    return Object.assign({}, state, { availableOffers, fetchingOffers: false });
  },
  // GET OFFER HANDLERS
  [OfferStateTypes.GET_OFFER_BEGIN]: (state: IOfferState) => {
    const newState = { ...state };
    return Object.assign(newState, { fetchingOfferPricing: true });
  },
  [OfferStateTypes.GET_OFFER_SUCCESS]: (state: IOfferState, { data }: IGetOfferSuccess) => {
    // Find the offer
    const offerId: string = data.onsaleInformation.meta.offerID;
    const availableOffers = { ...state.availableOffers };
    const availableOffer = get(availableOffers, offerId);
    if (availableOffer) {
      const offer = clone(availableOffer.data!);
      const returnedOffer = addOfferDetails(offer, data);
      availableOffers[offerId] = {
        data: returnedOffer,
        expireAt: availableOffer.expireAt,
      };
    }
    return Object.assign({}, state, { availableOffers, fetchingOffer: false });
  },
  [OfferStateTypes.GET_OFFER_FAILURE]: (state: IOfferState) => {
    const newState = { ...state };
    return Object.assign(newState, { fetchingOffer: false });
  },
  [OfferStateTypes.LOAD_MISSING_OFFERS]: (state: IOfferState) => {
    return { ...state, fetchingMissingOffers: true };
  },
  [OfferStateTypes.LOAD_MISSING_OFFERS_COMPLETE]: (state: IOfferState) => {
    return { ...state, fetchingMissingOffers: false };
  },
  [OfferStateTypes.SHOW_FILTER_PANEL]: (state: IOfferState) => {
    const newState = { ...state };
    return Object.assign(newState, { filterPanelActive: true });
  },
  [OfferStateTypes.HIDE_FILTER_PANEL]: (state: IOfferState) => {
    const newState = { ...state };
    return Object.assign(newState, { filterPanelActive: false });
  },
  [OfferStateTypes.SHOW_PRECART_PANEL]: (state: IOfferState) => {
    const newState = { ...state };
    return Object.assign(newState, { precartPanelActive: true });
  },
  [OfferStateTypes.HIDE_PRECART_PANEL]: (state: IOfferState) => {
    const newState = { ...state };
    return Object.assign(newState, { precartPanelActive: false });
  },
  [OfferStateTypes.SHOW_MAP_FILTERS]: (state: IOfferState) => {
    const newState = { ...state };
    return Object.assign(newState, { mapFiltersActive: true });
  },
  [OfferStateTypes.HIDE_MAP_FILTERS]: (state: IOfferState) => {
    const newState = { ...state };
    return Object.assign(newState, { mapFiltersActive: false });
  },
  [OfferStateTypes.UPDATE_OFFER_SEAT_COUNTS]: (state: IOfferState, { seatCounts }: IUpdateOfferSeatCounts) => {
    const availableOffers = { ...state.availableOffers };
    seatCounts.forEach((seatCount) => {
      const offerId = seatCount.Id.toString();
      const availableOffer = get(availableOffers, offerId);
      if (availableOffer && availableOffer.data) {
        const updateOffer = clone(availableOffer.data);
        updateOffer.seatCount = seatCount.SeatCount;
        availableOffers[offerId] = {
          data: updateOffer,
          expireAt: availableOffer.expireAt,
        };
      }
    });
    return Object.assign({}, state, { availableOffers });
  },
  [OfferStateTypes.FETCH_DEFAULT_OFFERS_BEGIN]: (state: IOfferState) => {
    return Object.assign({}, state, { fetchingDefaultOffers: true });
  },
  [OfferStateTypes.FETCH_DEFAULT_OFFERS_END]: (state: IOfferState) => {
    return Object.assign({}, state, { fetchingDefaultOffers: false });
  },
  [OfferStateTypes.REMOVE_EXPIRED_OFFERS]: (state: IOfferState) => {
    const newAvailableOffers: IAvailableOffers = {};
    forEach(state.availableOffers, (availableOffer, key) => {
      if (!isExpired(availableOffer)) {
        newAvailableOffers[key] = availableOffer;
      }
    });
    return Object.assign({}, state, { availableOffers: newAvailableOffers });
  },
};

export default function reducer(
  state: IOfferState = initialState,
  action: OfferStateActions,
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state; // @todo fix any crutch here.
}
