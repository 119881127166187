import React from 'react';
import { flatten, get } from 'lodash';
import {
  CARTING_REDUCER_KEY,
  EVENTS_REDUCER_KEY,
  INVENTORY_REDUCER_KEY,
  OFFERS_REDUCER_KEY,
} from '../../../../../store/store.types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { AXSBlock, AXSButton, AXSFlex, AXSIcon, AXSLoader, AXSPlaceholder } from '@axs/axs-ui';
import { closeCartDrawer, removeTickets } from '../../actions/cartingActions';
import { IProductIdentity, ISeatSelection } from '../../types/commonTypes';
import { CartDrawerTable, CartDrawerWrapper, Title, Total, OfferCell, CartItemsPane } from './style';
import {
  cartHasItems,
  getGroupedCartItems,
  getEvent,
  getProductIdentity,
  getQuantityInCart,
  IGroupedCartItems,
  getCurrency,
  getDefaultProduct,
} from '../../helpers';
import { ICart, ISelection } from '../../types/apiResponseTypes';
import { EventDetail } from '../../../../../components/EventDetail';
import { formatPrice } from '../../../../../common/utils/formatPrice';
import OfferPriceChart from '../../../../../common/PriceChart/OfferPriceChart';
import { SeatType } from '../../../../../common/Seat/SeatType';
import { handleCheckout, showFilterPanel } from '../../../../../actions/offersActions';
import { pages } from '../../../../../constants/routes';
import { IAvailableEvents } from '../../../../../types/eventsTypes';
import { IAvailableOffers } from '../../../../../types/offerTypes';
import { OfferType } from '../../../../../common/Offer/OfferType';
import OutsideClickHandler from 'react-outside-click-handler';

interface ICartDrawer {
  availableEvents: IAvailableEvents;
  availableOffers: IAvailableOffers;
  cart?: ICart;
  cartDrawerOpened: boolean;
  carting: boolean;
  closeCartDrawer: () => void;
  handleCheckout: (cart: ICart) => void;
  offerPrices: OfferPriceChart[];
  push: (location: string) => void;
  removeTickets: (product: IProductIdentity, items?: ISeatSelection[], quantity?: number) => void;
  t?: any;
  showFilterPanel: () => void;
}

class CartDrawer extends React.Component<ICartDrawer> {

  constructor(props: ICartDrawer, context: any) {
    super(props, context);
    this.handleChangeTicket = this.handleChangeTicket.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
  }

  public componentDidUpdate(prevProps: ICartDrawer) {
    const { cart, closeCartDrawer } = this.props;
    if (cart && !cart.selections) {
      closeCartDrawer();
    }
  }

  public handleChangeTicket(offerLocation: string): void {
    const { closeCartDrawer, push, showFilterPanel } = this.props;
    closeCartDrawer();
    push(offerLocation);
    showFilterPanel();
  }

  public handleRemoveOffer(product: IProductIdentity): void {
    const { removeTickets } = this.props;
    removeTickets(product);
  }

  public handleCheckout(): void {
    const { closeCartDrawer, handleCheckout, cart } = this.props;
    closeCartDrawer();
    if (cart) {
      handleCheckout(cart);
    }
  }

  public getGroupedCartItemRow(product: IProductIdentity, cartedItem: IGroupedCartItems,
                               cartedOfferCell: JSX.Element | null) {
    const { offerPrices, removeTickets } = this.props;
    const { price, quantity, rowLabel, seatLabel, seats, sectionLabel, typeLabel } = cartedItem;
    const isGA = get(seats, '0.rowID') === '0';
    const currency = getCurrency(offerPrices, product);
    const priceDisplay = formatPrice(price, currency);
    const handleRemoveTickets = () => (isGA ? removeTickets(product, undefined, quantity) :
      removeTickets(product, seats));
    return (
      <tr key={ `${typeLabel}-${sectionLabel}-${rowLabel}-${seatLabel}` }>
        { cartedOfferCell && (
        <OfferCell rowSpan={ 999 } className="pl-0">
          <AXSBlock color="axsui.components.table.color">{ cartedOfferCell }</AXSBlock>
        </OfferCell>
        ) }
        <td><AXSBlock color="axsui.components.table.color">{ sectionLabel }</AXSBlock></td>
        <td><AXSBlock color="axsui.components.table.color">{ rowLabel }</AXSBlock></td>
        <td><AXSBlock color="axsui.components.table.color">{ seatLabel }</AXSBlock></td>
        <td><AXSBlock color="axsui.components.table.color">{ typeLabel }</AXSBlock></td>
        <td style={ { whiteSpace: 'nowrap' } }>
          <AXSBlock color="axsui.components.table.color">
            { quantity }
            <AXSIcon
              color="#bbc7dc"
              height="15px"
              name="cancel"
              onClick={ (event: React.SyntheticEvent) => {
                handleRemoveTickets();
                event.stopPropagation();
              } }
              style={ { marginLeft: 5 } }
              width="15px"
            />
          </AXSBlock>
        </td>
        <td className="text-right">
          <AXSBlock color="axsui.components.table.color">{ priceDisplay }</AXSBlock>
        </td>
      </tr>
    );
  }

  public getCartedOfferInRows(cartSelection: ISelection) {
    const { availableEvents, availableOffers } = this.props;
    const itemRows: JSX.Element[] = [];
    const cartedProduct = getProductIdentity(cartSelection);
    const cartedItems = getGroupedCartItems(cartSelection);
    const availableOffer = get(availableOffers, cartedProduct.offerID);
    const offer = availableOffer && availableOffer.data;
    const isBundle = offer !== undefined && offer.offerType === OfferType.BUNDLE;
    // eventID would be -1 in a case of Bundle Offer
    const eventId = isBundle && offer ? offer.getFirstEventId() : cartSelection.eventID;
    const cartedEvent = getEvent(eventId, availableEvents);
    const { t } = this.props;
    let cartedOfferCell: JSX.Element | null = null;
    if (cartedEvent) {
      const offerLocation = pages.offer(cartedEvent.id, cartedProduct.offerID);
      cartedOfferCell = (
        <AXSBlock width="100%">
        <div>{ cartSelection.offerType === SeatType.FLASHSEATS ? cartedEvent.name
          : offer && offer.name }</div>
          <AXSButton
            borderless
            onClick={ () => this.handleChangeTicket(offerLocation) }
            color="axsui.text.link"
          >{ t('carting.addTickets') }
          </AXSButton>
          <AXSButton
            borderless
            onClick={ () => this.handleRemoveOffer(cartedProduct) }
            color="axsui.text.link"
          >{ t('carting.removeTicket') }
          </AXSButton>
        </AXSBlock>
      );
    } else {
      cartedOfferCell = (
        <div>
          <div />
        </div>
      );
    }

    cartedItems.forEach((item, index) => {
      itemRows.push(this.getGroupedCartItemRow(cartedProduct, item, index === 0 ? cartedOfferCell : null));
    });

    return (
      <div className="row" key={ `event-${ cartSelection.eventID }-${cartSelection.offerID}` }>
        <div className="col-12">{ cartedEvent ? <EventDetail event={ cartedEvent } variant="cart-drawer"  /> : (
          <AXSFlex alignItems="center">
            <AXSBlock height="30px">
              <AXSPlaceholder line width="75px" />
            </AXSBlock>
            <AXSBlock marginLeft="30px" height="30px">
              <AXSPlaceholder line width="200px"  />
              <AXSPlaceholder line width="100px"  />
            </AXSBlock>
          </AXSFlex>
        ) }</div>
        <div className="col-12">
          <AXSBlock paddingLeft="105px">
            <CartDrawerTable className="table">
              <thead>
              <tr key="headercard">
                <th scope="col">
                  <AXSBlock width="100%" color="axsui.components.table.headerColor">{ t('carting.offer') }</AXSBlock>
                </th>
                <th scope="col">
                  <AXSBlock width="60px" color="axsui.components.table.headerColor">{ t('carting.section') }</AXSBlock>
                </th>
                <th scope="col">
                  <AXSBlock width="60px" color="axsui.components.table.headerColor">{ t('carting.row') }</AXSBlock>
                </th>
                <th scope="col">
                  <AXSBlock width="60px" color="axsui.components.table.headerColor">{ t('carting.seat') }</AXSBlock>
                </th>
                <th scope="col">
                  <AXSBlock width="300px" color="axsui.components.table.headerColor">{ t('carting.type') }</AXSBlock>
                </th>
                <th scope="col">
                  <AXSBlock width="40px" color="axsui.components.table.headerColor">{ t('carting.quantity') }</AXSBlock>
                </th>
                <th className="text-right" scope="col">
                  <AXSBlock width="100px" color="axsui.components.table.headerColor">{ t('carting.price') }</AXSBlock>
                </th>
              </tr>
              </thead>
              <tbody>
              { itemRows }
              </tbody>
            </CartDrawerTable>
          </AXSBlock>
        </div>
      </div>
    );
  }

  public getCartDetailRows() {
    const { cart } = this.props;
    if (cart && cartHasItems(cart)) {
      return flatten(cart.selections.map(selection => this.getCartedOfferInRows(selection)));
    }
    return null;
  }

  public handleOutsideClick = () => {
    const { closeCartDrawer } = this.props;
    closeCartDrawer();
  }

  public render() {
    const { cart, cartDrawerOpened, carting, offerPrices, t } = this.props;

    const emptyCart = !cart || !cartHasItems(cart);
    if (!cartDrawerOpened || emptyCart) { return null; }
    const quantityInCart = getQuantityInCart(cart);
    const itemLabel = quantityInCart === 1 ? t('carting.itemReserved') : t('carting.itemsReserved');
    const cartStatus = !carting ?
      (<AXSBlock marginTop="10px">{ `(${quantityInCart} ${itemLabel})` }</AXSBlock>) :
      (<AXSLoader active className="float-left" />);
    let grandTotal: string = '';
    if (cart && !emptyCart) {
      const defaultProduct = getDefaultProduct(cart);
      const currency = getCurrency(offerPrices, defaultProduct);
      grandTotal = formatPrice(cart.grandTotal, currency);
    }

    const checkOutBlock = !emptyCart ? (
      <AXSFlex justifyContent="flex-end">
        <Total>{ grandTotal }</Total>
          <AXSButton
            color="#ffffff"
            backgroundColor="axsui.status.success.default"
            backgroundHover="axsui.status.success.hover"
            borderColor="axsui.status.success.default"
            borderColorHover="axsui.status.success.hover"
            onClick={ this.handleCheckout }
          >{ t('carting.checkout') }
          </AXSButton>
      </AXSFlex>
    ) : null;

    const cartDetailRows = this.getCartDetailRows();

    const cartDetails = !emptyCart ? (
      <CartItemsPane>
          { cartDetailRows }
      </CartItemsPane>
    ) : null;

    return (
      <CartDrawerWrapper>
        <OutsideClickHandler onOutsideClick={ this.handleOutsideClick }>
          <AXSBlock backgroundColor="axsui.ui.accent" padding="20px 0 40px">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <Title>{ t('carting.yourCart') }</Title>
                      { cartStatus }
                    </div>
                    <div className="col-6">
                      { checkOutBlock }
                    </div>
                  </div>
                  { cartDetails }
                </div>
              </div>
            </div>
          </AXSBlock>
        </OutsideClickHandler>
      </CartDrawerWrapper>
    );
  }
}

const mapStateToProps =  (state: any) => {
  return {
    availableEvents: state[EVENTS_REDUCER_KEY].availableEvents,
    availableOffers: state[OFFERS_REDUCER_KEY].availableOffers,
    cart: state[CARTING_REDUCER_KEY].cart,
    cartDrawerOpened: state[CARTING_REDUCER_KEY].cartDrawerOpened,
    carting: state[CARTING_REDUCER_KEY].carting,
    offerPrices: state[INVENTORY_REDUCER_KEY].offerPrices,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeCartDrawer: () => dispatch(closeCartDrawer()),
    handleCheckout: (cart: ICart) => dispatch(handleCheckout(cart)),
    push: (location: string) => dispatch(push(location)),
    removeTickets: (product: IProductIdentity, items?: ISeatSelection[], quantity?: number) =>
      dispatch(removeTickets(product, items, quantity)),
    showFilterPanel: () => dispatch(showFilterPanel()),
  };
};

const translated = withTranslation()(CartDrawer as any);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
