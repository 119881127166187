import familyImage from '../../assets/family.jpg';
import sportsImage from '../../assets/sports.jpg';
import musicImage from '../../assets/music.jpg';

/**
 * Converts hex colors to rbg. From https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
 *
 * @param hex
 */
export const hexToRgb = (hex: string) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hexString = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexString);
  return result ? {
    b: parseInt(result[3], 16),
    g: parseInt(result[2], 16),
    r: parseInt(result[1], 16),
  } : null;
};

/**
 * Theme utility which accepts a Hexadecimal color value and opacity and returns an rgba() value.
 *
 * @param hexColor
 * @param alpha
 */
export const opacify = (hexColor: string, alpha: string) => {
  const rgbColor = hexToRgb(hexColor);
  return rgbColor ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${alpha})` : hexColor;
};

/**
 * Returns an image for a given category.
 *
 * @param eventCategory
 */
export const getEventImageUrl = (eventCategory: string) => {
  switch (eventCategory) {
    case 'Family':
      return familyImage;
    case 'Sports':
      return sportsImage;
    case 'Music':
    case 'Concerts':
      return musicImage;
    default:
      return sportsImage;
  }
};
