import { ThunkAction } from 'redux-thunk';
import { AxiosError, AxiosResponse } from 'axios';
import { difference, flatMap, get, uniq } from 'lodash';
import { resetOrderDetails } from './orderActions';
import { setNotification } from './appActions';
import {
  fetchAvailableDeliveryMethods,
  fetchOffer,
} from '../api/ApiManager';
import {
  fetchFlashTickets,
  fetchOboUserProfile,
  fetchUserDetails,
  fetchUserOrders,
  fetchUserProfile,
  fetchUserPrimaryCreditCards,
  fetchUserSecondaryCreditCards,
  fetchUserToken,
  submitChangeDeliveryMethod,
  submitTransferTickets,
  fetchChangeDeliveryMethodStatus,
} from '../api/FanAccountApiManager';
import {
  AccountStateActions,
  AccountStateTypes,
  IGetOboUserProfileBegin,
  IGetOboUserProfileEnd,
  IGetUserProfileBegin,
  IGetUserProfileEnd,
  IGetUserDetailsBegin,
  IGetUserDetailsEnd,
  IGetUserOrdersFailure,
  IGetUserPrimaryCreditCardsBegin,
  IGetUserPrimaryCreditCardsEnd,
  IGetUserSecondaryCreditCardsBegin,
  IGetUserSecondaryCreditCardsEnd,
  IGetUserFlashTicketsBegin,
  IGetUserFlashTicketsEnd,
  IUserFlashTicketsFailure,
  IGetUserTokenBegin,
  IGetUserTokenEnd,
  IGetUserTokenRestore,
  IGetUserOrdersBegin,
  IGetUserOrdersEnd,
  IResetState,
  ITransferTicketsBegin,
  ITransferTicketsSuccess,
  ITransferTicketsError,
  IChangeDeliveryMethodBegin,
  IChangeDeliveryMethodUpdate,
  IChangeDeliveryMethodError,
  IChangeDeliveryMethodFinish,
  IGetAvailableDeliveryMethodsBegin,
  IGetAvailableDeliveryMethodsSuccess,
  IGetAvailableDeliveryMethodsFailure,
} from '../types/accountTypes';
import {
  IDeliveryMethod,
  IFlashTickets,
  IOnBehalfOfInfo,
  IUserDetails,
  IPrimaryUser,
  IPrimaryOrder,
  IPrimaryCreditCard,
  ISecondaryCreditCard,
  IChangeDeliveryMethodStatus,
} from '../api/types/fanAccount/responseTypes';
import { IFullStorageShape } from '../store/store.types';
import { ITransferTicketsRequest, IChangeDeliveryMethodRequest } from '../api/types/fanAccount/requestTypes';
import { resetSeatState } from './seatActions';
import { TICKET_STATES_IDS } from '../constants/ticketStatus';

export const getOboUserProfileBegin = (): IGetOboUserProfileBegin =>
  ({ type: AccountStateTypes.GET_OBO_USER_PROFILE_BEGIN });

export const getOboUserProfileEnd = (): IGetOboUserProfileEnd =>
  ({ type: AccountStateTypes.GET_OBO_USER_PROFILE_END });

export const getUserFlashTicketsBegin = (): IGetUserFlashTicketsBegin =>
  ({ type: AccountStateTypes.GET_USER_FLASH_TICKETS_BEGIN });

export const getUserFlashTicketsEnd = (flashTickets: IFlashTickets[] | null): IGetUserFlashTicketsEnd =>
  ({ type: AccountStateTypes.GET_USER_FLASH_TICKETS_END, flashTickets });

export const getUserFlashTicketsFailure = (errorMsg: AxiosError): IUserFlashTicketsFailure =>
  ({ type: AccountStateTypes.GET_USER_FLASH_TICKETS_FAILURE, errorMsg });

export const getUserTokenBegin = (contextId: number, email: string): IGetUserTokenBegin =>
  ({ type: AccountStateTypes.GET_USER_TOKEN_BEGIN, contextId, email });

export const getUserTokenEnd = (
  email: string,
  contextId: number,
  onBehalfOfInfo: IOnBehalfOfInfo,
): IGetUserTokenEnd =>
  ({ type: AccountStateTypes.GET_USER_TOKEN_END, email, contextId, onBehalfOfInfo });

export const getUserTokenRestore = (
  data: { [contextId: number]: { [email: string]: IOnBehalfOfInfo } },
): IGetUserTokenRestore =>
  ({ type: AccountStateTypes.GET_USER_TOKEN_RESTORE, data });

export const getUserProfileBegin = (contextId: number): IGetUserProfileBegin =>
  ({ type: AccountStateTypes.GET_USER_PROFILE_BEGIN, contextId });

export const getUserProfileEnd = (
  contextId: number,
  userProfile: IPrimaryUser,
): IGetUserProfileEnd =>
  ({ type: AccountStateTypes.GET_USER_PROFILE_END, contextId, userProfile });

export const getUserDetailsBegin = (): IGetUserDetailsBegin =>
  ({ type: AccountStateTypes.GET_USER_DETAILS_BEGIN });

export const getUserDetailsEnd = (
  contextId: number,
  userDetails: IUserDetails,
): IGetUserDetailsEnd =>
  ({ type: AccountStateTypes.GET_USER_DETAILS_END, contextId, userDetails });

export const getUserPrimaryCCBegin = (): IGetUserPrimaryCreditCardsBegin =>
  ({ type: AccountStateTypes.GET_USER_PRIMARY_CC_BEGIN });

export const getUserPrimaryCCEnd = (primaryCC: IPrimaryCreditCard[]): IGetUserPrimaryCreditCardsEnd =>
  ({ type: AccountStateTypes.GET_USER_PRIMARY_CC_END, primaryCC });

export const getUserSecondaryCCBegin = (): IGetUserSecondaryCreditCardsBegin =>
  ({ type: AccountStateTypes.GET_USER_SECONDARY_CC_BEGIN });

export const getUserSecondaryCCEnd = (secondaryCC: ISecondaryCreditCard[]): IGetUserSecondaryCreditCardsEnd =>
  ({ type: AccountStateTypes.GET_USER_SECONDARY_CC_END, secondaryCC });

export const getUserOrdersBegin = (): IGetUserOrdersBegin =>
  ({ type: AccountStateTypes.GET_USER_ORDERS_BEGIN });

export const getUserOrdersEnd = (
  contextId: number,
  customerEmail: string,
  primaryOrders: IPrimaryOrder[],
  flashOffers: any,
  flashTickets: IFlashTickets[] | null,
): IGetUserOrdersEnd =>
  ({ type: AccountStateTypes.GET_USER_ORDERS_END, contextId, customerEmail, primaryOrders, flashOffers, flashTickets });

export const getUserOrdersFailure = (errorMsg: AxiosError): IGetUserOrdersFailure =>
  ({ type: AccountStateTypes.GET_USER_ORDERS_FAILURE, errorMsg });

export const resetAccountState = (): IResetState =>
  ({ type: AccountStateTypes.RESET_STATE });

export const transferTicketsBegin = (): ITransferTicketsBegin =>
  ({ type: AccountStateTypes.TRANSFER_TICKETS_BEGIN });

export const transferTicketsSuccess = (): ITransferTicketsSuccess =>
  ({ type: AccountStateTypes.TRANSFER_TICKETS_SUCCESS });

export const transferTicketsError = (): ITransferTicketsError =>
  ({ type: AccountStateTypes.TRANSFER_TICKETS_ERROR });

export const changeDeliveryMethodBegin = (): IChangeDeliveryMethodBegin =>
  ({ type: AccountStateTypes.CHANGE_DELIVERY_METHOD_BEGIN });

export const changeDeliveryMethodUpdate = (status: IChangeDeliveryMethodStatus): IChangeDeliveryMethodUpdate =>
  ({ type: AccountStateTypes.CHANGE_DELIVERY_METHOD_UPDATE, status });

export const changeDeliveryMethodError = (error: AxiosError): IChangeDeliveryMethodError =>
  ({ type: AccountStateTypes.CHANGE_DELIVERY_METHOD_ERROR, error });

export const changeDeliveryMethodFinish = (): IChangeDeliveryMethodFinish =>
  ({ type: AccountStateTypes.CHANGE_DELIVERY_METHOD_FINISH });

export const getAvailableDeliveryMethodsBegin = (): IGetAvailableDeliveryMethodsBegin =>
  ({ type: AccountStateTypes.GET_AVAILABLE_DELIVERY_METHODS_BEGIN });

export const getAvailableDeliveryMethodsSuccess = (
  deliveryMethods: IDeliveryMethod[],
): IGetAvailableDeliveryMethodsSuccess =>
  ({ type: AccountStateTypes.GET_AVAILABLE_DELIVERY_METHODS_SUCCESS, deliveryMethods });

export const getAvailableDeliveryMethodsFailure = (error: AxiosError): IGetAvailableDeliveryMethodsFailure =>
  ({ type: AccountStateTypes.GET_AVAILABLE_DELIVERY_METHODS_FAILURE, error });

export const getOboAccountProfile = (
  contextId: number,
  accessToken: string,
): ThunkAction<void, IFullStorageShape, null, AccountStateActions> => (dispatch: any) => {
  dispatch(getOboUserProfileBegin());
  return fetchOboUserProfile(contextId, accessToken)
    .then((response: AxiosResponse<any>) => {
      dispatch(getOboUserProfileEnd());
    })
    .catch((error: AxiosError) => {
      dispatch(setNotification('danger', 'fanAccount.errors.accountProfile', true));
    });
};

export const getUserToken = (
  contextId: number,
  accessToken: string,
  email: string,
  successCallback: (token: string) => void,
  failureCallback: (error: AxiosError) => void,
): ThunkAction<void, IFullStorageShape, null, AccountStateActions> => (dispatch: any) => {
  dispatch(getUserTokenBegin(contextId, email));
  return fetchUserToken(contextId, accessToken, email)
    .then((response: AxiosResponse<IOnBehalfOfInfo>) => {
      const { token } = response.data;
      dispatch(getUserTokenEnd(email, contextId, response.data));
      if (successCallback) {
        successCallback(token);
      }
    })
    .catch((error: AxiosError) => {
      dispatch(setNotification('danger', 'Get token error', true));
      if (failureCallback) {
        failureCallback(error);
      }
    });
};

export const getUserProfile = (
  contextId: number,
  oboAccessToken: string,
  oboUserEmail: string,
  customerEmail: string,
): ThunkAction<void, IFullStorageShape, null, AccountStateActions> => (dispatch: any) => {
  dispatch(getUserProfileBegin(contextId));
  return fetchUserProfile(contextId, oboAccessToken, oboUserEmail, customerEmail)
    .then((response: AxiosResponse<IPrimaryUser>) => {
      const userProfile: IPrimaryUser = get(response, 'data');
      dispatch(getUserProfileEnd(contextId, userProfile));
    })
    .catch((error: AxiosError) => {
      dispatch(setNotification('danger', 'fanAccount.errors.lookUpEmail', true));
    });
};

export const getUserDetails = (
  contextId: number,
  oboAccessToken: string,
  oboUserEmail: string,
  customerEmail: string,
  customerAccessToken: string,
  region: number,
): ThunkAction<void, IFullStorageShape, null, AccountStateActions> => (dispatch: any) => {
  dispatch(getUserDetailsBegin());
  return fetchUserDetails(contextId, oboAccessToken, oboUserEmail, customerEmail, customerAccessToken, region)
    .then((response: AxiosResponse<IUserDetails>) => {
      const userDetails: IUserDetails = get(response, 'data');
      dispatch(getUserDetailsEnd(contextId, userDetails));
    })
    .catch((error: AxiosError) => {
      dispatch(setNotification('danger', 'fanAccount.errors.lookUpEmail', true));
    });
};

export const getUserPrimaryCreditCards = (
  contextId: number,
  accessToken: string,
): ThunkAction<void, IFullStorageShape, null, AccountStateActions> => (dispatch: any) => {
  dispatch(getUserPrimaryCCBegin());
  return fetchUserPrimaryCreditCards(contextId, accessToken)
    .then((response: AxiosResponse<IPrimaryCreditCard[]>) => {
      const primaryCreditCards = get(response, 'data');
      dispatch(getUserPrimaryCCEnd(primaryCreditCards));
    })
    .catch((error: AxiosError) => {
      dispatch(setNotification('danger', 'fanAccount.errors.primaryCC', true));
    });
};

export const getUserSecondaryCreditCards = (
  contextId: number,
  axsUserId: string,
  accessToken: string,
  region: number,
): ThunkAction<void, IFullStorageShape, null, AccountStateActions> => (dispatch: any) => {
  dispatch(getUserSecondaryCCBegin());
  return fetchUserSecondaryCreditCards(contextId, axsUserId, accessToken, region)
    .then((response: AxiosResponse<ISecondaryCreditCard[]>) => {
      const secondaryCreditCards = get(response, 'data');
      dispatch(getUserSecondaryCCEnd(secondaryCreditCards));
    })
    .catch((error: AxiosError) => {
      dispatch(setNotification('danger', 'fanAccount.errors.secondaryCC', true));
    });
};

export const getUserOrders = (
  contextId: number,
  customerAccessToken: string,
  customerEmail: string,
  region: number,
): ThunkAction<void, IFullStorageShape, null, AccountStateActions> => (dispatch: any) => {
  dispatch(getUserOrdersBegin());
  dispatch(getUserFlashTicketsBegin());
  const primaryOrdersPromise = fetchUserOrders(contextId, customerAccessToken, region);
  const flashTicketsPromise = fetchFlashTickets(contextId, customerAccessToken, region);

  return Promise.all([primaryOrdersPromise, flashTicketsPromise])
    .then((responses) => {
      const primaryOrders: IPrimaryOrder[] = get(responses[0], 'data.primaryOrders', []);
      const flashTickets: IFlashTickets[] = get(responses[1], 'data', [])
        .filter((ticket: IFlashTickets) => ticket.ticketState !== TICKET_STATES_IDS.CANCELLED);

      const missingOfferIds = getMissingOfferIds(flashTickets, primaryOrders);
      const offerPromises = missingOfferIds.map((offerId: number) => fetchOffer(contextId, offerId));
      Promise.all(offerPromises)
        .then((offerResponses) => {
          const flashOffers = offerResponses.map(offerResponse => get(offerResponse, 'data', null));
          dispatch(getUserOrdersEnd(contextId, customerEmail, primaryOrders, flashOffers, flashTickets));
          dispatch(getUserFlashTicketsEnd(flashTickets));
        })
        .catch(error => handleGetOrdersError(dispatch, error));
    })
    .catch(error => handleGetOrdersError(dispatch, error));
};
const handleGetOrdersError = (dispatch: any, error: any) => {
  dispatch(getUserOrdersFailure(error));
  dispatch(getUserFlashTicketsFailure(error));
  dispatch(setNotification('danger', 'fanAccount.errors.accountOrders', true));
};
const extractVeritixOfferIds = (orders: IPrimaryOrder[]) => {
  const extractOfferIds = (order: IPrimaryOrder) => order.products.map(product => product.offerId);
  return uniq(flatMap(orders, extractOfferIds));
};
const extractFlashOfferIds = (flashTickets: IFlashTickets[]) => {
  const offerIds = flashTickets.map((ticket: IFlashTickets) => ticket.primaryOfferId);
  return uniq(offerIds);
};
const getMissingOfferIds = (flashTickets: IFlashTickets[], orders: IPrimaryOrder[]) => {
  const veritixOfferIds = extractVeritixOfferIds(orders);
  const flashOfferIds = extractFlashOfferIds(flashTickets);
  return difference(flashOfferIds, veritixOfferIds);
};

export const transferTickets = (
  customerAccessToken: string,
  request: ITransferTicketsRequest,
  onSuccess: () => void,
): ThunkAction<void, IFullStorageShape, null, AccountStateActions> => (dispatch: any) => {
  dispatch(transferTicketsBegin());
  return submitTransferTickets(customerAccessToken, request)
    .then((response: AxiosResponse) => {
      dispatch(setNotification('info', 'fanAccount.errors.transferTicketsSuccess', true));
      dispatch(transferTicketsSuccess());
      dispatch(resetOrderDetails());
      if (onSuccess) {
        onSuccess();
      }
    })
    .catch((error: AxiosError) => {
      dispatch(setNotification('danger', 'fanAccount.errors.transferTicketsFailure', true));
      dispatch(transferTicketsError);
      dispatch(resetOrderDetails());
      if (onSuccess) {
        onSuccess();
      }
    });
};

export const changeDeliveryMethod = (
  contextId: number,
  customerAccessToken: string,
  region: number,
  orderId: string,
  request: IChangeDeliveryMethodRequest,
): ThunkAction<void, IFullStorageShape, null, AccountStateActions> => (dispatch: any) => {
  dispatch(changeDeliveryMethodBegin());
  return submitChangeDeliveryMethod(contextId, customerAccessToken, region, orderId, request)
    .then((response: AxiosResponse<IChangeDeliveryMethodStatus>) => {
      const status = get(response, 'data');
      dispatch(changeDeliveryMethodUpdate(status));
    })
    .catch((error: AxiosError) => {
      dispatch(changeDeliveryMethodError(error));
      dispatch(setNotification('danger', 'fanAccount.errors.changeModFailure', true));
    });
};

export const getChangeDeliveryMethodStatus = (
  contextId: number,
  customerAccessToken: string,
  region: number,
  orderId: string,
): ThunkAction<void, IFullStorageShape, null, AccountStateActions> => (dispatch: any) => {
  return fetchChangeDeliveryMethodStatus(contextId, customerAccessToken, region, orderId)
    .then((response: AxiosResponse<IChangeDeliveryMethodStatus>) => {
      const status = get(response, 'data');
      dispatch(changeDeliveryMethodUpdate(status));
    })
    .catch((error: AxiosError) => {
      dispatch(changeDeliveryMethodError(error));
      dispatch(setNotification('danger', 'fanAccount.errors.changeModFailure', true));
    });
};

export const finishChangeDeliveryMethod = () => (dispatch: any) => {
  dispatch(changeDeliveryMethodFinish());
  dispatch(resetOrderDetails());
  dispatch(resetSeatState());
};

export const getAvailableDeliveryMethods = (
  contextId: number,
  offerIds: number[],
): ThunkAction<void, IFullStorageShape, null, AccountStateActions> => (dispatch: any) => {
  dispatch(getAvailableDeliveryMethodsBegin());
  const deliveryMethodsRequests = offerIds.map(offerId => fetchAvailableDeliveryMethods(contextId, offerId));
  return Promise.all(deliveryMethodsRequests)
    .then((responses) => {
      const deliveryMethods = responses.map(({ data }) => data.deliveryMethods);
      dispatch(getAvailableDeliveryMethodsSuccess(deliveryMethods));
    })
    .catch((error: AxiosError) => {
      dispatch(getAvailableDeliveryMethodsFailure(error));
    });
};
