import React from 'react';
import { IStorageBaseProps, IStorageMap } from '../../types/storageTypes';
import { forEach } from 'lodash';


export default abstract class StorageBase<StorageBaseProps extends IStorageBaseProps> extends
  React.Component<StorageBaseProps> {

  public storageTarget?: Storage;

  public abstract getStorageMap(): IStorageMap;

  public render() {
    return null;
  }

  public componentDidUpdateStorageBase(prevProps: StorageBaseProps) {
    this.updateData(prevProps);
  }

  protected componentDidMountStorageBase(restoreFromStorage: () => void, restoredFromStorage: boolean) {
    if (!restoredFromStorage) {
      this.restoreData();
      restoreFromStorage();
    } else {
      this.updateData();
    }
  }

  protected restoreData() {
    forEach(this.getStorageMap(), ({ setter }, key) => {
      const value = this.storageTarget!.getItem(key);
      if (value !== null && value !== 'null') {
        try {
          setter(JSON.parse(value));
        } catch (err) {
          setter(value);
        }
      }
    });
  }

  protected updateData(prevProps?: IStorageBaseProps) {
    forEach(this.getStorageMap(), ({ dependsOn, value }, key) => {
      if (value !== undefined) {
        let shouldUpdate: boolean;
        if (!prevProps) {
          shouldUpdate = true;
        } else if (dependsOn) {
          shouldUpdate = dependsOn.some(key => prevProps[key] !== this.props[key]);
        } else {
          shouldUpdate = prevProps[key] !== value;
        }
        if (shouldUpdate) {
          this.storageTarget!.setItem(key, JSON.stringify(value));
        }
      }
    });
  }
}
