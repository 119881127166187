import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { push } from 'connected-react-router';

import Header from '../components/Header/';
import { getThemes, setThemeMode, clearNotification, toggleOverlay } from '../actions/appActions';
import { createSession, logout, clearSessionInfo } from '../actions/userActions';
import { APP_REDUCER_KEY, CARTING_REDUCER_KEY, OFFERS_REDUCER_KEY, USER_REDUCER_KEY } from '../store/store.types';
import { Preferences } from '../components/Preferences';
import { skinSchemas } from '../constants';
import { ICart } from '../mfe/carting/Carting/types/apiResponseTypes';
import { CartingActions } from '../mfe/carting';
import { CartNotification } from '../mfe/carting/Carting/components/CartNotification';
import { AppNotification } from '../components/AppNotification';
import { HeaderCart } from '../components/HeaderCart';
import CartDrawer from '../mfe/carting/Carting/components/CartDrawer';
import { cartHasItems } from '../mfe/carting/Carting/helpers';
import { ISettings } from '../types/userTypes';
import { withTranslation } from 'react-i18next';
import { find, get } from 'lodash';
import { AXSBlock } from '@axs/axs-ui';
import { hideFilterPanel, showFilterPanel } from '../actions/offersActions';
import { SessionStorage } from '../components/SessionStorage';
import { LocalStorage } from '../components/LocalStorage';
import { resetCartAndOrder } from '../mfe/carting/Carting/actions/cartingActions';
import { INotification } from '../types/appTypes';
import { pages } from '../constants/routes';
import { themeTypes, zIndexes } from '../theme';
import { ExpirationModal } from '../components/ExpirationModal';

export const ThemeModeContext = React.createContext(themeTypes.dark);

export interface IProps {
  authToken: string | null;
  cart?: ICart;
  cartDrawerOpened?: boolean;
  clearNotification: () => void;
  clearSessionInfo: () => void;
  contextId: number | null;
  createSession: (authToken: string, contextId?: number, outletId?: string, userCartTTL?: number) => void;
  creatingSession: boolean;
  filterPanelActive: boolean;
  getCart: () => void;
  getThemes?: any;
  hideFilterPanel: () => void;
  history?: any;
  location?: any;
  logout?: any;
  notification?: INotification;
  order: ICart | null;
  orderCheckedOut: boolean;
  overlayEnabled: boolean;
  pushLocation: (location: string) => void;
  resetCartAndOrder: (clearCustomer: boolean) => void;
  restoredFromLocalStorage: boolean;
  restoredFromSessionStorage: boolean;
  sessionId: string;
  setThemeMode?: any;
  settings: ISettings;
  showFilterPanel: () => void;
  t?: any;
  themeMode?: string;
  themes?: any;
  toggleCartDrawer: () => void;
  toggleOverlay: () => void;
  userId: string | null;
}

const GlobalStyle = createGlobalStyle<any>`
  body {
    background-color: ${({ themeMode }: any) => themeMode === themeTypes.dark ? '#040619' : '#f6f6f6'};
    color: ${({ themeMode }) => (themeMode === themeTypes.dark ? '#ebebeb' : '#333333')};
  }
  .touch-enabled {
    .prev, .next {
        display: none;
    }
  }
`;

/**
 * DO NOT add overflow rule here — it will break sticky OfferSummary on checkout page.
 */
const Viewport = styled.div<any>`
  margin-top: ${({ cartVisible }) => cartVisible ? '111px' : '65px' };
  min-height: ${({ cartVisible }) => cartVisible ? 'calc(100vh - 111px)' : 'calc(100vh - 65px)' };
  height: ${({ cartDrawerOpened }) => cartDrawerOpened ? 'calc(100vh - 111px)' : 'auto' };
`;

const Overlay = styled(AXSBlock)<{ active: boolean }>`
  position: absolute;
  display: ${({ active }) => active ? 'block' : 'none' };
  left: 0;
  top: 0;
  height: ${({ active }) => active ? 'calc(100vh)' : 0 };
  width: calc(100vw - 20px);
  background-color: ${({ active }) => active ? 'rgba(0, 0, 0, .7)' : 'transparent' };
  z-index: ${ zIndexes.OVERLAY };
`;

export class PageLayout extends React.Component<IProps> {

  public componentDidMount() {
    this.sessionCheck();
    this.outletCheck();
    const { themes, getThemes, cart, getCart } = this.props;
    if (themes.length < 1) {
      getThemes('axs', skinSchemas.THEME_SCHEMA_AXS_UI);
      getThemes('box-office-axs-dark', skinSchemas.THEME_SCHEMA_AXS_UI);
    }
    if (!cart && getCart) {
      getCart();
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    this.sessionCheck();
    this.outletCheck();
    this.clearOrderOnLeavingConfirmation();

    const { notification, clearNotification } = this.props;
    if (notification && notification.autoDismiss && notification !== prevProps.notification) {
      setTimeout(() => {
        clearNotification();
      },         6000);
    }
  }

  public render() {
    const {
      cart,
      cartDrawerOpened,
      children,
      clearNotification,
      clearSessionInfo,
      filterPanelActive,
      hideFilterPanel,
      location,
      logout,
      notification,
      overlayEnabled,
      pushLocation,
      setThemeMode,
      settings,
      showFilterPanel,
      t,
      toggleOverlay,
      themeMode,
      themes,
      toggleCartDrawer,
      userId,
    } = this.props;
    const selectedTheme = themeMode === themeTypes.dark ?
      find(themes, { skin: themeTypes.dark }) :
      find(themes, { skin: 'AXS' });
    const hasTouch = ('ontouchstart' in document.documentElement);
    const cartEnabled = cartDrawerOpened && cart && cart.selections && cart.selections.length > 0;

    const headerProps = {
      cart,
      cartDrawerOpened,
      location,
      toggleCartDrawer,
    };

    const appNotification = notification ? (
      <AppNotification
        active
        notification={ notification }
        handleClose={ () => clearNotification() }
      />
    ) : null;
    return (
      <ThemeProvider theme={ selectedTheme || {} }>
        <ThemeModeContext.Provider value={ themeMode ? themeMode : themeTypes.dark }>
          <LocalStorage />
          <SessionStorage />
          <Viewport
            cartVisible={ cart && cartHasItems(cart) && !location.pathname.startsWith(pages.confirmation) }
            className={ classnames([`${ themeMode }`, { 'touch-enabled': hasTouch }]) }
            cartDrawerOpened={ cartEnabled }
          >
            <GlobalStyle themeMode={ themeMode }/>
            <Header { ...headerProps } />
            { cart ? (
              <HeaderCart
                cart={ cart }
                cartDrawerOpened={ cartEnabled }
                filterPanelActive={ filterPanelActive }
                hideFilterPanel={ hideFilterPanel }
                location={ location }
                showFilterPanel={ showFilterPanel }
                toggleCartDrawer={ toggleCartDrawer }
              />
            ) : null }
            <CartNotification location={ location } t={ t } />
            <CartDrawer />
            <Overlay active={ overlayEnabled } />
            { appNotification }
            { children }
            {
              !location.pathname.includes('offer') && (
                <Preferences
                  clearSessionInfo={ clearSessionInfo }
                  logout={ logout }
                  pushLocation={ pushLocation }
                  setThemeMode={ setThemeMode }
                  settings={ settings }
                  toggleOverlay={ () => { toggleOverlay(); } }
                  userId={ userId }
                />
              )
            }
            <ExpirationModal />
          </Viewport>
        </ThemeModeContext.Provider>
      </ThemeProvider>
    );
  }

  private sessionCheck() {
    const {
      authToken,
      contextId,
      createSession,
      creatingSession,
      pushLocation,
      restoredFromSessionStorage,
      sessionId,
      settings,
      userId,
    } = this.props;
    if (restoredFromSessionStorage) {
      const authenticated = !!userId;
      if (!authenticated || !authToken || !contextId) { pushLocation(pages.login); }
      if (!sessionId && !creatingSession && authToken && contextId) {
        const selectedOutletId = get(settings, 'outlet.value');
        const userCartTTL = get(settings, 'cartTimer.value');
        createSession(authToken, contextId, selectedOutletId, userCartTTL);
      }
    }
  }

  private clearOrderOnLeavingConfirmation() {
    const { location, cart, order, orderCheckedOut, resetCartAndOrder } = this.props;
    const isConfirmationPage = location.pathname.startsWith(pages.confirmation);
    const isPreferencePage = location.pathname.startsWith(pages.preferences());
    if (!isConfirmationPage && !isPreferencePage && !cart && order && orderCheckedOut) {
      resetCartAndOrder(true);
    }
  }

  private outletCheck() {
    const { location, pushLocation, restoredFromLocalStorage, settings } = this.props;
    if (restoredFromLocalStorage) {
      const selectedOutletId = get(settings, 'outlet.value');
      const isPreferencePage = location.pathname === pages.preferences();
      if (!isPreferencePage && selectedOutletId === undefined) { pushLocation(pages.preferences()); }
    }
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearNotification: () => dispatch(clearNotification()),
    clearSessionInfo: () => dispatch(clearSessionInfo()),
    createSession: (authToken: string, contextId?: number, outletId?: string, userCartTTL?: number) =>
      dispatch(createSession(authToken, contextId, outletId, userCartTTL)),
    getCart: () => dispatch(CartingActions.getCart()),
    getThemes: (skinName: string, schemaName: string) => dispatch(getThemes(skinName, schemaName)),
    hideFilterPanel: () => dispatch(hideFilterPanel()),
    logout: () => dispatch(logout()),
    pushLocation: (location: string) => dispatch(push(location)),
    resetCartAndOrder: (clearCustomer: boolean) => dispatch(resetCartAndOrder(clearCustomer)),
    setThemeMode: (mode: string) => dispatch(setThemeMode(mode)),
    showFilterPanel: () => dispatch(showFilterPanel()),
    toggleCartDrawer: () => dispatch(CartingActions.toggleCartDrawer()),
    toggleOverlay: () => dispatch(toggleOverlay()),
  };
};

const mapStateToProps = (state: any,  ownProps: any) => {
  return {
    authToken: state[USER_REDUCER_KEY].authToken,
    cart: state[CARTING_REDUCER_KEY].cart,
    cartDrawerOpened: state[CARTING_REDUCER_KEY].cartDrawerOpened,
    contextId: state[USER_REDUCER_KEY].contextId,
    creatingSession: state[USER_REDUCER_KEY].creatingSession,
    filterPanelActive: state[OFFERS_REDUCER_KEY].filterPanelActive,
    location : ownProps.location,
    notification: state[APP_REDUCER_KEY].notification,
    order: state[CARTING_REDUCER_KEY].order,
    orderCheckedOut: state[CARTING_REDUCER_KEY].orderCheckedOut,
    overlayEnabled: state[APP_REDUCER_KEY].overlayEnabled,
    restoredFromLocalStorage: state[APP_REDUCER_KEY].restoredFromLocalStorage,
    restoredFromSessionStorage: state[APP_REDUCER_KEY].restoredFromSessionStorage,
    sessionId: state[USER_REDUCER_KEY].sessionId,
    settings: state[USER_REDUCER_KEY].settings,
    themeMode: state[APP_REDUCER_KEY].themeMode,
    themes: state[APP_REDUCER_KEY].themes,
    userId: state[USER_REDUCER_KEY].userId,
  };
};
const translated = withTranslation()(PageLayout as any);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
