export const NAMES_VALIDATOR = {
  // Allows for only letters and spaces between words
  FIRST_NAME_VALIDATOR: /^[a-zA-Z ]*$/,
  // Allows for letters, apostrophes, hyphens
  FULL_NAME_VALIDATOR: /^[a-zA-Z](?:[ '.\-a-zA-Z]*[a-zA-Z])?$/,
  // Allows for letters, apostrophes, hyphens
  LAST_NAME_VALIDATOR: /^[a-zA-Z](?:[ '.\-a-zA-Z]*[a-zA-Z])?$/,
  // Allows for letters and words ending with -, '
  POTENTIALLY_VALID_NAME: /^[a-z|A-Z]+[^I]?[ ,',-]$|^[a-zA-Z](?:[ '.\-a-zA-Z]*[a-zA-Z])?$/,
};

export const skinSchemas = {
  THEME_SCHEMA_AXS_UI: 'axs-ui',
  WBO_FEATURES: 'wbo-features',
};

export const THEME_MODES = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const TAB_NAMES = {
  ALL : 'all',
  CUSTOMERS : 'customers',
  EVENTS : 'events',
  ORDERS : 'orders',
  TICKETS : 'tickets',
};

export const paymentCodes = {
  AMEX: 'American Express',
  AX: 'American Express',
  CASH: 'Cash',
  CUP: 'CUP',
  Cash: 'Cash',
  DI: 'Discover',
  DISCOVER: 'Discover',
  JCB: 'JCB',
  MASTERCARD: 'Mastercard',
  MC: 'Mastercard',
  PayPal: 'PayPal',
  VI: 'Visa',
  VISA: 'Visa',
};

export const OFFERS_TAB_NAMES = {
  ALL: 'all',
  AVAILABLE: 'available',
};

export const countries =
  [
    {
      label :'United States',
      value : 'US',
    },
    {
      label :'Canada',
      value : 'CA',
    },
  ];


export const states = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Guam Gu',
    value: 'GU',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];

export const provinces = [
  {
    label: 'Quebec',
    value: 'QC',
  },
  {
    label: 'Alberta',
    value: 'AB',
  },
  {
    label: 'British Columbia',
    value: 'BC',
  },
  {
    label: 'Manitoba',
    value: 'MB',
  },
  {
    label: 'New Brunswick',
    value: 'NB',
  },
  {
    label: 'Newfoundland and Labrador',
    value: 'NL',
  },
  {
    label: 'Northwest Territories',
    value: 'NT',
  },
  {
    label: 'Nova Scotia',
    value: 'NS',
  },
  {
    label: 'Nunavut',
    value: 'NU',
  },
  {
    label: 'Ontario',
    value: 'ON',
  },
  {
    label: 'Prince Edward Island',
    value: 'PE',
  },
  {
    label: 'Saskatchewan',
    value: 'SK',
  },
  {
    label: 'Yukon',
    value: 'YT',
  },
];
