import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { getUserToken } from '../actions/accountActions';
import { IOnBehalfOfInfo } from '../api/types/fanAccount/responseTypes';

/**
 * Uses React Hooks to consolidate search  logic
 * passing empty array as last param to useEffect makes it the same as ComponentDidMount
 *
 */
export const useInputHelpers = () => {
  const [text, setText] = useState('');
  const [activeContext, setActiveContext] = useState('');
  const dispatch = useDispatch();
  // could potentially useSelector to get search query from redux
  return {
    activeContext,
    dispatch,
    setActiveContext,
    setText,
    text,
  };
};

/**
 * Uses React Hooks to hide the scroll of a page
 * when a modal is opened and
 * enables it when the modal is closed
 *
 */

export const useLockBodyScroll = () => {
  useEffect(() => {
    const overflowValue = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = overflowValue;
    };
  },        []);
};

/**
 * Uses React Hooks to request
 * an access token for a user.
 * @returns {string} - Customer access token.
 */

export const useUserTokenRequest = (
  userInfo: IOnBehalfOfInfo,
  contextId: number,
  customerEmail: string,
  oboAccessToken: string,
): string => {
  const savedCustomerToken = get(userInfo, `[${contextId}]['${customerEmail}'].token`, '');
  const [customerAccessToken, setCustomerToken] = useState(savedCustomerToken);
  const [isTokenRequested, setIsTokenRequested] = useState(false);
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (!customerAccessToken && !isTokenRequested) {
        setIsTokenRequested(true);
        dispatch(
          getUserToken(
          contextId,
          oboAccessToken,
          customerEmail,
          (token: string) => {
            setCustomerToken(token);
          },
          () => {},
        ));
      }
    },
    [contextId, customerAccessToken, customerEmail, dispatch, isTokenRequested, oboAccessToken, userInfo],
  );

  return customerAccessToken;
};
