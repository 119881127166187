import styled from 'styled-components';
import { AXSBlock } from '@axs/axs-ui';
import { colors } from '@axs/axs-ui/dist/constants/colors';
import { parseThemePath } from '@axs/axs-ui/dist/utils';

export const ClippedText = styled(AXSBlock)`
  color: ${({ color, theme }) => color && parseThemePath(color, theme, colors.GREY_400) };
  font-size: 16px;
`;


