import { AnyAction } from 'redux';

export interface IContext {
  id: number;
  name: string;
}

export interface IResolutionFeatures {
  customerCards?: boolean;
}

export interface IGlobalSearchFeatures {
  ticketCards?: boolean;
}

export interface IFeatures {
  contexts: IContext[];
  resolution?: IResolutionFeatures;
  globalSearch?: IGlobalSearchFeatures;
}

export interface IFeaturesState {
  client: string | null;
  features?: IFeatures | null;
}

export enum FeaturesActionTypes {
  SET_CLIENT = 'SET_CLIENT',
  SET_FEATURES = 'SET_FEATURES',
}

export interface ISetClient extends AnyAction {
  client: string | null;
  type: FeaturesActionTypes.SET_CLIENT;
}

export interface ISetFeatures extends AnyAction {
  features: IFeatures | null;
  type: FeaturesActionTypes.SET_FEATURES;
}

export type FeaturesActions =
  ISetFeatures;
