import { createStore as createReduxStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '../middleware/sagas/index';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { makeRootReducer } from './reducers';

const createStore = (history: any, initialState = {}) => {
  // ===============
  // Logger config
  // ===============
  const logger = createLogger({
    collapsed: true,
    logErrors: false,
  });

  // ======================================================
  // Middleware Config
  // ======================================================
  const sagaMiddleware = createSagaMiddleware();
  const middleware: any[] = [thunk, routerMiddleware(history), sagaMiddleware];

  const enhancers: never[] = [];
  const composeEnhancers = composeWithDevTools({});

  if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
  }

  const store =  createReduxStore(
    makeRootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware),
      ...enhancers,
    ),
  );
  sagaMiddleware.run(rootSaga);
  return store;
};

export default createStore;
