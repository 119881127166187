import CartingRequest from './CartingRequest';
import { ICartSelectionForAdd, ISeatSelectionForAdd } from '../types/apiRequestTypes';
import { IProductIdentity } from '../types/commonTypes';

export default class CartUpdateSelection extends CartingRequest {
  private items: ISeatSelectionForAdd[] = [];
  private strictCheck: boolean = false;
  constructor(public productIdentity: IProductIdentity) {
    super();
  }

  public updateSeats(item: ISeatSelectionForAdd): void {
    this.items.push(item);
  }

  public toJSON(): ICartSelectionForAdd {
    return {
      ...this.productIdentity,
      items: this.items,
      overwrite: false,
      strictCheck: this.strictCheck,
    };
  }
}
