import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { get, padStart } from 'lodash';
import { AXSModal, AXSFlex, AXSBlock, AXSButton } from '@axs/axs-ui';
import config from '../../config';
import { isExpired } from '../../utils/common';
import { extendCartExpiration, getCart } from '../../mfe/carting/Carting/actions/cartingActions';
import { extendSession, logout } from '../../actions/userActions';
import { zIndexes } from '../../theme';
import {
  CARTING_REDUCER_KEY,
  USER_REDUCER_KEY,
} from '../../store/store.types';
import { ICart } from '../../mfe/carting/Carting/types/apiResponseTypes';

enum ExpirationTypes {
  SESSION,
  CART,
}

interface IExpirationModalProps {
  cart: ICart | null;
  carting: boolean;
  creatingSession: boolean;
  extendCartExpiration: () => void;
  extendSession: () => void;
  getCart: () => void;
  logout: () => void;
  sessionExpiration: number | null;
  t?: any;
}

interface IExpirationModalState {
  active: boolean;
  type?: ExpirationTypes;
  sessionExpired: boolean;
}

class ExpirationModal extends React.Component<IExpirationModalProps, IExpirationModalState> {
  private checkHandle?: number;
  constructor(props: IExpirationModalProps, context: any) {
    super(props, context);
    this.state = {
      active: false,
      sessionExpired: false,
    };
    this.processComponentUpdate = this.processComponentUpdate.bind(this);
  }

  public componentDidMount() {
    this.processComponentUpdate();
  }

  public componentDidUpdate(prevProps: IExpirationModalProps) {
    if (prevProps.cart !== this.props.cart ||
        prevProps.sessionExpiration !== this.props.sessionExpiration) {
      this.processComponentUpdate();
    }
  }

  public componentWillUnmount() {
    clearTimeout(this.checkHandle);
  }

  public render() {
    const {
      cart,
      carting,
      creatingSession,
      extendCartExpiration,
      extendSession,
      logout,
      sessionExpiration,
      t,
    } = this.props;
    const { active, type, sessionExpired } = this.state;
    const actionButtons: JSX.Element[] = [];
    let modalType: string;
    let expireIn: number;
    const now = Date.now();
    if (type === ExpirationTypes.SESSION) {
      expireIn = sessionExpiration ? sessionExpiration - now : 0;
      if (!sessionExpired) {
        modalType = 'sessionExpiring';
        actionButtons.push(
          <AXSButton key={ 1 } onClick={ extendSession }>{ t('sessionModal.staySignedIn') }</AXSButton>,
        );
        actionButtons.push(<AXSButton key={ 2 } onClick={ logout } secondary>{ t('buttons.signOut') }</AXSButton>);
      } else {
        modalType = 'sessionExpired';
        actionButtons.push(<AXSButton key={ 1 } onClick={ logout }>{ t('buttons.signIn') }</AXSButton>);
      }
    } else {
      modalType = 'cartExpiring';
      const cartExpiration = get(cart, 'expiration');
      expireIn = cartExpiration ? cartExpiration - now : 0;
      actionButtons.push(
        <AXSButton key={ 1 } onClick={ extendCartExpiration }>{ t('sessionModal.extendCart') }</AXSButton>,
      );
    }
    expireIn = Math.trunc(expireIn / 1000);
    const expireInSecond = padStart((expireIn % 60).toString(), 2, '0');
    const expireInMinute = Math.trunc(expireIn / 60);

    const countDown: JSX.Element | null = !sessionExpired ? (
      <AXSFlex flexWrap="wrap" justifyContent="center" marginBottom="20px">
        <AXSBlock fontSize="60px" fontWeight="bold">{ `${expireInMinute}:${expireInSecond}` }</AXSBlock>
      </AXSFlex>
    ) : null;

    return (
      <AXSModal
        active={ active && !carting && !creatingSession }
        handleClose={ () => {} }
        height="450px"
        showClose={ false }
        width="600px"
        zIndex={ zIndexes.EXPIRATION_MODAL }
      >
        <AXSFlex flexWrap="wrap" justifyContent="center" marginTop="60px" marginBottom="20px">
          <AXSBlock fontSize="36px">{ t(`sessionModal.${modalType}.title`) }</AXSBlock>
        </AXSFlex>
        { countDown }
        <div className="row justify-content-center">
          <div className="col-8">
            <AXSFlex flexWrap="wrap" justifyContent="center" marginBottom="40px">
              <AXSBlock>{ t(`sessionModal.${modalType}.description`) }</AXSBlock>
            </AXSFlex>
            <AXSFlex justifyContent="center" marginTop="30px">
              { actionButtons }
            </AXSFlex>
          </div>
        </div>
      </AXSModal>
    );
  }

  private processComponentUpdate() {
    clearTimeout(this.checkHandle);
    const nextCheck = this.shouldActivateModal();
    if (nextCheck) {
      this.checkHandle = setTimeout(this.processComponentUpdate, nextCheck);
    }
  }

  private shouldActivateModal(): number {
    const { cart, getCart, sessionExpiration } = this.props;
    let nextCheck: number = 0;
    if (cart) {
      const cartExpiration = get(cart, 'expiration');
      if (!isExpired(cartExpiration)) {
        if (this.shouldGiveWarn(cartExpiration)) {
          this.setState({ active: true, type: ExpirationTypes.CART, sessionExpired: false });
          return 1000;
        }
        nextCheck = this.getNextCheck(cartExpiration);
      } else {
        getCart();
      }
    }
    if (sessionExpiration) {
      if (this.shouldGiveWarn(sessionExpiration)) {
        const sessionExpired = isExpired(sessionExpiration);
        this.setState({ active: true, type: ExpirationTypes.SESSION, sessionExpired });
        return sessionExpired ? 0 : 1000;
      }
      if (!nextCheck) {
        nextCheck = this.getNextCheck(sessionExpiration);
      }
    }
    this.setState({ active: false, type: undefined, sessionExpired: false });
    return nextCheck;
  }

  private shouldGiveWarn(expiration: number) {
    const now = Date.now();
    return expiration < now + (config.expirationWarning + 2) * 1000;
  }

  private getNextCheck(expiration: number) {
    const now = Date.now();
    const nextCheck = expiration - now - ((config.expirationWarning + 1) * 1000);
    if (nextCheck > 0) {
      return nextCheck;
    }
    return 0;
  }
}

const mapStateToProps =  (state: any) => {
  return {
    cart: state[CARTING_REDUCER_KEY].cart,
    carting: state[CARTING_REDUCER_KEY].carting,
    creatingSession: state[USER_REDUCER_KEY].creatingSession,
    sessionExpiration: state[USER_REDUCER_KEY].sessionExpiration,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    extendCartExpiration: () => dispatch(extendCartExpiration()),
    extendSession: () => dispatch(extendSession()),
    getCart: () => dispatch(getCart()),
    logout: () => dispatch(logout()),
  };
};

const translated = withTranslation()(ExpirationModal as any);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
