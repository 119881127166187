import { flattenDeep } from 'lodash';
import { takeLatest, select, put } from 'redux-saga/effects';
import { SearchStateTypes } from '../../types/searchTypes';
import {
  IPrimaryOrder,
  IPrimaryOrderSeat,
  IPrimaryOrderProduct,
  ITestGlobalSearchCustomer,
} from '../../api/types/fanAccount/responseTypes';
import { globalSearchTickets } from '../../api/ApiManager';
import { searchGlobalTicketsSuccess, searchGlobalTicketsFailure } from '../../actions/globalSearchActions';
import globalSearchCustomers from '../../mockData/GlobalSearchCustomers.json';
import { getTicketStatus } from '../../utils/ticketStatus';

// ToDo: for testing only
// When data comes from the server, we need to transfer this logic to the reducer
const getTestTickets = (customers: any[]) => {
  return flattenDeep(customers.map((customer: ITestGlobalSearchCustomer) => {
    const { orders: { primaryOrders: orders }, address } = customer;
    return orders.map((order: IPrimaryOrder) => {
      const { products = [] } = order;
      return products.map((product: IPrimaryOrderProduct) => {
        const { seats = [] } = product;
        return seats.map((seat: IPrimaryOrderSeat) => {
          return {
            ...seat,
            customer: {
              address,
              axsUserId: customer.axsUserId,
              email: customer.email,
              firstName: customer.firstName,
              lastName: customer.lastName,
              phone: customer.phone || '',
            },
            orderNumber: order.orderNumber,
            product,
            ticketStatus: getTicketStatus(product, seat, null),
          };
        });
      });
    });
  }));
};

function* fetchTicketsSaga(action: any) {
  const globalState = yield select();
  const authToken: string | null = globalState.user.authToken;
  const contextId: number | null = globalState.user.contextId;
  if (!authToken || !contextId) return;

  try {
    const request = globalSearchTickets(contextId, authToken, action.searchQuery);
    const response = yield(request);
    const tickets = response.data.results;

    if (action.payload.toLowerCase() === 'testtickets') {
      // ToDo: for testing only
      yield put(searchGlobalTicketsSuccess(getTestTickets(globalSearchCustomers.customers)));
    } else {
      yield put(searchGlobalTicketsSuccess(tickets));
    }
  } catch (error) {
    if (action.payload.toLowerCase() === 'testtickets') {
      // ToDo: for testing only
      yield put(searchGlobalTicketsSuccess(getTestTickets(globalSearchCustomers.customers)));
    } else {
      yield put(searchGlobalTicketsFailure());
    }
  }
}

/////////////////
// Watchers
/////////////////
export function* fetchTicketsWatcher() {
  yield takeLatest(SearchStateTypes.SEARCH_TICKETS_BEGIN, fetchTicketsSaga);
}

