import {
  IClearSessionInfo,
  ICloseNotification,
  ICreatingSession,
  IExtendSession,
  IGetSessionInfoBegin,
  IGetSessionInfoSuccess,
  IGetWorkstationBegin,
  ILoginBegin,
  ILoginFailure,
  ILoginResolutionUserSuccess,
  ILoginSuccess,
  ILoginUser,
  ILogout,
  IResetSearchCriteria,
  ISessionInfo,
  ISetOutlets,
  ISetSelectedPriceCodes,
  ISetSession,
  ISetWorkstation,
  ISetting,
  ISettings,
  ITogglePaymentMethod,
  ITogglePriceLevel,
  IWorkStation,
  PaymentMethods,
  UserStateActions,
  UserStateTypes,
} from '../types/userTypes';
import { IUserPreferences } from '../api/types/fanAccount/responseTypes';
import { deleteCart, closeCartDrawer } from '../mfe/carting/Carting/actions/cartingActions';
import { ThunkAction } from 'redux-thunk';
import { IFullStorageShape } from '../store/store.types';
import { createSession as createSessionApi, loginUser, fetchOutlets, fetchWorkstationInfo, loginResolutionUser } from '../api/ApiManager';
import { IOutlet, IAccountResponse } from '../api/types/eventtool/responseTypes';
import { fetchOboUserProfile } from '../api/FanAccountApiManager';
import { push } from 'connected-react-router';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';
import { FlowType } from '../common/Offer/FlowType';
import { pages } from '../constants/routes';
import { setNotification } from './appActions';
import mockWorkstation from '../constants/fallback/workstation.json';
import { resetAccountState } from './accountActions';

const eventToolServiceErrorPath = 'response.data.ResponseStatus.Message';

export const closeNotification = (): ICloseNotification =>
  ({ type: UserStateTypes.CLOSE_NOTIFICATION });

export const togglePriceCode = (priceCodeId: number, count: number): ISetSelectedPriceCodes =>
  ({ type: UserStateTypes.TOGGLE_PRICE_CODE, priceCodeId, count });

export const togglePriceLevel = (priceLevelId: number): ITogglePriceLevel =>
  ({ type: UserStateTypes.TOGGLE_PRICE_LEVEL, priceLevelId });

export const resetSearchCriteria = (): IResetSearchCriteria =>
  ({ type: UserStateTypes.RESET_SEARCH_CRITERIA });

export const togglePaymentMethod = (method: PaymentMethods): ITogglePaymentMethod =>
  ({ type: UserStateTypes.TOGGLE_PAYMENT_METHOD, method });

export const setSelectedEventId = (id: number) =>
  ({ type: UserStateTypes.SET_SELECTED_EVENT_ID, id });

export const setSelectedFlowType = (flowType: FlowType) =>
  ({ type: UserStateTypes.SET_SELECTED_FLOW_TYPE, flowType });

export const setSelectedOfferId = (id: number | undefined) =>
  ({ type: UserStateTypes.SET_SELECTED_OFFER_ID, id });

export const setSetting = (key: string, setting: ISetting) =>
  ({ key, setting, type: UserStateTypes.SET_SETTING });

export const storeSettings = (settings: ISettings) =>
  ({ settings, type: UserStateTypes.STORE_SETTINGS });

export const setWorkstation = (workstation?: IWorkStation, expireAt?: number): ISetWorkstation =>
  ({ expireAt, type: UserStateTypes.SET_WORKSTATION, workstation });

export const getWorkstationBegin = (): IGetWorkstationBegin =>
  ({ type: UserStateTypes.GET_WORKSTATION_BEGIN });

// tslint:disable-next-line: max-line-length
export const login = (user: ILoginUser, genericErrorString: string): ThunkAction<void, IFullStorageShape, null, UserStateActions> => (dispatch: any) => {

  const handleError = (error: AxiosError) => {
    const errorMsg = get(error, eventToolServiceErrorPath, genericErrorString);
    dispatch(loginFailure(errorMsg));
  };

  dispatch(loginBegin());
  return loginUser(user)
      .then((response: AxiosResponse<any>) => {
        const authToken = get(response, 'data.BearerToken');
        const userId = get(response, 'data.UserName');
        const contextId = parseInt(get(response, 'data.Meta.Context', '0') as string);

        loginResolutionUser(authToken)
          .then((loginResponse: AxiosResponse<IAccountResponse>) => {
            const accessToken = get(loginResponse, 'data.Oauth.access_token');
            const refreshToken = get(loginResponse, 'data.Oauth.refresh_token');
            fetchOboUserProfile(contextId, accessToken)
              .then((profileResponse: AxiosResponse<IUserPreferences>) => {
                const { email } = profileResponse.data;
                dispatch(resetAccountState());
                dispatch(loginSuccess(authToken, contextId, userId));
                dispatch(loginResolutionUserSuccess(accessToken, refreshToken, email));
                dispatch(push(pages.preferences()));
              })
              .catch((err) => {
                dispatch(resetAccountState());
                dispatch(setNotification('danger', 'Unable to login Resolution'));
                dispatch(loginSuccess(authToken, contextId, userId));
                dispatch(push(pages.preferences()));
              });
          })
          .catch(handleError);
      })
      .catch(handleError);
};

const dispatchForInvalidSession = (dispatch: any) => {
  dispatch(setSession(null));
  dispatch(loginFailure('Unable to create valid Box Office session'));
};

// tslint:disable-next-line: max-line-length
export const createSession = (authToken: string, contextId?: number, outletId?: string, userCartTTL?: number): ThunkAction<void, any, null, UserStateActions> => (dispatch: any) => {
  dispatch(creatingSession());
  const contextIdStr = contextId ? contextId.toString() : undefined;
  return createSessionApi(authToken, contextIdStr, outletId, userCartTTL)
  .then((response: AxiosResponse<any>) => {
    if (response && response.data) {
      const validSession: boolean = get(response, 'data.sessionConfiguration.boxOffice.validSession', false);
      if (validSession) {
        const sessionId: string = get(response, 'data.sessionConfiguration.boxOffice.sessionId', '');
        const sessionExpiration: number = get(response, 'data.sessionConfiguration.boxOffice.expireAt');
        dispatch(setSession(sessionId, sessionExpiration));
      } else {
        dispatchForInvalidSession(dispatch);
      }
    }
  })
  .catch(() => {
    dispatchForInvalidSession(dispatch);
  });
};

export const logout = (): ThunkAction<void, any, null, UserStateActions> => (dispatch: any) => {
  dispatch({ type: UserStateTypes.LOGOUT } as ILogout);
  dispatch(closeCartDrawer());
  dispatch(deleteCart());
  localStorage.removeItem('axsApiEndpoint');
};

// tslint:disable-next-line: max-line-length
export const getOutlets = (authToken: string, contextId: number): ThunkAction<void, any, null, UserStateActions> => (dispatch: any) => {
  return fetchOutlets(contextId, authToken)
    .then((response: AxiosResponse<any>) => {
      const outlets = get(response, 'data.Outlets', []);
      dispatch(setOutlets(outlets));
      if (outlets.length === 0) {
        dispatch(setNotification('danger', 'preferences.unableToLoadOutlets', true));
      }
    })
    .catch((error: AxiosError) => {
      dispatch(setOutlets([]));
      dispatch(setNotification('danger', 'preferences.unableToLoadOutlets', true));
    });
};


export const loginBegin = (): ILoginBegin =>
  ({ type: UserStateTypes.LOGIN_BEGIN });

export const loginSuccess = (authToken: string, contextId: number, userId: string): ILoginSuccess =>
  ({ authToken, contextId, type: UserStateTypes.LOGIN_SUCCESS, userId });

export const loginFailure = (errorMsg: string): ILoginFailure =>
  ({ type: UserStateTypes.LOGIN_FAILURE, errorMsg });

export const loginResolutionUserSuccess = (
  accessToken: string,
  refreshToken: string,
  oboUserEmail: string,
): ILoginResolutionUserSuccess =>
  ({ accessToken, refreshToken, oboUserEmail, type: UserStateTypes.LOGIN_RESOLUTION_SUCCESS });

export const creatingSession = (): ICreatingSession =>
  ({ type: UserStateTypes.CREATING_SESSION });

export const setSession = (sessionId: string | null, sessionExpiration?: number): ISetSession =>
  ({ type: UserStateTypes.SET_SESSION, sessionExpiration, sessionId });

export const extendSession = (): IExtendSession =>
  ({ type: UserStateTypes.EXTEND_SESSION });

export const getSessionInfoBegin = (): IGetSessionInfoBegin =>
  ({ type : UserStateTypes.GET_SESSION_INFO_BEGIN });

export const clearSessionInfo = (failure: boolean = false): IClearSessionInfo =>
  ({ failure, type : UserStateTypes.CLEAR_SESSION_INFO });

export const getSessionInfoSuccess = (sessionInfo: ISessionInfo): IGetSessionInfoSuccess =>
  ({ type : UserStateTypes.GET_SESSION_INFO_SUCCESS , sessionInfo });

export const setOutlets = (outlets: IOutlet[]): ISetOutlets =>
  ({ outlets, type: UserStateTypes.SET_OUTLETS });

// tslint:disable-next-line: max-line-length
export const getWorkstationInfo = (sessionId: string): ThunkAction<void, IFullStorageShape, null, UserStateActions> => (dispatch: any) => {
  dispatch(getWorkstationBegin());
  return fetchWorkstationInfo(sessionId)
    .then((response: AxiosResponse<any>) => {
      const workstation: IWorkStation = response.data;
      workstation.printers = workstation.printers.map(printer =>
        ({ name: printer.name, displayName: printer.displayName || printer.name }));
      workstation.printerStocks = workstation.printerStocks || mockWorkstation.printerStocks;
      workstation.printerTypes = workstation.printerTypes || mockWorkstation.printerTypes;
      dispatch(setWorkstation(workstation));
    })
    .catch((error: AxiosError) => {
      const errorMsg = get(error, 'response.data.ResponseStatus.Message', 'preferences.unableToLoadWorkstationInfo');
      dispatch(setNotification('danger', errorMsg, true, true));
      dispatch(setWorkstation(mockWorkstation));
    });
};
