import { IAddress } from '../../api/types/fanAccount/responseTypes';

export enum ACCOUNT_PAYMENT_METHOD_SOURCE {
  BACK_OFFICE = 'BACK_OFFICE',
  FLASH = 'FLASH',
}

export class AccountCreditCard {
  constructor(
    public address: IAddress,
    public expMonth: number,
    public expYear: number,
    public id: string,
    public isPrimary: boolean,
    public lastFourDigits: string,
    public lastUsedDate: string,
    public nameOnCard: string,
    public source: string,
    public type: string,
  ) {
  }
}
