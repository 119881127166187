import Price from '.';

class DynamicPrice extends Price {

  constructor(
    public value: number,
    public priceLevelId: number,
    public priceCodeId: number,
    public sectionId: number,
    public rowId?: number,
    public seatId?: number,
  ) {
    super(value, priceLevelId, priceCodeId);
  }

}

export default DynamicPrice;
