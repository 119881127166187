import axios, { AxiosRequestConfig } from 'axios';
import config from '../../../../config';
import CartingRequest from '../classes/CartingRequest';
import EmptyRequest from '../classes/EmptyRequest';
import CartActionRequest from '../classes/CartActionRequest';
import CartAddSelection from '../classes/CartAddSelection';
import CartRemoveSelection from '../classes/CartRemoveSelection';
import CreateOrderRequest from '../classes/CreateOrderRequest';

const UAPI_HOST = `${config.protocol}${config.uAPICommerce}`;
const CARTING_PATH = `${UAPI_HOST}/veritix/cart/v2`;

const CARTING_ENDPOINTS = {
  addToCart: {
    method: 'post',
    url: `${CARTING_PATH}/add-items?checkPriceChange=true`,
  },
  checkOrderStatus: {
    method: 'get',
    url: `${UAPI_HOST}/v2/order/status`,
  },
  createOrder: {
    method: 'post',
    url: `${UAPI_HOST}/veritix/order/v2`,
  },
  deleteCart: {
    method: 'delete',
    url: `${CARTING_PATH}`,
  },
  getCart: {
    method: 'get',
    url: `${CARTING_PATH}`,
  },
  removeFromCart: {
    method: 'post',
    url: `${CARTING_PATH}/remove-items`,
  },
  updateCart: {
    method: 'post',
    url: `${CARTING_PATH}/update-items`,
  },
  updateTimer: {
    method: 'post',
    url: `${CARTING_PATH}/update-timer`,
  },
};

const commonHeaders = {
  Accept: 'application/json',
  'Cache-Control': 'no-cache',
  'Content-Type': 'application/json',
};

function callApi<RequestType extends CartingRequest>(endpoint: string) {
  return (request: RequestType) => {
    const requestOptions: AxiosRequestConfig = {
      headers: { ...commonHeaders },
      ...CARTING_ENDPOINTS[endpoint],
      withCredentials: true,
    };
    if (requestOptions.method === 'post') {
      requestOptions.data = request.toJSON();
    }
    return axios(requestOptions);
  };
}

export default {
  addToCart: callApi<CartActionRequest<CartAddSelection>>('addToCart'),
  checkOrderStatus: callApi<EmptyRequest>('checkOrderStatus'),
  createOrder: callApi<CreateOrderRequest>('createOrder'),
  deleteCart: callApi<EmptyRequest>('deleteCart'),
  getCart: callApi<EmptyRequest>('getCart'),
  removeFromCart: callApi<CartActionRequest<CartRemoveSelection>>('removeFromCart'),
  updateCart: callApi<EmptyRequest>('updateCart'),
  updateTimer: callApi<EmptyRequest>('updateTimer'),
};
