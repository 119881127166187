import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

interface IAppContainerProps {
  routes: any;
  store: any;
  history: any;
}

export class Index extends React.Component<IAppContainerProps> {
  public render() {
    const { store, routes, history } = this.props;
    return(
      <Provider store={ store }>
        <ConnectedRouter history={ history }>
          { routes }
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default Index;
