import styled from 'styled-components';

export const CartTable = styled.table`
  margin-top: 12px;

  thead th {
    border-bottom: solid 1px #4a6694;
    border-top: none;
    color: #4a6694;
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 1;
    text-transform: uppercase;
  }

  thead th:first-child {
    padding-left: 0;
  }

  thead th:last-child,
  tbody td:last-child {
    padding-right: 0;
  }

  tbody td {
    border-top: none;
    color: #bbc7dc;
    font-size: 16px;

    & .c-axs-icon--cancel {
      cursor: pointer;
      svg {
        margin-top: -5px;
      }
    }
  }
`;

export const ActionLink = styled.div`
  color: #007dff;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
`;
