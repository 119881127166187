import { takeLatest, select, put } from 'redux-saga/effects';
import { SearchStateTypes } from '../../types/searchTypes';
import { fetchEventOffers, fetchOffer, fetchOfferPricing, globalSearchOrders } from '../../api/ApiManager';
import {
  searchGlobalOrdersSuccess,
  searchGlobalOrdersFailure,
  completeGlobalSearch} from '../../actions/globalSearchActions';
import {
  fetchDefaultOffersBegin,
  fetchDefaultOffersEnd,
  fetchEventOffersBegin,
  fetchEventOffersSuccess,
  getOfferBegin,
  getOfferSuccess,
  loadMissingOffersAbort,
  loadMissingOffersComplete,
} from '../../actions/offersActions';
import { ICart, ISelection } from '../../mfe/carting/Carting/types/apiResponseTypes';
import { get, uniq } from 'lodash';
import { SeatType } from '../../common/Seat/SeatType';
import { CartingActionTypes } from '../../mfe/carting/Carting/types/cartingTypes';
import { OfferStateTypes, IAvailableOffers } from '../../types/offerTypes';
import { getOfferPricingBegin, getOfferPricingSuccess } from '../../actions/inventoryActions';
import { isExpired } from '../../utils/common';
import { EventsStateTypes } from '../../types/eventsTypes';
import { IOrderSearchResponse } from '../../common/SearchDataTypes/SearchResponse';
import { AxiosResponse } from 'axios';

function* fetchOffersSaga(action: any) {
  const globalState = yield select();
  const authToken: string | null = globalState.user.authToken;
  const contextId: number | null = globalState.user.contextId;
  const searchText = action.payload;
  const page = action.page;
  const results = action.resultCount;
  if (authToken && contextId) {
    try {
      const request = globalSearchOrders(contextId, authToken, searchText, page, results);
      const response: AxiosResponse<IOrderSearchResponse> = yield(request);
      const orders: IOrderSearchResponse = response.data;
      yield put(searchGlobalOrdersSuccess(orders));
      yield put(completeGlobalSearch());
      // in the future  Higher up Saga will determine when all data is all fetched.
    } catch (err) {
      // failure
      yield put(searchGlobalOrdersFailure(err));
    }
  }
}

/**
 * This Saga will load any offers not found in the offers -> availableOffers array.
 * It loads offers by offers by event endpoint from ET Services and by unified API endpoint.
 */
function* fetchMissingOffersSaga() {
  const globalState = yield select();
  const availableOffers: IAvailableOffers = globalState.offers.availableOffers;
  const authToken: string | null = globalState.user.authToken;
  const contextId: number | null = globalState.user.contextId;
  const cart: ICart = globalState.carting.cart;

  const cartEventIds: string[] = [];
  const cartOfferIds: string[] = [];
  if (cart && cart.selections && cart.selections.length > 0) {
    cart.selections.forEach((selection: ISelection) => {
      const availableOffer = get(availableOffers, selection.offerID);
      if (selection.offerType !== SeatType.FLASHSEATS && isExpired(availableOffer)) {
        cartEventIds.push(selection.eventID);
        cartOfferIds.push(selection.offerID);
      }
    });
  }

  // If nothing missing, abort.
  if (!contextId || !authToken || (cartEventIds.length === 0 && cartOfferIds.length === 0)) {
    yield put(loadMissingOffersAbort());
    return;
  }

  const uniqueEventIds = uniq(cartEventIds);
  const uniqueOfferIds = uniq(cartOfferIds);

  // @todo: Refactor to run these requests in parallel.
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < uniqueEventIds.length; i = i + 1) {
    yield put(fetchEventOffersBegin());
    const request = fetchEventOffers(contextId, authToken, Number(uniqueEventIds[i]), true,
                                     globalState.user.settings.outlet.value);
    try {
      const response = yield request;
      const data = response.data;
      yield put(fetchEventOffersSuccess(data, Number(uniqueEventIds[i])));
    } catch (error) {
      console.log(`Fetch event offers: ${error}`);
    }
  }
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < uniqueOfferIds.length; i = i + 1) {
    yield put(getOfferBegin());
    const request = fetchOffer(contextId, Number(uniqueOfferIds[i]));
    const response = yield request;
    const data = response.data;
    yield put(getOfferSuccess(data));
  }
  yield put(loadMissingOffersComplete());
}

/**
 * This saga is activated after an Offer is loaded.
 */
function* fetchOfferPricingSaga() {
  const globalState = yield select();
  const availableOffers: IAvailableOffers = globalState.offers.availableOffers;
  const { contextId, selectedOfferId } = globalState.user;
  if (!selectedOfferId) { return; }
  const availableOffer = get(availableOffers, selectedOfferId.toString());
  const offer = availableOffer && availableOffer.data;
  if (contextId && offer) {
    yield put(getOfferPricingBegin());
    const request = fetchOfferPricing(contextId, selectedOfferId, true, offer.isResaleEnabled);
    const response = yield request;
    const data = response.data;
    yield put(getOfferPricingSuccess(data));
  }
}

/**
 * This saga is triggered after the default event is fetched successfully.
 */
function* fetchDefaultEventOffers() {
  const globalState = yield select();
  const defaultEventId = globalState.user.settings.event.value;
  const authToken: string | null = globalState.user.authToken;
  const contextId: number | null = globalState.user.contextId;
  const outletId: number = Number(globalState.user.settings.outlet.value);
  if (!defaultEventId || !contextId || !authToken) { return; }
  const request = fetchEventOffers(contextId, authToken, Number(defaultEventId), false, outletId);
  yield put(fetchDefaultOffersBegin());
  try {
    const response = yield request;
    const data = response.data;
    yield put(fetchEventOffersSuccess(data, Number(defaultEventId)));
  } catch (error) {
    console.log(`Fetch event offers: ${error}`);
  }
  yield put(fetchDefaultOffersEnd());
}

/////////////////
// Watchers
/////////////////
export function* fetchOffersWatcher() {
  yield takeLatest(SearchStateTypes.SEARCH_ORDERS_BEGIN, fetchOffersSaga);
}

export function* fetchMissingOffersWatcher() {
  yield takeLatest(CartingActionTypes.CARTING_END, fetchMissingOffersSaga);
}

export function* fetchOfferPricingWatcher() {
  yield takeLatest(OfferStateTypes.GET_OFFER_SUCCESS, fetchOfferPricingSaga);
}

export function* fetchDefaultEventOffersWatcher() {
  yield takeLatest(EventsStateTypes.GET_DEFAULT_EVENT_END, fetchDefaultEventOffers);
}
