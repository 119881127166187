import { takeLatest, select, put } from 'redux-saga/effects';
import { SearchStateTypes } from '../../types/searchTypes';
import { globalSearchCustomers } from '../../api/ApiManager';
import { globalSearchCustomersSuccess, searchGlobalCustomersFailure } from '../../actions/globalSearchActions';
import mockedCustomers from '../../mockData/customersGlobalSearchFallback.json';

function* fetchCustomersSaga(action: any) {
  const globalState = yield select();
  const authToken: string | null = globalState.user.authToken;
  const contextId: number | null = globalState.user.contextId;
  if (!authToken || !contextId) return;

  try {
    const request = globalSearchCustomers(contextId, authToken, action.searchQuery);
    const response = yield(request);
    const customers = response.data.results;
    if (action.searchQuery.toLowerCase() === 'testcustomers') {
      yield put(globalSearchCustomersSuccess([...mockedCustomers.data, ...customers]));
    } else {
      yield put(globalSearchCustomersSuccess(customers));
    }
  } catch (error) {
    if (action.searchQuery.toLowerCase() === 'testcustomers') {
      yield put(globalSearchCustomersSuccess(mockedCustomers.data));
    } else {
      yield put(searchGlobalCustomersFailure());
    }
  }
}

/////////////////
// Watchers
/////////////////
export function* fetchCustomersWatcher() {
  yield takeLatest(SearchStateTypes.SEARCH_CUSTOMERS_BEGIN, fetchCustomersSaga);
}

