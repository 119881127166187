import { IProductIdentity } from './commonTypes';
import { SeatType } from '../../../../common/Seat/SeatType';
import { QuantityRule } from '../../../../common/Offer/QuantityRule';

export interface ISuccessCartingResponse {
  cart: ICart;
  message?: string;
  results?: ICartingResult[];
  sessionExpireAt?: number;
}

export interface ISuccessOrderResponse {
  orderID?: string;
  status: string;
  orderDate: string;
  hasPrintImmediatelyTickets?: boolean;
  success: boolean;
  tradeActionId?: number;
}

export interface ICartingResult extends IProductIdentity {
  success: boolean;
  error?: {
    code: number;
    message: string;
  };
  priceChange?: number;
}

export interface ICartingMessage {
  message: string;
  sessionExpireAt?: number;
}

export interface ICartingError extends ICartingMessage {
  referenceNumber: string;
  success: boolean;
}

export interface IOrderError extends ICartingError {
  errorDetails?: string;
  orderID?: string;
}

export interface IOrderPayment {
  maskedNumber: string;
  paymentType: string;
}

export interface IOrderStatus {
  errorDetails?: string;
  payment?: IOrderPayment;
  status: OrderStatus;
  success: boolean;
}

export type ICartingResponse = ISuccessCartingResponse | ISuccessOrderResponse |
  ICartingMessage | ICartingError | IOrderError | IOrderStatus;

export type IOrderingResult = ISuccessOrderResponse | IOrderError;

export interface ICart {
  expiration: number;
  subTotal: number;
  feeTotal: number;
  grandTotal: number;
  selections: ISelection[];
  fees: IFee[];
  paymentMethods: IPaymentMethod[];
  taxCompProfiles?: ITaxCompProfile[];
  deliveryMethods: IDeliveryMethods;
  isEMV: boolean;
}

export interface ISelection {
  category: string;
  deliveryMethodID: string;
  eventID: string;
  offerType: SeatType;
  offerID: string;
  offerGroupID: string;
  priceTotal: number;
  productID: string;
  quantity: number;
  selectionError: QuantityRule;
  sellerNotes?: string;
  types: ISelectionType[];
  validSelection: boolean;
}

export interface ISelectionType {
  priceTypeID: string;
  priceLevelID: string;
  label: string;
  unitPrice: number;
  quantity: number;
  priceTotal: number;
  preTaxUnitPrice?: number;
  taxes: ITax[];
  items: ITypeItem[];
}

export interface ITax {
  id: number;
  amount: number;
}

export interface ITypeItem {
  info1: string;
  info2: string;
  neighborhoodPrintDescription?: string;
  rowID: string;
  rowLabel: string;
  seatID: string;
  seatLabel: string;
  sectionID: string;
  sectionLabel: string;
  sectionPrintDescription?: string;
}

export interface IFee {
  id: string;
  label: string;
  unitPrice: number;
  preTaxUnitPrice?: number;
  quantity: number;
  taxes?: ITax[];
  total: number;
}

export interface IPaymentMethod {
  id?: string;
  code: string;
}

export interface ITaxCompProfile {
  id: number;
  name: string;
  taxRate: number;
}

export interface IDeliveryMethods {
  [OfferID: string]: IDeliveryMethod[];
}

export interface IDeliveryMethod {
  id: string;
  description: string;
  process: string;
  specialInstructions: string;
}

export enum OrderStatus {
  BAD_CREDIT_CARD = 'BAD_CREDIT_CARD',
  CART_EXPIRED = 'CART_EXPIRED',
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
  DUPLICATE_CREDIT_CARD = 'DUPLICATE_CREDIT_CARD',
  ERROR_ACCOUNT = 'ERROR_ACCOUNT',
  ERROR_FS_CREDITCARD = 'ERROR_FS_CREDITCARD',
  ERROR_FS_MEMBER = 'ERROR_FS_MEMBER',
  ERROR_FS_PRICE_CHANGED = 'ERROR_FS_PRICE_CHANGED',
  ERROR_FS_SAMEOWNER = 'ERROR_FS_SAMEOWNER',
  ERROR_FS_TRADE = 'ERROR_FS_TRADE',
  ERROR_FS_TRADE_FINAL = 'ERROR_FS_TRADE_FINAL',
  ERROR_PLACE_ORDER = 'ERROR_PLACE_ORDER',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PENDING = 'PENDING',
  UNAVAILABLE = 'UNAVAILABLE',
}

