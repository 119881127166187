import CartingRequest from './CartingRequest';
import { ISeatSelection, IProductIdentity } from '../types/commonTypes';
import { ICartSelectionForRemove } from '../types/apiRequestTypes';

export default class CartRemoveSelection extends CartingRequest {
  private items: ISeatSelection[] = [];
  private strictCheck: boolean = false;
  constructor(public productIdentity: IProductIdentity, public quantityToRemove: number = -1,
              public priceTypeID?: string, public priceLevelID?: string) {
    super();
  }

  public addItems(items: ISeatSelection[]): void {
    this.items.push(...items);
  }

  public toJSON(): ICartSelectionForRemove {
    const json: ICartSelectionForRemove = {
      ...this.productIdentity,
      strictCheck: this.strictCheck,
    };
    if (this.items.length) {
      json.items = this.items;
    } else {
      json.quantityToRemove = this.quantityToRemove;
      if (this.priceTypeID) {
        json.priceTypeID = this.priceLevelID;
      }
      if (this.priceLevelID) {
        json.priceLevelID = this.priceLevelID;
      }
    }
    return json;
  }
}
