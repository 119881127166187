import {
  IMapState,
  MapActions,
  MapActionTypes,
} from '../types/mapTypes';

import Seat from '../../../../common/Seat/Seat';
import Section from '../../../../common/Section/Section';
import config from '../config';
import { loadScript } from '../helpers';

// Loading Both MMC Libraries
loadScript(config.MMCTicketing2DLibrary);
loadScript(config.MMCTicketing3DLibrary);

export const initialState: IMapState = {
  mapInventory: [],
  maps: [],
  previewMapError: false,
};

const ACTION_HANDLERS = {
  // tslint:disable-next-line: max-line-length
  [MapActionTypes.MAP_UPDATE_INVENTORY]: (state: IMapState, action: { mapId: any, sections: Section[], seats: Seat[] }) => {
    if (state && state.maps.length > 0) {
      const mapInstance = state.maps.find(map => map.containerId === action.mapId);
      if (mapInstance) {
        mapInstance.updateInventory(action.sections, action.seats);
      }
    }
    return Object.assign({}, state, { isMapLoading: false });
  },
};
export default function reducer(
  state: IMapState = initialState,
  action: MapActions,
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state; // @todo fix any crutch here.
}
