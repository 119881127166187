import { cloneDeep, isEmpty } from 'lodash';
import {
  ICartingState,
  CartingActions,
  CartingActionTypes,
} from '../types/cartingTypes';
import {
  ICartingError,
  ICartingMessage,
  ISuccessCartingResponse,
  ISuccessOrderResponse,
  OrderStatus,
  IOrderStatus,
  IOrderingResult,
} from '../types/apiResponseTypes';
import Customer from '../classes/Customer';
import { ICustomer, IAddress } from '../types/apiRequestTypes';
import { ICreditCard } from '../../../../types/creditCard';
import { validateAddress } from '../helpers';

export const initialState: ICartingState = {
  address:  {
    address1 : '',
    address2 : '',
    city : '',
    countryCode : '',
    postalCode: '',
    primaryPhone : '',
    regionCode : '',
  },
  cartDrawerOpened: false,
  carting: false,
  cashTendered: null,
  customer: null,
  errors: {},
  lastOrderStatus: null,
  lastOrderingResult: null,
  order: null,
  orderAutoPrinted: false,
  orderCheckedOut: false,
  ordering: false,
};

const setCustomerDetail = (state: ICartingState, email?: string, firstName?: string,
                           lastName?: string, creditCard?: ICreditCard | null, address?: IAddress | null)
  : ICustomer => {
  const customer = new Customer(state.customer || undefined);
  if (email !== undefined) {
    customer.email = email;
  }
  if (firstName !== undefined) {
    customer.firstName = firstName;
  }
  if (lastName !== undefined) {
    customer.lastName = lastName;
  }
  if (creditCard !== undefined) {
    customer.creditCard = creditCard || undefined;
  }
  if (address) {
    customer.resaleAddress = address;
  }
  return customer.toJSON();
};

const ACTION_HANDLERS = {
  [CartingActionTypes.CARTING_BEGIN]: (state: ICartingState) => {
    return Object.assign({}, state, { carting: true });
  },
  [CartingActionTypes.CARTING_END]: (state: ICartingState, { payload, status }: CartingActions) => {
    const newState: Partial<ICartingState> = { carting: false };
    const { results, cart, message } = payload as ISuccessCartingResponse;
    const { referenceNumber, success } = payload as ICartingError;
    newState.cart = cart || null;
    newState.lastCartingStatus = status;
    if (results && results.length) {
      newState.lastCartingResult = results[0];
    } else {
      let result: ICartingError | ICartingMessage | undefined;
      if (message && referenceNumber) {
        result = { message, referenceNumber, success };
      } else if (message) {
        result = { message };
      }
      newState.lastCartingResult = result;
    }
    return Object.assign({}, state, newState);
  },
  [CartingActionTypes.SET_CUSTOMER_CREDIT_CARD]: (state: ICartingState, { creditCard }: CartingActions) => {
    const customer = setCustomerDetail(state, undefined, undefined, undefined, creditCard as ICreditCard | null);
    return Object.assign({}, state, { customer });
  },
  [CartingActionTypes.VALIDATE_CUSTOMER_ADDRESS]: (state: ICartingState) => {
    // refactor validate logic
    const customerAddress = state.address;
    const errors = validateAddress(customerAddress);
    if (!isEmpty(errors)) {
      return Object.assign({}, state, { errors });
    }  {
      const customer = setCustomerDetail(state, undefined, undefined, undefined, undefined, customerAddress);
      return Object.assign({}, state, { errors }, { customer });
    }
  },
  [CartingActionTypes.SET_CUSTOMER_EMAIL]: (state: ICartingState, { email }: CartingActions) => {
    const customer = setCustomerDetail(state, email as string);
    return Object.assign({}, state, { customer });
  },
  [CartingActionTypes.SET_CUSTOMER_FIRST_NAME]: (state: ICartingState, { firstName }: CartingActions) => {
    const customer = setCustomerDetail(state, undefined, capitalize(firstName));
    return Object.assign({}, state, { customer });
  },
  [CartingActionTypes.SET_CUSTOMER_LAST_NAME]: (state: ICartingState, { lastName }: CartingActions) => {
    const customer = setCustomerDetail(state, undefined, undefined, capitalize(lastName));
    return Object.assign({}, state, { customer });
  },
  [CartingActionTypes.SET_PHONE_NUMBER] : (state: ICartingState, { phoneNumber }: CartingActions) => {
    const address = Object.assign({}, { ...state.address }  , { primaryPhone : phoneNumber });
    return Object.assign({}, state, { address });
  },
  [CartingActionTypes.SET_ADDRESS_1] : (state: ICartingState, { address1 }: CartingActions) => {
    const address = Object.assign({}, { ...state.address } , { address1 });
    return Object.assign({}, state, { address });
  },
  [CartingActionTypes.SET_ADDRESS_2] : (state: ICartingState, { address2 }: CartingActions) => {
    const address = Object.assign({}, { ...state.address }  , { address2 });
    return Object.assign({}, state, { address });
  },
  [CartingActionTypes.SET_COUNTRY] : (state: ICartingState, { country: countryCode }: CartingActions) => {
    const address = Object.assign({}, { ...state.address }  , { countryCode });
    return Object.assign({}, state, { address });
  },
  [CartingActionTypes.SET_STATE] : (state: ICartingState, { state: regionCode }: CartingActions) => {
    const address = Object.assign({}, { ...state.address }  , { regionCode });
    return Object.assign({}, state, { address });
  },
  [CartingActionTypes.SET_CITY] : (state: ICartingState, { city }: CartingActions) => {
    const address = Object.assign({}, { ...state.address }  , { city });
    return Object.assign({}, state, { address });
  },
  [CartingActionTypes.SET_POSTAL_CODE] : (state: ICartingState, { postalCode }: CartingActions) => {
    const address = Object.assign({}, { ...state.address }  , { postalCode });
    return Object.assign({}, state, { address });
  },
  [CartingActionTypes.PLACE_ORDER_BEGIN]: (state: ICartingState) => {
    return Object.assign({}, state, { ordering: true });
  },
  [CartingActionTypes.PLACE_ORDER_END]: (state: ICartingState, { payload, status }: CartingActions) => {
    const newState: Partial<ICartingState> = {};
    const orderingResult = payload as ISuccessOrderResponse;
    if (orderingResult.status !== OrderStatus.PENDING) {
      newState.ordering = false;
    }
    if (orderingResult.orderID !== null && state.cart) {
      newState.order = cloneDeep(state.cart);
      newState.cart = null;
    }
    newState.lastOrderingResult = payload as IOrderingResult;
    return Object.assign({}, state, newState);
  },
  [CartingActionTypes.PROCESS_ORDER_STATUS]: (state: ICartingState, { payload }: CartingActions) => {
    const newState: Partial<ICartingState> = {};
    const orderStatus = payload as IOrderStatus;
    if (orderStatus.success) {
      newState.lastOrderStatus = orderStatus;
      newState.ordering = orderStatus.status === OrderStatus.PENDING;
    } else {
      newState.ordering = false;
      newState.lastOrderStatus = {
        status: OrderStatus.UNAVAILABLE,
        success: false,
      };
    }
    return Object.assign({}, state, newState);
  },
  [CartingActionTypes.OPEN_CART_DRAWER]: (state: ICartingState) => {
    return Object.assign({}, state, { cartDrawerOpened: true });
  },
  [CartingActionTypes.CLOSE_CART_DRAWER]: (state: ICartingState) => {
    return Object.assign({}, state, { cartDrawerOpened: false });
  },
  [CartingActionTypes.TOGGLE_CART_DRAWER]: (state: ICartingState) => {
    return Object.assign({}, state, { cartDrawerOpened: !state.cartDrawerOpened });
  },
  [CartingActionTypes.CLEAR_CARTING_RESULT]: (state: ICartingState) => {
    return Object.assign({}, state, { lastCartingResult: undefined, lastCartingStatus: undefined });
  },
  [CartingActionTypes.CLEAR_ORDERING_RESULT]: (state: ICartingState) => {
    return Object.assign({}, state, { lastOrderingResult: null });
  },
  [CartingActionTypes.RESET_CART_AND_ORDER]: (state: ICartingState, { clearCustomer }: CartingActions) => {
    const newState: Partial<ICartingState> = {
      cart: undefined,
      cashTendered: null,
      lastCartingResult: undefined,
      lastCartingStatus: undefined,
      lastOrderStatus: null,
      lastOrderingResult: null,
      order: null,
      orderAutoPrinted: false,
      orderCheckedOut: false,
    };
    if (clearCustomer) {
      newState.customer = null;
    } else if (state.customer) {
      newState.customer = { ...state.customer };
      newState.customer.cards = undefined;
    }
    return Object.assign({}, state, newState);
  },
  [CartingActionTypes.COMPLETE_CHECKOUT]: (state: ICartingState) => {
    return Object.assign({}, state, { orderCheckedOut: true });
  },
  [CartingActionTypes.TENDER_CASH]: (state: ICartingState, { amount }: CartingActions) => {
    return Object.assign({}, state, { cashTendered: amount || null });
  },
  [CartingActionTypes.SET_ORDER_AUTO_PRINTED]: (state: ICartingState) => {
    return Object.assign({}, state,  { orderAutoPrinted: true });
  },
  [CartingActionTypes.RESTORE_CARTING]: (state: ICartingState, action: CartingActions) => {
    const {
      cashTendered,
      customer,
      lastOrderingResult,
      order,
      orderAutoPrinted,
      orderCheckedOut,
    } = action;
    return Object.assign({}, state, {
      cashTendered,
      customer,
      lastOrderingResult,
      order,
      orderAutoPrinted,
      orderCheckedOut,
    });
  },
};

const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export default function reducer(state: ICartingState = initialState, action: CartingActions) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
