interface IConfig {
  MMCTicketing2DLibrary: string;
  MMCTicketing3DLibrary: string;
  zoom: {
    default: number,
    increment: number,
    maxSectionHeight: number,
    minSeatLevel: number,
    mobile: number,
    scaleBy: number,
    seatLevel: number,
    zoomSpeed: number,
  };
}

const config: IConfig = {
  MMCTicketing2DLibrary: '//tk3d.tk3dapi.com/d2m/v2/latest/viewer.js',
  MMCTicketing3DLibrary: '//tk3d.tk3dapi.com/ticketing3d/stable/TICKETING3D.js',
  zoom: {
    default: 1.025,
    increment: 0.1,
    maxSectionHeight: 50,
    minSeatLevel: 5.0,
    mobile: 2.5,
    scaleBy: 1.018,
    seatLevel: 10.0,
    zoomSpeed: 16.6,
  },
};

export default config;
