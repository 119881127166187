import React from 'react';
import classnames from 'classnames';
import { ClippedText } from './styles';

export const EventDetailVenue = ({ align, children, className, color }: any) =>
  (
    <ClippedText
      color={ color }
      textAlign={ align }
      className={ classnames(['test-event-title-resizer', 'event-venue', className]) }
    >{ children }
    </ClippedText>
  );

EventDetailVenue.defaultProps = {
  color: 'axsui.text.elements.h2',
};
