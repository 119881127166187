import * as Sentry from '@sentry/browser';
import config from '../config';
import version from '../version.json';
import sentryOptions from './options';

const DEVELOPMENT = 'development';

const activate = (): void => {
  if (process.env.REACT_APP_SENTRY_ON || process.env.NODE_ENV !== DEVELOPMENT) {
    Sentry.init({
      dsn: config.sentryDSN,
      environment: process.env.REACT_APP_ENVIRONMENT || DEVELOPMENT,
      release: version.version,
      ...sentryOptions,
    });
  }
};

export default { activate };
