import axios, { AxiosRequestConfig } from 'axios';
import config from '../config/';
import { header } from '../constants/headerConstants';
import { ITransferTicketsRequest, IChangeDeliveryMethodRequest } from './types/fanAccount/requestTypes';

export const FAN_ACCOUNT_API_HOST = config.fanAccountApiHost;

const getOboUserProfilePath = (): string => '/api/User/Profile/';
const getUserTokenPath = (email: string): string => `/api/user/lookup-by-email/${encodeURI(email)}/`;
const getUserProfilePath = (email: string): string => `/api/user/${encodeURI(email)}/veritix-profile/`;
const getUserPrimaryCreditCardPath = (): string => '/api/credit-card/primary?skipFiltering=true';
const getUserSecondaryCreditCardPath = (): string => '/api/credit-card/secondary';
const getUserDetailsPath = (): string => '/api/user/detailed-profile/';
const getUserOrdersPath = (): string => '/api/orders/all/?includeUnpaid=true&includeCancelled=true';
const getSingleOrderPath = (orderId: string): string => `/api/orders/single/${orderId}/?includeCancelled=true`;
// tslint:disable-next-line: max-line-length
const getTicketHistoryPath = (orderId: string, eventId: string, eventZoneId: string, seatGroupId: string, seatId: string): string =>
  `/api/Tickets/primary-history/${orderId}/${eventId}/${eventZoneId}/${seatGroupId}/${seatId}?includeDetails=true`;
const getFlashTicketsPath = (): string => '/api/Tickets/flash';
const getTransferTicketsPath = (): string => '/api/Tickets/transfer';
const getChangeDeliveryMethodPath = (orderId: string): string => `/api/Orders/single/${orderId}/delivery-method`;
const getChangeDeliveryMethodStatusPath = (orderId: string): string => `/api/Orders/single/${orderId}/delivery-method`;

export const fetchOboUserProfile = (contextId: number, accessToken: string) => {
  const path = getOboUserProfilePath();
  const headers = {
    [header.xfasAccessToken]: accessToken,
    [header.xfasAccountNumber]: 0,
    [header.xfasBackend]: 'AXSAPI',
    [header.xfasContextId]: contextId,
  };
  const requestOptions: AxiosRequestConfig = {
    data: {
      AccessToken: accessToken,
      IncludeVeritixData: true,
    },
    headers,
    method: 'POST',
    url: `${FAN_ACCOUNT_API_HOST}${path}`,
  };
  return axios(requestOptions);
};

export const fetchUserToken = (contextId: number, oboAccessToken: string, customerEmail: string) => {
  const path = getUserTokenPath(customerEmail);
  const headers = {
    [header.xfasAccessToken]: oboAccessToken,
    [header.xfasAccountNumber]: 0,
    [header.xfasContextId]: contextId,
  };
  const requestOptions: AxiosRequestConfig = {
    headers,
    method: 'GET',
    url: `${FAN_ACCOUNT_API_HOST}${path}`,
  };
  return axios(requestOptions);
};

export const fetchUserProfile = (
  contextId: number,
  oboAccessToken: string,
  oboUserEmail: string,
  customerEmail: string,
) => {
  const path = getUserProfilePath(customerEmail);
  const headers = {
    [header.xfasAccessToken]: oboAccessToken,
    [header.xfasAccountNumber]: 0,
    [header.xfasContextId]: contextId,
    [header.xfasEmail]: oboUserEmail,
  };
  const requestOptions: AxiosRequestConfig = {
    headers,
    method: 'GET',
    url: `${FAN_ACCOUNT_API_HOST}${path}`,
  };
  return axios(requestOptions);
};

export const fetchUserDetails = (
  contextId: number, oboAccessToken: string, oboUserEmail: string,
  customerEmail: string, customerAccessToken: string, region: number,
) => {
  const path = getUserDetailsPath();
  const headers = {
    [header.xfasAccessToken]: oboAccessToken,
    [header.xfasAccountNumber]: 0,
    [header.xfasBackend]: 'AXSAPI',
    [header.xfasContextId]: contextId,
    [header.xfasEmail]: oboUserEmail,
    [header.xfasRegion]: region,
  };
  const requestOptions: AxiosRequestConfig = {
    data: {
      accessToken: customerAccessToken,
      email: customerEmail,
    },
    headers,
    method: 'POST',
    url: `${FAN_ACCOUNT_API_HOST}${path}`,
  };
  return axios(requestOptions);
};


export const fetchUserPrimaryCreditCards = (contextId: number, customerAccessToken: string) => {
  const path = getUserPrimaryCreditCardPath();
  const headers = {
    [header.xfasAccessToken]: customerAccessToken,
    [header.xfasAccountNumber]: 0,
    [header.xfasBackend]: 'AXSAPI',
    [header.xfasContextId]: contextId,
  };
  const requestOptions: AxiosRequestConfig = {
    headers,
    method: 'GET',
    url: `${FAN_ACCOUNT_API_HOST}${path}`,
  };
  return axios(requestOptions);
};

export const fetchUserSecondaryCreditCards = (
  contextId: number,
  axsUserId: string,
  customerAccessToken: string,
  region: number,
) => {
  const path = getUserSecondaryCreditCardPath();
  const headers = {
    [header.xfasAccessToken]: customerAccessToken,
    [header.xfasAccountNumber]: 0,
    [header.xfasAxsUserId]: axsUserId,
    [header.xfasBackend]: 'AXSAPI',
    [header.xfasContextId]: contextId,
    [header.xfasRegion]: region,
  };
  const requestOptions: AxiosRequestConfig = {
    headers,
    method: 'GET',
    url: `${FAN_ACCOUNT_API_HOST}${path}`,
  };
  return axios(requestOptions);
};

export const fetchUserOrders = (contextId: number, customerAccessToken: string, region: number) => {
  const path = getUserOrdersPath();
  const headers = {
    [header.xfasAccessToken]: customerAccessToken,
    [header.xfasAccountNumber]: 0,
    [header.xfasBackend]: 'AXSAPI',
    [header.xfasContextId]: contextId,
    [header.xfasRegion]: region,
  };
  const requestOptions: AxiosRequestConfig = {
    headers,
    method: 'GET',
    url: `${FAN_ACCOUNT_API_HOST}${path}`,
  };
  return axios(requestOptions);
};

export const fetchSingleOrder = (contextId: number, customerAccessToken: string, region: number, orderId: string) => {
  const path = getSingleOrderPath(orderId);
  const headers = {
    [header.xfasAccessToken]: customerAccessToken,
    [header.xfasAccountNumber]: 0,
    [header.xfasBackend]: 'AXSAPI',
    [header.xfasContextId]: contextId,
    [header.xfasRegion]: region,
  };
  const requestOptions: AxiosRequestConfig = {
    headers,
    method: 'GET',
    url: `${FAN_ACCOUNT_API_HOST}${path}`,
  };
  return axios(requestOptions);
};

export const fetchSeatHistory = (
  contextId: number,
  customerAccessToken: string,
  orderId: string,
  seatVid: {
    eventId: string,
    eventZoneId: string,
    seatGroupId: string,
    seatId: string,
  },
  ticketId?: string,
) => {
  const path = getTicketHistoryPath(
    orderId,
    seatVid.eventId,
    seatVid.eventZoneId,
    seatVid.seatGroupId,
    seatVid.seatId,
  );
  const headers = {
    [header.xfasAccessToken]: customerAccessToken,
    [header.xfasAccountNumber]: 0,
    [header.xfasBackend]: 'AXSAPI',
    [header.xfasContextId]: contextId,
    [header.xfasRegion]: 1,
  };
  const requestOptions: AxiosRequestConfig = {
    headers,
    method: 'GET',
    url: `${FAN_ACCOUNT_API_HOST}${path}`,
  };
  return axios(requestOptions);
};

export const fetchFlashTickets = (contextId: number, accessToken: string, region: number) => {
  const path = getFlashTicketsPath();
  const headers = {
    [header.xfasAccessToken]: accessToken,
    [header.xfasAccountNumber]: 0,
    [header.xfasBackend]: 'AXSAPI',
    [header.xfasContextId]: contextId,
    [header.xfasRegion]: region,
  };

  const requestOptions: AxiosRequestConfig = {
    headers,
    method: 'GET',
    url: `${FAN_ACCOUNT_API_HOST}${path}`,
  };

  return axios(requestOptions);
};

export const submitTransferTickets = (
  accessToken: string,
  request: ITransferTicketsRequest,
) => {
  const path = getTransferTicketsPath();
  const headers = {
    [header.xfasAccessToken]: accessToken,
    [header.xfasAccountNumber]: 0,
    [header.xfasBackend]: 'AXSAPI',
    [header.xfasContextId]: 0,
    [header.xfasRegion]: 0,
  };

  const requestOptions: AxiosRequestConfig = {
    data: request,
    headers,
    method: 'POST',
    url: `${FAN_ACCOUNT_API_HOST}${path}`,
  };

  return axios(requestOptions);
};

export const submitChangeDeliveryMethod = (
  contextId: number,
  customerAccessToken: string,
  region: number,
  orderId: string,
  request: IChangeDeliveryMethodRequest,
) => {
  const path = getChangeDeliveryMethodPath(orderId);
  const headers = {
    [header.xfasAccessToken]: customerAccessToken,
    [header.xfasAccountNumber]: 0,
    [header.xfasBackend]: 'AXSAPI',
    [header.xfasContextId]: contextId,
    [header.xfasRegion]: region,
  };

  const requestOptions: AxiosRequestConfig = {
    data: request,
    headers,
    method: 'POST',
    url: `${FAN_ACCOUNT_API_HOST}${path}`,
  };

  return axios(requestOptions);
};

export const fetchChangeDeliveryMethodStatus = (
  contextId: number,
  customerAccessToken: string,
  region: number,
  orderId: string,
) => {
  const path = getChangeDeliveryMethodStatusPath(orderId);
  const headers = {
    [header.xfasAccessToken]: customerAccessToken,
    [header.xfasAccountNumber]: 0,
    [header.xfasBackend]: 'AXSAPI',
    [header.xfasContextId]: contextId,
    [header.xfasRegion]: region,
  };

  const requestOptions: AxiosRequestConfig = {
    headers,
    method: 'GET',
    url: `${FAN_ACCOUNT_API_HOST}${path}`,
  };

  return axios(requestOptions);
};
