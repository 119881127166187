import styled from 'styled-components';
import { AXSBlock, AXSFlex, AXSIcon, AXSLabel } from '@axs/axs-ui';
import { colors } from '@axs/axs-ui/dist/constants/colors';
import { headerBarHeight, themeColors, zIndexes } from '../../theme';

export const HeaderBar = styled(AXSBlock)`
  background-color: ${({ theme }) => theme.skin === 'AXS' ? colors.WHITE : themeColors.BLUE_6 };
  min-height: ${ headerBarHeight };
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${ zIndexes.MAIN_HEADER };
`;

export const LogoRow = styled(AXSFlex)`
  height: 65px;
`;

export const Logo = styled(AXSIcon)`
  color: ${({ theme }) => theme.skin === 'AXS' ? colors.BLUE_MEDIUM_300 : colors.WHITE };
`;

export const AXSPill = styled(AXSLabel)`
`;

export const Expander = styled(AXSIcon)`
  margin-left: 3px;
`;

export const CartStatus = styled.div`
  cursor: pointer;
`;
