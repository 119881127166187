import { AnyAction } from 'redux';
import {
  ICart,
  ICartingError,
  ICartingMessage,
  ICartingResponse,
  ICartingResult,
  IOrderStatus,
  IOrderingResult,
} from './apiResponseTypes';
import { ICustomer, IAddress } from './apiRequestTypes';
import { ICreditCard } from '../../../../types/creditCard';

export interface ICartingState {
  address: IAddress;
  cart?: ICart | null;
  cartDrawerOpened: boolean;
  carting: boolean;
  cashTendered: number | null;
  customer: ICustomer | null;
  errors: any;
  lastCartingResult?: ICartingResult | ICartingMessage | ICartingError;
  lastCartingStatus?: number;
  lastOrderStatus: IOrderStatus | null;
  lastOrderingResult: IOrderingResult | null;
  order: ICart | null;
  orderAutoPrinted: boolean;
  orderCheckedOut: boolean;
  ordering: boolean;
}

export enum CartingActionTypes {
  CARTING_BEGIN = 'CARTING_BEGIN',
  CARTING_END = 'CARTING_END',
  CLEAR_CARTING_RESULT = 'CLEAR_CARTING_RESULT',
  CLEAR_ORDERING_RESULT = 'CLEAR_ORDERING_RESULT',
  CLOSE_CART_DRAWER = 'CLOSE_CART_DRAWER',
  COMPLETE_CHECKOUT = 'COMPLETE_CHECKOUT',
  OPEN_CART_DRAWER = 'OPEN_CART_DRAWER',
  PLACE_ORDER_BEGIN = 'PLACE_ORDER_BEGIN',
  PLACE_ORDER_END = 'PLACE_ORDER_END',
  PROCESS_ORDER_STATUS = 'PROCESS_ORDER_STATUS',
  RESET_CART_AND_ORDER = 'RESET_CART_AND_ORDER',
  RESTORE_CARTING = 'RESTORE_CARTING',
  SET_ADDRESS_1 = 'SET_ADDRESS_1',
  SET_ADDRESS_2 = 'SET_ADDRESS_2',
  SET_CITY = 'SET_CITY',
  SET_COUNTRY = 'SET_COUNTRY',
  SET_CUSTOMER_CREDIT_CARD = 'SET_CUSTOMER_CREDIT_CARD',
  SET_CUSTOMER_EMAIL = 'SET_CUSTOMER_EMAIL',
  SET_CUSTOMER_FIRST_NAME = 'SET_CUSTOMER_FIRST_NAME',
  SET_CUSTOMER_LAST_NAME = 'SET_CUSTOMER_LAST_NAME',
  SET_ORDER_AUTO_PRINTED = 'SET_ORDER_AUTO_PRINTED',
  SET_PHONE_NUMBER = 'SET_PHONE_NUMBER',
  SET_POSTAL_CODE = 'SET_POSTAL_CODE',
  SET_STATE = 'SET_STATE',
  TENDER_CASH = 'TENDER_CASH',
  TOGGLE_CART_DRAWER = 'TOGGLE_CART_DRAWER',
  VALIDATE_CUSTOMER_ADDRESS = 'VALIDATE_CUSTOMER_ADDRESS',
}

export interface IValidateCustomerAddress extends AnyAction {
  type: CartingActionTypes.VALIDATE_CUSTOMER_ADDRESS;
}
export interface ISetPhoneNumber extends AnyAction {
  type: CartingActionTypes.SET_PHONE_NUMBER;
}
export interface ISetAddress1 extends AnyAction {
  type: CartingActionTypes.SET_ADDRESS_1;
}
export interface ISetAddress2 extends AnyAction {
  type: CartingActionTypes.SET_ADDRESS_2;
}
export interface ISetCity extends AnyAction {
  type: CartingActionTypes.SET_CITY;
}
export interface ISetState extends AnyAction {
  type: CartingActionTypes.SET_STATE;
}
export interface ISetPostalCode extends AnyAction {
  type: CartingActionTypes.SET_POSTAL_CODE;
}
export interface ISetCountry extends AnyAction {
  type: CartingActionTypes.SET_COUNTRY;
}

export interface ICartingBegin extends AnyAction {
  type: CartingActionTypes.CARTING_BEGIN;
}

export interface ICartingEnd extends AnyAction {
  type: CartingActionTypes.CARTING_END;
  payload: ICartingResponse;
  status?: number;
}

export interface IProcessOrderStatus extends AnyAction {
  payload: ICartingResponse;
  type: CartingActionTypes.PROCESS_ORDER_STATUS;
}

export interface ISetCustomerCreditCard extends AnyAction {
  type: CartingActionTypes.SET_CUSTOMER_CREDIT_CARD;
  creditCard: ICreditCard | null;
}

export interface ISetCustomerEmail extends AnyAction {
  type: CartingActionTypes.SET_CUSTOMER_EMAIL;
  email: string;
}

export interface ISetCustomerFirstName extends AnyAction {
  type: CartingActionTypes.SET_CUSTOMER_FIRST_NAME;
  firstName: string;
}

export interface ISetCustomerLastName extends AnyAction {
  type: CartingActionTypes.SET_CUSTOMER_LAST_NAME;
  lastName: string;
}

export interface IPlaceOrderBegin extends AnyAction {
  type: CartingActionTypes.PLACE_ORDER_BEGIN;
}

export interface IPlaceOrderEnd extends AnyAction {
  type: CartingActionTypes.PLACE_ORDER_END;
  payload: ICartingResponse;
  status?: number;
}

export interface IOpenCartDrawer extends AnyAction {
  type: CartingActionTypes.OPEN_CART_DRAWER;
}

export interface ICloseCartDrawer extends AnyAction {
  type: CartingActionTypes.CLOSE_CART_DRAWER;
}

export interface IToggleCartDrawer extends AnyAction {
  type: CartingActionTypes.TOGGLE_CART_DRAWER;
}

export interface IClearCartingResult extends AnyAction {
  type: CartingActionTypes.CLEAR_CARTING_RESULT;
}

export interface IClearOrderingResult extends AnyAction {
  type: CartingActionTypes.CLEAR_ORDERING_RESULT;
}

export interface IResetCartAndOrder extends AnyAction {
  type: CartingActionTypes.RESET_CART_AND_ORDER;
  clearCustomer: boolean;
}

export interface ICompleteCheckout extends AnyAction {
  type: CartingActionTypes.COMPLETE_CHECKOUT;
}

export interface ITenderCash extends AnyAction {
  type: CartingActionTypes.TENDER_CASH;
  amount?: number;
}

export interface ISetOrderAutoPrinted extends AnyAction {
  type: CartingActionTypes.SET_ORDER_AUTO_PRINTED;
}

export interface IRestoreCarting extends AnyAction {
  cashTendered: number | null;
  customer: ICustomer | null;
  lastOrderingResult: IOrderingResult | null;
  order: ICart | null;
  orderAutoPrinted: boolean;
  orderCheckedOut: boolean;
  type: CartingActionTypes.RESTORE_CARTING;
}

export type CartingActions =
  ICartingBegin |
  ICartingEnd |
  IClearCartingResult |
  IClearOrderingResult |
  ICloseCartDrawer |
  ICompleteCheckout |
  IOpenCartDrawer |
  IPlaceOrderBegin |
  IPlaceOrderEnd |
  IProcessOrderStatus |
  IResetCartAndOrder |
  IRestoreCarting |
  ISetAddress1 |
  ISetAddress2 |
  ISetCity |
  ISetCountry |
  ISetCustomerCreditCard |
  ISetCustomerEmail |
  ISetCustomerFirstName |
  ISetCustomerLastName |
  ISetPhoneNumber |
  ISetPostalCode |
  ISetState |
  IToggleCartDrawer |
  IValidateCustomerAddress;
