enum ProcessType {
  EXTERNAL_LISTED_FLASHSEATS = 'ExternalListedFlashSeats',
  EXTERNAL_LISTED_PRINT_AT_HOME = 'ExternalListedPrintAtHome',
  EXTERNAL_LISTED_PRINT_IMMEDIATELY = 'ExternalListedPrintImmediately',
  EXTERNAL_LISTED_PDF = 'ExternalListedPdf',
  EXTERNAL_LISTED_STANDARD_SHIPPING = 'ExternalListedStandardShipping',
  EXTERNAL_LISTED_WILL_CALL = 'ExternalListedWillCall',
  EXTERNAL_PDF = 'ExternalPdf',
  FLASHSEATS = 'FlashSeats',
  FLASHSEATS_HOLD = 'FlashSeatsHold',
  PRINT_AT_HOME = 'PrintAtHome',
  PRINT_IMMEDIATELY = 'PrintImmediately',
  STANDARD_SHIPPING = 'StandardShipping',
  WILL_CALL = 'WillCall',
}
export { ProcessType };
