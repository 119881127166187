import CustomerAddress from './CustomerAddress';

export enum CUSTOMER_PAYMENT_METHOD_SOURCE {
  BACK_OFFICE = 'BACK_OFFICE',
  FLASH = 'FLASH',
}

export class CustomerCreditCard {

  constructor(
    public billingAddress: CustomerAddress,
    public cardNumber: string,
    public expireMonth: string,
    public expireYear: string,
    public nameOnCard: string,
    public paymentID: string,
    public code: string,
    public source: string,
  ) {
  }
}
