import { IRawDynamicPriceRanges, IDynamicPrices } from '../../api/types/unifiedapi/responseTypes';
import { IDynamicPriceRanges, IMinMax } from '../../common/Price/DynamicPriceRange';
import DynamicPrice from '../../common/Price/DynamicPrice';
import { get } from 'lodash';

/**
 * Receives the dynamic price range structure from the api and maps it to an Interface
 *
 * @param IRawDynamicPriceRanges
 */

export const cookDynamicPriceRanges = (rawDynamicPriceRanges: IRawDynamicPriceRanges): IDynamicPriceRanges => {
  const dynamicPriceRanges: IDynamicPriceRanges = {};
  for (const key of Object.keys(rawDynamicPriceRanges)) {
    const [PriceLevelID, PriceCodeID, SectionID] = key.split('-');
    if (PriceLevelID === '*' && PriceCodeID === '*' && SectionID === '*') {
      dynamicPriceRanges.all = rawDynamicPriceRanges[key];
    } else if (PriceCodeID === '*' && SectionID === '*') {
      dynamicPriceRanges.PL = dynamicPriceRanges.PL || {};
      dynamicPriceRanges.PL[PriceLevelID] = rawDynamicPriceRanges[key];
    } else if (SectionID === '*') {
      dynamicPriceRanges.PLPC = dynamicPriceRanges.PLPC || {};
      dynamicPriceRanges.PLPC[PriceLevelID] = dynamicPriceRanges.PLPC[PriceLevelID] || {};
      dynamicPriceRanges.PLPC[PriceLevelID][PriceCodeID] = rawDynamicPriceRanges[key];
    } else if (PriceLevelID === '*') {
      dynamicPriceRanges.PCSections = dynamicPriceRanges.PCSections || {};
      dynamicPriceRanges.PCSections[PriceCodeID] = dynamicPriceRanges.PCSections[PriceCodeID] || {};
      dynamicPriceRanges.PCSections[PriceCodeID][SectionID] = rawDynamicPriceRanges[key];
    } else {
      dynamicPriceRanges.PLPCSections = dynamicPriceRanges.PLPCSections || {};
      dynamicPriceRanges.PLPCSections[PriceLevelID] = dynamicPriceRanges.PLPCSections[PriceLevelID] || {};
      dynamicPriceRanges.PLPCSections[PriceLevelID][PriceCodeID] =
        dynamicPriceRanges.PLPCSections[PriceLevelID][PriceCodeID] || {};
      dynamicPriceRanges.PLPCSections[PriceLevelID][PriceCodeID][SectionID] = rawDynamicPriceRanges[key];
    }
  }
  return dynamicPriceRanges;
};

/**
 * Receives the dynamic price raw data from api and Build a list of Dynamic prices
 *
 * @param IDynamicPrices
 */
export const cookDynamicPrices = (rawDynamicPrices: IDynamicPrices): DynamicPrice[] => {
  const dynamicPrices: DynamicPrice[] = [];
  Object.keys(rawDynamicPrices).forEach((dynamicPriceId: string) => {
    const [PriceCodeID, SectionID, RowID, SeatID, PriceLevelID] = dynamicPriceId.split('-');
    const dynamicPrice: DynamicPrice = new DynamicPrice(
      rawDynamicPrices[dynamicPriceId],
      parseInt(PriceLevelID),
      parseInt(PriceCodeID),
      parseInt(SectionID),
      parseInt(RowID),
      parseInt(SeatID),
    );
    dynamicPrices.push(dynamicPrice);
  });
  return dynamicPrices;
};

export const getDynamicPriceLevelMinMax = (dpRange: IDynamicPriceRanges, priceLevelId: number): IMinMax | undefined => {
  const priceLevelMinMax: IMinMax | undefined = get(dpRange, `PL.${priceLevelId}`);
  if (priceLevelMinMax) {
    return {
      max: priceLevelMinMax.max,
      min: priceLevelMinMax.min,
    };
  }
  return undefined;
};
