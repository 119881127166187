import React from 'react';

const AXSLogo = (
    <svg viewBox="0 0 1006 439">
        <defs>
            <path id="a" d="M0 .026h315.91v366.388H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
// tslint:disable-next-line: max-line-length
                d="M911.05 150.798c-18.146-4.79-39.681-9.697-57.808-14.61-3.625-.982-45.393-11.49-45.393-36.03 0-27.792 34.061-32.078 57.109-32.078 28.406 0 64.784 10.927 75.443 15.438 10.658 4.511 14.827.826 20.176-5.584l33.32-39.948c2.038-2.446 2.004-7.902-3.666-10.408 0 0-61.068-27.063-122.613-27.063-31.649 0-57.993 2.175-83.906 13.138-25.64 10.86-61.808 37.419-61.808 92.566 0 74.146 55.48 92.03 82.426 100.129 20.683 6.225 79.679 16.535 96.123 28.576 11.939 8.742 15.557 22.57 12.894 35.747-3.128 15.468-20.755 28.904-62.935 28.904-33.714 0-55.495-8.454-88.373-24.734-8.515-4.217-9.634-4.27-14.737 1.747l-34.618 40.818c-5.239 6.177-9.016 11.037-.353 16.096 16.505 9.64 86.913 38.733 147.578 38.733 68.124 0 140.091-26.663 140.091-114.819 0-42.527-15.884-87.333-88.95-106.618"
            />
            <g transform="translate(0 .307)">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <path
                    fill="currentColor"
// tslint:disable-next-line: max-line-length
                    d="M205.318 275.9c-13.28 18.168-31.979 27.257-56.087 27.257-15.998 0-28.669-4.67-38.013-14.014-9.333-9.35-14.014-21.907-14.014-37.698 0-21.194 6.752-36.143 20.26-44.868 13.492-8.713 56.435-11.958 56.435-11.958 8.197-.625 21.64-1.13 29.854-1.13h6.56c8.22 0 14.938 1.936 14.938 9.037 0 27.697-6.653 55.203-19.933 73.375M162.011.026c-71.6 0-119.52 20.64-130.028 25.112-6.692 2.848-6.304 8.263-4.267 10.707l33.32 39.95c7.363 8.824 11.61 8.608 18.482 6.211.19-.066 33.744-14.375 82.493-14.375 20.765 0 36.426 3.902 47.2 11.373 14.576 10.109 14.84 27.506 15.882 37.976.697 7.004-6.56 10.778-14.78 10.778h-48.302c-55.25 0-96.074 9.714-122.443 29.125C13.193 176.306 0 206.371 0 247.075c0 37.61 11.057 66.888 33.181 87.865 22.124 20.977 53.79 31.474 92.692 31.474 39.138 0 71.745-13.655 99.349-48.346 7.019-8.822 11.712-1.46 11.712-1.46l31.017 36.46s.218.272.636.678c1.24 1.209 4.284 3.641 8.721 3.641h30.13c5.26 0 8.414-2.816 8.472-6.838V158.29c0-59.403-11.464-100.628-34.436-123.698C258.535 11.556 218.707.026 162.011.026"
                />
            </g>
            <path
                fill="currentColor"
// tslint:disable-next-line: max-line-length
                d="M726.694 14.855c4.674-5.518 2.598-9.986-4.61-9.986H570.256c-5.414 0-11.835 3.938-14.29 8.761l-11.998 23.925 47.928 136.239 134.799-158.94zM690.847 424.929L482.377 16.55c-3.273-6.431-11.829-11.682-19.063-11.682H313.626c-7.202 0-9.296 4.468-4.61 9.986l144.496 173.234-79.739 157.878c-3.256 6.441 0 11.724 7.209 11.724l42.41-.004c5.902 0 9.344-4.307 9.344-4.307l80.028-94.256 138.213 165.82c.053.064 7.58 9.363 16.067 9.363h18.244c2.433 0 10.385.074 5.562-9.374l-.003-.004"
            />
        </g>
    </svg>
    );

export default AXSLogo;
