import { takeLatest, select, put } from 'redux-saga/effects';
import { ISessionInfo, ISessionItem, ISessionItemFromAPI, IUserState, UserStateTypes } from '../../types/userTypes';
import { getSessionInfo, createSession } from '../../api/ApiManager';
import { clearSessionInfo, getSessionInfoSuccess, loginFailure, setSession } from '../../actions/userActions';
import { get } from 'lodash';
import { getStartOfTodayUTC, getEndOfTodayUTC } from '../../utils/common';

export function* getSessionData() {
  const globalState = yield select();
  const contextId = globalState.user.contextId;
  const userId = globalState.user.userId;
  const startDateTimeUtc = getStartOfTodayUTC();
  const endDateTimeUtc = getEndOfTodayUTC();
  const sessionId = globalState.user.sessionId;

  try {
    const request = getSessionInfo(contextId, userId, startDateTimeUtc, endDateTimeUtc, sessionId);
    const response = yield(request);
    const final = wrapperCamelHelper(response.data);
    yield put(getSessionInfoSuccess(final));
  } catch (err) {
    yield put(clearSessionInfo(true));
  }
}

const wrapperCamelHelper = (data: any): ISessionInfo => {
  const resultObj: ISessionInfo = {
    grandTotal : 0,
    items: [],
    totalCount: 0,
  };
  resultObj.grandTotal = data.GrandTotal;
  resultObj.totalCount = data.TotalCount;
  if (data.Items) {
    data.Items.forEach((Item: ISessionItemFromAPI) => {
      const formattedItem: ISessionItem = {
        amount: Item.Amount,
        count: Item.Count,
        mopLabel: Item.MopLabel,
      };
      resultObj.items.push(formattedItem);
    });
  }
  return resultObj;
};

function* extendSession() {
  const globalState = yield select();
  const { authToken, contextId, settings } = globalState.user as IUserState;
  const { value: outletId } = settings.outlet;
  const userCartTTL = get(settings, 'cartTimer.value');
  if (authToken) {
    try {
      const contextIdStr = contextId ? contextId.toString() : undefined;
      const request = createSession(authToken, contextIdStr, outletId, userCartTTL, true);
      const response = yield(request);
      if (response && response.data) {
        const validSession: boolean = get(response, 'data.sessionConfiguration.boxOffice.validSession', false);
        if (validSession) {
          const sessionId: string = get(response, 'data.sessionConfiguration.boxOffice.sessionId', '');
          const sessionExpiration: number = get(response, 'data.sessionConfiguration.boxOffice.expireAt');
          yield put(setSession(sessionId, sessionExpiration));
        } else {
          yield put(setSession(null));
          yield put(loginFailure('Unable to extend valid Box Office session'));
        }
      }
    } catch (err) {
      yield put(setSession(null));
      yield put(loginFailure('Unable to extend valid Box Office session'));
    }
  }
}

/////////////////
// Watchers
/////////////////

export function* fetchSessionDataWatcher() {
  yield takeLatest(UserStateTypes.GET_SESSION_INFO_BEGIN, getSessionData);
}

export function* extendSessionWatcher() {
  yield takeLatest(UserStateTypes.EXTEND_SESSION, extendSession);
}
