import { takeLatest, select, put } from 'redux-saga/effects';
import { SearchStateTypes } from '../../types/searchTypes';
import { fetchEventById, fetchEventsByName } from '../../api/ApiManager';
import { searchEventsSuccess, searchEventsFailure } from '../../actions/globalSearchActions';

import {
  getDefaultEventBegin,
  getDefaultEventEnd,
  getEventByIdBegin,
  getEventByIdSuccess,
  loadMissingEventsAbort,
  loadMissingEventsComplete,
} from '../../actions/eventsActions';
import { get, uniq } from 'lodash';
import { ICart, ISelection } from '../../mfe/carting/Carting/types/apiResponseTypes';
import { CartingActionTypes } from '../../mfe/carting/Carting/types/cartingTypes';
import { IAvailableEvents, IAvailableEvent, EventsStateTypes, IGetDefaultEvent } from '../../types/eventsTypes';
import { setNotification } from '../../actions/appActions';
import { isExpired } from '../../utils/common';
import { setSetting } from '../../actions/userActions';

function* fetchEventsSaga(action: any) {
  const globalState = yield select();
  const authToken: string | null = globalState.user.authToken;
  const contextId: number | null = globalState.user.contextId;
  const searchText = action.searchText;
  if (authToken && contextId) {
    try {
      const eventsResponse = yield(fetchEventsByName(contextId, authToken, searchText));
      yield put(searchEventsSuccess({ ...eventsResponse.data, contextId }));
      // yield put(globalSearchComplete());
      // in the future  Higher up Saga will determine when all data is all fetched.
    } catch (err) {
      // failure
      yield put(searchEventsFailure(err));
    }
  }
}

/**
 * This Saga will load any events not found in the events -> availableEvents array.
 */
function* fetchMissingEventsSaga() {
  const globalState = yield select();
  const availableEvents: IAvailableEvents = globalState.events.availableEvents;
  const authToken: string | null = globalState.user.authToken;
  const contextId: number | null = globalState.user.contextId;
  const cart: ICart = globalState.carting.cart;

  const cartEventIds: string[] = [];
  if (cart && cart.selections && cart.selections.length > 0) {
    cart.selections.forEach((selection: ISelection) => {
      const availableEvent = get(availableEvents, selection.eventID);
      if (isExpired(availableEvent)) {
        cartEventIds.push(selection.eventID);
      }
    });
  }

  // If nothing missing, abort.
  if (!contextId || !authToken || cartEventIds.length === 0) {
    yield put(loadMissingEventsAbort());
    return;
  }

  const uniqueIds = uniq(cartEventIds);

  // @TODO - This need to be reworked to run these in parallel.
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < uniqueIds.length; i = i + 1) {
    yield put(getEventByIdBegin());
    const request = fetchEventById(contextId, authToken, uniqueIds[i]);
    const response = yield request;
    const data = response.data;
    data.contextId = contextId;
    yield put(getEventByIdSuccess(data));
  }
  yield put(loadMissingEventsComplete());
}

/**
 * Users can add a default event for first item display on the dashboard page. This Saga will load it if needed.
 */
function* fetchDefaultEvent(action: IGetDefaultEvent) {
  const globalState = yield select();
  const availableEvents: IAvailableEvents = globalState.events.availableEvents;
  const defaultEventId = action.eventId || globalState.user.settings.event.value;
  if (!defaultEventId) { return; }
  const defaultEvent: IAvailableEvent | undefined = get(availableEvents, defaultEventId);
  const authToken: string | null = globalState.user.authToken;
  const contextId: number | null = globalState.user.contextId;

  if (isExpired(defaultEvent)) {
    yield put(getDefaultEventBegin());
    try {
      const request = fetchEventById(contextId as number, authToken as string, defaultEventId);
      const response = yield request;
      if (response && response.data && response.data.Event && response.data.Event.Id) {
        const data = response.data;
        yield put(getEventByIdSuccess(data));
        const event = data.Event;
        yield put(setSetting('event', { label: event.EventName, value: event.Id.toString() }));
      } else {
        yield put(setNotification('danger', 'events.unableToLoadDefaultEvent', true));
      }
    } catch (e) {
      yield put(setNotification('danger', 'events.unableToLoadDefaultEvent', true));
    }
  } else {
    const event = defaultEvent.data!;
    yield put(setSetting('event', { label: event.name, value: event.id.toString() }));
  }
  yield put(getDefaultEventEnd());
}

/////////////////
// Watchers
/////////////////
export function* fetchEventsWatcher() {
  yield takeLatest(SearchStateTypes.SEARCH_EVENTS_BEGIN, fetchEventsSaga);
}

export function* fetchDefaultEventsWatcher() {
  yield takeLatest(EventsStateTypes.GET_DEFAULT_EVENT, fetchDefaultEvent);
  yield takeLatest(EventsStateTypes.GET_UPCOMING_EVENTS_SUCCESS, fetchDefaultEvent);
}

export function* fetchMissingEventsWatcher() {
  yield takeLatest(CartingActionTypes.CARTING_END, fetchMissingEventsSaga);
}
