export const ORDER_NUMBER_FLASH = 'FLASH';

export const IN_ACCOUNT_STATUS = {
  CONVERTED_IN_ACCOUNT: 4,
  CONVERTED_NOT_IN_ACCOUNT: 5,
  IN_ACCOUNT: 1,
  SOLD: 3,
  STAGING: 0,
  TRANSFERRED: 2,
};

export const TICKET_DELIVERY_TYPE = {
  ADMINISTRATIVE_TRANSFER_COMPLETED: 4,
  CANCELLATION: 5,
  PRIMARY_DELIVERY: 1,
  REDEEMED: 8,
  SALE: 3,
  TRANSFER_COMPLETED: 2,
  TRANSFER_RECALLED: 6,
  TRANSFER_WAITING_FOR_PICKUP: 7,
  UNUSED: 9,
};

export const TICKET_STATUSES = {
  AWAITING_DELIVERY: 'AWAITING DELIVERY',
  CANCELLED: 'CANCELLED',
  E_TICKET: 'E-TICKET',
  FORWARDED: 'FORWARDED',
  IN_ACCOUNT: 'IN ACCOUNT',
  LISTED: 'LISTED',
  NOT_REDEEMED: 'NOT REDEEMED',
  PENDING: 'PENDING',
  POSTED: 'POSTED',
  PRINTED: 'PRINTED',
  RECEIVED: 'RECEIVED',
  REDEEMED: 'REDEEMED',
  RESOLD: 'RESOLD',
  SHIPPED: 'SHIPPED',
  SOLD: 'SOLD',
  SOLD_REDEEMED: 'SOLD-REDEEMED',
  STAGING: 'STAGING',
  TRANSFERRED: 'TRANSFERRED',
  TRANSFERRED_REDEEMED: 'TRANSFERRED-REDEEMED',
  TRANSFER_WAITING: 'TRANSFER WAITING',
};

export const TICKET_STATES_IDS = {
  CANCELLED: 7,
  EVENT_OVER: 8,
  LISTED: 2,
  PHANTOM: 11, // not utilized
  RECALL_PENDING: 10,
  REDEEMED: 5,
  TRADABLE: 1,
  TRADE_PENDING: 3, // not utilized
  TRANSFER_PENDING: 4, // not utilized
  TRANSFER_WAITING: 9,
  UNUSED: 6, // same as 'Not Redeemed'
};
