import React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import LoginLayout from '../layouts/Login';

const LoginContainer = Loadable({
  loader: () => import('../containers/login'),
  loading: () => null,
});

export default function (store: any, props: any) {
  // call Register Reducer here
  const { match } = props;
  return (
    <Switch>
      <LoginLayout>
        <Route path={ match.url } component={ LoginContainer } />
      </LoginLayout>
    </Switch>
  );
}
