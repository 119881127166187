import { fetchEventsByName, fetchUpcomingEvents } from '../api/ApiManager';
import { ThunkAction } from 'redux-thunk';
import { IFullStorageShape } from '../store/store.types';
import { AxiosResponse, AxiosError } from 'axios';
import get from 'lodash/get';

import {
  EventsStateActions,
  EventsStateTypes,
  IGetCmsEventAbort,
  IGetCmsEventBegin,
  IGetCmsEventCompleted,
  IGetCmsEventFailure,
  IGetCmsEventSuccess,
  IGetDefaultEvent,
  IGetDefaultEventBegin,
  IGetDefaultEventEnd,
  IGetEventByIdBegin,
  IGetEventByIdFailure,
  IGetEventByIdSuccess,
  IGetEventsByNameBegin,
  IGetEventsByNameFailure,
  IGetEventsByNameSuccess,
  IGetUpcomingEventsBegin,
  IGetUpcomingEventsFailure,
  IGetUpcomingEventsSuccess,
  IRemoveExpiredEvents,
  IUpdateEventSeatCounts,
} from '../types/eventsTypes';
import { IUpcomingEvents, IEventOffers } from '../api/types/eventtool/responseTypes';
import { ISeatCount } from '../types/common';
import { removeExpiredOffers } from './offersActions';

export const getEventFromOffers = (data: IEventOffers) =>
  ({ type: EventsStateTypes.GET_EVENT_FROM_OFFERS, data });

export const getEventsFromOffer = (data: IEventOffers) =>
  ({ type: EventsStateTypes.GET_EVENTS_FROM_OFFER, data });

// tslint:disable-next-line: max-line-length
export const getUpcomingEvents = (contextId: number, authToken: string, userId: string, outletId?: number, limit?: number): ThunkAction<void, IFullStorageShape, null, EventsStateActions> => (dispatch: any) => {
  dispatch(fetchUpcomingEventsBegin());
  return fetchUpcomingEvents(contextId, authToken, userId, outletId, limit)
    .then((response: AxiosResponse<any>) => {
      const data: any = get(response, 'data');
      if (data && data.Events && data.Events.length > 0) {
        data.contextId = contextId;
        dispatch(fetchUpcomingEventsSuccess(data));
        dispatch(removeExpiredEvents());
        dispatch(removeExpiredOffers());
      } else {
        dispatch(fetchUpcomingEventsFailure(get(data, 'ResponseStatus.Message')));
      }
    })
    .catch((error: AxiosError) => {
      const errorMsg = get(error, 'response.data.ResponseStatus.Message');
      dispatch(fetchUpcomingEventsFailure(errorMsg));
    });
};

// tslint:disable-next-line: max-line-length
export const getEventsByName = (contextId: number, authToken: string, eventName: string): ThunkAction<void, IFullStorageShape, null, EventsStateActions> => (dispatch: any) => {
  dispatch(fetchEventsByNameBegin());
  const errorMsg = `Unable to load events by name ${eventName}`;
  return fetchEventsByName(contextId, authToken, eventName)
    .then((response: AxiosResponse<any>) => {
      const data: any = get(response, 'data');
      if (data) {
        data.contextId = contextId;
        dispatch(fetchEventsByNameSuccess(data));
      } else {
        dispatch(fetchEventsByNameFailure({ error: errorMsg }));
      }
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        dispatch(fetchEventsByNameFailure({ error: errorMsg }));
      }
    });
};


/**
 * Watched by fetchMissingEventsWatcher - See Sagas Middleware.*
 */
export const loadMissingEvents = () =>
  ({ type : EventsStateTypes.LOAD_MISSING_EVENTS });

export const loadMissingEventsAbort = () =>
  ({ type: EventsStateTypes.LOAD_MISSING_EVENTS_ABORT });

export const loadMissingEventsComplete = () =>
  ({ type: EventsStateTypes.LOAD_MISSING_EVENTS_COMPLETE });

export const getCmsEventBegin = (eventIds: number[]): IGetCmsEventBegin =>
  ({ type: EventsStateTypes.GET_CMS_EVENT_BEGIN, eventIds });

export const getCmsEventSuccess = (data: any): IGetCmsEventSuccess =>
  ({ type: EventsStateTypes.GET_CMS_EVENT_SUCCESS, data });

export const getCmsEventFailure = (errorMsg: string): IGetCmsEventFailure =>
  ({ type: EventsStateTypes.GET_CMS_EVENT_FAILURE, errorMsg });

export const getCmsEventAbort = (): IGetCmsEventAbort =>
  ({ type: EventsStateTypes.GET_CMS_EVENT_ABORT });


export const getCmsEventCompleted = (): IGetCmsEventCompleted =>
  ({ type: EventsStateTypes.GET_CMS_EVENT_COMPLETED });

export const fetchUpcomingEventsBegin = (): IGetUpcomingEventsBegin =>
  ({ type: EventsStateTypes.GET_UPCOMING_EVENTS_BEGIN });

export const fetchUpcomingEventsSuccess = (data: IUpcomingEvents): IGetUpcomingEventsSuccess =>
  ({ type: EventsStateTypes.GET_UPCOMING_EVENTS_SUCCESS, data });

export const fetchUpcomingEventsFailure = (errorMsg: string): IGetUpcomingEventsFailure =>
  ({ type: EventsStateTypes.GET_UPCOMING_EVENTS_FAILURE, errorMsg });

export const fetchEventsByNameBegin = (): IGetEventsByNameBegin =>
  ({ type: EventsStateTypes.FETCH_EVENTS_BY_NAME_BEGIN });

export const fetchEventsByNameSuccess = (data: any): IGetEventsByNameSuccess =>
  ({ type: EventsStateTypes.FETCH_EVENTS_BY_NAME_SUCCESS, data });

export const fetchEventsByNameFailure = (data: any): IGetEventsByNameFailure =>
  ({ type: EventsStateTypes.FETCH_EVENTS_BY_NAME_FAILURE, data });

export const getEventByIdBegin = (): IGetEventByIdBegin =>
  ({ type: EventsStateTypes.GET_EVENT_BY_ID_BEGIN });

export const getEventByIdSuccess = (data: any): IGetEventByIdSuccess =>
  ({ data, type: EventsStateTypes.GET_EVENT_BY_ID_SUCCESS });

export const getEventByIdFailure = (errorMsg: {}): IGetEventByIdFailure =>
  ({ type: EventsStateTypes.GET_EVENT_BY_ID_FAILURE, errorMsg });

export const getDefaultEvent = (eventId?: number): IGetDefaultEvent =>
  ({ type: EventsStateTypes.GET_DEFAULT_EVENT, eventId });

export const getDefaultEventBegin = (): IGetDefaultEventBegin =>
  ({ type: EventsStateTypes.GET_DEFAULT_EVENT_BEGIN });

export const getDefaultEventEnd = (): IGetDefaultEventEnd =>
  ({ type: EventsStateTypes.GET_DEFAULT_EVENT_END });

export const updateEventSeatCounts = (seatCount: ISeatCount): IUpdateEventSeatCounts =>
  ({ seatCount, type: EventsStateTypes.UPDATE_EVENT_SEAT_COUNTS });

export const removeExpiredEvents = (): IRemoveExpiredEvents =>
  ({ type: EventsStateTypes.REMOVE_EXPIRED_EVENTS });
