import * as React from 'react';
import { AXSPill, CartStatus, Expander } from './styles';
import styled from 'styled-components';
import { AXSBlock, AXSFlex } from '@axs/axs-ui';
import { ICart } from '../../mfe/carting/Carting/types/apiResponseTypes';
import { ThemeModeContext } from '../../layouts/PageLayout';
import { headerCartBarHeight, themeColors, themeTypes, zIndexes } from '../../theme';
import { pages } from '../../constants/routes';

const CartRow = styled(AXSFlex)`
  border-top: ${({ borderColor }) => `1px solid ${borderColor}` };
  left: 0;
  height: ${ headerCartBarHeight };
  position: absolute;
  top: 65px;
  width: 100%;
  z-index: ${ zIndexes.CART_HEADER };
`;

interface IHeaderCart {
  cart: ICart;
  filterPanelActive: boolean;
  hideFilterPanel: () => void;
  location: any;
  toggleCartDrawer: () => void;
  cartDrawerOpened?: boolean;
  showFilterPanel: () => void;
}

const HeaderCart = ({ toggleCartDrawer, cart, cartDrawerOpened, location, hideFilterPanel }: IHeaderCart) => {

  const themeMode = React.useContext(ThemeModeContext);
  const getQuantityInCart = (cart: ICart) => {
    if (cart && cart.selections && cart.selections.length) {
      return cart.selections.reduce((total, selection): number => total + selection.quantity, 0);
    }
    return 0;
  };

  const quantityInCart = getQuantityInCart(cart);

  const itemLabel = quantityInCart === 1 ? 'Item Reserved' : 'Items Reserved';
  const isConfirmationPage = location.pathname === pages.confirmation;
  const cartHasItem = quantityInCart > 0;

  const cartStatus = !isConfirmationPage && cartHasItem ? (
    <CartStatus
      className="col-6 d-flex justify-content-end"
      onClick={ () => {
        toggleCartDrawer();
        hideFilterPanel();
      } }
    >
      <AXSPill marginRight="10px" fontSize="20px" padding="0 20px">{ quantityInCart }</AXSPill>
      <AXSBlock
        color={ themeMode === themeTypes.dark ? themeColors.BLUE_24 : themeColors.GREY_DARK }
        fontSize="14px"
        inline={ true }
        marginRight="12px"
      >
        { itemLabel }
        <Expander
          color="axsui.status.info.default"
          name={ cartDrawerOpened ? 'keyboard-arrow-up' : 'keyboard-arrow-down' }
        />
      </AXSBlock>
    </CartStatus>
  ) : null;

  return !isConfirmationPage && cartHasItem ?
    (
    <CartRow
      alignItems="center"
      backgroundColor="axsui.ui.secondaryAccent"
      borderColor={ themeMode === themeTypes.dark ? themeColors.BLACK : themeColors.GREY_LIGHTEST }
      className="header__cart"
      height="35px"
    >
      <div className="col-6 d-flex align-items-center align-content-center" />
      { cartStatus }
    </CartRow>
    ) : null;
};

export default HeaderCart;
