import { ICustomer } from '../mfe/carting/Carting/types/apiRequestTypes';

export const GUEST_CHECKOUT_MODE = {
  OFF: '0',
  ON: '1',
};

export const guestCheckoutModeOptions = [
  {
    label: 'Off',
    value: GUEST_CHECKOUT_MODE.OFF,
  },
  {
    label: 'On',
    value: GUEST_CHECKOUT_MODE.ON,
  },
];

export const guestCheckoutModeCustomer: ICustomer = {
  shipping: {
    email: 'axsboxoffice@gmail.com',
    firstName: 'Box',
    lastName: 'Office',
  },
};
