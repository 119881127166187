import * as React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import PageLayout from '../layouts/PageLayout';
import { matchPath } from 'react-router';
import { get } from 'lodash';
import { pages } from '../constants/routes';

// Main Container/jsx Displayed on the page
const OfferContainer = Loadable({
  loader: () => import('../containers/offer'),
  loading: () => null,
});

export default function (store: any, props: any) {
  // call Register Reducer here
  const match = props.match;
  const path: any = matchPath(match.url, {
    exact: true,
    path: pages.offer(':eventId', ':offerId'),
    strict: false,
  });
  return (
    <Switch>
      <PageLayout>
        <Route
          path={ match.url }
          render={ (props) => {
            const more = {
              ...props,
              selectedEventId: parseInt(get(path, 'params.eventId', 0)),
              selectedOfferId: parseInt(get(path, 'params.offerId', 0)),
            };
            return <OfferContainer { ...more } />;
          }
        }
        />
      </PageLayout>
    </Switch>
  );
}
