import React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import { matchPath } from 'react-router';
import { get } from 'lodash';

import PageLayout from '../layouts/PageLayout';
import { pages } from '../constants/routes';

// Main Container/jsx Displayed on the page
const EventsContainer = Loadable({
  loader: () => import('../containers/events'),
  loading: () => null,
});

export default function (store: any, props: any) {
    // call Register Reducer here
  const match = props.match;
  const selectedEventId: any = matchPath(match.url, {
    exact: true,
    path: pages.event(':eventId'),
    strict: false,
  });

  const renderFunction = (props: any) => {
    const more = {
      ...props,
      selectedEventId: parseInt(get(selectedEventId, 'params.eventId', 0)),
    };
    return <EventsContainer {...more} />;
  };

  return (
    <Switch>
      <PageLayout>
          <Route
            path={ match.url }
            render={ renderFunction }
          />
      </PageLayout>
    </Switch>
  );
}
