import PriceLevel from '../PriceLevel';
import PriceCode from '../PriceCode';
import Product from '../Product/Product';
import Offer from './Offer';

class OfferGroup {

  constructor(
    public id: number,
    public name: string,
    public priceLevels: PriceLevel[],
    public priceCodes: PriceCode[],
    public maxQty: number = 0,
    public minQty: number = 0,
    public stepQty: number = 1,
    public offer: Offer,
    public products: Product[],
    // public groupFactor: number | undefined, TODO Flex
    // public statusCodes:StatusCode[], TODO
    public venueMapImage?: string | null,
    public description?: string,
  ) {
  }

  public getGroupSearchCriteria() {
    const { maxQty, minQty, stepQty, offer } = this;
    let groupMinQty = minQty;
    let groupMaxQty = maxQty;
    let groupStepQty = stepQty;

    if (groupMinQty === 0 && groupMaxQty === 0) {
      groupStepQty = 1;
      groupMinQty = offer.minQty;
      groupMaxQty = offer.maxQty;
    } // Else, grab the group factor TODO Flex

    return {
      max: groupMaxQty,
      min: groupMinQty,
      step: groupStepQty,
    };
  }

  public toString() {
    return `OfferGroup ID: ${this.id}`;
  }

  public getDefaultProduct() {
    return this.products && this.products.length > 0 ? this.products[0] : null;
  }
}

export default OfferGroup;
