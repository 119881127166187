import {
  CustomersStateActions,
  CustomersStateTypes,
  ICustomersState,
  ISetCustomerFilterEventSettings,
  ISetCustomerFilterOrderSettings,
} from '../types/customersTypes';

export const initialState: ICustomersState = {
  customer: null,
  fetchingCustomers: false,
  fetchingCustomersError: false,
  filterSettingsEvent: {
    date: {
      range: [],
      status: 'eventDateAllEvents',
    },
    ticketStatuses: [
      'ticketStatusInAccount',
      'ticketStatusRedeemed',
      'ticketStatusShipped',
      'ticketStatusPrinted',
      'ticketStatusPending',
      'ticketStatusStaging',
      'ticketStatusReceived',
      'ticketStatusTransferWaiting',
      'ticketStatusTransferred',
      'ticketStatusTransferredRedeemed',
      'ticketStatusListed',
      'ticketStatusSold',
      'ticketStatusSoldRedeemed',
      'ticketStatusNotRedeemed',
      'ticketStatusCancelled',
      'ticketStatusAwaitingDelivery',
    ],
  },
  filterSettingsOrder: {
    date: {
      range: [],
      status: 'orderDateAllDates',
    },
    orderStatuses: ['0', '3', '4', '5', '6'],
    paymentStatuses: ['0', '1', '2', '3', '4'],
  },
};

const ACTION_HANDLERS = {
  [CustomersStateTypes.RESET_CUSTOMER_STATE]: (state: ICustomersState) => {
    return {
      ...state,
      ...initialState,
    };
  },

  [CustomersStateTypes.SET_CUSTOMER_FILTER_ORDER_SETTINGS]:
    (state: ICustomersState, action: ISetCustomerFilterOrderSettings) => {
      const { settings } = action;
      return { ...state, filterSettingsOrder: settings };
    },

  [CustomersStateTypes.SET_CUSTOMER_FILTER_EVENT_SETTINGS]:
    (state: ICustomersState, action: ISetCustomerFilterEventSettings) => {
      const { settings } = action;
      return { ...state, filterSettingsEvent: settings };
    },
};

export default function reducer(state: ICustomersState = initialState, action: CustomersStateActions) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state; // @todo fix any crutch here.
}
