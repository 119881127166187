import {
  ApplicationStateActions,
  ApplicationStateTypes,
  IClearNotification,
  IFetchThemeBegin,
  IFetchThemeFailure,
  IFetchThemeSuccess,
  INotification,
  INotificationType,
  IPrintingSpec,
  IRestoreFromLocalStorage,
  IRestoreFromSessionStorage,
  ISetNotification,
  ISkin,
  IToggleOverlay,
  PrintItems,
} from '../types/appTypes';
import { IFullStorageShape } from '../store/store.types';
import { ThunkAction } from 'redux-thunk';
import { get } from 'lodash';
import {
  fetchSkin,
  sendToPrinter,
  sendSessionCardToPrinter,
} from '../api/ApiManager';
import { AxiosResponse, AxiosError } from 'axios';
import { ISettings } from '../types/userTypes';
import { setOrderAutoPrinted } from '../mfe/carting/Carting/actions/cartingActions';
import { IFetchSkinResponse } from '../api/types/fanskin/responseTypes';

export const getThemes = (skinName: string, schemaName: string): ThunkAction<void, IFullStorageShape,
  null, ApplicationStateActions> => (dispatch: any) => {
    dispatch(fetchThemeBegin());
    return fetchSkin(skinName, schemaName)
    .then((response: AxiosResponse<IFetchSkinResponse<ISkin>>) => {
      if (response && response.data && response.data.data) {
        dispatch(fetchThemeSuccess(response.data.data));
      } else {
        dispatch(fetchThemeFailure({ error: 'Unable to load event.' }));
      }
    })
    .catch((error: AxiosError) => {
      if (error) {
        const errorMessage = get(error, 'response.data.errors.message', 'Unable to retrieve skin.');
        dispatch(fetchThemeFailure({ error: errorMessage }));
      }
    });
  };

// tslint:disable-next-line: max-line-length
export const setNotification = (notificationType: INotificationType, message: string | string[], translate: boolean = false, autoDismiss: boolean = false): ISetNotification => {
  const notification: INotification = { message, notificationType, translate, autoDismiss };
  return { notification, type: ApplicationStateTypes.SET_NOTIFICATION };
};

export const clearNotification = (): IClearNotification =>
  ({ type: ApplicationStateTypes.CLEAR_NOTIFICATION });

export const setThemeMode = (mode: string) =>
  ({ type: ApplicationStateTypes.SET_THEME_MODE, mode });

export const fetchThemeBegin = (): IFetchThemeBegin =>
  ({ type: ApplicationStateTypes.FETCH_THEME_BEGIN });

export const fetchThemeSuccess = (skin: any): IFetchThemeSuccess =>
  ({ type: ApplicationStateTypes.FETCH_THEME_SUCCESS, skin });

export const fetchThemeFailure = (error: any): IFetchThemeFailure =>
  ({ type: ApplicationStateTypes.FETCH_THEME_FAILURE, error });

export const toggleOverlay = (): IToggleOverlay =>
  ({ type: ApplicationStateTypes.TOGGLE_OVERLAY });

export const getStartPrintingMessages = (printItems: PrintItems[]): string[] => {
  const messages: string[] = ['printing.prepareToPrint'];
  const items = printItems.map(item => `printing.${item}`);
  items.forEach((item, index) => {
    const lastIndex = items.length - 1;
    if (index > 0 && index < lastIndex) { messages.push(', '); }
    if (items.length > 1 && index === lastIndex) { messages.push('printing.and'); }
    messages.push(item);
  });
  return messages;
};

export const getStartPrintingSessionCardMessage = (): string => {
  return 'printing.prepareToPrint';
};

export const printTickets = (
  session: string,
  orderId: string,
  printItems: PrintItems[],
  settings: ISettings,
  autoPrint: boolean = false,
  seatIdsToPrint?: number[],
): ThunkAction<void, any, null, ApplicationStateActions> => (dispatch: any) => {
  dispatch(setNotification('info', getStartPrintingMessages(printItems), true));
  const printingSpec: IPrintingSpec = {
    printer: settings.printer.value,
    stockId: settings.stock.value,
    typeId: settings.type.value,
  };
  return sendToPrinter(session, orderId, printItems, printingSpec, seatIdsToPrint)
    .then((response: AxiosResponse<any>) => {
      const message = get(response, 'data.message');
      if (message) { dispatch(setNotification('success', message)); }
      if (autoPrint) { dispatch(setOrderAutoPrinted()); }
    })
    .catch((error: AxiosError) => {
      const message = get(error, 'response.data.message', 'printing.unableToPrint');
      dispatch(setNotification('danger', message, true));
    });
};

export const printSessionCard = (
  sessionId: string,
  userId: string,
  printer: string,
  startDateTime: string,
  endDateTime: string): ThunkAction<void, IFullStorageShape, null, ApplicationStateActions> =>
  (dispatch: any, getState) => {
    dispatch(setNotification('info', getStartPrintingSessionCardMessage(), true));
    const contextId = get(getState(), 'user.contextId', '');
    const outletLabel = get(getState(), 'user.settings.outlet.label', '');
    return sendSessionCardToPrinter(contextId, sessionId, userId, outletLabel, printer, startDateTime, endDateTime)
    .then((response: AxiosResponse<any>) => {
      const message = get(response, 'data.message');
      if (message) { dispatch(setNotification('success', message)); }
    })
    .catch((error: AxiosError) => {
      const message = get(error, 'response.data.message', 'printing.unableToPrint');
      dispatch(setNotification('danger', message, true));
    });
  };

export const restoreFromLocalStorage = (): IRestoreFromLocalStorage =>
  ({ type: ApplicationStateTypes.RESTORE_LOCAL_STORAGE });

export const restoreFromSessionStorage = (): IRestoreFromSessionStorage =>
  ({ type: ApplicationStateTypes.RESTORE_SESSION_STORAGE });

