enum MapType {
  MAP_2D = 'MAP_2D',
  MAP_3D = 'MAP_3D',
}

enum Map2DKeywords {
  MAP = 'map',
  VIEW_2D = 'view2d',
  CONTAINER = 'block-seat-map',
  HIDDEN_CONTAINER = 'hiddenMMCMap',
}

enum SeatTag {
  standard = 'standard',
  flashseats = 'flashseats',
  accessible = 'accessible',
  standardDeselected = 'standardDeselected',
  flashseatsDeselected = 'flashseatsDeselected',
  selected = 'SELECTED',
  cart = 'CARTED',
  filtered = 'FILTERED',
}

enum Map3DKeywords {
  BLOCKSEAT = 'blockmap',
  BLOCKSEAT_ALL = 'blockmap_all',
  BLOCKSEAT_VIP = 'blockmap_vip',
  BLOCK_MAP_HEIGHT_OFFSET = 105,
  BLOCK_MAP_RATIO = 0.6384,
  BLOCK_MAP_RATIO_MOBILE = 1.000,
  COLLAPSE_BUTTON_CLASS = 'onfullscreen-tk3d',
  EXPAND_BUTTON_CLASS = 'full-button-tk3d',
  EXPAND_BUTTON_ID = 'expand-view-from-seat-button',
  GROUPS_PLUGIN = 'GroupsPlugin',
  HIDDEN_CONTAINER = 'hiddenMMCMap',
  MAP = 'map',
  OVERVIEW = 'blockmap_overview',
  OVERVIEW_MAP_MOBILE_HEIGHT = 115,
  OVERVIEW_MAP_WIDTH_OFFSET = 14,
  ROWS_PLUGIN = 'RowsPlugin',
  SEAT = 'seatmap',
  SEAT_MAP_HEIGHT_OFFSET = 220,
  SEAT_MAP_RATIO = 0.567,
  SELECTION_PLUGIN = 'SelectionPlugin',
  SIDEBAR_ID = 'pick-a-seat--sidebar',
  SIDEBAR_VIEW_EXPANDED_CLASS = 'view-from-seat-expanded',
  STATUS_AVAILABLE = 'available',
  STATUS_SEAT = 'seat',
  STATUS_SELECTED = 'selected',
  VIEW_3D = 'view3d',
}

enum Zoom {
  default = 1.025,
  mobile = 2.5,
  increment = 0.1,
  seat_level = 30.0,
  scale_by = 1.018,
  zoomSpeed = 16.6,
}

export { MapType, Map2DKeywords, Map3DKeywords, Zoom, SeatTag };
