class ResalePrice {

  constructor(
    public sectionLabel: string,
    public offerListingId: number,
    public price: number,
  ) {
  }

}

export default ResalePrice;
