import { AnyAction } from 'redux';
import VTEvent from '../common/Event/VTEvent';
import { IOrderSearchResponse } from '../common/SearchDataTypes/SearchResponse';
import { ICustomerTicket, IGlobalSearchCustomerInfo } from '../api/types/fanAccount/mockResponseTypes';
import {
  IAddress,
  IOnBehalfOfInfo,
  IPrimaryOrder, IPrimaryOrderProduct,
  IPrimaryOrderSeat,
  IUserOrders,
} from '../api/types/fanAccount/responseTypes';

export interface ISearchState {
  customers: IGlobalSearchCustomerInfo[];
  customersSearching: boolean;
  events?: VTEvent[];
  eventSearching: boolean;
  error: string;
  globalSearchComplete: boolean;
  orders?: IOrderSearchResponse;
  ordersSearching: boolean;
  searchQuery: string;
  tickets?: ICustomerTicket[];
  ticketsSearching: boolean;
}

export enum SearchStateTypes {
  // orders
  SEARCH_ORDERS_BEGIN = 'SEARCH_ORDERS_BEGIN',
  SEARCH_ORDERS_SUCCESS = 'SEARCH_ORDERS_SUCCESS',
  SEARCH_ORDERS_FAILURE = 'SEARCH_ORDERS_FAILURE',
  // events
  SEARCH_EVENTS_BEGIN = 'SEARCH_EVENTS_BEGIN',
  SEARCH_EVENTS_SUCCESS = 'SEARCH_EVENTS_SUCCESS',
  SEARCH_EVENTS_FAILURE = 'SEARCH_EVENTS_FAILURE',
  // customers
  SEARCH_CUSTOMERS_BEGIN = 'SEARCH_CUSTOMERS_BEGIN',
  SEARCH_CUSTOMERS_SUCCESS = 'SEARCH_CUSTOMERS_SUCCESS',
  SEARCH_CUSTOMERS_FAILURE = 'SEARCH_CUSTOMERS_FAILURE',
  // customers
  SEARCH_TICKETS_BEGIN = 'SEARCH_TICKETS_BEGIN',
  SEARCH_TICKETS_SUCCESS = 'SEARCH_TICKETS_SUCCESS',
  SEARCH_TICKETS_FAILURE = 'SEARCH_TICKETS_FAILURE',
  //
  GLOBAL_SEARCH_COMPLETE = 'GLOBAL_SEARCH_COMPLETE',
  SET_SEARCH_QUERY = 'SET_SEARCH_QUERY',
}

/* Orders */
export interface ISearchOrdersBegin extends AnyAction {
  type: SearchStateTypes.SEARCH_ORDERS_BEGIN;
  payload: any;
  page: number;
  resultCount: number;
}
export interface ISearchOrdersSuccess extends AnyAction {
  type: SearchStateTypes.SEARCH_ORDERS_SUCCESS;
  orders: IOrderSearchResponse;
}
export interface ISearchOrdersFailure extends AnyAction {
  type: SearchStateTypes.SEARCH_ORDERS_FAILURE;
}

/* Events */
export interface ISearchEventsBegin extends AnyAction {
  type: SearchStateTypes.SEARCH_EVENTS_BEGIN;
  searchText: string;
}
export interface ISearchEventsSuccess extends AnyAction {
  type: SearchStateTypes.SEARCH_EVENTS_SUCCESS;
  data: any;
}
export interface ISearchEventsFailure extends AnyAction {
  type: SearchStateTypes.SEARCH_EVENTS_SUCCESS;
  errorMsg: string;
}

/* Customers */
export interface ISearchCustomersBegin extends AnyAction {
  type: SearchStateTypes.SEARCH_CUSTOMERS_BEGIN;
  searchQuery: string;
}
export interface IGlobalSearchCustomersSuccess extends AnyAction {
  type: SearchStateTypes.SEARCH_CUSTOMERS_SUCCESS;
  customers: IGlobalSearchCustomerInfo[];
}
export interface ISearchCustomersFailure extends AnyAction {
  type: SearchStateTypes.SEARCH_CUSTOMERS_FAILURE;
}

/* Tickets */
export interface ISearchTicketsBegin extends AnyAction {
  type: SearchStateTypes.SEARCH_TICKETS_BEGIN;
  searchQuery: string;
}
export interface ISearchTicketsSuccess extends AnyAction {
  type: SearchStateTypes.SEARCH_TICKETS_SUCCESS;
  tickets: ICustomerTicket[];
}
export interface ISearchTicketsFailure extends AnyAction {
  type: SearchStateTypes.SEARCH_TICKETS_FAILURE;
}
export interface IGlobalSearchCustomer extends IOnBehalfOfInfo {
  address: IAddress;
  orders?: IUserOrders[];
}
export interface IGlobalSearchTicket extends IPrimaryOrderSeat {
  customer: IGlobalSearchCustomer;
  orderNumber: IPrimaryOrder['orderNumber'];
  product: IPrimaryOrderProduct;
}

//
export interface ISetSearchQuery extends AnyAction {
  type: SearchStateTypes.SET_SEARCH_QUERY;
  searchQuery: string;
}
export interface IGlobalSearchComplete extends AnyAction {
  type: SearchStateTypes.GLOBAL_SEARCH_COMPLETE;
}

export type SearchStateActions =
  // orders
  ISearchOrdersBegin |
  ISearchOrdersFailure |
  ISearchOrdersSuccess |
  // events
  ISearchEventsBegin |
  ISearchEventsSuccess |
  ISearchEventsFailure |
  // customers
  IGlobalSearchCustomersSuccess |
  ISearchCustomersBegin |
  ISearchCustomersFailure |
  // tickets
  ISearchTicketsBegin |
  ISearchTicketsSuccess |
  ISearchTicketsFailure |
  //
  IGlobalSearchComplete |
  ISetSearchQuery;
