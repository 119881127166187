class CustomerBaseGlobalSearch {

  constructor(
    public vxId: number,
    public contextId: number,
    public accountNumber: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public addressLine1: string,
    public city: string,
    public state: string,
    public country: string,
    public zip: string,
    public sinceDate: string,
    public phone1: string,
    public shipTo?: string,
    public suffix?: string,
    public prefix?: string,
    public phone1Type?: number,
    public phone2?: string,
    public phone3?: string,
    public phone3Type?: number,
    public contextAlias?: string,
    public alternateName?: string,
    public addressLine2?: string,
  ) {
  }

  public toString(): string {
    return `Customer: ${this.vxId} - ${this.firstName} ${this.lastName}`;
  }
}

export default CustomerBaseGlobalSearch;
