import { AnyAction } from 'redux';
import { AxiosError } from 'axios';
import {
  IAddress,
  IDeliveryMethod,
  IFlashTickets,
  IOnBehalfOfInfo,
  IPrimaryCreditCard,
  IPrimaryOrder,
  ISecondaryCreditCard,
  IUserDetails,
  IPrimaryUser,
  IChangeDeliveryMethodStatus,
} from '../api/types/fanAccount/responseTypes';
import { AccountCreditCard } from '../common/Account/AccountCreditCard';
import { AccountEvent } from '../common/Account/AccountEvent';


export interface IAccountState {
  accountError: string | null;
  accountNumber: string | null;
  authToken: string | null;
  availableDeliveryMethods: any[];
  axsUserId: string | null;
  birthDay: string | null;
  changeModError: boolean;
  changeModStartTime: number;
  changeModStatus: IChangeDeliveryMethodStatus | null;
  contextId: number | null;
  createdDate: string | null;
  creditCards: AccountCreditCard[] | null;
  driverLicense: string | null;
  email: string | null;
  events: AccountEvent[] | null;
  firstName: string | null;
  flashTickets: IFlashTickets[] | null;
  lastName: string | null;
  onBehalfOfInfo: { [contextId: number]: { [email: string]: IOnBehalfOfInfo } };
  orders: IPrimaryOrder[] | null;
  phoneNumbers: string[] | null;
  primaryAddresses: IAddress[] | null;
  primaryCC: IPrimaryCreditCard[] | null;
  region: number | null;
  secondaryAddresses: IAddress[] | null;
  secondaryCC: ISecondaryCreditCard[] | null;
}

export enum AccountStateTypes {
  GET_OBO_USER_PROFILE_BEGIN = 'GET_OBO_USER_PROFILE_BEGIN',
  GET_OBO_USER_PROFILE_END = 'GET_OBO_USER_PROFILE_END',
  GET_USER_FLASH_TICKETS_BEGIN = 'GET_USER_FLASH_TICKETS_BEGIN',
  GET_USER_FLASH_TICKETS_END = 'GET_USER_FLASH_TICKETS_END',
  GET_USER_FLASH_TICKETS_FAILURE = 'GET_USER_FLASH_TICKETS_FAILURE',
  GET_USER_TOKEN_BEGIN = 'GET_USER_TOKEN_BEGIN',
  GET_USER_TOKEN_END = 'GET_USER_TOKEN_END',
  GET_USER_TOKEN_RESTORE = 'GET_USER_TOKEN_RESTORE',
  GET_USER_PROFILE_BEGIN = 'GET_USER_PROFILE_BEGIN',
  GET_USER_PROFILE_END = 'GET_USER_PROFILE_END',
  GET_USER_DETAILS_BEGIN = 'GET_USER_DETAILS_BEGIN',
  GET_USER_DETAILS_END = 'GET_USER_DETAILS_END',
  GET_USER_PRIMARY_CC_BEGIN = 'GET_USER_PRIMARY_CC_BEGIN',
  GET_USER_PRIMARY_CC_END = 'GET_USER_PRIMARY_CC_END',
  GET_USER_SECONDARY_CC_BEGIN = 'GET_USER_SECONDARY_CC_BEGIN',
  GET_USER_SECONDARY_CC_END = 'GET_USER_SECONDARY_CC_END',
  GET_USER_ORDERS_BEGIN = 'GET_USER_ORDERS_BEGIN',
  GET_USER_ORDERS_END = 'GET_USER_ORDERS_END',
  GET_USER_ORDERS_FAILURE = 'GET_USER_ORDERS_FAILURE',
  GET_USER_EVENTS_BEGIN = 'GET_USER_EVENTS_BEGIN',
  RESET_STATE = 'RESET_STATE',
  TRANSFER_TICKETS_BEGIN = 'TRANSFER_TICKETS_BEGIN',
  TRANSFER_TICKETS_SUCCESS = 'TRANSFER_TICKETS_SUCCESS',
  TRANSFER_TICKETS_ERROR = 'TRANSFER_TICKETS_ERROR',
  CHANGE_DELIVERY_METHOD_BEGIN = 'CHANGE_DELIVERY_METHOD_BEGIN',
  CHANGE_DELIVERY_METHOD_UPDATE = 'CHANGE_DELIVERY_METHOD_UPDATE',
  CHANGE_DELIVERY_METHOD_ERROR = 'CHANGE_DELIVERY_METHOD_ERROR',
  CHANGE_DELIVERY_METHOD_FINISH = 'CHANGE_DELIVERY_METHOD_FINISH',
  GET_AVAILABLE_DELIVERY_METHODS_BEGIN = 'GET_AVAILABLE_DELIVERY_METHODS_BEGIN',
  GET_AVAILABLE_DELIVERY_METHODS_SUCCESS = 'GET_AVAILABLE_DELIVERY_METHODS_SUCCESS',
  GET_AVAILABLE_DELIVERY_METHODS_FAILURE = 'GET_AVAILABLE_DELIVERY_METHODS_FAILURE',
}

export interface IGetOboUserProfileBegin extends AnyAction {
  type: AccountStateTypes.GET_OBO_USER_PROFILE_BEGIN;
}

export interface IGetOboUserProfileEnd extends AnyAction {
  type: AccountStateTypes.GET_OBO_USER_PROFILE_END;
}

export interface IGetUserFlashTicketsBegin extends AnyAction {
  type: AccountStateTypes.GET_USER_FLASH_TICKETS_BEGIN;
}

export interface IGetUserFlashTicketsEnd extends AnyAction {
  type: AccountStateTypes.GET_USER_FLASH_TICKETS_END;
  flashTickets: IFlashTickets[] | null;
}

export interface IUserFlashTicketsFailure extends AnyAction {
  type: AccountStateTypes.GET_USER_FLASH_TICKETS_FAILURE;
  errorMsg: AxiosError;
}

export interface IGetUserTokenBegin extends AnyAction {
  contextId: number;
  email: string;
  type: AccountStateTypes.GET_USER_TOKEN_BEGIN;
}

export interface IGetUserTokenEnd extends AnyAction {
  email: string;
  contextId: number;
  onBehalfOfInfo: IOnBehalfOfInfo;
  type: AccountStateTypes.GET_USER_TOKEN_END;
}

export interface IGetUserTokenRestore extends AnyAction {
  data: { [contextId: number]: { [axsUserId: string]: IOnBehalfOfInfo } };
  type: AccountStateTypes.GET_USER_TOKEN_RESTORE;
}

export interface IGetUserProfileBegin extends AnyAction {
  contextId: number;
  type: AccountStateTypes.GET_USER_PROFILE_BEGIN;
}

export interface IGetUserProfileEnd extends AnyAction {
  contextId: number;
  userProfile: IPrimaryUser;
  type: AccountStateTypes.GET_USER_PROFILE_END;
}

export interface IGetUserDetailsBegin extends AnyAction {
  type: AccountStateTypes.GET_USER_DETAILS_BEGIN;
}

export interface IGetUserDetailsEnd extends AnyAction {
  contextId: number;
  userDetails: IUserDetails;
  type: AccountStateTypes.GET_USER_DETAILS_END;
}

export interface IGetUserPrimaryCreditCardsBegin extends AnyAction {
  type: AccountStateTypes.GET_USER_PRIMARY_CC_BEGIN;
}

export interface IGetUserPrimaryCreditCardsEnd extends AnyAction {
  type: AccountStateTypes.GET_USER_PRIMARY_CC_END;
  primaryCC: IPrimaryCreditCard[];
}

export interface IGetUserSecondaryCreditCardsBegin extends AnyAction {
  type: AccountStateTypes.GET_USER_SECONDARY_CC_BEGIN;
}

export interface IGetUserSecondaryCreditCardsEnd extends AnyAction {
  type: AccountStateTypes.GET_USER_SECONDARY_CC_END;
  secondaryCC: ISecondaryCreditCard[];
}

export interface IGetUserOrdersBegin extends AnyAction {
  type: AccountStateTypes.GET_USER_ORDERS_BEGIN;
}

export interface IGetUserOrdersEnd extends AnyAction {
  contextId: number;
  customerEmail: string;
  type: AccountStateTypes.GET_USER_ORDERS_END;
  primaryOrders: IPrimaryOrder[];
  flashOffers: any;
  flashTickets: IFlashTickets[] | null;
}

export interface IGetUserOrdersFailure extends AnyAction {
  type: AccountStateTypes.GET_USER_ORDERS_FAILURE;
  errorMsg: AxiosError;
}

export interface IResetState extends AnyAction {
  type: AccountStateTypes.RESET_STATE;
}

export interface ITransferTicketsBegin extends AnyAction {
  type: AccountStateTypes.TRANSFER_TICKETS_BEGIN;
}

export interface ITransferTicketsSuccess extends AnyAction {
  type: AccountStateTypes.TRANSFER_TICKETS_SUCCESS;
}

export interface ITransferTicketsError extends AnyAction {
  type: AccountStateTypes.TRANSFER_TICKETS_ERROR;
}

export interface IChangeDeliveryMethodBegin extends AnyAction {
  type: AccountStateTypes.CHANGE_DELIVERY_METHOD_BEGIN;
}

export interface IChangeDeliveryMethodUpdate extends AnyAction {
  type: AccountStateTypes.CHANGE_DELIVERY_METHOD_UPDATE;
  status: IChangeDeliveryMethodStatus;
}

export interface IChangeDeliveryMethodError extends AnyAction {
  type: AccountStateTypes.CHANGE_DELIVERY_METHOD_ERROR;
}

export interface IChangeDeliveryMethodFinish extends AnyAction {
  type: AccountStateTypes.CHANGE_DELIVERY_METHOD_FINISH;
}

export interface IGetAvailableDeliveryMethodsBegin extends AnyAction {
  type: AccountStateTypes.GET_AVAILABLE_DELIVERY_METHODS_BEGIN;
}

export interface IGetAvailableDeliveryMethodsSuccess extends AnyAction {
  type: AccountStateTypes.GET_AVAILABLE_DELIVERY_METHODS_SUCCESS;
  deliveryMethods: IDeliveryMethod[];
}

export interface IGetAvailableDeliveryMethodsFailure extends AnyAction {
  type: AccountStateTypes.GET_AVAILABLE_DELIVERY_METHODS_FAILURE;
}

export type AccountStateActions =
  IGetOboUserProfileBegin |
  IGetOboUserProfileEnd |
  IGetUserTokenBegin |
  IGetUserTokenEnd |
  IGetUserTokenRestore |
  IGetUserProfileBegin |
  IGetUserDetailsBegin |
  IGetUserDetailsEnd |
  IGetUserOrdersFailure |
  IGetUserPrimaryCreditCardsBegin |
  IGetUserPrimaryCreditCardsEnd |
  IGetUserSecondaryCreditCardsBegin |
  IGetUserSecondaryCreditCardsEnd |
  IGetUserProfileEnd |
  IGetUserOrdersBegin |
  IGetUserOrdersEnd |
  IResetState |
  ITransferTicketsBegin |
  ITransferTicketsSuccess |
  ITransferTicketsError |
  IChangeDeliveryMethodBegin |
  IChangeDeliveryMethodUpdate |
  IChangeDeliveryMethodError |
  IChangeDeliveryMethodFinish |
  IGetAvailableDeliveryMethodsBegin |
  IGetAvailableDeliveryMethodsSuccess |
  IGetAvailableDeliveryMethodsFailure;
