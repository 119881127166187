import CartingRequest from './CartingRequest';
import { ICreateOrderRequest, ICustomer } from '../types/apiRequestTypes';
import Customer from './Customer';

export default class CreateOrderRequest extends CartingRequest {
  private customer: Customer;
  constructor(customer: ICustomer | null, guestCheckoutOrder: boolean, public machineName?: string,
              public cashPaymentTypeID?: string, public cashTendered?: number) {
    super();
    this.customer = new Customer(customer || undefined);
    if (guestCheckoutOrder) {
      this.customer.setGuestCheckoutCustomer();
    }
  }

  public toJSON(): ICreateOrderRequest {
    const json: ICreateOrderRequest = {
      asyncPayment: this.cashPaymentTypeID === undefined,
      customer: this.customer.toJSON(),
      workstationId: this.machineName,
    };
    if (this.cashPaymentTypeID !== undefined && this.cashTendered !== undefined) {
      json.payWithCash = {
        cashTendered: this.cashTendered,
        paymentTypeID: this.cashPaymentTypeID,
      };
    }
    return json;
  }
}
