import StorageBase from '../StorageBase/StorageBase';
import { connect } from 'react-redux';
import {
    APP_REDUCER_KEY,
    USER_REDUCER_KEY,
  } from '../../store/store.types';
import { loginSuccess, setSession } from '../../actions/userActions';
import { ISessionStorageProps, IStorageMap } from '../../types/storageTypes';
import { restoreFromSessionStorage } from '../../actions/appActions';

class SessionStorage extends StorageBase<ISessionStorageProps> {
  constructor(public sessionStorageProps: ISessionStorageProps) {
    super(sessionStorageProps);
    this.storageTarget = sessionStorage;
  }

  public componentDidMount() {
    const { restoreFromSessionStorage, restoredFromSessionStorage } = this.props;
    this.componentDidMountStorageBase(restoreFromSessionStorage, restoredFromSessionStorage);
  }

  public componentDidUpdate(prevProps: ISessionStorageProps) {
    this.componentDidUpdateStorageBase(prevProps);
  }

  public getStorageMap(): IStorageMap {
    const {
      authToken,
      contextId,
      loginSuccess,
      sessionExpiration,
      sessionId,
      setSession,
      userId,
        } = this.props;
    return {
      session: {
        dependsOn: ['sessionExpiration', 'sessionId'],
        setter: ({ sessionExpiration, sessionId }) => setSession(sessionId, sessionExpiration),
        value: { sessionExpiration, sessionId },
      },
      user: {
        dependsOn: ['authToken', 'contextId', 'userId'],
        setter: ({ authToken, contextId, userId }) => loginSuccess(authToken, contextId, userId),
        value: { authToken, contextId, userId },
      },
    };
  }
}

const mapStateToProps =  (state: any) => {
  return {
    authToken: state[USER_REDUCER_KEY].authToken,
    contextId: state[USER_REDUCER_KEY].contextId,
    restoredFromSessionStorage: state[APP_REDUCER_KEY].restoredFromSessionStorage,
    sessionExpiration: state[USER_REDUCER_KEY].sessionExpiration,
    sessionId: state[USER_REDUCER_KEY].sessionId,
    userId: state[USER_REDUCER_KEY].userId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loginSuccess: (authToken: string, contextId: number, userId: string) =>
      dispatch(loginSuccess(authToken, contextId, userId)),
    restoreFromSessionStorage: () => dispatch(restoreFromSessionStorage()),
    setSession: (sessionId: string | null, sessionExpiration?: number) =>
      dispatch(setSession(sessionId, sessionExpiration)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionStorage);

