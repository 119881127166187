//
//  The configuration is kept in AWS SSM Parameter Store
//  https://docs.aws.amazon.com/systems-manager/latest/userguide/systems-manager-parameter-store.html
//
//  The WebBox office parameters can be queried using AWS CLI using the following:
//  export ENV=development
//  where env = development|qa|production
//  aws ssm get-parameters-by-path /${ENV}/webboxoffice --recusursive --with-decryption
//
//  The terraform repository git@github.com:aegaxs/tf-web-b2b.git is the source of parameters values
//  The file webboxoffice.tf contains all the parameters
//
//  https://github.com/aegaxs/tf-web-b2b/blob/master/applications/webboxoffice.tf
//
// resource "aws_ssm_parameter" "webboxoffice_1" {
//   name   = "/${var.octopus_environment}/webboxoffice/axsApiHost"
//     type   = "String"
//     value  = "${var.axsApi}"
//     description = "The API host endpoint"
//     overwrite = true
//   }
//
// Steps to update or create .env file from AWS SSM
//
// One time setup:
//   Mac download gsed: http://gridlab-d.shoutwiki.com/wiki/Mac_OSX/Gsed
//   download latest aws-env from https://github.com/Droplr/aws-env/tree/master/bin
//
//
// tslint:disable-next-line:max-line-length
// AWS_ENV_PATH=/development/webboxoffice AWS_REGION=us-west-2 aws-env --recursive --format=dotenv | gsed 's/.*=/\U&/; s/^/REACT_APP_/' > .env
//
interface IConfig {
  axsApiHost: string;
  axsApiToken: string;
  axsSiteId: string;
  boxIoApi: string;
  changeModDelay: number;
  changeModTimeout: number;
  contextMaster: string;
  eventToolApiHost: string;
  eventToolApiToken: string;
  expirationWarning: number;
  fanAccountApiHost: string;
  globalSearchApi: string;
  orderStatusCheckInterval: number;
  outletTypes: string;
  protocol: string;
  sentryDSN: string;
  shelfLife: {
    [key: string]: number;
  };
  uAPIAccounts: string;
  uAPICommerce: string;
  versionService: string;
}


const config: IConfig = {
  axsApiHost: process.env.REACT_APP_AXSAPIHOST!,
  axsApiToken: process.env.REACT_APP_AXSAPITOKEN!,
  axsSiteId: process.env.REACT_APP_AXSSITEID!,
  boxIoApi: process.env.REACT_APP_BOXIOAPI!,
  changeModDelay: Number(process.env.REACT_APP_CHANGE_MOD_DELAY),
  changeModTimeout: Number(process.env.REACT_APP_CHANGE_MOD_TIMEOUT),
  contextMaster: process.env.REACT_APP_CONTEXTMASTER!,
  eventToolApiHost: process.env.REACT_APP_EVENTTOOLAPIHOST!,
  eventToolApiToken: process.env.REACT_APP_EVENTTOOLAPITOKEN!,
  expirationWarning: Number(process.env.REACT_APP_EXPIRATIONWARNING),
  fanAccountApiHost: process.env.REACT_APP_FANACCOUNT!,
  globalSearchApi: process.env.REACT_APP_GLOBALSEARCHAPI!,
  orderStatusCheckInterval: Number(process.env.REACT_APP_ORDERSTATUSCHECKINTERVAL),
  outletTypes: process.env.REACT_APP_OUTLETTYPES!,
  protocol: process.env.REACT_APP_PROTOCOL!,
  sentryDSN: process.env.REACT_APP_SENTRYDSN!,
  shelfLife: {
    general: Number(process.env.REACT_APP_SHELFLIFE_GENERAL),
    orderPendingTimeout: Number(process.env.REACT_APP_SHELFLIFE_ORDERPENDINGTIMEOUT),
  },
  uAPIAccounts: process.env.REACT_APP_ACCOUNTSAPI!,
  uAPICommerce: process.env.REACT_APP_UAPICOMMERCE!,
  versionService: process.env.REACT_APP_VERSIONSERVICE_URL!,
};

export default config;
