export const pages = {
  changeModOrder: (email: string, orderId: number | string, eventId: number | string) => `/customer/${email}/orders/${orderId}/${eventId}/change-mod`,
  changeModTicket: (
    email: string,
    orderId: string,
    eventCode: string,
    zoneId: string,
    seatGroupId: string,
    seatId: number | string,
  ) => `/customer/${email}/orders/${orderId}/ticket/${eventCode}/${zoneId}/${seatGroupId}/${seatId}/change-mod`,
  checkout: '/checkout',
  confirmation: '/confirmation',
  customer: (email: string, tab: string) => `/customer/${email}/${tab}`,
  event: (eventId: string | number) => `/event/${eventId}`,
  home: '/home',
  legacyOrder: (orderId: string | number) => `/order/${orderId}`,
  login: '/login',
  offer: (eventId: string | number, offerId: string | number) => `/event/${eventId}/offer/${offerId}`,
  order: (email: string, orderId: string | number) => `/customer/${email}/orders/${orderId}`,
  orderTab: (email: string, orderId: string | number, tab: string) => `/customer/${email}/orders/${orderId}/${tab}`,
  preferences: (edit: boolean = false) => `/preferences${edit ? '/edit' : ''}`,
  reset: '/reset',
  search: '/search',
  ticket: (
    email: string,
    orderId: string,
    eventId: string,
    eventZoneId: string,
    seatGroupId: string,
    seatId: string,
  ) =>
    `/customer/${email}/orders/${orderId}/ticket/${eventId}/${eventZoneId}/${seatGroupId}/${seatId}`,
  transferOrder: (email: string, orderId: number | string, eventId: number | string) => `/customer/${email}/orders/${orderId}/${eventId}/transfer`,
  transferTicket: (
    email: string,
    orderId: string,
    eventCode: string,
    zoneId: string,
    seatGroupId: string,
    seatId: number | string,
  ) =>
    `/customer/${email}/orders/${orderId}/ticket/${eventCode}/${zoneId}/${seatGroupId}/${seatId}/transfer`,
};
