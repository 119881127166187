import { flatMap, isEmpty, orderBy, uniqBy } from 'lodash';
import { compareByPrimaryAndCity } from '../utils/address';
import {
  mapAccountEvents,
  mapPayPalAccounts,
  mapPrimaryCreditCard,
  mapSecondaryCreditCard,
  appendFlashTicket,
  appendCustomerEmail,
  appendParents,
  sortPaymentActivity,
} from './utils/accountMapper';
import { getAvailableMods } from '../utils/ticketMODs';
import { AccountCreditCard } from '../common/Account/AccountCreditCard';
import {
  AccountStateActions,
  AccountStateTypes,
  IAccountState,
  IGetUserDetailsEnd,
  IGetUserFlashTicketsEnd,
  IGetUserOrdersEnd,
  IGetUserOrdersFailure,
  IGetUserPrimaryCreditCardsEnd,
  IGetUserProfileEnd,
  IGetUserSecondaryCreditCardsEnd,
  IGetUserTokenEnd,
  IGetUserTokenRestore,
  ITransferTicketsBegin,
  ITransferTicketsSuccess,
  ITransferTicketsError,
  IChangeDeliveryMethodBegin,
  IChangeDeliveryMethodUpdate,
  IChangeDeliveryMethodError,
  IChangeDeliveryMethodFinish,
  IGetAvailableDeliveryMethodsSuccess,
} from '../types/accountTypes';
import {
  IPrimaryCreditCard,
  ISecondaryCreditCard,
  IPrimaryOrder,
} from '../api/types/fanAccount/responseTypes';

export const initialState: IAccountState = {
  accountError: null,
  accountNumber: null,
  authToken: null,
  availableDeliveryMethods: [],
  axsUserId: null,
  birthDay: null,
  changeModError: false,
  changeModStartTime: 0,
  changeModStatus: null,
  contextId: null,
  createdDate: null,
  creditCards: null,
  driverLicense: null,
  email: null,
  events: null,
  firstName: null,
  flashTickets: null,
  lastName: null,
  onBehalfOfInfo: {},
  orders: null,
  phoneNumbers: null,
  primaryAddresses: null,
  primaryCC: null,
  region: 1,
  secondaryAddresses: null,
  secondaryCC: [],
};

const resetState = (state: IAccountState) => {
  const newData = {
    ...initialState,
  };
  return { ...state, ...newData };
};

const resetOrders = (state: IAccountState) => {
  return {
    ...state,
    creditCards: initialState.creditCards,
    events: initialState.events,
    flashTickets: initialState.flashTickets,
    orders: initialState.orders,
  };
};

const buildCreditCards = (
  primaryCC: IPrimaryCreditCard[] | null,
  secondaryCC: ISecondaryCreditCard[] | null,
  primaryOrders: IPrimaryOrder[] | null,
): AccountCreditCard[] | null => {
  if (primaryCC && primaryOrders && secondaryCC) {
    const primary = primaryCC.map(mapPrimaryCreditCard);
    const secondary = secondaryCC.map(mapSecondaryCreditCard);
    const allPayPalAccounts = flatMap(primaryOrders, order => order.payments)
      .filter(payment => payment.transactionTypeDescription === 'PayPal')
      .map(mapPayPalAccounts);
    const payPal = uniqBy(orderBy(allPayPalAccounts, cc => cc.lastUsedDate, 'desc'), cc => cc.id);
    const nonPrimaryCards = [
      ...primary.filter(x => !x.isPrimary),
      ...payPal,
      ...secondary,
    ];
    const sortedNonPrimaryCards = orderBy(
      nonPrimaryCards,
      [cc => !!cc.lastUsedDate, cc => cc.lastUsedDate],
      ['desc', 'desc'],
    );
    return [
      ...primary.filter(x => x.isPrimary),
      ...sortedNonPrimaryCards,
    ];
  }
  return null;
};

const ACTION_HANDLERS = {

  [AccountStateTypes.RESET_STATE]: (state: IAccountState) => {
    return resetState(state);
  },

  [AccountStateTypes.GET_USER_TOKEN_END]: (state: IAccountState, action: IGetUserTokenEnd) => {
    const newData = {
      onBehalfOfInfo: {
        ...state.onBehalfOfInfo,
        [action.contextId]: {
          ...state.onBehalfOfInfo[action.contextId],
          [action.email]: action.onBehalfOfInfo,
        },
      },
    };
    return { ...state, ...newData };
  },

  [AccountStateTypes.GET_USER_TOKEN_RESTORE]: (state: IAccountState, action: IGetUserTokenRestore) => {
    const newData = {
      onBehalfOfInfo: action.data,
    };
    return { ...state, ...newData };
  },

  [AccountStateTypes.GET_USER_PROFILE_BEGIN]: (state: IAccountState) => {
    const newData = {
      ...initialState,
      onBehalfOfInfo: state.onBehalfOfInfo,
    };
    return { ...state, ...newData };
  },

  [AccountStateTypes.GET_USER_PROFILE_END]: (state: IAccountState, action: IGetUserProfileEnd) => {
    const { userProfile } = action;
    userProfile.addresses.sort(compareByPrimaryAndCity);
    const primaryAddresses = userProfile.addresses;

    const newData = {
      accountNumber: userProfile.accountNumber,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      primaryAddresses,
    };
    return { ...state, ...newData };
  },

  [AccountStateTypes.GET_USER_DETAILS_END]: (state: IAccountState, action: IGetUserDetailsEnd) => {
    const { userDetails } = action;

    userDetails.primaryAddresses.sort(compareByPrimaryAndCity);
    const primaryAddresses = userDetails.primaryAddresses;

    userDetails.secondaryAddresses.sort(compareByPrimaryAndCity);
    const secondaryAddresses = userDetails.secondaryAddresses.filter(x => x.country !== 'US' || x.state !== 'NA');

    const newData = {
      accountNumber: userDetails.accountNumber,
      avatarUrl: userDetails.avatarUrl,
      birthday: userDetails.birthday,
      createdDate: userDetails.createdDate,
      driversLicense: userDetails.driversLicense,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      phoneNumbers: userDetails.phoneNumbers,
      primaryAddresses,
      secondaryAddresses,
    };
    return { ...state, ...newData };
  },

  [AccountStateTypes.GET_USER_PRIMARY_CC_END]: (state: IAccountState, action: IGetUserPrimaryCreditCardsEnd) => {
    const creditCards = buildCreditCards(action.primaryCC, state.secondaryCC, state.orders);
    const newData = {
      creditCards,
      primaryCC: action.primaryCC,
    };
    return { ...state, ...newData };
  },

  [AccountStateTypes.GET_USER_SECONDARY_CC_END]: (state: IAccountState, action: IGetUserSecondaryCreditCardsEnd) => {
    const creditCards = buildCreditCards(state.primaryCC, action.secondaryCC, state.orders);
    const newData = {
      creditCards,
      secondaryCC: action.secondaryCC,
    };
    return { ...state, ...newData };
  },

  [AccountStateTypes.GET_USER_ORDERS_END]: (
    state: IAccountState,
    { contextId, customerEmail, primaryOrders, flashOffers, flashTickets }: IGetUserOrdersEnd,
  ) => {
    appendFlashTicket(contextId, primaryOrders, flashTickets);
    appendCustomerEmail(primaryOrders, customerEmail);
    appendParents(primaryOrders);
    sortPaymentActivity(primaryOrders);
    const creditCards = buildCreditCards(state.primaryCC, state.secondaryCC, primaryOrders);
    const newData = {
      creditCards,
      events: mapAccountEvents(primaryOrders, flashOffers, flashTickets),
      isOrderFetching: false,
      orders: primaryOrders,
    };
    return { ...state, ...newData };
  },

  [AccountStateTypes.GET_USER_ORDERS_FAILURE]: (state: IAccountState, { errorMsg }: IGetUserOrdersFailure) => {
    return {
      ...state,
      accountError: errorMsg,
      isOrderFetching: false,
    };
  },

  [AccountStateTypes.GET_USER_FLASH_TICKETS_END]: (state: IAccountState, { flashTickets }: IGetUserFlashTicketsEnd,
  ) => ({ ...state, flashTickets }),

  [AccountStateTypes.TRANSFER_TICKETS_BEGIN]: (
    state: IAccountState,
    action: ITransferTicketsBegin,
  ) => {
    return state;
  },

  [AccountStateTypes.TRANSFER_TICKETS_SUCCESS]: (
    state: IAccountState,
    action: ITransferTicketsSuccess,
  ) => {
    return resetOrders(state);
  },

  [AccountStateTypes.TRANSFER_TICKETS_ERROR]: (
    state: IAccountState,
    action: ITransferTicketsError,
  ) => {
    return resetOrders(state);
  },

  [AccountStateTypes.CHANGE_DELIVERY_METHOD_BEGIN]: (
    state: IAccountState,
    action: IChangeDeliveryMethodBegin,
  ) => {
    return {
      ...state,
      changeModError: false,
      changeModStartTime: Date.now(),
      changeModStatus: null,
    };
  },

  [AccountStateTypes.CHANGE_DELIVERY_METHOD_UPDATE]: (
    state: IAccountState,
    action: IChangeDeliveryMethodUpdate,
  ) => {
    return {
      ...state,
      changeModStatus: action.status,
    };
  },

  [AccountStateTypes.CHANGE_DELIVERY_METHOD_ERROR]: (
    state: IAccountState,
    action: IChangeDeliveryMethodError,
  ) => {
    return {
      ...state,
      changeModError: true,
    };
  },

  [AccountStateTypes.CHANGE_DELIVERY_METHOD_FINISH]: (
    state: IAccountState,
    action: IChangeDeliveryMethodFinish,
  ) => {
    return {
      ...resetOrders(state),
      changeModError: false,
      changeModStartTime: 0,
      changeModStatus: null,
    };
  },
  [AccountStateTypes.GET_AVAILABLE_DELIVERY_METHODS_BEGIN]: (
    { availableDeliveryMethods, ...state }: IAccountState,
  ) => {
    if (!isEmpty(availableDeliveryMethods)) {
      return { ...state, availableDeliveryMethods: [] };
    }
    return state;
  },
  [AccountStateTypes.GET_AVAILABLE_DELIVERY_METHODS_SUCCESS]: (
    state: IAccountState,
    { deliveryMethods }: IGetAvailableDeliveryMethodsSuccess,
  ) => {
    const availableDeliveryMethods = getAvailableMods(deliveryMethods);
    return { ...state, availableDeliveryMethods };
  },
};

export default function reducer(
  state: IAccountState = initialState,
  action: AccountStateActions,
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state; // @todo fix any crutch here.
}
