import { every, filter, find, forEach, get, some, sortBy, uniq } from 'lodash';
import { ICart, ISelection, ITypeItem, ISelectionType, ITax, IDeliveryMethod } from '../types/apiResponseTypes';
import { IProductIdentity, ISeatSelection } from '../types/commonTypes';
import { VTEvent } from '../../../../common/Event';
import { ICartingState } from '../types/cartingTypes';
import Customer from '../classes/Customer';
import Offer from '../../../../common/Offer/Offer';
import OfferPriceChart from '../../../../common/PriceChart/OfferPriceChart';
import Seat from '../../../../common/Seat/Seat';
import { Currency, DEFAULT_CURRENCY } from '../../../../common/Price/Currency';
import { IAddress } from '../types/apiRequestTypes';
import PriceLevel from '../../../../common/PriceLevel';
import { SeatType } from '../../../../common/Seat/SeatType';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import Section from '../../../../common/Section/Section';
import { IAvailableEvents } from '../../../../types/eventsTypes';
import { IAvailableOffers } from '../../../../types/offerTypes';
import { ProcessType } from '../../../../common/DeliveryMethod/ProcessType';
import { GUEST_CHECKOUT_MODE } from '../../../../constants/guestCheckoutMode';
import { OfferType } from '../../../../common/Offer/OfferType';

export enum SelectionError {
  MULTIPLE_RESALE_OFFER = 'MULTIPLE_RESALE_OFFER',
  NO_MIXED_CART = 'NO_MIXED_CART',
}

export const canCart = (cart: ICart, offerType: SeatType, offerId: number): SelectionError | null => {
  let cartError: SelectionError | null = null;
  if (cart && cart.selections && cart.selections.length) {
    const resaleOfferIds = uniq(filter(cart.selections, { offerType }).map(selection => selection.offerID));
    cart.selections.forEach((selection: ISelection) => {
      if (selection.offerType !== offerType) {
        cartError = SelectionError.NO_MIXED_CART;
        return;
      }
      if (offerType === SeatType.FLASHSEATS && resaleOfferIds.length > 0 &&
        !resaleOfferIds.includes(offerId.toString())) {
        cartError = SelectionError.MULTIPLE_RESALE_OFFER;
        return;
      }
    });
  }
  return cartError;
};

export const cartHasResale = (cart: ICart): boolean => {
  if (get(cart, 'selections.length')) {
    return cart.selections.some(selection => selection.offerType === SeatType.FLASHSEATS);
  }
  return false;
};

export const cartHasItems = (cart: ICart, offerID?: string): boolean => {
  if (cart.selections && cart.selections.length) {
    if (offerID) {
      return cart.selections.some(selection => selection.offerID === offerID);
    }
    return true;
  }
  return false;
};

export const getCartedOfferIds = (cart: ICart): number[] => {
  if (cart.selections && cart.selections.length) {
    return uniq(cart.selections.map(selection => parseInt(selection.offerID)));
  }
  return [];
};

export const getQuantityInCart = (cart?: ICart): number => {
  if (cart && cartHasItems(cart)) {
    return cart.selections.reduce((total, selection): number => total + selection.quantity, 0);
  }
  return 0;
};

export const getProductEvent = (product: IProductIdentity, availableOffers: IAvailableOffers,
                                availableEvents: IAvailableEvents): VTEvent | null => {
  const availableOffer = get(availableOffers, product.offerID);
  const offer = availableOffer && availableOffer.data;
  if (offer) {
    const offerGroup = offer.offerGroups.find(offerGroup => offerGroup.id.toString() === product.offerGroupID);
    if (offerGroup) {
      const groupProduct = offerGroup.products.find(groupProduct => groupProduct.id.toString() === product.productID);
      if (groupProduct) {
        const availableEvent = get(availableEvents, groupProduct.eventId.toString());
        if (availableEvent) {
          return availableEvent.data || null;
        }
      }
    }
  }
  // If the offer is not found (in case of resale), return the event
  const eventID = product.eventID;
  if (eventID) {
    const availableEvent = get(availableEvents, eventID);
    if (availableEvent) {
      return availableEvent.data || null;
    }
  }
  return null;
};

/**
 * Returns an event from availableEvents given an eventID.
 *
 * @param eventId
 * @param availableEvents
 */
export const getEvent = (eventId: string, availableEvents: IAvailableEvents): VTEvent | undefined => {
  const availableEvent = get(availableEvents, eventId);
  return availableEvent && availableEvent.data;
};

/**
 * Returns an array of eventIDs from the cart selections.
 *
 * @param selections
 */
export const getEventIdsFromSelections = (selections: ISelection[]) =>
  selections.map((selection: ISelection) => (selection.eventID));

/**
 * Returns the total for an event in the cart selections.
 *
 * @param eventId
 * @param cartOrOrder
 */
export const getEventTotal = (eventId: number, cartOrOrder: ICart | undefined) => {

  if (cartOrOrder === undefined || eventId === undefined) {
    return 0;
  }

  const events = filter(cartOrOrder.selections, { eventID: eventId.toString() });
  let total = 0;
  events.forEach((selection: any) => {
    total = total + selection.priceTotal;
  });

  return total;
};

export const getProductIdentity = (cartSelection: ISelection): IProductIdentity => {
  const { offerID, offerGroupID, productID, eventID, offerType } = cartSelection;
  return {
    eventID,
    offerGroupID,
    offerID,
    offerType,
    productID,
  };
};

export interface IGroupedCartItems {
  price: number;
  quantity: number;
  rowLabel: string;
  seatLabel: string;
  seats: ISeatSelection[];
  sectionLabel: string;
  typeLabel: string;
}

export const getGroupedCartItems = (cartSelection: ISelection): IGroupedCartItems[] => {
  let cartItems: IGroupedCartItems[] = [];
  const seatType: SeatType = cartSelection.offerType;
  cartSelection.types.forEach((selectionType) => {
    const { label: typeLabel, unitPrice: price } = selectionType;
    selectionType.items.forEach((typeItem) => {
      const { rowID, rowLabel, seatID, seatLabel, sectionLabel } = typeItem;
      const seat: ISeatSelection = { rowID, seatID, seatType };
      const existingItem = find(cartItems, { rowLabel, seatLabel, sectionLabel, typeLabel });
      if (existingItem) {
        existingItem.seats.push(seat);
        existingItem.quantity += 1;
        existingItem.price += price;
      } else {
        cartItems.push({
          price,
          quantity: 1,
          rowLabel,
          seatLabel,
          seats: [seat],
          sectionLabel,
          typeLabel,
        });
      }
    });
  });
  // Making sure all the seat are in the same order because order will vary
  // depending on the price type selection
  cartItems = sortBy(cartItems, 'seatLabel');
  cartItems = sortBy(cartItems, 'rowLabel');
  cartItems = sortBy(cartItems, 'sectionLabel');
  return cartItems;
};

export const getDefaultOfferProduct = (offer: Offer): IProductIdentity | null => {
  const defaultGroup = offer ? offer.getDefaultOfferGroup() : null;
  const defaultProduct = defaultGroup ? defaultGroup.getDefaultProduct() : null;
  if (defaultGroup && defaultProduct) {
    return {
      offerGroupID: defaultGroup.id.toString(),
      offerID: offer.id.toString(),
      productID: offer.offerType === OfferType.SINGLE ? defaultProduct.id.toString() : undefined,
    };
  }
  return null;
};

export const getSeats = (cart: ICart, offer: Offer, selectedEventId: number): Seat[] => {
  const seats: Seat[] = [];
  if (offer && cart && cart.selections) {
    const mainOfferId = offer.id;
    const offerSelection: ISelection | undefined =
      cart.selections.find((selection: ISelection) => {
        const sameOffer = selection.offerID === mainOfferId.toString();
        const isResale = selection.offerType === SeatType.FLASHSEATS;
        const sameEvent = selection.eventID === selectedEventId.toString();
        return sameOffer || (isResale && sameEvent);
      });
    if (offerSelection && offerSelection.types) {
      offerSelection.types.forEach((selectionType: ISelectionType) => {
        selectionType.items.forEach((typeItem: ITypeItem) => {
          const seat: Seat = new Seat(
            parseInt(typeItem.seatID), typeItem.seatLabel, null, null, mainOfferId,
            parseInt(offerSelection.productID), parseInt(typeItem.sectionID), typeItem.sectionLabel,
            parseInt(typeItem.rowID), typeItem.rowLabel);
          seats.push(seat);
        });
      });
    }
  }
  return seats;
};

/**
 * Returns seats that fall within a price range value.
 *
 * @param seatInventory
 * @param priceRangeValue
 */
export const getSeatsInPriceRange = (seatInventory: Seat[], priceRangeValue: number[]) => {
  const inventory: Seat[] = cloneDeep(seatInventory);
  const seats: Seat[] = [];
  const seatKeys = Object.keys(inventory);
  if (seatKeys.length > 0) {
    seatKeys.forEach((seatKey: string) => {
      const isWithinRange = getSeatsWithinPriceRange(inventory[seatKey].prices, priceRangeValue);
      seats[seatKey] = inventory[seatKey];
      if (!isWithinRange) {
        inventory[seatKey].id = null; // Used within MMCMap2D for price range filtering.
      }
    });
  }
  return seats;
};

/**
 * Return only sections that have some unfiltered seats.
 *
 * @param seatInventory
 * @param sectionInventory
 */
export const getSectionsInPriceRange = (seatInventory: Seat[],
                                        sectionInventory: Section[]) => {
  return sectionInventory.filter((section: Section) => {
    const seats = seatInventory.filter((seat: Seat) => seat.id === null && section.id === seat.sectionId);
    if (seats.length < 1) {
      return section;
    }
    return null;
  });
};

/**
 * Filters an array of numbers and returns any that fall within a given price range.
 *
 * @param prices
 * @param priceRangeValue
 */
const getSeatsWithinPriceRange = (prices: number[], priceRangeValue: number[]) => {
  const filteredPrices = prices.filter((price: number) =>
    (price > 0 && priceRangeValue[0] <= (price / 100) && priceRangeValue[1] >= (price / 100)));
  return filteredPrices.length > 0;
};

export const getCartSelections = (cart: ICart, offerId: number, eventId: number): ISelection[] => {
  const resultSelections: ISelection[] = [];
  if (cart && cart.selections && offerId) {
    cart.selections.forEach((selection: ISelection) => {
      if ((selection.offerType !== SeatType.FLASHSEATS && selection.offerID === offerId.toString()) ||
          (selection.offerType === SeatType.FLASHSEATS && selection.eventID === eventId.toString())) {
        resultSelections.push(selection);
      }
    });
  }
  return resultSelections;
};
export const isReadyToPlaceOrder = (cartState: ICartingState, guestCheckoutMode: string, selectedPaymentMethod?: string,
                                    hasResaleTicket: boolean = false): boolean => {
  const { cart, carting, order, ordering } = cartState;
  const customer = new Customer(cartState.customer || undefined);
  const isValidAddress = hasResaleTicket && isEmpty(validateAddress(cartState.address));
  const cartOrOrder = cart || order;
  if (!cartOrOrder) {
    return false;
  }
  if (hasResaleTicket && !isValidAddress) { return false; }
  return cartHasItems(cartOrOrder) && !carting && !ordering &&
    (customer.hasAllRequiredDetails() ||
    (guestCheckoutMode === GUEST_CHECKOUT_MODE.ON && selectedPrintImmediately(cartOrOrder))) &&
    (selectedPaymentMethod !== undefined || cartOrOrder.grandTotal === 0);
};

export const getCashPaymentTypeId = (cart: ICart): string | undefined => {
  const cashPaymentMethod = cart.paymentMethods.find(paymentMethod => paymentMethod.code === 'Cash');
  if (cashPaymentMethod) {
    return cashPaymentMethod.code;
  }
  return undefined;
};


export const getCurrency = (offerPrices: OfferPriceChart[], product?: IProductIdentity): Currency => {
  if (product) {
    const priceChart = offerPrices.find(price => price.offerId.toString() === product.offerID &&
      price.offerGroupId.toString() === product.offerGroupID);
    if (priceChart) {
      return priceChart.currency;
    }
  }
  return DEFAULT_CURRENCY;
};

export const getDefaultProduct = (cart: ICart): IProductIdentity | undefined => {
  if (cart.selections && cart.selections.length) {
    const { offerID, offerGroupID, productID } = cart.selections[0];
    return { offerID, offerGroupID, productID };
  }
  return undefined;
};

export const getPaymentMethodCodes = (cart: ICart): string[] => {
  return cart.paymentMethods.map(method => method.code);
};

/**
 * Validate address on resale ticket
 */
// TODO this will need extra param for cases where only postal code is necessary
  // get address validate library
export const validateAddress = (address: IAddress) => {
  const addressErrors: any = {};
  const {
      address1,
      city,
      postalCode,
      countryCode,
      regionCode,
      primaryPhone,
    } = address;

  if (!postalCode || !validatePostalCode(postalCode, countryCode)) {
    addressErrors.postalCode = 'Valid Postal Code Is Required';
  }
  if (!address1) {
    addressErrors.address1 = 'Address is Required';
  }
  if (!city) {
    addressErrors.city = 'City is Required';
  }
  if (!countryCode) {
    addressErrors.countryCode = 'Country is Required';
  }
  if (!regionCode) {
    addressErrors.regionCode = 'State is Required';
  }
  if (!primaryPhone) {
    addressErrors.primaryPhone = 'Phone Number is Required';
  }
  return addressErrors;
};

export const formatResaleAddress = (address: any) => {
  const {
    address1,
    address2,
    city,
    postalCode,
    regionCode,
    countryCode,
    primaryPhone } = address;
  return {
    address1,
    address2,
    city,
    countryCode : countryCode.value,
    postalCode,
    primaryPhone,
    regionCode : regionCode.value,
  };
};

/**
 * Validates postal code for US and Canada
 * @param {string} postalCode
 * @param countryCode
 * @return {boolean}
 */
export const validatePostalCode = (postalCode: string, countryCode: any) => {
  if (countryCode.value === 'US') {
    const usPostalCodes = new RegExp('^\\d{5}(-{0,1}\\d{4})?$');
    return usPostalCodes.test(postalCode);
  }  {
    const canadaPostalCodes = new RegExp(/([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i);
    return canadaPostalCodes.test(postalCode.replace(/\W+/g, ''));
  }
};

/**
 * Checks is email is valid and in proper format
 * @param {string} email
 * @return { boolean }
 */

export const isValidEmailAddress = (email: string) => {
  const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  return emailRegex.test(email);
};
/**
 * Returns the available ticket count for a selected price level.
 *
 * @param priceLevels
 * @param selectedPriceLevels
 */
export const getSelectedPriceLevelsTicketCount = (priceLevels?: PriceLevel[], selectedPriceLevels?: number[]) => {
  let priceLevelTicketCount = 0;
  if (priceLevels && selectedPriceLevels !== undefined) {
    if (Number(selectedPriceLevels[0]) === 0) {
      // handle "All" price levels
      forEach(priceLevels, (item: PriceLevel) => {
        priceLevelTicketCount = priceLevelTicketCount += item.availability || 0;
      });
    } else {
      const priceLevel = find(priceLevels, { id: Number(selectedPriceLevels[0]) });
      priceLevelTicketCount = priceLevel && priceLevel.availability ? priceLevel.availability : 0;
    }
  }
  return priceLevelTicketCount;
};

/**
 * Returns the total tax on a cart. @TODO move this to uAPI.
 *
 * @param cart
 */
export const getOrderTaxTotal = (cart: ICart) => {
  let total = 0;
  cart.selections.forEach((selection: ISelection) => {
    selection.types.forEach((type: ISelectionType) => {
      if (type.taxes) {
        type.taxes.forEach((tax: ITax) => {
          total = total + (tax.amount * type.quantity);
        });
      }
    });
  });
  return total;
};

  /**
   * Returns delivery method description
   * Currently there are no related offers in place. Therefore, current design allows for only one MoD
   * Once design changes and allows for mixed MoD then the below logic will need to change
   * Therefore, method will return 'Mixed Methods of Delivery' if more than one event selected and if they
   * have different Mods
   * @param order
   */
export const getMethodOfDelivery = (order: ICart, mixedMod: string, noModSelected: string): string => {
  const selections: ISelection[] = order.selections;
  if (!selections || !selections.length || selections.length === 0) {
    return noModSelected;
  }
  const firstSelection: ISelection = selections[0];
  for (const selection of selections) {
    if (selection.deliveryMethodID !== firstSelection.deliveryMethodID) {
      return mixedMod;
    }
  }
  const modID: string = firstSelection.deliveryMethodID;
  const offerID: string = firstSelection.offerID;
  const deliveryMethod: IDeliveryMethod | undefined = find(order.deliveryMethods[offerID], (mod: IDeliveryMethod) => {
    const modId = mod.id;
    return modID === modId;
  });
  return deliveryMethod ? deliveryMethod.description : '';
};

/**
 * A function to determine if each carted offer has at least 1 print immediately MOD.
 *
 * @param cart
 */
export const hasPrintImmediatelyMODs = (cart: ICart): boolean => {
  return every(cart.deliveryMethods, mods => some(mods, mod => mod.process === ProcessType.PRINT_IMMEDIATELY));
};

/**
 * A function to determine if all the selected MODs are print immediately MOD.
 *
 * @param cart
 */
export const selectedPrintImmediately = (cart: ICart): boolean => {
  return every(cart.selections, (selection) => {
    const mods = get(cart, `deliveryMethods.${selection.offerID}`, []) as IDeliveryMethod[];
    const mod = mods.find(mod => mod.id === selection.deliveryMethodID);
    return mod && mod.process === ProcessType.PRINT_IMMEDIATELY;
  });
};
