import { Currency } from '../Price/Currency';

/**
 * Formats an amount given in cents to us currency.
 * @param price
 * @param currency
 * @param locale
 * @param decimalPoints
 */
const formatPrice = (price: number, currency: Currency = Currency.USD,
                     decimalPoints: number = 2, locale: string = 'en-US') => {
  return new Intl.NumberFormat(locale, {
    currency,
    minimumFractionDigits: decimalPoints,
    style: 'currency',
  }).format(price / 100);
};

/**
 * Adds a space between a currency symbol and price.
 * @param price - '$123'
 * @returns string - '$ 123.00'.
 */

const addSpaceToAPrice = (price: number) => {
  const formattedPrice = formatPrice(price);
  const index: number = [].findIndex.call(formattedPrice, char => char === '0' || parseFloat(char));
  return `${formattedPrice.slice(0, index)} ${formattedPrice.slice(index)}`;
};

export { formatPrice, addSpaceToAPrice };

