import { get } from 'lodash';
import {
  IGetSeatHistoryEnd,
  ISeatState,
  SeatStateTypes,
  SeatStateActions,
  IGetSeatCustomerProfileEnd,
} from '../types/seatTypes';
import { states } from '../constants';

export const initialState: ISeatState = {
  customerEmail: null,
  customerName: null,
  seatHistoryItems: null,
  seatHistoryVid: null,
};

const ACTION_HANDLERS = {

  // Customer

  [SeatStateTypes.GET_SEAT_CUSTOMER_PROFILE_BEGIN]: (state: ISeatState) => {
    const newData = {
      customerEmail: initialState.customerEmail,
      customerName: initialState.customerName,
    };
    return { ...state, ...newData };
  },

  [SeatStateTypes.GET_SEAT_CUSTOMER_PROFILE_END]: (state: ISeatState, action: IGetSeatCustomerProfileEnd) => {
    const email = get(action, 'userProfile.email');
    const firstName = get(action, 'userProfile.firstName');
    const lastName = get(action, 'userProfile.lastName');
    const newData = {
      customerEmail: email,
      customerName: `${firstName} ${lastName}`,
    };
    return { ...state, ...newData };
  },

  // Seat History

  [SeatStateTypes.GET_SEAT_HISTORY_BEGIN]: (state: ISeatState) => {
    const newDate = {
      seatHistoryItems: initialState.seatHistoryItems,
      seatHistoryVid: initialState.seatHistoryVid,
    };
    return { ...state, ...newDate };
  },

  [SeatStateTypes.GET_SEAT_HISTORY_END]: (state: ISeatState, action: IGetSeatHistoryEnd) => {
    const newData = {
      seatHistoryItems: action.seatHistoryItems,
      seatHistoryVid: action.seatVid,
    };
    return { ...state, ...newData };
  },

  [SeatStateTypes.RESET_SEAT_STATE]: () => {
    return { ...states, ...initialState };
  },
};

export default function reducer(
  state: ISeatState = initialState,
  action: SeatStateActions,
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state; // @todo fix any crutch here.
}
