import { SearchStateTypes,
  IGlobalSearchCustomersSuccess,
  ISearchCustomersBegin,
  ISearchCustomersFailure,
} from '../types/searchTypes';
import { IGlobalSearchCustomerInfo } from '../api/types/fanAccount/mockResponseTypes';
import { IOrderSearchResponse } from '../common/SearchDataTypes/SearchResponse';

/* Orders */
export const searchGlobalOrdersBegin = (payload: any, page: number = 1, resultCount: number = 20) => {
  return { type : SearchStateTypes.SEARCH_ORDERS_BEGIN, payload, page, resultCount };
};
export const searchGlobalOrdersSuccess = (orders: IOrderSearchResponse) => {
  return { type : SearchStateTypes.SEARCH_ORDERS_SUCCESS, orders };
};
export const searchGlobalOrdersFailure = (payload: any) => {
  return { type : SearchStateTypes.SEARCH_ORDERS_FAILURE, payload };
};

/* Events */
export const searchEventsBegin = (searchText: string) => {
  return { type : SearchStateTypes.SEARCH_EVENTS_BEGIN, searchText };
};
export const searchEventsSuccess = (data: any) => {
  return { type : SearchStateTypes.SEARCH_EVENTS_SUCCESS, data };
};
export const searchEventsFailure = (errorMsg: string) => {
  return { type : SearchStateTypes.SEARCH_EVENTS_FAILURE, errorMsg };
};

/* Customers */
export const searchCustomersBegin = (searchQuery: string): ISearchCustomersBegin => {
  return { type : SearchStateTypes.SEARCH_CUSTOMERS_BEGIN, searchQuery };
};
export const globalSearchCustomersSuccess = (customers: IGlobalSearchCustomerInfo[])
  : IGlobalSearchCustomersSuccess => {
  return { type : SearchStateTypes.SEARCH_CUSTOMERS_SUCCESS, customers };
};
export const searchGlobalCustomersFailure = (): ISearchCustomersFailure => {
  return { type : SearchStateTypes.SEARCH_CUSTOMERS_FAILURE };
};

/* Tickets */
export const searchGlobalTicketsBegin = (payload: any) => {
  return { type : SearchStateTypes.SEARCH_TICKETS_BEGIN, payload };
};
export const searchGlobalTicketsSuccess = (tickets: any) => {
  return { type : SearchStateTypes.SEARCH_TICKETS_SUCCESS, tickets };
};
export const searchGlobalTicketsFailure = () => {
  return { type : SearchStateTypes.SEARCH_TICKETS_FAILURE };
};

//
export const completeGlobalSearch = () => {
  return { type : SearchStateTypes.GLOBAL_SEARCH_COMPLETE };
};
export const setSearchQuery = (searchQuery: string) => {
  return { type : SearchStateTypes.SET_SEARCH_QUERY, searchQuery };
};
