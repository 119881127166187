import Address from '../Address/Address';

class Venue {

  constructor(
      public id: number,
      public name?: string,
      public address?: Address,
      public homePageUrl?: string,
      public notes?: string,
    ) {
  }

  public toString() {
    return `Venue: ${this.id} - ${this.name}`;
  }
}

export default Venue;
