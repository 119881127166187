class BaseEvent {

  constructor(
    public id: number,
    public name: string,
    public startDate: string,
    public endDate: string,
    public description?: string,
    public eventImageUrl?: string,
    public cms: boolean = false,
    ) {
  }

  public toString() {
    return `Event ID: ${this.id}`;
  }
}

export default BaseEvent;
