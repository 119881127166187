import React from 'react';
import { AXSBlock, AXSIcon, AXSFormInput, AXSFlex } from '@axs/axs-ui';
import { Link, withRouter } from 'react-router-dom';
import AXSLogo from '../../assets/AXSLogo';
import {
  HeaderBar,
  Logo,
  LogoRow,
} from './styles';
import styled from 'styled-components';
import { withInputHooks } from '../../hoc/';
import { ThemeModeContext } from '../../layouts/PageLayout';
import { themeColors, themeTypes } from '../../theme';

const SearchInput = styled(AXSFormInput)`
  margin-top: 9px;

  input {
    border-radius: 4px !important;
  }
`;

const LogoLink = styled(Link)`
  margin-left: 10px;
  &:hover { text-decoration: none; }
`;

const Header = ({
    handleSubmitSearch,
    handleKeyDown,
    history,
    location,
    handleInputChange,
    text,
  }: any) => {

  const themeMode = React.useContext(ThemeModeContext);

  const headerText = location.pathname.includes('customer')
    ? 'Resolution' : 'Sales';

  const submitSearch = (text: string) => {
    handleSubmitSearch(text);
  };

  return (
    <HeaderBar id="headerBar" width="100%" className="header">
      <LogoRow justifyContent="flex-start">
        <AXSFlex alignItems="center">
          <LogoLink to="/home"><AXSFlex alignItems="center" marginLeft="16px">
            <Logo
              color={ themeMode === themeTypes.dark ? themeColors.WHITE : themeColors.AXS_BLUE }
              path={ AXSLogo }
              width="50px"
            />
            <AXSBlock
              fontSize="18px"
              marginTop="-3px"
              marginLeft="8px"
              color={ themeMode === themeTypes.dark ? themeColors.WHITE  : themeColors.GREY_DARKEST }
            >
              { headerText }
            </AXSBlock>
          </AXSFlex></LogoLink>
        </AXSFlex>
        <AXSBlock marginLeft="auto" marginRight="24px">
          { !location.pathname.includes('search') && (
          <SearchInput
            placeholder={ 'Search for an Event, Customer, or Order' }
            iconPosition={ 'right' }
            width="380px"
            onChange={ handleInputChange }
            value={ text }
            onKeyDown={ handleKeyDown }
            icon={ (
                <AXSIcon
                  color="axsui.text.elements.h3.color"
                  name={ 'search' }
                  onClick={ () =>  submitSearch(text) }
                />
            ) }
          />
          ) }
        </AXSBlock>
      </LogoRow>
    </HeaderBar>
  );
};

Header.displayName = 'Header';
const wrappedHeader = withInputHooks(Header);
export default withRouter(wrappedHeader);
