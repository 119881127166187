import CartingRequest from './CartingRequest';
import { ICartSelections } from '../types/apiRequestTypes';

export default class CartActionRequest<SelectionType extends CartingRequest> extends CartingRequest {
  constructor(public selection: SelectionType) {
    super();
  }

  public toJSON(): ICartSelections {
    return {
      selections: [this.selection.toJSON()],
    };
  }
}
