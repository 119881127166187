import moment from 'moment';
import { filter, flatMap, uniq } from 'lodash';
import {
  IFlashTickets,
  IPrimaryCreditCard,
  IPrimaryOrder,
  IPrimaryOrderProduct,
  IPrimaryOrderSeat,
  IPrimaryPayment,
  ISecondaryCreditCard,
} from '../../api/types/fanAccount/responseTypes';
import { AccountCreditCard, ACCOUNT_PAYMENT_METHOD_SOURCE } from '../../common/Account/AccountCreditCard';
import { AccountEvent } from '../../common/Account/AccountEvent';
import { CREDIT_CARD_CODES } from '../../constants/creditCardCodes';
import { ORDER_NUMBER_FLASH } from '../../constants/ticketStatus';

export const mapPrimaryCreditCard = (source: IPrimaryCreditCard): AccountCreditCard => {
  const expDate = moment(source.expDate);
  const expMonth = expDate.month() + 1;
  const expYear = expDate.year();
  return new AccountCreditCard(
    source.address,
    expMonth,
    expYear,
    source.id,
    source.isPrimary,
    source.lastFour,
    source.lastUsedDate,
    source.nameOnCard,
    ACCOUNT_PAYMENT_METHOD_SOURCE.BACK_OFFICE,
    source.type,
  );
};

export const mapSecondaryCreditCard = (source: ISecondaryCreditCard): AccountCreditCard => {
  return new AccountCreditCard(
    source.address,
    source.expMonth,
    source.expYear,
    source.id,
    false,
    source.lastFourDigits,
    source.lastUsedDate,
    source.fullName,
    ACCOUNT_PAYMENT_METHOD_SOURCE.FLASH,
    source.type,
  );
};

export const mapPayPalAccounts = (source: IPrimaryPayment): AccountCreditCard => {
  return new AccountCreditCard(
    null as any,
    0,
    0,
    source.chargedTo,
    false,
    '',
    source.date,
    source.chargedTo,
    ACCOUNT_PAYMENT_METHOD_SOURCE.BACK_OFFICE,
    CREDIT_CARD_CODES.PAY_PAL,
  );
};

export const appendFlashTicket = (
  contextId: number,
  orders: IPrimaryOrder[],
  flashTickets: IFlashTickets[] | null,
) => {
  orders.forEach((order: IPrimaryOrder) => {
    order.products.forEach((product: IPrimaryOrderProduct) => {
      product.seats.forEach((seat: IPrimaryOrderSeat) => {
        const primarySeatId =
          `${contextId}|${product.eventCode}|${product.zoneId}|${seat.primarySeatGroupId}|${seat.primarySeatId}`;
        const flashTicket = (flashTickets && flashTickets
          .find((flashTicket: IFlashTickets) =>
            primarySeatId === flashTicket.primarySeatId)) || null;
        if (flashTicket) seat.flashTicket = flashTicket;
      });
    });
  });
};

export const appendCustomerEmail = (orders: IPrimaryOrder[], customerEmail: string) => {
  orders.forEach((order: IPrimaryOrder) => {
    order.customerEmail = customerEmail;
  });
};

const compareByDatePredicate = (paymentA: IPrimaryPayment, paymentB: IPrimaryPayment): number => {
  return -moment.utc(paymentA.date).diff(moment.utc(paymentB.date));
};

export const sortPaymentActivity = (orders: IPrimaryOrder[]) => {
  orders.forEach((order: IPrimaryOrder) => {
    order.payments.sort(compareByDatePredicate);
  });
};

export const appendParents = (orders: IPrimaryOrder[]) => {
  orders.forEach((order: IPrimaryOrder) => {
    order.products.forEach((product: IPrimaryOrderProduct) => {
      product.parent = order;
      product.seats.forEach((seat: IPrimaryOrderSeat) => {
        seat.parent = product;
      });
    });
  });
  return orders;
};

export const mapAccountEvents = (
  orders: IPrimaryOrder[],
  offerOnsaleInfos: any,
  flashTickets: IFlashTickets[] | null,
): AccountEvent[] => {

  const allVeritixSeats = flatMap(flatMap(orders, order => order.products), product => product.seats);
  const flashOnlyTickets = filter(flashTickets, flash => !allVeritixSeats.some(veritix => isSameSeats(veritix, flash)));
  const flashOrders = createFlashOrders(orders, offerOnsaleInfos, flashOnlyTickets);

  const allOrders = [...orders, ...flashOrders];

  const allProducts = flatMap(allOrders, order => order.products);
  const eventCodes = uniq(allProducts.map(product => product.eventCode));

  return eventCodes.map((eventCode: string) => {
    const eventOrders = allOrders.filter((ord) => {
      const products = ord.products;
      return products.find(product => product.eventCode === eventCode);
    });
    const products = allProducts.filter(product => product.eventCode === eventCode);
    return new AccountEvent(products[0], eventOrders);
  });
};

const isSameSeats = (seat: IPrimaryOrderSeat, flashTicket: IFlashTickets) => {
  const [
    , // flashContextId is not need here
    flashEventCode,
    flashZoneId,
    flashSeatGroupId,
    flashSeatId,
  ] = flashTicket.primarySeatId.split('|');

  return +seat.parent.parent.orderNumber === flashTicket.primaryOrderId &&
    seat.parent.eventCode === flashEventCode &&
    seat.parent.zoneId === flashZoneId &&
    seat.primarySeatGroupId === flashSeatGroupId &&
    seat.primarySeatId === Number(flashSeatId);
};

export const createFlashOrders = (
  orders: IPrimaryOrder[],
  offerOnsaleInfos: any,
  flashTickets: IFlashTickets[],
): IPrimaryOrder[] => {
  const veritixProducts = flatMap(orders, order => order.products);
  const eventConfigIds = uniq(flashTickets.map(ticket => ticket.eventConfigId));
  const eventOrders = eventConfigIds.map((eventConfigId) => {
    const eventTickets = flashTickets.filter(x => x.eventConfigId === eventConfigId);
    const eventOfferIds = uniq(eventTickets.map(ticket => ticket.primaryOfferId));
    const products = eventOfferIds.map((primaryOfferId) => {
      let flashProduct;
      const offerTickets = flashTickets.filter(ticket => ticket.primaryOfferId === primaryOfferId);
      const existingVeritixProduct = veritixProducts.find(offer => offer.offerId === primaryOfferId);
      if (existingVeritixProduct) {
        flashProduct = mapProductFromVeritixProduct(existingVeritixProduct);
      } else {
        const offer = offerOnsaleInfos.find((offr: any) => +offr.onsaleInformation.meta.offerID === primaryOfferId);
        flashProduct = mapProductFromOnSaleInfo(offer.onsaleInformation, offerTickets[0]);
      }
      flashProduct.seats = offerTickets.map(mapTicketFromFlashData);
      return flashProduct;
    });
    return mapOrder(products);
  });
  return appendParents(eventOrders);
};

const mapTicketFromFlashData = (ticket: IFlashTickets) => {
  return {
    allowPaperConversion: false,
    barcode: {
      status: '',
      statusId: 0,
      value: '',
    },
    barcodeIsValid: false,
    cancelled: false,
    description: '',
    externalSeatInfo1: '',
    externalSeatInfo2: '',
    flashTicket: ticket,
    forwardInformation: {
      forwardAllowed: false,
      forwardedDate: '',
      forwardedToCustomer: {
        email: '',
        firstName: '',
        lastName: '',
        message: '',
      },
      wasForwarded: false,
    },
    fulfillment: {
      deliveryMODType: 4,
      deliveryMethod: '0',
      dmDescription: 'Flash Seats',
      fulfillmentId: '',
      fulfillmentTimestamp: '',
      isETicket: false,
      isFlashSeat: true,
    },
    fulfillmentHeldUntil: '',
    isGA: false,
    itemNumber: 0,
    itemPrice: 0,
    lastScannedDate: '',
    name: '',
    number: ticket.seat,
    parent: null as any,
    priceCodeDescription: '',
    priceCodePrintDescription: ticket.priceCodePrintDescription || '',
    priceLevelDescription: '',
    primaryOrderCustomerEmail: ticket.primaryOrderCustomerEmail,
    primaryOrderNumber: ticket.primaryOrderId,
    primarySeatGroupId: String(ticket.seatGroupId),
    primarySeatId: ticket.seatId,
    printDescription: '',
    resaleInformation: {
      resaleAllowed: false,
      wasResold: false,
    },
    row: ticket.row,
    seatNeighborhood: '',
    section: ticket.sectionPrintDescription,
    sectionPrintDescription: ticket.sectionPrintDescription,
    shipDate: '',
    state: '',
    ticketStatus: '',
    transferFromEmail: ticket.transferFromEmail,
    transferInformation: {
      transferAllowed: false,
    },
    transferToEmail: ticket.transferToEmail,
    userCode: '',
  };
};

const mapProductFromVeritixProduct = (product: IPrimaryOrderProduct) => {
  return { ...product, seats: [] as IPrimaryOrderSeat[] };
};

const mapProductFromOnSaleInfo = (onsaleInformation: any, ticket: IFlashTickets) => {
  const product = onsaleInformation.groups[0].products[0];
  const event = onsaleInformation.events[0];
  const venue = onsaleInformation.venues[0];
  const zoneId = ticket.primarySeatId.split('|')[2];
  return {
    city: venue.city,
    closeDate: '',
    coreTypeNumber: 0,
    description: '',
    endDate: event.endDate,
    eventCategoryDescription: product.zoneDescription,
    eventCode: event.eventID,
    eventTicketScheduleDate: '',
    eventTitle: event.title,
    facilityName: venue.name,
    flashSeatsScheduleDate: '',
    isForwardProduct: false,
    offerDescription: '',
    offerId: onsaleInformation.meta.offerID,
    offerName: onsaleInformation.title,
    openDate: '',
    parent: null as any,
    productCode: '',
    productId: 0,
    quantity: 0,
    seats: [] as IPrimaryOrderSeat[],
    standardShippingScheduleDate: '',
    startDate: event.date,
    venueTimeZone: '',
    zoneId,
  };
};

const mapOrder = (products: IPrimaryOrderProduct[]) => {
  return {
    currencyCode: 0,
    customerEmail: '',
    dateCreated: '',
    fees: [],
    orderNumber: ORDER_NUMBER_FLASH,
    orderStateCode: '',
    orderStateCodeId: 0,
    paymentScheduled: 0,
    paymentStatus: 0,
    payments: [],
    products,
    system: '',
    totalPaid: 0,
    totalPrice: 0,
  };
};
