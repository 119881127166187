import { AnyAction } from 'redux';
import Offer from '../common/Offer/Offer';
import { IEventOffers } from '../api/types/eventtool/responseTypes';
import { ISeatCount, IExpiration } from './common';
import { IOnsaleInformation } from '../api/types/unifiedapi/responseTypes';

export type IAvailableOffer = IExpiration<Offer>;

export interface IAvailableOffers {
  [key: string]: IAvailableOffer;
}

export interface IOfferState {
  availableOffers: IAvailableOffers;
  fetchingOffer: boolean;
  fetchingOffers: boolean;
  fetchingDefaultOffers: boolean;
  fetchingMissingOffers: boolean;
  filterPanelActive: boolean;
  mapFiltersActive: boolean;
  precartPanelActive: boolean;
}

export enum OfferStateTypes {
  GET_OFFER_BEGIN = 'GET_OFFER_BEGIN',
  GET_OFFER_SUCCESS = 'GET_OFFER_SUCCESS',
  GET_OFFER_FAILURE = 'GET_OFFER_FAILURE',
  FETCH_DEFAULT_OFFERS_BEGIN = 'FETCH_DEFAULT_OFFERS_BEGIN',
  FETCH_DEFAULT_OFFERS_END = 'FETCH_DEFAULT_OFFERS_END',
  FETCH_EVENT_OFFERS_BEGIN = 'FETCH_EVENT_OFFERS_BEGIN',
  FETCH_EVENT_OFFERS_SUCCESS = 'FETCH_EVENT_OFFERS_SUCCESS',
  FETCH_EVENT_OFFERS_FAILURE = 'FETCH_EVENT_OFFERS_FAILURE',
  LOAD_MISSING_OFFERS = 'LOAD_MISSING_OFFERS',
  LOAD_MISSING_OFFERS_ABORT = 'LOAD_MISSING_OFFERS_ABORT',
  LOAD_MISSING_OFFERS_COMPLETE = 'LOAD_MISSING_OFFERS_COMPLETE',
  SHOW_FILTER_PANEL = 'SHOW_FILTER_PANEL',
  HIDE_FILTER_PANEL = 'HIDE_FILTER_PANEL',
  SHOW_MAP_FILTERS = 'SHOW_MAP_FILTERS',
  HIDE_MAP_FILTERS = 'HIDE_MAP_FILTERS',
  SHOW_PRECART_PANEL = 'SHOW_PRECART_PANEL',
  HIDE_PRECART_PANEL = 'HIDE_PRECART_PANEL',
  REMOVE_EXPIRED_OFFERS = 'REMOVE_EXPIRED_OFFERS',
  UPDATE_OFFER_SEAT_COUNTS = 'UPDATE_OFFER_SEAT_COUNTS',
}

export interface IShowFilterPanel extends AnyAction {
  type: OfferStateTypes.SHOW_FILTER_PANEL;
}

export interface IHideFilterPanel extends AnyAction {
  type: OfferStateTypes.HIDE_FILTER_PANEL;
}

export interface IShowPrecartPanel extends AnyAction {
  type: OfferStateTypes.SHOW_PRECART_PANEL;
}

export interface IHidePrecartPanel extends AnyAction {
  type: OfferStateTypes.HIDE_PRECART_PANEL;
}

export interface IShowMapFilters extends AnyAction {
  type: OfferStateTypes.SHOW_MAP_FILTERS;
}

export interface IHideMapFilters extends AnyAction {
  type: OfferStateTypes.HIDE_MAP_FILTERS;
}

export interface IFetchEventOffersBegin extends AnyAction {
  type: OfferStateTypes.FETCH_EVENT_OFFERS_BEGIN;
}

export interface IFetchEventOffersSuccess extends AnyAction {
  type: OfferStateTypes.FETCH_EVENT_OFFERS_SUCCESS;
  data: IEventOffers;
}

export interface IFetchEventOffersFailure extends AnyAction {
  type: OfferStateTypes.FETCH_EVENT_OFFERS_FAILURE;
}

export interface IGetOfferBegin extends AnyAction {
  type: OfferStateTypes.GET_OFFER_BEGIN;
}

export interface IGetOfferSuccess extends AnyAction {
  type: OfferStateTypes.GET_OFFER_SUCCESS;
  data: IOnsaleInformation;
}

export interface IGetOfferFailure extends AnyAction {
  type: OfferStateTypes.GET_OFFER_FAILURE;
}

export interface IUpdateOfferSeatCounts extends AnyAction {
  seatCounts: ISeatCount[];
  type: OfferStateTypes.UPDATE_OFFER_SEAT_COUNTS;
}

export interface IFetchDefaultOffersBegin extends AnyAction {
  type: OfferStateTypes.FETCH_DEFAULT_OFFERS_BEGIN;
}

export interface IFetchDefaultOffersEnd extends AnyAction {
  type: OfferStateTypes.FETCH_DEFAULT_OFFERS_END;
}

export interface IRemoveExpiredOffers extends AnyAction {
  type: OfferStateTypes.REMOVE_EXPIRED_OFFERS;
}

export type OfferStateActions =
  IFetchDefaultOffersBegin |
  IFetchDefaultOffersEnd |
  IFetchEventOffersBegin |
  IFetchEventOffersFailure |
  IFetchEventOffersSuccess |
  IGetOfferBegin |
  IGetOfferFailure |
  IGetOfferSuccess |
  IHideFilterPanel |
  IHideMapFilters |
  IHidePrecartPanel |
  IRemoveExpiredOffers |
  IShowFilterPanel |
  IShowMapFilters |
  IShowPrecartPanel |
  IUpdateOfferSeatCounts;
