import React from 'react';
import { useInputHelpers } from '../hooks';
import { SearchStateTypes } from '../types/searchTypes';
import { pages } from '../constants/routes';

const prohibitedChars = ['*', '<', '>'];
/**
 * Higher Order component which utilizes React hooks
 * Utilizing an HOC was the only way to get this wrapper to work with both class and stateless components
 * Need to store search query in redux to share value across pages wince we aren't using url params
 * @param WrappedComponent
 * @return {(props: any) => any}
 */
export const withInputHooks = (WrappedComponent: any) => {

  return (props: any) => {
    const { text, setText, activeContext, setActiveContext, dispatch } = useInputHelpers();

    const handleKeyDown = (e: any) => {
      const keyDownText = e.target.value;
      if (e.key === 'Enter') {
        handleSubmitSearch(keyDownText);
      }
    };
    const handleInputChange = (e: any) => {
      const text =  e.target.value;
      const lastInput = text.split('')[text.length - 1];
      if (!prohibitedChars.includes(lastInput)) {
        return setText(e.target.value);
      }
    };
    const handleSubmitSearch = (text: string) => {
      props.history.push(pages.search);
      dispatch({ type: SearchStateTypes.SET_SEARCH_QUERY, searchQuery : text.trim() });
      setActiveContext('');
    };

    return (
      <WrappedComponent
        text={ text }
        activeContext={ activeContext }
        setActiveContext={ setActiveContext }
        handleInputChange={ handleInputChange }
        handleKeyDown={ handleKeyDown }
        handleSubmitSearch={ handleSubmitSearch }
        { ...props }
      />
    );
  };
};
