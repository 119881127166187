import CartingRequest from './CartingRequest';
import { ICartSelectionForAdd, ISeatSelectionForAdd } from '../types/apiRequestTypes';
import { ISearchCriteria, IProductIdentity } from '../types/commonTypes';

export default class CartAddSelection extends CartingRequest {
  private items: ISeatSelectionForAdd[] = [];
  private searches: ISearchCriteria[] = [];
  private strictCheck: boolean = false;
  constructor(public productIdentity: IProductIdentity, public deliveryMethodID?: string,
              public isPickASeat: boolean = false, public overwrite: boolean = false) {
    super();
  }

  public pickSeats(items: ISeatSelectionForAdd[]): void {
    if (this.isPickASeat) {
      this.items.push(...items);
    }
  }

  public addSearch(search: ISearchCriteria): void {
    if (!this.isPickASeat) {
      this.searches.push(search);
    }
  }

  public toJSON(): ICartSelectionForAdd {
    const json: ICartSelectionForAdd = {
      ...this.productIdentity,
      overwrite: this.overwrite,
      strictCheck: this.strictCheck,
    };
    if (this.deliveryMethodID) {
      json.deliveryMethodID = this.deliveryMethodID;
    }
    if (this.isPickASeat) {
      json.items = this.items;
    } else if (this.searches.length) {
      json.searches = this.searches;
    }
    return json;
  }
}
