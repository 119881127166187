class Price {

  constructor(
    public value: number, // value in cents
    public priceLevelId: number,
    public priceCodeId: number,
  ) {
  }

  public toString() {
    return `Price: ${this.value}`;
  }
}

export default Price;
