import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import { EventsStateTypes, IAvailableEvents } from '../../types/eventsTypes';
import { fetchCmsEvent } from '../../api/ApiManager';
import {
  getCmsEventSuccess,
  getCmsEventFailure,
  getCmsEventCompleted,
  getCmsEventBegin,
} from '../../actions/eventsActions';
import { get, filter, set } from 'lodash';
import { isExpired } from '../../utils/common';

function* fetchCmsEventSaga() {
  try {
    const globalState = yield select();
    const availableEvents: IAvailableEvents = globalState.events.availableEvents;
    const contextId = globalState.user.contextId;
    const events = filter(availableEvents, event => !isExpired(event) && !get(event, 'data.cms', false));
    const eventIds = events.map(event => get(event, 'data.id', 0));
    yield put(getCmsEventBegin(eventIds));

    const cmsEventRequests = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < eventIds.length; i = i + 1) {
      const request = call(fetchCmsEvent, contextId, eventIds[i]);
      cmsEventRequests.push(request);
    }
    const responses = yield all(cmsEventRequests);

    for (let i = 0; i < responses.length; i = i + 1) {
      set(responses[i], 'data.contextId', contextId);
      set(responses[i], 'data.eventId', eventIds[i]);
      yield put(getCmsEventSuccess(responses[i].data));
    }
    yield put(getCmsEventCompleted());
  } catch (err) {
    // failure
    yield put(getCmsEventFailure(err));
  }
}

/////////////////
// Watchers
/////////////////
export function* getCmsEventsWatcher() {
  yield takeLatest(EventsStateTypes.GET_UPCOMING_EVENTS_SUCCESS, fetchCmsEventSaga);
  yield takeLatest(EventsStateTypes.GET_EVENT_FROM_OFFERS, fetchCmsEventSaga);
  yield takeLatest(EventsStateTypes.FETCH_EVENTS_BY_NAME_SUCCESS, fetchCmsEventSaga);
}
