import moment from 'moment';
import Outlet from '../Outlet';
import { FlowType } from './FlowType';
import OfferGroup from './OfferGroup';
import { MapType } from '../../mfe/map/Map/constants';
import { OfferType } from './OfferType';
import get from 'lodash/get';

export interface IOffer {
  id: number;
  lookupId: string;
  eventId: number;
  offerGroups: OfferGroup[];
  offerType: OfferType;
  isMMCEnabled: boolean;
  isMmc3D: boolean;
  name?: string;
  description?: string;
  onsaleDate?: string;
  offsaleDate?: string;
  outlets?: Outlet[];
  mmcMapId?: string;
  allowSingleSeats: boolean;
  isResaleEnabled: boolean;
  flowType?: FlowType;
  relatedOffers?: IOffer[];
  upsellOffers?: IOffer[];
  maxQty: number;
  minQty: number;
  seatCount?: number;
}

class Offer {

  constructor(
    public id: number,
    public lookupId: string,
    public eventId: number,
    public offerGroups: OfferGroup[],
    public offerType: OfferType,
    public isMMCEnabled: boolean,
    public isMmc3D: boolean,
    public name?: string,
    public description?: string,
    public onsaleDate?: string,
    public offsaleDate?: string,
    public outlets?: Outlet[],
    public mmcMapId?: string,
    public allowSingleSeats: boolean = true,
    public isResaleEnabled: boolean = false,
    public flowType?: FlowType,
    public relatedOffers?: Offer[],
    public upsellOffers?: Offer[],
    public maxQty: number = 0,
    public minQty: number = 0,
    public seatCount?: number,

  ) {
  }

  public toString() {
    return `Offer ID: ${ this.id }`;
  }

  public getMapType() {
    if (this.flowType === FlowType.PICK_A_SEAT_2D) {
      return MapType.MAP_2D;
    }
    if (this.flowType === FlowType.PICK_A_SEAT_3D) {
      return MapType.MAP_3D;
    }
    return null;
  }

  public hasPickASeatFlow() {
    return this.mmcMapId !== undefined && this.mmcMapId !== null && this.mmcMapId !== '';
  }

  public hasResaleFlow() {
    return false;
  }

  public getDefaultOfferGroup() {
    return this.offerGroups && this.offerGroups.length > 0 ? this.offerGroups[0] : null;
  }

  public isSoldOut () {
    return this.seatCount !== undefined ? this.seatCount === 0 : false;
  }

  public isOnsaleFuture() {
    const now = Date.now();
    const onSaleDate = moment(this.onsaleDate);
    return onSaleDate.isAfter(now);
  }

  public isOnsalePast() {
    const now = Date.now();
    const offSaleDate = moment(this.offsaleDate);
    return offSaleDate.isBefore(now);
  }

  public isAvailable() {
    return !this.isOnsaleFuture() && !this.isOnsalePast() && !this.isSoldOut();
  }

  public getFirstEventId() {
    return get(this, 'offerGroups[0].products[0].eventId');
  }
}

export default Offer;
