import Carting from './Carting';
import {
  closeCartDrawer,
  getCart,
  openCartDrawer,
  removeTickets,
  searchTickets,
  setCustomerEmail,
  setCustomerFirstName,
  setCustomerLastName,
  toggleCartDrawer,
} from './Carting/actions/cartingActions';

const CartingActions = {
  closeCartDrawer,
  getCart,
  openCartDrawer,
  removeTickets,
  searchTickets,
  setCustomerEmail,
  setCustomerFirstName,
  setCustomerLastName,
  toggleCartDrawer,
};

export {
  Carting,
  CartingActions,
};
