class PriceLevel {

  constructor(
    public id: number,
    public description: string,
    public sortOrder: number,
    private minPrice?: number,
    private maxPrice?: number,
    public availability?: number,
  ) {
  }

  public toString() {
    return `PriceLevel: ${this.id} - ${this.description}`;
  }

  public setPriceRange(min: number | undefined, max: number | undefined) {
    this.minPrice = min;
    this.maxPrice = max;
  }

  public getPriceRange() {
    return {
      max: this.maxPrice,
      min: this.minPrice,
    };
  }
}

export default PriceLevel;
