import PriceLevel from '../PriceLevel';
import PriceCode from '../PriceCode';
import Price from '../Price';
import { find, filter, get, min } from 'lodash';
import ResalePrice from '../Price/ResalePrice';
import { IPriceRange } from '../../types/inventoryTypes';
import DynamicPrice from '../Price/DynamicPrice';
import { IDynamicPriceRanges } from '../Price/DynamicPriceRange';
import Seat from '../Seat/Seat';

class PriceChart {

  constructor(
    public zoneId: number,
    public eventId: number,
    public productId: number,
    private priceLevels: PriceLevel[],
    private priceCodes: PriceCode[],
    private prices: Price[],
    public resalePrices: ResalePrice[],
    public dynamicPrices: DynamicPrice[],
    public dynamicPriceRanges: IDynamicPriceRanges,
    public isSoldOut: boolean,
    public priceRange?: IPriceRange,
    public resalePriceRange?: IPriceRange,
  ) {

    this.priceRange = this.getPriceRange(prices, dynamicPriceRanges);
    this.resalePriceRange = this.getResalePriceRange(resalePrices);
  }

  public getPriceLevels() {
    return this.priceLevels;
  }

  public getPriceCodes() {
    return this.priceCodes;
  }

  public getPrices() {
    return this.prices;
  }

  /**
   * Return the minimum price for a specific price Level (Used for BA flow)
   *
   * @param {number} priceLevelId The price level ID
   * @param {number[]} priceCodeIds The list of selected price Code Ids
   *
   * @returns {number | undefined} The minimum price
   */
  public getMinPrice(priceLevelId: number, priceCodeIds: number[]): number | undefined {
    const prices: Price[] = this.getPriceLevelPrices(priceLevelId, priceCodeIds);
    return min(prices.map(price => price.value));
  }

  /**
   * Return the List of Prices for a specific price Level
   *
   * @param {number} priceLevelId The price level ID
   * @param {number[]} priceCodeIds The list of selected price Code Ids
   *
   * @returns {Price[]} The list of Price object
   */
  public getPriceLevelPrices(priceLevelId: number, priceCodeIds: number[]): Price[] {
    if (priceCodeIds.length === 0) return [];
    const prices: Price[] = this.prices.filter((price: Price) => {
      return price.priceLevelId === priceLevelId &&
        priceCodeIds.includes(price.priceCodeId);
    });
    if (prices && prices.length > 0) {
      return prices;
    }
    return [];
  }

  /**
   * Return the possible seat prices, including dynamic price
   *
   * @param {Seat} seat The seat Object
   * @param {number} priceCodeId The price Code ID (optionnal), if set, the method returns 1 item
   * @param {boolean} includeDynamicPrice include dynamic price?
   *
   * @returns {number[]} The list of prices
   */
  public getSeatPrices(seat: Seat, priceCodeId?: number, includeDynamicPrice: boolean = true): number[] {
    if (!seat || !seat.priceLevelId) return [];
    let regularPrices: Price[] = filter(this.prices, { priceLevelId: seat.priceLevelId });
    if (priceCodeId) {
      regularPrices = filter(regularPrices, { priceCodeId });
    }
    let prices: number[] = [];
    if (includeDynamicPrice && this.dynamicPrices) {
      let dynPrices: DynamicPrice[] = filter(this.dynamicPrices, {
        priceLevelId: seat.priceLevelId,
        rowId: seat.rowId,
        seatId: seat.id,
        sectionId: seat.sectionId,
      });
      if (priceCodeId !== undefined) {
        dynPrices = filter(dynPrices, { priceCodeId });
      }
      // Replace the regular price with dynamic price
      regularPrices.forEach((price: Price) => {
        // Find the price
        const dynPrice = find(dynPrices, { priceCodeId: price.priceCodeId });
        // hs dynamic
        if (dynPrice) {
          prices.push(dynPrice.value);
        } else {
          prices.push(price.value);
        }
      });
    } else {
      prices = regularPrices.map(p => p.value);
    }
    return prices;
  }

  /**
   * Return the seat Resale Price
   *
   * @param {string} sectionLabel The Seat's section label
   * @param {number} offerListingId The seat's flash Seat Offer Listing Id
   *
   * @returns {number | undefined} The resale price
   */
  public getSeatResalePrice(sectionLabel: string, offerListingId: number): number | undefined {
    const resalePrice: ResalePrice | undefined = find(this.resalePrices, { sectionLabel, offerListingId });
    if (resalePrice) {
      return resalePrice.price;
    }
    return undefined;
  }

  /**
   * Calculates the price Range of the Chart
   *
   * @param {Price[]} prices List of all price Objects
   * @param {IDynamicPriceRanges} dpRanges Pre-calculated dynamic prices ranges (from the API)
   *
   * @returns {IPriceRange} the Price Range
   */
  private getPriceRange(prices: Price[], dpRanges: IDynamicPriceRanges): IPriceRange {
    if (prices.length) {
      const pricing: number[] = prices.map((price: Price) => price.value);
      const sortedPrices = pricing.slice().sort((a: number, b: number) =>  a - b);
      const priceRange: IPriceRange = {
        max: sortedPrices[sortedPrices.length - 1],
        min: sortedPrices[0],
      };
      // Dynamic Price magic
      const dynamicPriceRangeMin = get(dpRanges, 'all.min');
      const dynamicPriceRangeMax = get(dpRanges, 'all.max');
      if (priceRange.min && dynamicPriceRangeMin !== undefined) {
        priceRange.min = Math.min(priceRange.min, dynamicPriceRangeMin);
      }
      if (priceRange.max && dynamicPriceRangeMax !== undefined) {
        priceRange.max = Math.max(priceRange.max, dynamicPriceRangeMax);
      }
      return priceRange;
    }
    return {
      max: undefined,
      min: undefined,
    };
  }

  /**
   * Calculates the price Range of the Resale prices
   *
   * @param {ResalePrice[]} prices List of all Resale Price Objects
   *
   * @returns {IPriceRange} the Price Range
   */
  private getResalePriceRange(prices: ResalePrice[]): IPriceRange {
    if (prices.length) {
      const pricing: number[] = prices.map((price: ResalePrice) => price.price);
      const sortedPrices = pricing.slice().sort((a: number, b: number) => {
        return a - b;
      });
      return {
        max: sortedPrices[sortedPrices.length - 1],
        min: sortedPrices[0],
      };
    }
    return {
      max: undefined,
      min: undefined,
    };
  }
}

export default PriceChart;
