import * as React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import PageLayout from '../layouts/PageLayout';
import { matchPath } from 'react-router';
import { get } from 'lodash';
import { pages } from '../constants/routes';

const OrderDetailsContainer = Loadable({
  loader: () => import('../containers/order'),
  loading: () => null,
});

const AccountOrderPageContainer = Loadable({
  loader: () => import('../containers/order/OrderDetailsContainer'),
  loading: () => null,
});

export default function (store: any, props: any) {
  const match = props.match;
  const path: any = matchPath(match.url, {
    exact: true,
    path: pages.order(':email', ':orderId'),
    strict: false,
  });
  const pathTab: any = matchPath(match.url, {
    exact: true,
    path: pages.orderTab(':email', ':orderId', ':tab'),
    strict: false,
  });

  const renderComponent = (props: any) => {
    const componentProps = {
      ...props,
      customerEmail: get(path, 'params.email') || get(pathTab, 'params.email'),
      orderId: parseInt(get(path, 'params.orderId', get(pathTab, 'params.orderId', 0))),
      tab: get(pathTab, 'params.tab', 'details'),
    };

    return (
      <>
        <AccountOrderPageContainer { ...store } { ...componentProps } />
        <OrderDetailsContainer { ...store } { ...componentProps } />
      </>
    );
  };

  return(
    <Switch>
      <PageLayout>
        <Route
          path={ match.url }
          render={ renderComponent }
        />
      </PageLayout>
    </Switch>
  );
}

