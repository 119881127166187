import { filter, sortBy } from 'lodash';
import moment from 'moment';
import {
  IGlobalSearchCustomersSuccess,
  ISearchState,
  SearchStateActions,
  SearchStateTypes,
  ISearchOrdersBegin,
  ISearchOrdersSuccess,
  ISearchOrdersFailure,
  ISearchEventsBegin,
  ISearchEventsSuccess,
  ISearchEventsFailure,
  ISearchCustomersBegin,
  ISearchCustomersFailure,
  ISetSearchQuery, ISearchTicketsFailure, ISearchTicketsSuccess,
} from '../types/searchTypes';
import VTEvent from '../common/Event/VTEvent';
import { mapCustomerBaseGlobalSearch } from './utils/mockCustomerMapper';

export const initialState: ISearchState = {
  customers: [],
  customersSearching: false,
  error: '',
  eventSearching: false,
  events: [],
  globalSearchComplete : false,
  orders : {
    contexts: {

    },
    pageNumber: 0,
    pageSize: 0,
    totalResults: 0,
  },
  ordersSearching: false,
  searchQuery : '',
  tickets : [],
  ticketsSearching: false,
};

// customer
const handleSearchCustomersBegin = (state: ISearchState, action: ISearchCustomersBegin): ISearchState => {
  return { ...state, customersSearching: true };
};

const handleSearchCustomersSuccess = (state: ISearchState, action: IGlobalSearchCustomersSuccess):
  ISearchState => {
  const customers = action.customers.map(mapCustomerBaseGlobalSearch);
  return { ...state, customers, customersSearching: false };
};

const handleSearchCustomersFailure = (state: ISearchState, action: ISearchTicketsFailure): ISearchState => {
  return { ...state, customersSearching: false };
};

// ticket
const handleSearchTicketsBegin = (state: ISearchState, action: ISearchCustomersBegin): ISearchState => {
  return { ...state, ticketsSearching: true };
};

const handleSearchTicketsSuccess = (state: ISearchState, action: ISearchTicketsSuccess):
  ISearchState => {
  const { tickets } = action;
  return { ...state, tickets, ticketsSearching: false };
};

const handleSearchTicketsFailure = (state: ISearchState, action: ISearchCustomersFailure): ISearchState => {
  return {
    ...state,
    tickets: [],
    ticketsSearching: false,
  };
};

const ACTION_HANDLERS = {
  // events
  [SearchStateTypes.SEARCH_EVENTS_BEGIN] : (state: ISearchState, action: ISearchEventsBegin) => {
    const newState = {};
    return Object.assign(newState, state, { eventSearching : true });
  },
  [SearchStateTypes.SEARCH_EVENTS_SUCCESS] : (state: ISearchState, action: ISearchEventsSuccess) => {
    const events = action.data.Events;
    const newEvents: VTEvent[] = [];
    events.forEach((event: any) => {
      const newEvent: VTEvent = new VTEvent(
        event.Id,
        action.data.contextId,
        event.EventName,
        null,
        event.StartDate,
        event.EndDate,
        event.LookupId,
      );
      newEvents.push(newEvent);
    });

    let futureEvents = filter(newEvents, (event: VTEvent) => {
      const eventEndDate = moment(event.endDate);
      return eventEndDate.isAfter(moment().startOf('day'));
    });

    futureEvents = sortBy(futureEvents, 'startDate');

    const newState = {};
    return Object.assign(newState, state, { events: futureEvents, eventSearching: false });
  },
  [SearchStateTypes.SEARCH_EVENTS_FAILURE] : (state: ISearchState, action: ISearchEventsFailure) => {
    return Object.assign(state, { error : action.errorMsg, eventSearching: false });
  },

  // orders
  [SearchStateTypes.SEARCH_ORDERS_BEGIN] : (state: ISearchState, action: ISearchOrdersBegin) => {
    return {
      ...state,
      globalSearchComplete : false,
      ordersSearching: true,
      page: action.page,
      results: action.resultCount,
      searchQuery : action.payload,
    };
  },
  [SearchStateTypes.SEARCH_ORDERS_SUCCESS] : (state: ISearchState, action: ISearchOrdersSuccess) => {
    const orders = action.orders;
    const newState = {};
    return Object.assign(newState, state, { orders }, { ordersSearching: false });
  },
  [SearchStateTypes.SEARCH_ORDERS_FAILURE] : (state: ISearchState, action: ISearchOrdersFailure) => {
    return {
      ...state,
      error : action.payload,
      globalSearchComplete : true,
      ordersSearching: false,
    };
  },

  // customers
  [SearchStateTypes.SEARCH_CUSTOMERS_BEGIN] : handleSearchCustomersBegin,
  [SearchStateTypes.SEARCH_CUSTOMERS_SUCCESS] : handleSearchCustomersSuccess,
  [SearchStateTypes.SEARCH_CUSTOMERS_FAILURE] : handleSearchCustomersFailure,

  // tickets
  [SearchStateTypes.SEARCH_TICKETS_BEGIN] : handleSearchTicketsBegin,
  [SearchStateTypes.SEARCH_TICKETS_SUCCESS] : handleSearchTicketsSuccess,
  [SearchStateTypes.SEARCH_TICKETS_FAILURE] : handleSearchTicketsFailure,

  //
  [SearchStateTypes.GLOBAL_SEARCH_COMPLETE] : (state: ISearchState) => {
    const newState = {};
    return Object.assign(newState, state, { globalSearchComplete : true });
  },
  [SearchStateTypes.SET_SEARCH_QUERY] : (state: ISearchState, action: ISetSearchQuery) => {
    const newState = {};
    return Object.assign(newState, state, { searchQuery : action.searchQuery });
  },
};

export default function reducer(
  state: ISearchState = initialState,
  action: SearchStateActions,
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state;
}
