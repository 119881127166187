import * as React from 'react';
import { AXSNotification } from '@axs/axs-ui';
import { IAXSNotificationProps } from '@axs/axs-ui/dist/elements/AXSNotification';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { zIndexes } from '../../theme';
import { INotification } from '../../types/appTypes';

interface INotificationProps extends IAXSNotificationProps {
  active: boolean;
}

const Notification = styled(AXSNotification)<INotificationProps>`
  position: sticky;
  top: 0;
  transform: ${({ active }) => active ? 'translate3d(0,0,0)' : 'translate3d(0,-200px,0)' };
  transition: .2s;
  width: 100%;
  z-index: ${ zIndexes.NOTIFICATION };
`;

interface IAppNotification {
  active: boolean;
  notification: INotification;
  handleClose: () => void;
}

const AppNotification = ({ active, notification, handleClose }: IAppNotification) => {
  const [t] = useTranslation();
  const { message } = notification;
  const rawMessages: string[] = Array.isArray(message) ? message : [message];
  const messages = rawMessages.map(message => notification.translate ? t(message) : message);
  return (
    <Notification active={ active } handleClose={ handleClose } type={ notification.notificationType }>
      <div>{ messages.join(' ') }</div>
    </Notification>
  );
};

export default AppNotification;
