import { combineReducers, Store } from 'redux';
import { ReducerMap, StoreShape } from './store.types';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import accountReducer from '../reducers/accountReducer';
import appReducer from '../reducers/appReducer';
import cartReducer from '../mfe/carting/Carting/reducers/cartingReducer';
import customersReducer from '../reducers/customersReducer';
import eventsReducer from '../reducers/eventsReducer';
import featuresReducer from '../reducers/featuresReducer';
import inventoryReducer from '../reducers/inventoryReducer';
import mapReducer from '../mfe/map/Map/reducers/mapReducer';
import offersReducer from '../reducers/offersReducer';
import orderReducer from '../reducers/orderReducer';
import searchReducer from '../reducers/searchReducer';
import seatReducer from '../reducers/seatReducer';
import userReducer from '../reducers/userReducer';

let asyncReducers: ReducerMap = {};

export const makeRootReducer = (history: any) => {
  return combineReducers({
    account: accountReducer,
    app: appReducer,
    carting: cartReducer,
    customers: customersReducer,
    events: eventsReducer,
    features: featuresReducer,
    inventory: inventoryReducer,
    map: mapReducer,
    offers: offersReducer,
    orders: orderReducer,
    router: connectRouter(history),
    search: searchReducer,
    seat: seatReducer,
    user: userReducer,
    ...asyncReducers,
  });
};

const history = createBrowserHistory();

export function registerReducer(store: Store<StoreShape>, newReducers: ReducerMap): Store<StoreShape> {
  asyncReducers = { ...asyncReducers, ...newReducers };
  store.replaceReducer(makeRootReducer(history) as any); // @todo - Had to add as any. Revisit.
  return store;
}
