import { opacify } from '../common/utils/themeUtils';

export const headerBarHeight = '65px';
export const headerCartBarHeight = '46px';
export const eventHeaderHeight = '169px';
export const maxPreCartHeight = '312px';
export const preCartHeaderHeight = '70px';
export const preCartContentHeight = '204px';
export const filterHeaderHeight = '150px';

/**
 * These will eventually be moved to AXS-UI. @TODO move to AXS-UI
 */
export const themeColors = {
  AXS_BLUE:               '#0054a1',
  BLACK:                  '#000000',
  BLACK_10:               opacify('#000000', '.1'),
  BLACK_20:               opacify('#000000', '.2'),
  BLACK_70:               opacify('#000000', '.7'),
  BLACK_85:               opacify('#000000', '.85'),
  BLACK_RUSSIAN:          '#040619',
  BLUE_10:                '#11225f',
  BLUE_11:                '#003268',
  BLUE_12:                '#1d3570',
  BLUE_14:                '#3b5583',
  BLUE_16:                '#4a6694',
  BLUE_18:                '#5f7697',
  BLUE_2:                 '#000410',
  BLUE_20:                '#798daa',
  BLUE_22:                '#a1aec2',
  BLUE_24:                '#bbc7dc',
  BLUE_25:                '#00205b',
  BLUE_3:                 '#000328',
  BLUE_3_20:               opacify('#000328', '.2'),
  BLUE_4:                 '#04061a',
  BLUE_6:                 '#000e2e',
  BLUE_7:                 '#02123a',
  BLUE_8:                 '#091746',
  BLUE_BLACK:             '#1d1f31',
  BLUE_GRAY:              '#182346',
  CTA_BLUE:               '#007def',
  CTA_BLUE_ACTIVE:        '#0060c4',
  CTA_BLUE_HOVER:         '#007dff',
  DARK_MODE_WHITE:        '#ebebeb',
  GREEN:                  'axsui.status.success.default',
  GREEN_HOVER:            'axsui.status.success.hover',
  GREY_200:               '#ededed',
  GREY_201:               '#bcbcbc',
  GREY_DARK:              '#929292',
  GREY_DARKER:            '#717171',
  GREY_DARKEST:           '#505050',
  GREY_LIGHT:             '#cfcfcf',
  GREY_LIGHTER:           '#dedede',
  GREY_LIGHTEST:          '#f6f6f6',
  GREY_MEDIUM:            '#aaaaaa',
  LIGHT_MODE_LT_BLUE:     '#e4edf9',
  LINK_BLUE:              '#007dff',
  LINK_BLUE_HOVER:        '#0056b3',
  PERSIAN_GREEN:          '#00A7A3',
  WHITE:                  '#ffffff',
  WHITE_10:               opacify('#ffffff', '.1'),
  WHITE_20:               opacify('#ffffff', '.2'),
  WHITE_70:               'rgba(255, 255, 255, .7)',
};

export const cartBorder = (themeMode: string) =>
  themeMode === themeTypes.dark ? '1px solid rgba(255, 255, 255, 0.1)' : '1px solid rgba(0, 0, 0, 0.1)';

/**
 * EXPIRATION_MODAL has the highest priority.
 * <AXSModal> has z-index 1000 by default.
 * The order of the content (from the highest to lowest priority)
 * EXPIRATION_MODAL
 * MODALS (<AXSModal>)
 * CART_DRAWER, FILTERS
 * PREFERENCES_SECTION
 * OVERLAY
 * CART_HEADER, MAIN_HEADER, NOTIFICATION
 * MAP_TOOLTIP
 * ...rest
 */

export const zIndexes = {
  CALENDAR: 1500,
  CART_DRAWER: 900,
  CART_HEADER: 600,
  DROPDOWN: 2,
  EXPIRATION_MODAL: 2000,
  FILTERS: 900,
  MAIN_HEADER: 600,
  MAP_LOADING: 101,
  MAP_TOOLTIP: 500,
  NOTIFICATION: 600,
  OVERLAY: 700,
  PREFERENCES_SECTION: 800,
};

/**
 * Some components don't use normal theme path coloring.
 */
export const edpPushButtonDark = {
  background: themeColors.BLACK,
  backgroundHover: themeColors.BLACK,
  borderColor : themeColors.BLUE_14,
  borderColorHover : themeColors.BLUE_14,
  color : themeColors.BLUE_18,
  colorHover : themeColors.WHITE,
};

export const edpPushButtonDarkActive = {
  background: themeColors.BLUE_10,
  backgroundHover: themeColors.CTA_BLUE,
  borderColor : themeColors.BLUE_10,
  borderColorHover : themeColors.BLUE_10,
  color : themeColors.WHITE,
  colorHover : themeColors.WHITE,
};

export const edpPushButtonLight = {
  background: 'transparent',
  backgroundHover: 'transparent',
  borderColor : themeColors.AXS_BLUE,
  borderColorHover : themeColors.CTA_BLUE,
  color : themeColors.AXS_BLUE,
  colorHover : themeColors.CTA_BLUE,
};

export const edpPushButtonLightActive = {
  background: themeColors.AXS_BLUE,
  backgroundHover: themeColors.CTA_BLUE,
  borderColor : themeColors.AXS_BLUE,
  borderColorHover : themeColors.CTA_BLUE,
  color : themeColors.WHITE,
  colorHover : themeColors.WHITE,
};

export const edpOfferTabsDark = {
  background: 'transparent',
  backgroundColorActive: themeColors.WHITE_20,
  borderColor : themeColors.WHITE_20,
  borderColorActive : themeColors.WHITE_20,
  color : themeColors.WHITE,
  colorActive : themeColors.WHITE,
};

export const edpOfferTabsLight = {
  background: 'transparent',
  backgroundColorActive: themeColors.BLUE_3_20,
  borderColor : themeColors.BLUE_3_20,
  borderColorActive : themeColors.BLUE_3_20,
  color : themeColors.BLUE_3,
  colorActive : themeColors.BLUE_3,
};

export const offerTabsLight = {
  background: 'transparent',
  backgroundColorActive: themeColors.LIGHT_MODE_LT_BLUE,
  borderColor : themeColors.LIGHT_MODE_LT_BLUE,
  borderColorActive : themeColors.LIGHT_MODE_LT_BLUE,
  color : themeColors.BLUE_11,
  colorActive : themeColors.BLUE_11,
};

export const offerTabsDark = {
  background: 'transparent',
  backgroundColorActive: themeColors.BLUE_10,
  borderColor : themeColors.BLUE_10,
  borderColorActive : themeColors.BLUE_10,
  color : themeColors.BLUE_24,
  colorActive : themeColors.BLUE_24,
};
export const themeModeButtonsDark = {
  background: 'transparent',
  backgroundColorActive: themeColors.BLUE_BLACK,
  borderColor : themeColors.BLUE_BLACK,
  borderColorActive : themeColors.BLUE_BLACK,
  color : themeColors.BLUE_24,
  colorActive : themeColors.BLUE_24,
};

export const themeModeButtonsLight = {
  background: 'transparent',
  backgroundColorActive: themeColors.LIGHT_MODE_LT_BLUE,
  borderColor : themeColors.LIGHT_MODE_LT_BLUE,
  borderColorActive : themeColors.LIGHT_MODE_LT_BLUE,
  color : themeColors.BLUE_11,
  colorActive : themeColors.BLUE_11,
};

export const themeTypes = {
  dark: 'dark',
  light: 'light',
};

