import * as React from 'react';
import { AXSBlock, AXSDateDisplay, AXSModal } from '@axs/axs-ui';
import styled from 'styled-components';
import parseColor from '@axs/axs-ui/dist/utils/colorHelpers';
import { colors } from '@axs/axs-ui/dist/constants/colors';
import { themeColors, themeTypes } from '../../theme';

interface IEventDetailModal {
  active: boolean;
  children: React.ReactChild;
  eventDate: string;
  eventTitle: string;
  handleClose: () => void;
  venue?: string;
}
const Title = styled.h2`
  font-size: 36px;
`;

const Venue = styled.h2`
  font-size: 14px;
  color: ${({ theme }) => parseColor('axsui.text.elements.h2.color', theme, colors.GREY_400) };
`;

const MoreInfo = styled.h2`
  font-size: 14px;
  color: ${({ theme }) => parseColor('axsui.text.secondary', theme, colors.GREY_400) };
  margin-top: 80px;
`;

const EventInfo = styled.h2`
  font-size: 14px;
  color: ${({ theme }) => theme.skin === themeTypes.dark ?
  parseColor('axsui.text.elements.h2.color', theme, themeColors.BLUE_20) :
  parseColor('axsui.text.secondary', theme, themeColors.GREY_DARKEST) };
`;

const EventDetailModal = ({ active, handleClose, children, venue, eventDate, eventTitle }: IEventDetailModal) => {

  return (
      <AXSModal
        showClose
        handleClose={ handleClose }
        active={ active }
        width="900px"
        height="600px"
      >
        <AXSBlock padding="80px 95px">
          <AXSBlock>
            <AXSBlock>
              <AXSDateDisplay fontSize="16px" date={ eventDate } displayFormat="full" displayYear displayWeekday />
            </AXSBlock>
            <Title>
            { eventTitle }
            </Title>
            <Venue>{ venue }</Venue>
          </AXSBlock>
          <MoreInfo>More Event Info</MoreInfo>
          <EventInfo>
            { children }
          </EventInfo>
        </AXSBlock>
      </AXSModal>
  );
};

export default EventDetailModal;
