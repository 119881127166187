import React from 'react';
import classnames from 'classnames';
import { ClippedText } from './styles';
import { headerVariants } from '../EventDetail/EventDetail';

export const EventDetailTitle = ({ align, variant, children, className }: any) => {

  let fontSize = '';
  let fontWeight = '';
  let lineHeight = '';

  switch (variant) {
    case headerVariants.header:
      fontSize = '24px';
      fontWeight = 'bold';
      lineHeight = '32px';
      break;
    case headerVariants.edp:
      fontSize = '42px';
      lineHeight = '50px';
      break;
    case headerVariants.cartDrawer:
      fontSize = '24px';
      lineHeight = '30px';
      break;
    case headerVariants.confirmation:
      fontSize = '17px';
      lineHeight = '30px';
      break;
  }

  return (
    <ClippedText
      fontSize={ fontSize }
      fontWeight={ fontWeight }
      lineHeight={ lineHeight }
      textAlign={ align }
      className={ classnames(['test-event-title-resizer', 'event-title', className]) }
    >
      { children }
    </ClippedText>
  );
};

