import {
  IAvailableEvent,
  IAvailableEvents,
  IEventsState,
  EventsStateActions,
  EventsStateTypes,
  IGetEventFromOffers,
  IGetEventsByNameSuccess,
  IGetEventsByNameFailure,
  IUpdateEventSeatCounts,
  IGetEventsFromOffer,
} from '../types/eventsTypes';
import { VTEvent } from '../common/Event';
import Venue from '../common/Venue';
import Address from '../common/Address/Address';
import { filter, forEach, get, clone, find } from 'lodash';
import Category from '../common/Category/Category';
import { IEvent, IUpcomingEvents } from '../api/types/eventtool/responseTypes';
import { getExpiration, isExpired } from '../utils/common';
import { IUapiEvent } from '../api/types/unifiedapi/responseTypes';

const EVENT_SHELF_LIFE = 'general';

export const initialState: IEventsState = {
  availableEvents: {},
  fetchingCMSEvents: false,
  fetchingDefaultEvent: false,
  fetchingEvents: false,
  fetchingEventsError: false,
  fetchingMissingEvents: false,
};

const addAnEvent = (event: any, availableEvents: IAvailableEvents, contextId: number): void => {
  const eventId = get(event, 'Id') as number;
  if (!eventId) { return; }
  const venue: Venue = new Venue(get(event, 'VenueId'));
  const newEvent: VTEvent = new VTEvent(
    eventId,
    contextId,
    get(event, 'EventName'),
    venue,
    get(event, 'StartDate'),
    get(event, 'EndDate'),
    get(event, 'LookupId'));
  const availableEvent: IAvailableEvent = {
    data: newEvent,
    expireAt: getExpiration(EVENT_SHELF_LIFE),
  };
  availableEvents[eventId.toString()] = availableEvent;
};

const ACTION_HANDLERS = {
  [EventsStateTypes.GET_CMS_EVENT_BEGIN]: (state: IEventsState, action: { eventIds: number[] }) => {
    const availableEvents = { ...state.availableEvents };
    const eventIds = action.eventIds.map(id => id.toString());
    const events = filter(availableEvents, (event, id) => eventIds.includes(id));
    events.forEach((availableEvent) => {
      if (availableEvent && availableEvent.data) {
        const updateEvent = clone(availableEvent.data);
        updateEvent.cms = true;
        availableEvents[updateEvent.id.toString()] = {
          data: updateEvent,
          expireAt: availableEvent.expireAt,
        };
      }
    });
    return Object.assign({}, state, { availableEvents, fetchingCMSEvents: true });
  },
  [EventsStateTypes.GET_CMS_EVENT_SUCCESS]: (state: IEventsState, action: { data: any }) => {
    const availableEvents: IAvailableEvents  = { ...state.availableEvents };
    const imageUrl = get(action, 'data.imageURL');
    const supportingAct = get(action, 'data.supportingAct');
    const description = get(action, 'data.description');
    const eventId = action.data.eventId.toString();
    const availableEvent = get(availableEvents, eventId);
    if (availableEvent && availableEvent.data) {
      const event = clone(availableEvent.data);
      if (imageUrl) {
        event.eventImageUrl = imageUrl;
      }
      if (description) {
        event.description = description;
      }
      if (supportingAct) {
        event.supportingAct = supportingAct;
      }
      availableEvents[eventId] = {
        data: event,
        expireAt: availableEvent.expireAt,
      };
      return Object.assign({}, state, { availableEvents });
    }
    return Object.assign({}, state);
  },
  [EventsStateTypes.GET_CMS_EVENT_COMPLETED]: (state: IEventsState) => {
    const newState = { ...state };
    return Object.assign(newState, { fetchingCMSEvents: false });
  },
  [EventsStateTypes.GET_CMS_EVENT_FAILURE]: (state: IEventsState) => {
    const newState = { ...state };
    return Object.assign(newState, { fetchingCMSEvents: false });
  },
  [EventsStateTypes.GET_UPCOMING_EVENTS_BEGIN]: (state: IEventsState) => {
    const newState = { ...state };
    return Object.assign(newState, { fetchingEvents: true, fetchingEventsError: false });
  },
  [EventsStateTypes.GET_UPCOMING_EVENTS_FAILURE]: (state: IEventsState) => {
    const newState = { ...state };
    return Object.assign(newState, { fetchingEvents: false, fetchingEventsError: true });
  },
  [EventsStateTypes.GET_UPCOMING_EVENTS_SUCCESS]: (state: IEventsState, action: { data: IUpcomingEvents }) => {
    const newAvailableEvents: IAvailableEvents = { ...state.availableEvents };
    const data = action.data.Events;
    const contextId = parseInt(action.data.contextId);
    data.forEach((e: IEvent) => {
      const { Address1, Address2, City, Zip, State } = e.VenueAddress;
      const { Id, StartDate, EndDate, EventName, VenueId } = e;
      const venueName = e.Venue;

      const venueAddress: Address = new Address(Address1, Address2, '', City, Zip, 'US', State);
      const categories: any = [];
      if (e.Categories && Array.isArray(e.Categories)) {
        e.Categories.forEach((ct: any) => {
          categories.push(new Category(ct.Id, ct.Name, ct.TypeId));
        });
      }
      const venue: Venue = new Venue(VenueId, venueName, venueAddress);
      const event: VTEvent = new VTEvent(+Id, contextId, EventName, venue, StartDate, EndDate);
      if (!event.isPast()) {
        event.categories = categories;
        newAvailableEvents[Id.toString()] = {
          data: event,
          expireAt: getExpiration(EVENT_SHELF_LIFE),
        };
      }
    });

    return Object.assign({}, state, {
      availableEvents: newAvailableEvents,
      fetchingEvents: false,
      fetchingEventsError: false,
    });
  },
  [EventsStateTypes.GET_EVENTS_FROM_OFFER]: (state: IEventsState, { data }: IGetEventsFromOffer) => {
    const events: IUapiEvent[] = get(data, 'onsaleInformation.events');
    const availableEvents = { ...state.availableEvents };
    events.forEach((event) => {
      const existingEvent = availableEvents[event.eventID];
      if (!existingEvent) {
        // Find the venue
        const venueId = event.venueMap.venueMapID;
        const venueData = find(data.onsaleInformation.venues, { venueID: venueId });
        if (venueData) {
          const venueAddress: Address = new Address(
            venueData.address1,
            venueData.address2, '',
            venueData.city,
            venueData.zipCode,
            venueData.country,
            venueData.state);
          const venue: Venue = new Venue(parseInt(venueData.venueID), venueData.name, venueAddress);
          const newEvent: VTEvent = new VTEvent(
            parseInt(event.eventID),
            data.onsaleInformation.meta.contextID,
            event.title,
            venue,
            event.date,
            event.date,
            undefined);
          availableEvents[event.eventID] = {
            data: newEvent,
            expireAt: getExpiration(EVENT_SHELF_LIFE),
          };
        }
      }
    });
    return Object.assign({}, state, { availableEvents });
  },
  [EventsStateTypes.GET_EVENT_FROM_OFFERS]: (state: IEventsState, { data }: IGetEventFromOffers) => {
    // If the available Event are empty (in the case of a deep link)
    // we build a list of 1 event with the data got from this API
    const eventData = data.Event;
    const contextId = data.contextId;
    const newEventId = get(eventData, 'Id', 0);
    const availableEvents = { ...state.availableEvents };
    const availableEvent = get(availableEvents, newEventId);

    // If the event doesnt exist, add it
    if (eventData) {
      if (isExpired(availableEvent)) {
        const venueAddress: Address = new Address(
          get(eventData, 'VenueAddress.Address1'),
          get(eventData, 'VenueAddress.Address2'), '',
          get(eventData, 'VenueAddress.City'),
          get(eventData, 'VenueAddress.Zip'),
          'US',
          get(eventData, 'VenueAddress.State'));
        const venue: Venue = new Venue(get(eventData, 'VenueId'), get(eventData, 'Venue'), venueAddress);
        const newEvent: VTEvent = new VTEvent(
          newEventId,
          contextId,
          get(eventData, 'EventName'),
          venue,
          get(eventData, 'StartDate'),
          get(eventData, 'EndDate'),
          get(eventData, 'LookupId'));
        newEvent.seatCount = get(eventData, 'SeatCount');
        availableEvents[newEventId] = {
          data: newEvent,
          expireAt: getExpiration(EVENT_SHELF_LIFE),
        };
      } else {
        // Try to add the seat Count to the existing event
        const updateEvent = clone(availableEvent.data!);
        updateEvent.seatCount = get(eventData, 'SeatCount');
        availableEvents[newEventId] = {
          data: updateEvent,
          expireAt: availableEvent.expireAt,
        };
      }
      return Object.assign({}, state, { availableEvents });
    }
    return Object.assign({}, state);
  },
  [EventsStateTypes.FETCH_EVENTS_BY_NAME_SUCCESS]: (state: IEventsState, { data }: IGetEventsByNameSuccess) => {
    const eventsData = data.Events;
    const contextId = data.contextId;
    const availableEvents: IAvailableEvents = { ...state.availableEvents };

    eventsData.forEach((event: any) => {
      const eventId = get(event, 'Id', 0);
      const eventIdString = eventId.toString();
      // Try to find the event First
      const existingEvent = get(availableEvents, eventIdString);
      if (isExpired(existingEvent)) {
        const venue: Venue = new Venue(get(event, 'VenueId'));
        const newEvent: VTEvent = new VTEvent(
          eventId,
          contextId,
          get(event, 'EventName'),
          venue,
          get(event, 'StartDate'),
          get(event, 'EndDate'),
          get(event, 'LookupId'));
        if (!newEvent.isPast()) {
          availableEvents[eventIdString] = {
            data: newEvent,
            expireAt: getExpiration(EVENT_SHELF_LIFE),
          };
        }
      }
    });
    return Object.assign({}, state, { availableEvents });
  },
  [EventsStateTypes.GET_EVENT_BY_ID_BEGIN]: (state: IEventsState) => {
    const newState = { ...state };
    return Object.assign(newState, { fetchingEvents: true, fetchingEventsError: false });
  },
  [EventsStateTypes.GET_EVENT_BY_ID_SUCCESS]: (state: IEventsState, { data }: IGetEventsByNameSuccess) => {
    const availableEvents: IAvailableEvents = { ...state.availableEvents };
    addAnEvent(data.Event, availableEvents, data.contextId);
    return Object.assign({}, state, { availableEvents, fetchingEvents: false });
  },
  [EventsStateTypes.GET_EVENT_BY_ID_FAILURE]: (state: IEventsState, { errorMsg }: IGetEventsByNameFailure) => {
    return { ...state, fetchingEvents: false, fetchingEventsError: true };
  },
  [EventsStateTypes.GET_DEFAULT_EVENT_BEGIN]: (state: IEventsState) => {
    const newState = { ...state };
    return Object.assign(newState, { fetchingDefaultEvent: true });
  },
  [EventsStateTypes.GET_DEFAULT_EVENT_END]: (state: IEventsState) => {
    const newState = { ...state };
    return Object.assign(newState, { fetchingDefaultEvent: false });
  },
  [EventsStateTypes.LOAD_MISSING_EVENTS]: (state: IEventsState) => {
    return { ...state, fetchingMissingEvents: true };
  },
  [EventsStateTypes.LOAD_MISSING_EVENTS_COMPLETE]: (state: IEventsState) => {
    return { ...state, fetchingMissingEvents: false };
  },
  [EventsStateTypes.UPDATE_EVENT_SEAT_COUNTS]: (state: IEventsState, { seatCount }: IUpdateEventSeatCounts) => {
    const availableEvents: IAvailableEvents = { ...state.availableEvents };
    const eventId = get(seatCount, 'Id', 0).toString();
    const availableEvent = get(availableEvents, eventId);
    if (availableEvent && availableEvent.data) {
      const event = clone(availableEvent.data);
      event.seatCount = seatCount.SeatCount;
      availableEvents[eventId] = {
        data: event,
        expireAt: availableEvent.expireAt,
      };
    }
    return Object.assign({}, state, { availableEvents });
  },
  [EventsStateTypes.REMOVE_EXPIRED_EVENTS]: (state: IEventsState) => {
    const newAvailableEvents: IAvailableEvents = {};
    forEach(state.availableEvents, (availableEvent, key) => {
      if (!isExpired(availableEvent)) {
        newAvailableEvents[key] = availableEvent;
      }
    });
    return Object.assign({}, state, { availableEvents: newAvailableEvents });
  },
};

export default function reducer(
  state: IEventsState = initialState,
  action: EventsStateActions,
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state; // @todo fix any crutch here.
}
