import get from 'lodash/get';
import { ThunkAction } from 'redux-thunk';
import { AxiosError, AxiosResponse } from 'axios';
import { push } from 'connected-react-router';

import { IFullStorageShape } from '../store/store.types';
import { getEventFromOffers, getEventsFromOffer, updateEventSeatCounts } from './eventsActions';
import { setSelectedEventId } from './userActions';
import { fetchEventOffers, fetchOffer } from '../api/ApiManager';
import {
  IFetchEventOffersBegin,
  IFetchEventOffersFailure,
  IFetchEventOffersSuccess,
  IGetOfferBegin,
  IGetOfferFailure,
  IGetOfferSuccess,
  IHideFilterPanel,
  IHideMapFilters,
  IHidePrecartPanel,
  IShowFilterPanel,
  IShowMapFilters,
  IShowPrecartPanel,
  OfferStateActions,
  OfferStateTypes,
  IUpdateOfferSeatCounts,
  IFetchDefaultOffersBegin,
  IFetchDefaultOffersEnd,
  IRemoveExpiredOffers,
} from '../types/offerTypes';
import { setNotification } from './appActions';
import { ICart, ISelection } from '../mfe/carting/Carting/types/apiResponseTypes';
import { QuantityRule } from '../common/Offer/QuantityRule';
import { SeatType } from '../common/Seat/SeatType';
import { IEventOffers } from '../api/types/eventtool/responseTypes';
import { pages } from '../constants/routes';
import { ISeatCount } from '../types/common';
import { IOnsaleInformation } from '../api/types/unifiedapi/responseTypes';

export const handleCheckout = (cart: ICart)
: ThunkAction<void, IFullStorageShape, null, OfferStateActions> => (dispatch: any) => {
  let selectionError: QuantityRule | null = null;
  const cartSelection: ISelection[] | null = get(cart, 'selections');
  if (cartSelection) {
    cartSelection.forEach((selection: ISelection) => {
      if (selection.offerType === SeatType.FLASHSEATS && !selection.validSelection
        && selection.selectionError !== undefined) {
        selectionError = selection.selectionError;
        return;
      }
    });
    if (selectionError === null) {
      dispatch(push(pages.checkout));
    } else {
      dispatch(setNotification('danger', `carting.errors.${selectionError}_ERROR`, true));
    }
  } else {
    dispatch(setNotification('danger', 'carting.errors.noCart', true));
  }
};

// tslint:disable-next-line: max-line-length
const handleEventOffersSuccess = (dispatch: any, response: AxiosResponse<any>, contextId: number, eventId: number, redirectPath?: string) => {
  const data: IEventOffers = get(response, 'data');
  if (data) {
    data.contextId = contextId;
    dispatch(fetchEventOffersSuccess(data, eventId));
    dispatch(getEventFromOffers(data));
    dispatch(setSelectedEventId(eventId));
    if (redirectPath) {
      dispatch(push(`${redirectPath}`));
    }
  } else {
    const errorMessage = 'Unable to load event offers.';
    dispatch(setNotification('danger', errorMessage));
    dispatch(fetchEventOffersFailure({ error: errorMessage }));
  }
};

const handleUpdateSeatCountsSuccess = (dispatch: any, response: AxiosResponse<any>) => {
  const data: any = get(response, 'data');
  if (data) {
    const offerSeatCounts = get(data, 'Offers', []) as ISeatCount[];
    dispatch(updateOfferSeatCounts(offerSeatCounts));
    const eventSeatCount = get(data, 'Event') as ISeatCount | undefined;
    if (eventSeatCount) {
      dispatch(updateEventSeatCounts(eventSeatCount));
    }
  }
};

// tslint:disable-next-line: max-line-length
export const getEventOffers = (contextId: number, authToken: string, eventId: number, outletId?: number, redirectPath?: string): ThunkAction<void, IFullStorageShape, null, OfferStateActions> => (dispatch: any) => {
  dispatch(fetchEventOffersBegin());
  dispatch(setSelectedEventId(eventId));
  // Fetch the event offers with faster non-inventory call. Once that's done, we can populate the event page with
  // offers then follow up with slower call that includes inventory, below.
  return fetchEventOffers(contextId, authToken, eventId, false, outletId)
    .then((response: AxiosResponse<any>) => {
      handleEventOffersSuccess(dispatch, response, contextId, eventId, redirectPath);
      // Fetch the offers again, this time with slower inventory seat load.
      fetchEventOffers(contextId, authToken, eventId, true, outletId).then((response: AxiosResponse<any>) => {
        handleUpdateSeatCountsSuccess(dispatch, response);
      }).catch();
    })
    .catch((error) => {
      if (error) {
        const errorMessage = 'offers.errorMessages.noOffersLoaded';
        dispatch(setNotification('danger', errorMessage, true));
        dispatch(fetchEventOffersFailure({ error: errorMessage }));
      }
    });
};

export const getOffer = (
  contextId: number,
  offerId: number,
  redirectPath?: string,
): ThunkAction<void, IFullStorageShape, null, OfferStateActions> => (dispatch: any) => {
  dispatch(getOfferBegin());
  return fetchOffer(contextId, offerId)
    .then((response: AxiosResponse<any>) => {
      if (response && response.data && response.status === 200) {
        if (redirectPath) {
          dispatch(push(`${redirectPath}`));
        }
        dispatch(getOfferSuccess(response.data)); // Upon Offer Load Success, fetchOfferPricingSaga Saga called.
        dispatch(getEventsFromOffer(response.data));
      } else {
        dispatch(setNotification('danger', 'offers.errorMessages.unableToGetOffer', true));
        dispatch(getOfferFailure({ error: 'Unable to get offer.' }));
      }
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        dispatch(setNotification('danger', 'offers.errorMessages.unableToGetOffer', true));
        dispatch(getOfferFailure({ error: 'Unable to get offer.' }));
      }
    });
};

/**
 * Watched by fetchMissingOffersWatcher - See Sagas Middleware.
 *
 * @param ids
 */
export const loadMissingOffers = () =>
  ({ type : OfferStateTypes.LOAD_MISSING_OFFERS });

export const loadMissingOffersAbort = () =>
  ({ type: OfferStateTypes.LOAD_MISSING_OFFERS_ABORT });

export const loadMissingOffersComplete = () =>
  ({ type: OfferStateTypes.LOAD_MISSING_OFFERS_COMPLETE });

export const fetchEventOffersBegin = (): IFetchEventOffersBegin =>
  ({ type: OfferStateTypes.FETCH_EVENT_OFFERS_BEGIN });

export const fetchEventOffersSuccess = (data: IEventOffers, eventId: number): IFetchEventOffersSuccess =>
  ({ type: OfferStateTypes.FETCH_EVENT_OFFERS_SUCCESS, data, eventId });

export const fetchEventOffersFailure = (error: any): IFetchEventOffersFailure =>
  ({ type: OfferStateTypes.FETCH_EVENT_OFFERS_FAILURE, error });

// GET OFFER
export const getOfferBegin = (): IGetOfferBegin =>
  ({ type: OfferStateTypes.GET_OFFER_BEGIN });

export const getOfferSuccess = (data: IOnsaleInformation): IGetOfferSuccess =>
  ({ type: OfferStateTypes.GET_OFFER_SUCCESS, data });

export const getOfferFailure = (error: any): IGetOfferFailure =>
  ({ type: OfferStateTypes.GET_OFFER_FAILURE, error });

export const showFilterPanel = (): IShowFilterPanel =>
  ({ type: OfferStateTypes.SHOW_FILTER_PANEL });

export const hideFilterPanel = (): IHideFilterPanel =>
  ({ type: OfferStateTypes.HIDE_FILTER_PANEL });

export const showPrecartPanel = (): IShowPrecartPanel =>
  ({ type: OfferStateTypes.SHOW_PRECART_PANEL });

export const hidePrecartPanel = (): IHidePrecartPanel =>
  ({ type: OfferStateTypes.HIDE_PRECART_PANEL });

export const showMapFilters = (): IShowMapFilters =>
  ({ type: OfferStateTypes.SHOW_MAP_FILTERS });

export const hideMapFilters = (): IHideMapFilters =>
  ({ type: OfferStateTypes.HIDE_MAP_FILTERS });

export const updateOfferSeatCounts = (seatCounts: ISeatCount[]): IUpdateOfferSeatCounts =>
  ({ seatCounts, type: OfferStateTypes.UPDATE_OFFER_SEAT_COUNTS });

export const fetchDefaultOffersBegin = (): IFetchDefaultOffersBegin =>
  ({ type: OfferStateTypes.FETCH_DEFAULT_OFFERS_BEGIN });

export const fetchDefaultOffersEnd = (): IFetchDefaultOffersEnd =>
  ({ type: OfferStateTypes.FETCH_DEFAULT_OFFERS_END });

export const removeExpiredOffers = (): IRemoveExpiredOffers =>
  ({ type: OfferStateTypes.REMOVE_EXPIRED_OFFERS });
