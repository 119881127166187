import { SeatType } from './SeatType';

export class Seat {

  constructor(
    public id: number,
    public description: string,
    public priceLevelId: number | null,
    public displayOrder: number | null,
    public offerId: number,
    public productId: number,
    public sectionId: number,
    public sectionLabel: string,
    public rowId: number,
    public rowLabel: string,
    public seatType?: SeatType,
    public info1?: string,
    public info2?: string,
    public prices?: number[],
  ) {
  }

  public toString() {
    return `Seat: ${this.id} - ${this.description}`;
  }

}

export default Seat;
