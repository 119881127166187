import get from 'lodash/get';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse, AxiosError } from 'axios';
import { push } from 'connected-react-router';

import { IFullStorageShape } from '../store/store.types';
import { fetchOfferPricing, fetchOfferInventory } from '../api/ApiManager';
import {
  IAddOfferInventorySeats,
  IGetOfferInventoryBegin,
  IGetOfferInventorySuccess,
  IGetOfferInventoryFailure,
  IGetOfferPricingBegin,
  IGetOfferPricingFailure,
  IGetOfferPricingSuccess,
  InventoryStateActions,
  InventoryStateTypes,
} from '../types/inventoryTypes';
import Seat from '../common/Seat/Seat';
import { setNotification } from './appActions';
import { setSession } from './userActions';
import { OfferType } from '../common/Offer/OfferType';

export const getOfferPricing = (
  contextId: number,
  offerId: number,
  getSections: boolean,
  includeResale: boolean,
  redirectPath?: string,
): ThunkAction<void, IFullStorageShape, null, InventoryStateActions> => (dispatch: any) => {
  dispatch(getOfferPricingBegin());
  return fetchOfferPricing(contextId, offerId, getSections, includeResale)
    .then((response: AxiosResponse<any>) => {
      if (response && response.data && response.status === 200) {
        dispatch(getOfferPricingSuccess(response.data));
        if (redirectPath) {
          dispatch(push(redirectPath));
        }
      } else {
        dispatch(setNotification('danger', 'offers.errorMessages.unableToLoadPricing', true));
        dispatch(getOfferPricingFailure({ error: 'Unable to get pricing.' }));
        dispatch(setSession(null));
      }
    })
    .catch((error: AxiosError) => {
      if (error) {
        const errorMessage = get(error, 'response.data.errors.message', 'Unable to get offer pricing.');
        dispatch(setNotification('danger', errorMessage));
        dispatch(getOfferPricingFailure({ error: errorMessage }));
        dispatch(setSession(null));
      }
    });
};

export const getOfferInventory = (
  contextId: number,
  offerId: number,
  eventId: number,
  includeResale: boolean,
  offerType: OfferType,
): ThunkAction<void, IFullStorageShape, null, InventoryStateActions> => (dispatch: any) => {
  dispatch(getOfferInventoryBegin());
  return fetchOfferInventory(contextId, offerId, eventId, includeResale)
    .then((response: AxiosResponse<any>) => {
      if (response && response.data && response.status === 200) {
        dispatch(getOfferInventorySuccess({ ...response.data, contextId, offerId, offerType }));
      } else {
        dispatch(setNotification('danger', 'offers.errorMessages.unableToLoadInventory', true));
        dispatch(getOfferInventoryFailure({ error: 'Unable to get inventory.' }));
      }
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        const errorMessage = get(error, 'response.data.errors.message', 'Unable to get offer inventory.');
        dispatch(setNotification('danger', errorMessage));
        dispatch(getOfferInventoryFailure({ error: errorMessage }));
      }
    });
};

export const addOfferInventorySeats = (seats: Seat[]): IAddOfferInventorySeats =>
  ({ type: InventoryStateTypes.ADD_OFFER_INVENTORY_SEATS, seats });

// GET INVENTORY
export const getOfferInventoryBegin = (): IGetOfferInventoryBegin =>
  ({ type: InventoryStateTypes.GET_OFFER_INVENTORY_BEGIN });

export const getOfferInventorySuccess = (data: any): IGetOfferInventorySuccess =>
  ({ type: InventoryStateTypes.GET_OFFER_INVENTORY_SUCCESS, data });

export const getOfferInventoryFailure = (error: any): IGetOfferInventoryFailure =>
  ({ type: InventoryStateTypes.GET_OFFER_INVENTORY_FAILURE, error });

// GET OFFER PRICE
export const getOfferPricingBegin = (): IGetOfferPricingBegin =>
  ({ type: InventoryStateTypes.GET_OFFER_PRICING_BEGIN });

export const getOfferPricingSuccess = (data: any): IGetOfferPricingSuccess =>
  ({ type: InventoryStateTypes.GET_OFFER_PRICING_SUCCESS, data });

export const getOfferPricingFailure = (error: any): IGetOfferPricingFailure =>
  ({ type: InventoryStateTypes.GET_OFFER_PRICING_FAILURE, error });
