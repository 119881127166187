import { get, uniq } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse, AxiosError } from 'axios';
import { fetchOrderDetails, fetchOffer } from '../api/ApiManager';
import { fetchFlashTickets, fetchSingleOrder } from '../api/FanAccountApiManager';
import { setNotification } from './appActions';
import {
  getUserFlashTicketsBegin,
  getUserFlashTicketsEnd,
  getUserFlashTicketsFailure,
} from './accountActions';
import {
  IGetOrderBegin,
  IGetOrderFailure,
  IGetOrderSuccess,
  IResetOrderDetails,
  OrderStateActions,
  OrderStateTypes,
  IGetSingleOrderBegin,
  IGetSingleOrderSuccess,
  IGetSingleOrderFailure,
  ISetExistingOrder,
} from '../types/orderTypes';
import { IFullStorageShape } from '../store/store.types';
import { IFlashTickets, IPrimaryOrder } from '../api/types/fanAccount/responseTypes';
import { createFlashOrders } from '../reducers/utils/accountMapper';
import { TICKET_STATES_IDS } from '../constants/ticketStatus';

// tslint:disable-next-line: max-line-length
export const fetchOrder = (orderId: number): ThunkAction<void, IFullStorageShape, null, OrderStateActions> => (dispatch: any) => {
  dispatch(fetchOrderBegin());
  return fetchOrderDetails(orderId)
    .then((response: AxiosResponse<any>) => {
      const orderDetails = get(response, 'data.orderDetailsResponse', {});
      dispatch(fetchOrderSuccess(orderDetails));
    })
    .catch((error: AxiosError) => {
      const errorMessage = get(error, 'response.data.error.message', 'Error fetching Order');
      dispatch(fetchOrderFailure(errorMessage));
    });
};

export const fetchOrderBegin = (): IGetOrderBegin => {
  return { type: OrderStateTypes.GET_ORDER_BEGIN };
};

export const fetchOrderSuccess = (orderDetails: any): IGetOrderSuccess => {
  return { type: OrderStateTypes.GET_ORDER_SUCCESS, orderDetails };
};

export const fetchOrderFailure = (error: string): IGetOrderFailure => {
  return { type: OrderStateTypes.GET_ORDER_FAILURE, error };
};

export const resetOrderDetails = (): IResetOrderDetails => {
  return { type: OrderStateTypes.RESET_ORDER_DETAILS };
};

export const getSingleOrderBegin = (): IGetSingleOrderBegin => {
  return { type: OrderStateTypes.GET_SINGLE_ORDER_BEGIN };
};

export const getSingleOrderSuccess = (
  contextId: number,
  customerEmail: string,
  primaryOrder: IPrimaryOrder,
  flashTickets: IFlashTickets[] | null,
): IGetSingleOrderSuccess => {
  return { type: OrderStateTypes.GET_SINGLE_ORDER_SUCCESS, contextId, customerEmail, primaryOrder, flashTickets };
};

export const getSingleOrderFailure = (error: string): IGetSingleOrderFailure => (
  { type: OrderStateTypes.GET_SINGLE_ORDER_FAILURE, error }
);

export const setExistingOrder = (customerEmail: string, primaryOrder: IPrimaryOrder): ISetExistingOrder => (
  { type: OrderStateTypes.SET_EXISTING_ORDER, customerEmail, primaryOrder }
);

export const getSingleOrder = (
  contextId: number,
  customerAccessToken: string,
  customerEmail: string,
  region: number,
  orderId: string,
): ThunkAction<void, IFullStorageShape, null, OrderStateActions> => (dispatch: any) => {
  dispatch(getSingleOrderBegin());
  dispatch(getUserFlashTicketsBegin());

  const primaryOrderPromise = fetchSingleOrder(contextId, customerAccessToken, region, orderId);
  const flashTicketsPromise = fetchFlashTickets(contextId, customerAccessToken, region);

  return Promise.all([primaryOrderPromise, flashTicketsPromise])
    .then((responses) => {
      const primaryOrder: IPrimaryOrder = get(responses[0], 'data.order', []);
      const flashTickets: IFlashTickets[] = get(responses[1], 'data', [])
        .filter((ticket: IFlashTickets) => ticket.ticketState !== TICKET_STATES_IDS.CANCELLED);
      dispatch(getSingleOrderSuccess(contextId, customerEmail, primaryOrder, flashTickets));
      dispatch(getUserFlashTicketsEnd(flashTickets));
    })
    .catch((error: AxiosError) => {
      const errorMessage = get(error, 'response.data.error.message', 'Error fetching Order');
      dispatch(getSingleOrderFailure(errorMessage));
      dispatch(getUserFlashTicketsFailure(errorMessage));
      dispatch(setNotification('danger', 'fanAccount.errors.accountOrders', true));
    });
};

export const getFlashTickets = (
  contextId: number,
  customerAccessToken: string,
  customerEmail: string,
  eventCode: string,
  region: number,
): ThunkAction<void, IFullStorageShape, null, OrderStateActions> => (dispatch: any) => {
  dispatch(getSingleOrderBegin());
  dispatch(getUserFlashTicketsBegin());
  return fetchFlashTickets(contextId, customerAccessToken, region)
    .then((response) => {
      const allFlashTickets: IFlashTickets[] = get(response, 'data', [])
        .filter((ticket: IFlashTickets) => ticket.ticketState !== TICKET_STATES_IDS.CANCELLED);
      const eventFlashTickets = allFlashTickets
        .filter(ticket => getEventCode(ticket) === eventCode)
        .filter(ticket => ticket.primaryOrderCustomerEmail !== customerEmail);
      const offerIds = eventFlashTickets.map(ticket => ticket.primaryOfferId);
      const offerPromises = uniq(offerIds).map((offerId: number) => fetchOffer(contextId, offerId));
      Promise.all(offerPromises)
        .then((offerResponses) => {
          const flashOffers = offerResponses.map(offerResponse => get(offerResponse, 'data', null));
          const orders = createFlashOrders([], flashOffers, eventFlashTickets);
          dispatch(getSingleOrderSuccess(contextId, customerEmail, orders[0], eventFlashTickets));
          dispatch(getUserFlashTicketsEnd(allFlashTickets));
        })
        .catch(error => handleGetOrdersError(dispatch, error));
    })
    .catch(error => handleGetOrdersError(dispatch, error));
};
const getEventCode = (ticket: IFlashTickets) => {
  const { primarySeatId = '' } = ticket;
  const vxIdComponents = primarySeatId.split('|');
  return get(vxIdComponents, '[1]');
};
const handleGetOrdersError = (dispatch: any, error: any) => {
  dispatch(getSingleOrderFailure(error));
  dispatch(getUserFlashTicketsFailure(error));
  dispatch(setNotification('danger', 'fanAccount.errors.accountOrders', true));
};
