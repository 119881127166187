import React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';

import PageLayout from '../layouts/PageLayout';

// Main Container/jsx Displayed on the page
const PreferencesContainer = Loadable({
  loader: () => import('../containers/preferences'),
  loading: () => null,
});

export default function (store: any, props: any) {
  // call Register Reducer here
  const match = props.match;
  return (
    <Switch>
      <PageLayout>
        <Route path={ match.url } component={ PreferencesContainer } />
      </PageLayout>
    </Switch>
  );
}
