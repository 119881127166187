import React from 'react';
import { AXSFlex, AXSBlock, AXSFlexItem } from '@axs/axs-ui';
import { MOPItem } from './styles';
import { paymentCodes } from '../../constants';
import { ISessionItem } from '../../types/userTypes';
import { formatPrice } from '../../common/utils/formatPrice';

/**
 * Displays Credit Card Type and Amount
 * @param item
 * @return {any}
 * @constructor
 */
const LineItem = ({ mopLabel, amount }: ISessionItem) => {
  return (
    <AXSBlock>
      <AXSFlex alignItems="stretch" justifyContent="space-between">
        <AXSFlexItem marginTop="10px" color="axsui.text.secondary">
          <MOPItem>{ getPaymentCode(mopLabel) }</MOPItem>
        </AXSFlexItem>
        <AXSFlexItem marginTop="10px">
          <MOPItem>{ formatPrice(amount * 100) }</MOPItem>
          { /*TODO create format currency func - BOX 331*/ }
        </AXSFlexItem>
      </AXSFlex>
    </AXSBlock>
  );
};

const getPaymentCode = (id: string) => {
  return paymentCodes[id] || '';
};

export default LineItem;
