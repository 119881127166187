import { Reducer } from 'redux';
import { IAccountState } from '../types/accountTypes';
import { IApplicationState } from '../types/appTypes';
import { ICustomersState } from '../types/customersTypes';
import { IOfferState } from '../types/offerTypes';
import { IInventoryState } from '../types/inventoryTypes';
import { IUserState } from '../types/userTypes';
import { ICartingState } from '../mfe/carting/Carting/types/cartingTypes';
import { IMapState } from '../mfe/map/Map/types/mapTypes';
import { IEventsState } from '../types/eventsTypes';
import { ISearchState } from '../types/searchTypes';
import { IOrderState } from '../types/orderTypes';
import { IFeaturesState } from '../types/featuresTypes';
import { ISeatState } from '../types/seatTypes';

export const ACCOUNT_REDUCER_KEY = 'account';
export const APP_REDUCER_KEY = 'app';
export const CUSTOMERS_REDUCER_KEY = 'customers';
export const EVENTS_REDUCER_KEY = 'events';
export const OFFERS_REDUCER_KEY = 'offers';
export const USER_REDUCER_KEY = 'user';
export const INVENTORY_REDUCER_KEY = 'inventory';
export const CARTING_REDUCER_KEY = 'carting';
export const MAP_REDUCER_KEY = 'map';
export const SEARCH_REDUCER_KEY = 'search';
export const ORDER_REDUCER_KEY = 'orders';
export const FEATURES_REDUCER_KEY = 'features';
export const SEAT_REDUCER_KEY = 'seat';

export interface IFullStorageShape {
  [ACCOUNT_REDUCER_KEY]: IAccountState;
  [APP_REDUCER_KEY]: IApplicationState;
  [CUSTOMERS_REDUCER_KEY]: ICustomersState;
  [EVENTS_REDUCER_KEY]: IEventsState;
  [OFFERS_REDUCER_KEY]: IOfferState;
  [USER_REDUCER_KEY]: IUserState;
  [INVENTORY_REDUCER_KEY]: IInventoryState;
  [CARTING_REDUCER_KEY]: ICartingState;
  [MAP_REDUCER_KEY]: IMapState;
  [SEARCH_REDUCER_KEY]: ISearchState;
  [ORDER_REDUCER_KEY]: IOrderState;
  [FEATURES_REDUCER_KEY]: IFeaturesState;
  [SEAT_REDUCER_KEY]: ISeatState;
}

export type StoreShape = Partial<IFullStorageShape>;
export type NamespaceKey = keyof StoreShape;

export type ReducerMap = Partial<
    { [k in NamespaceKey]: Reducer<IFullStorageShape[k]> }
>;
