import { AnyAction } from 'redux';
import { VTEvent } from '../common/Event';
import { ISeatCount, IExpiration } from './common';
import { IEventOffers } from '../api/types/eventtool/responseTypes';
import { IOnsaleInformation } from '../api/types/unifiedapi/responseTypes';

export type IAvailableEvent = IExpiration<VTEvent>;

export interface IAvailableEvents {
  [key: string]: IAvailableEvent;
}

export interface IEventsState {
  availableEvents: IAvailableEvents;
  fetchingCMSEvents: boolean;
  fetchingDefaultEvent: boolean;
  fetchingEvents: boolean;
  fetchingEventsError: boolean;
  fetchingMissingEvents: boolean;
}

export enum EventsStateTypes {
  FETCH_EVENTS_BY_NAME_BEGIN = 'FETCH_EVENTS_BY_NAME_BEGIN',
  FETCH_EVENTS_BY_NAME_FAILURE = 'FETCH_EVENTS_BY_NAME_FAILURE',
  FETCH_EVENTS_BY_NAME_SUCCESS = 'FETCH_EVENTS_BY_NAME_SUCCESS',
  GET_CMS_EVENT_ABORT = 'GET_CMS_EVENT_ABORT',
  GET_CMS_EVENT_BEGIN = 'GET_CMS_EVENT_BEGIN',
  GET_CMS_EVENT_COMPLETED = 'GET_CMS_EVENT_COMPLETED',
  GET_CMS_EVENT_FAILURE = 'GET_CMS_EVENT_FAILURE',
  GET_CMS_EVENT_SUCCESS = 'GET_CMS_EVENT_SUCCESS',
  GET_DEFAULT_EVENT = 'GET_DEFAULT_EVENT',
  GET_DEFAULT_EVENT_BEGIN = 'GET_DEFAULT_EVENT_BEGIN',
  GET_DEFAULT_EVENT_END = 'GET_DEFAULT_EVENT_END',
  GET_EVENT_BY_ID_BEGIN = 'GET_EVENT_BY_ID_BEGIN',
  GET_EVENT_BY_ID_FAILURE = 'GET_EVENT_BY_ID_FAILURE',
  GET_EVENT_BY_ID_SUCCESS = 'GET_EVENT_BY_ID_SUCCESS',
  GET_EVENT_FROM_OFFERS = 'GET_EVENT_FROM_OFFERS',
  GET_EVENTS_FROM_OFFER = 'GET_EVENTS_FROM_OFFER',
  GET_UPCOMING_EVENTS_BEGIN = 'GET_UPCOMING_EVENTS_BEGIN',
  GET_UPCOMING_EVENTS_FAILURE = 'GET_UPCOMING_EVENTS_FAILURE',
  GET_UPCOMING_EVENTS_SUCCESS = 'GET_UPCOMING_EVENTS_SUCCESS',
  LOAD_MISSING_EVENTS = 'LOAD_MISSING_EVENTS',
  LOAD_MISSING_EVENTS_ABORT = 'LOAD_MISSING_EVENTS_ABORT',
  LOAD_MISSING_EVENTS_COMPLETE = 'LOAD_MISSING_EVENTS_FINISHED',
  REMOVE_EXPIRED_EVENTS = 'REMOVE_EXPIRED_EVENTS',
  UPDATE_EVENT_SEAT_COUNTS = 'UPDATE_EVENT_SEAT_COUNTS',
}

export interface IGetDefaultEvent extends AnyAction {
  type: EventsStateTypes.GET_DEFAULT_EVENT;
  eventId?: number;
}

export interface IGetDefaultEventBegin extends AnyAction {
  type: EventsStateTypes.GET_DEFAULT_EVENT_BEGIN;
}

export interface IGetDefaultEventEnd extends AnyAction {
  type: EventsStateTypes.GET_DEFAULT_EVENT_END;
}

export interface IGetCmsEventBegin extends AnyAction {
  type: EventsStateTypes.GET_CMS_EVENT_BEGIN;
  eventIds: number[];
}

export interface IGetCmsEventSuccess extends AnyAction {
  type: EventsStateTypes.GET_CMS_EVENT_SUCCESS;
  data: any;
}

export interface IGetCmsEventFailure extends AnyAction {
  type: EventsStateTypes.GET_CMS_EVENT_FAILURE;
  errorMsg: any;
}

export interface IGetCmsEventCompleted extends AnyAction {
  type: EventsStateTypes.GET_CMS_EVENT_COMPLETED;
}

export interface IGetCmsEventAbort extends AnyAction {
  type: EventsStateTypes.GET_CMS_EVENT_ABORT;
}

export interface IGetUpcomingEventsBegin extends AnyAction {
  type: EventsStateTypes.GET_UPCOMING_EVENTS_BEGIN;
}

export interface IGetUpcomingEventsSuccess extends AnyAction {
  type: EventsStateTypes.GET_UPCOMING_EVENTS_SUCCESS;
  data: any;
}

export interface IGetUpcomingEventsFailure extends AnyAction {
  type: EventsStateTypes.GET_UPCOMING_EVENTS_FAILURE;
  errorMsg: any;
}

export interface IGetEventFromOffers extends AnyAction {
  type: EventsStateTypes.GET_EVENT_FROM_OFFERS;
  data: IEventOffers;
}

export interface IGetEventsFromOffer extends AnyAction {
  type: EventsStateTypes.GET_EVENTS_FROM_OFFER;
  data: IOnsaleInformation;
}

export interface IGetEventsByNameBegin extends AnyAction {
  type: EventsStateTypes.FETCH_EVENTS_BY_NAME_BEGIN;
}

export interface IGetEventsByNameSuccess extends AnyAction {
  type: EventsStateTypes.FETCH_EVENTS_BY_NAME_SUCCESS;
}

export interface IGetEventsByNameFailure extends AnyAction {
  type: EventsStateTypes.FETCH_EVENTS_BY_NAME_FAILURE;
}

export interface IGetEventByIdBegin extends AnyAction {
  type: EventsStateTypes.GET_EVENT_BY_ID_BEGIN;
}

export interface IGetEventByIdSuccess extends AnyAction {
  type: EventsStateTypes.GET_EVENT_BY_ID_SUCCESS; data: any;
}

export interface IGetEventByIdFailure extends AnyAction {
  type: EventsStateTypes.GET_EVENT_BY_ID_FAILURE; errorMsg: {};
}

export interface IUpdateEventSeatCounts extends AnyAction {
  type: EventsStateTypes.UPDATE_EVENT_SEAT_COUNTS; seatCount: ISeatCount;
}

export interface IRemoveExpiredEvents extends AnyAction {
  type: EventsStateTypes.REMOVE_EXPIRED_EVENTS;
}

export type EventsStateActions =
  IGetCmsEventAbort |
  IGetCmsEventBegin |
  IGetCmsEventCompleted |
  IGetCmsEventFailure |
  IGetCmsEventSuccess |
  IGetDefaultEvent |
  IGetDefaultEventBegin |
  IGetDefaultEventEnd |
  IGetEventByIdBegin |
  IGetEventByIdFailure |
  IGetEventByIdSuccess |
  IGetEventFromOffers |
  IGetEventsFromOffer |
  IGetEventsByNameBegin |
  IGetEventsByNameFailure |
  IGetEventsByNameSuccess |
  IGetUpcomingEventsBegin |
  IGetUpcomingEventsFailure |
  IGetUpcomingEventsSuccess |
  IRemoveExpiredEvents |
  IUpdateEventSeatCounts;
