import BaseEvent from './BaseEvent';
import Venue from '../Venue';
import Category from '../Category/Category';
import moment from 'moment';

class VTEvent extends BaseEvent {

  constructor(
    public id: number,
    public contextId: number,
    public name: string,
    public venue: Venue | null,
    public startDate: string,
    public endDate: string,
    public lookupId?: string,
    public description?: string,
    public internalInformation?: string,
    public webProfileName?: string,
    public seatCount?: number,
    public supportingAct?: string,
    public categories?: Category[],
  ) {
    super(id, name, startDate, endDate, description);
  }

  public toString() {
    return `Event ID ${this.id} - ${this.name}`;
  }

  public isPast() {
    const now = Date.now();
    const endDate = moment(this.endDate).add(5, 'days');
    return endDate.isBefore(now);
  }
}

export default VTEvent;
