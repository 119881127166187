import React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';

import PageLayout from '../layouts/PageLayout';

// Main Container/jsx Displayed on the page
const ConfirmationContainer = Loadable({
  loader: () => import('../containers/confirmation'),
  loading: () => null,
});

export default function (store: any, props: any) {
  // call Register Reducer here
  const match = props.match;

  const renderFunction = (props: any) => {
    const more = {
      ...props,
    };
    return <ConfirmationContainer {...more} />;
  };

  return (
    <Switch>
      <PageLayout>
        <Route
          path={ match.url }
          render={ renderFunction }
        />
      </PageLayout>
    </Switch>
  );
}
