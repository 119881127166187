import * as React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CARTING_REDUCER_KEY } from '../../../../../store/store.types';
import { clearCartingResult } from '../../actions/cartingActions';
import { ICartingMessage, ICartingError, ICartingResult } from '../../types/apiResponseTypes';
import { Notification } from '../../components/Notification';

interface ICartNotification {
  clearCartingResult: () => void;
  lastCartingResult?: ICartingResult | ICartingMessage | ICartingError;
  lastCartingStatus?: number;
  location: string;
  t?: any;
}

class CartNotification extends React.Component<ICartNotification> {
  constructor(props: ICartNotification, context: any) {
    super(props, context);
    this.getErrorMessage = this.getErrorMessage.bind(this);
  }

  public getErrorMessage(): any {
    const { lastCartingResult, lastCartingStatus, t } = this.props;
    let message: string | null = null;
    // TODO Implement all cart mapping error Messages
    if (lastCartingStatus === 411) {
      message = 'carting.errors.conflict';
    } else if (lastCartingResult) {
      message = (lastCartingResult as ICartingMessage).message;
      if ((lastCartingResult as ICartingResult).priceChange === 1) {
        message = 'carting.errors.priceIncrease';
      } else if ((lastCartingResult as ICartingResult).priceChange === -1) {
        message = 'carting.errors.priceDrop';
      }
    }
    if (!message) {
      const error = (lastCartingResult as ICartingResult).error;
      if (error) {
        message = error.message;
      }
    }
    if (message && message.startsWith('carting.')) { message = t(message); }
    return message;
  }

  public render() {
    const { clearCartingResult, lastCartingResult, lastCartingStatus, location } = this.props;
    const hasPriceChange = lastCartingResult && (lastCartingResult as ICartingResult).priceChange;
    if (!lastCartingResult ||
       (lastCartingStatus !== undefined && lastCartingStatus < 400 && !hasPriceChange)) { return null; }

    const errorMessage = this.getErrorMessage();
    return (
      <Notification
        location={ location }
        handleClose={ clearCartingResult }
        errorMessage={ errorMessage }
        active
      />
    );
  }
}

const mapStateToProps =  (state: any, props: any) => {
  return {
    ...props,
    lastCartingResult: state[CARTING_REDUCER_KEY].lastCartingResult,
    lastCartingStatus: state[CARTING_REDUCER_KEY].lastCartingStatus,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearCartingResult: () => dispatch(clearCartingResult()),
  };
};

const translated = withTranslation()(CartNotification as any);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
