import { ThunkAction } from 'redux-thunk';
import { IFullStorageShape } from '../store/store.types';
import { FeaturesActionTypes, IFeatures, FeaturesActions } from '../types/featuresTypes';
import { fetchSkin } from '../api/ApiManager';
import { skinSchemas } from '../constants';
import { AxiosResponse, AxiosError } from 'axios';
import { IFetchSkinResponse } from '../api/types/fanskin/responseTypes';
import featuresFallBack from '../constants/fallback/features.json';
import config from '../config';

export const setFeatures = (features: IFeatures | null) =>
  ({ features, type: FeaturesActionTypes.SET_FEATURES });

export const setClient = (client: string | null) =>
  ({ client, type: FeaturesActionTypes.SET_CLIENT });

// tslint:disable-next-line: max-line-length
export const getFeatures = (client?: string): ThunkAction<void, IFullStorageShape, null, FeaturesActions> => (dispatch: any) => {
  if (client) {
    dispatch(setClient(client));
  }
  if (client === config.contextMaster) {
    dispatch(setFeatures(featuresFallBack));
    return;
  }
  return fetchSkin(client || 'null', skinSchemas.WBO_FEATURES)
  .then((response: AxiosResponse<IFetchSkinResponse<IFeatures>>) => {
    if (response && response.data && response.data.success && response.data.data) {
      dispatch(setFeatures(response.data.data));
    } else {
      dispatch(setFeatures(null));
    }
  })
  .catch((error: AxiosError) => {
    if (error) {
      dispatch(setFeatures(null));
    }
  });
};
