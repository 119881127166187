import React from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import classnames from 'classnames';
import styled from 'styled-components';
import { AXSBlock, AXSButton, AXSFlex, AXSTab, AXSTabs } from '@axs/axs-ui';
import { themeModeButtonsDark, themeModeButtonsLight, themeColors, themeTypes, zIndexes } from '../../theme';
import { pages } from '../../constants/routes';
import version from '../../version.json';
import { ThemeModeContext } from '../../layouts/PageLayout';
import SessionCard from '../SessionCard/index';
import { Expander, Wrapper, BottomWrapper, WrapperContent, SettingsWrapper } from './styles';
import { ISettings } from '../../types/userTypes';

export interface IPreferencesProps {
  clearSessionInfo: () => void;
  logout?: any;
  pushLocation?: (location: string) => void;
  setThemeMode?: any;
  settings?: ISettings;
  toggleOverlay: () => void;
  userId: string | null;
}

const Session = styled(AXSBlock)`
  border-top: 1px solid rgba(255, 255, 255, .2);
  padding-top: 20px;
`;

const EditLink = styled(AXSBlock)`
  background-color: transparent;
  color: ${ themeColors.LINK_BLUE };
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${ themeColors.LINK_BLUE_HOVER };
    text-decoration: underline;
  }
`;

const Settings = styled(AXSBlock)`
  height: calc(100vh - 380px);
  margin-bottom: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 250px;
`;

export const Preferences = ({ clearSessionInfo, logout, pushLocation, setThemeMode, settings, userId,
                              toggleOverlay }: IPreferencesProps) => {

  const themeMode = React.useContext(ThemeModeContext);
  const [t] = useTranslation();
  const [active, setActive] = React.useState(false);
  const toggleTheme = (themeType: string) => {
    expanderClickHandler();
    setThemeMode(themeType);
  };

  const expanderClickHandler = () => {
    setActive(!active);
    toggleOverlay();
  };

  const goToEditPreferences = () => {
    if (pushLocation) {
      expanderClickHandler();
      pushLocation(pages.preferences(true));
    }
  };

  const availableSettings = [
    { label: t('preferences.currentOutlet'), setting: 'outlet' },
    { label: t('preferences.sellingLocation'), setting: 'location' },
    { label: t('preferences.defaultEvent'), setting: 'event' },
    { label: t('preferences.guestCheckoutMode'), setting: 'guestCheckoutMode' },
    { label: t('preferences.printer'), setting: 'printer' },
    { label: t('preferences.printingMode'), setting: 'mode' },
    { label: t('preferences.printingStock'), setting: 'stock' },
    { label: t('preferences.receiptMode'), setting: 'receipt' },
    { label: t('preferences.cartTimer'), setting: 'cartTimer' },
  ];

  return (
    <OutsideClickHandler onOutsideClick={ () => { if (active) { expanderClickHandler(); } } }>
      <Wrapper
        active={ active }
        backgroundColor={ themeMode === themeTypes.dark ? themeColors.BLUE_2 : themeColors.WHITE }
        boxShadow={ themeMode === themeTypes.dark ? 'none' : `2px -2px 6px 0 ${themeColors.GREY_LIGHTER }` }
        className={ classnames({ active, 'preferences-toggle': true }) }
        userId={ userId }
        zIndex={ zIndexes.PREFERENCES_SECTION }
      >
        <Expander
          color={ themeMode === themeTypes.dark ? themeColors.BLUE_20 : themeColors.CTA_BLUE }
          name={ active ? 'keyboard-arrow-up' : 'keyboard-arrow-down' }
          onClick={ expanderClickHandler }
        />
        { !active ? (
          <AXSBlock
            color="axsui.text.secondary"
            fontSize="16px"
            margin="0 0 20px 0"
            onClick={ expanderClickHandler }
            position="relative"
          >
            { t('preferences.preferencesAndAccount') }
            <AXSBlock color="axsui.text.elements.h2.color" fontSize="14px">
              { userId }
            </AXSBlock>
          </AXSBlock>
        ) : null }
        <AXSBlock
          color="axsui.text.primary"
          fontSize="24px"
          fontWeight="bold"
          inline
          marginRight="12px"
        >
          { t('preferences.preferences') }
        </AXSBlock>
        <EditLink
          inline
          onClick={ goToEditPreferences }
          padding="0 0 20px 0"
        >{ t('buttons.edit') }
        </EditLink>
        <SettingsWrapper>
          <AXSFlex alignItems="flex-end">
           <Settings>
              { availableSettings.map((item, i) => (
                <AXSBlock key={ `setting-${i}` } marginBottom="20px">
                  <AXSBlock fontSize="15px" color="axsui.text.secondary">{ item.label }</AXSBlock>
                  <AXSBlock fontSize="16px" fontWeight="bold">
                    <AXSBlock>{ settings && settings[item.setting] && settings[item.setting].label }</AXSBlock>
                  </AXSBlock>
                </AXSBlock>
              ))}
            </Settings>
          </AXSFlex>
        </SettingsWrapper>
        <BottomWrapper>
          <WrapperContent>
            <AXSFlex>
              <AXSBlock key={ 'setting-session-card' } marginBottom="20px"/>
              <SessionCard
                clearSessionInfo={ clearSessionInfo }
                expanderClickHandler={ expanderClickHandler }
                toggleOverlay={ toggleOverlay }
              />
            </AXSFlex>
          </WrapperContent>
            <AXSFlex width="100%" alignItems="center" justifyContent="space-between" marginTop="40px">
              <Session width="100%" color="axsui.text.secondary"/>
            </AXSFlex>
          <WrapperContent>
            { userId ? (
                <AXSFlex width="100%" alignItems="center" justifyContent="space-between">
                    <AXSBlock
                      color="axsui.text.primary"
                      fontSize="24px"
                      fontWeight="bold"
                      inline
                      marginRight="12px"
                    >
                      { t('login.account') }
                    </AXSBlock>
                    <AXSButton
                      borderless
                      onClick={ () => {
                        logout();
                        toggleOverlay();
                      } }
                    >{ t('buttons.signOut') }
                    </AXSButton>
                </AXSFlex>
              ) : null
            }
          </WrapperContent>
          <AXSBlock marginTop="20px">
            <AXSTabs
              handleMenuClick={ (e: any, value: any) => toggleTheme(value.id) }
              backgroundColor={
                themeMode === themeTypes.dark ? themeModeButtonsDark.background : themeModeButtonsLight.background
              }
              backgroundColorActive={
                themeMode === themeTypes.dark ?
                  themeModeButtonsDark.backgroundColorActive : themeModeButtonsLight.backgroundColorActive
              }
              borderColor={ themeMode === themeTypes.dark ?
                themeModeButtonsDark.borderColor : themeModeButtonsLight.borderColor }
              borderColorActive={
                themeMode === themeTypes.dark ?
                  themeModeButtonsDark.borderColorActive : themeModeButtonsLight.borderColorActive
              }
              color={ themeMode === themeTypes.dark ? themeModeButtonsDark.color : themeModeButtonsLight.color }
              colorActive={ themeMode === themeTypes.dark ?
                themeModeButtonsDark.colorActive : themeModeButtonsLight.colorActive }
            >
              <AXSTab
                active={ themeMode === themeTypes.light }
                id={ themeTypes.light }
              >
                { t('preferences.light') }
              </AXSTab>
              <AXSTab
                active={ themeMode === themeTypes.dark }
                id={ themeTypes.dark }
              >
                { t('preferences.dark') }
              </AXSTab>
            </AXSTabs>
            <AXSBlock opacity=".2" textAlign="center" fontSize="12px">
              { version.version }
            </AXSBlock>
          </AXSBlock>
        </BottomWrapper>
      </Wrapper>
    </OutsideClickHandler>
  );
};
