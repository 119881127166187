import Seat from '../Seat/Seat';
import Section from '../Section/Section';

class OfferInventory {

  constructor(
    public offerId: number,
    public eventId: number,
    public contextId: number,
    public seats: Seat[],
    public seatCount: number,
    public sections: Section[],
  ) {
  }
}

export default OfferInventory;
