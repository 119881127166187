import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import config from '../config/';
import { IPrintingSpec, PrintItems } from '../types/appTypes';
import { ILoginUser } from '../types/userTypes';

/**
 * The event tool services api host is read from localstorage, where it was set in the Login container
 * after determining the correct endpoint via the Version Service. See the login container
 * and https://axsteam.atlassian.net/wiki/x/iAHeSQ
 *
 * @returns {*}
 */
const getApiHost = () => {
  return localStorage.getItem('axsApiEndpoint') || config.protocol + config.eventToolApiHost;
};

export const UAPI_ACCOUNT_HOST = config.uAPIAccounts;
export const PROTOCOL = config.protocol;
export const UAPI_COMMERCE_HOST = config.uAPICommerce;
export const GLOBAL_SEARCH_API = config.globalSearchApi;
export const BOX_OFFICE_IO_API = config.boxIoApi;

interface IHeaders {
  Accept: string;
  'Content-Type': string;
}

const getLoginPath = (): string => '/auth';
const getLoginResolutionPath = (): string => '/wbo/resolution/login';

const getWorkstationInfoPath = (sessionId: string): string => `/api/workstation?sessionId=${sessionId}`;
const getPrinterPath = (sessionId?: string): string => `/api/printing?sessionId=${sessionId}`;

const getPrintSessionPath = (sessionId: string): string => {
  return `/api/sessioncard?sessionId=${sessionId}`;
};

const getSkinPath = (skinName: string, schemaName: string): string =>
  `/fanskin/${skinName}/${schemaName}`;

const getCmsEventsPath = (contextId: number, eventId: number): string =>
  `/v3/axs/event/${contextId}/${eventId}`;

const getInventoryPath = (includeResale: boolean): string => `/v3/inventory?includeResale=${includeResale}`;

const getOfferPricingPath = (contextId: number, offerId: number, getSections: boolean,
                             includeResale: boolean): string =>
 `/v3/inventory/price?contextId=${contextId}&offerId=${offerId}&getSections=${getSections}
&includeSoldOuts=true&includeDynamicPrice=true&includeResale=${includeResale}`;

const getEventOffersPath = (contextId: number, eventId: number, inventory: boolean, outletId?: number): string =>
  `/offers/event/${contextId}/${eventId}?inventory=${inventory}&OutletId=${outletId}`;

const getEventsByNamePath = (contextId: number, eventName: string): string =>
  `/events/retrievebyname/${contextId}/name?Name=${encodeURIComponent(eventName)}`;

const getEventById = (contextId: number, id: string): string =>
  `/events/retrievebyid/${contextId}/${id}`;

// TODO Add the OutletId PArameter when the outlet have been selected on the preferences
const getUpcomingEventsPath = (contextId: number, userId: string, outletId?: number, limit= 200): string => {
  if (outletId) return `/events/upcoming/${contextId}?UserId=${userId}&Limit=${limit}&OutletId=${outletId}`;
  return `/events/upcoming/${contextId}?UserId=${userId}&Limit=${limit}`;
};

const getOutletsPath = (contextId: number): string =>
  `/sysoutlets/all/${contextId}`;

const getOfferPath = (contextId: number, offerId: number): string =>
  `/offer/${contextId}/${offerId}`;

// tslint:disable-next-line: max-line-length
const getSessionPath = (contextId: number, userId: string, startDateTime: string, endDateTime: string, sessionId: string): string => {
  // tslint:disable-next-line: max-line-length
  return `/v1/report/mop?contextId=${contextId}&userId=${userId}&startDateTime=${startDateTime}&endDateTime=${endDateTime}&sessionId=${sessionId}`;
};

const getDeliveryMethodsPath = (contextId: number, offerId: number) => `/delivery-methods/${contextId}/${offerId}?includeIgnoredMODs=true`;

export const GLOBAL_SEARCH_ORDERS = '/Orders';
export const GLOBAL_SEARCH_CUSTOMERS = '/Customers';
export const GLOBAL_SEARCH_TICKETS = '/Tickets';

const commonHeaders: IHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const xAxsContextId = 'x-axs-context-id';

export const loginUser = (user: ILoginUser): Promise<AxiosResponse> => {
  const authOptions: AxiosRequestConfig = {
    data: user,
    headers: { ...commonHeaders },
    method: 'POST',
    url: getApiHost() + getLoginPath(),
  };
  return axios(authOptions);
};

export const loginResolutionUser = (authToken: string) => {
  const path = getLoginResolutionPath();
  const requestOptions: AxiosRequestConfig = {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${authToken}`,
    },
    method: 'GET',
    url: getApiHost() + path,
  };
  return axios(requestOptions);
};

export const fetchWorkstationInfo = (sessionId: string): Promise<AxiosResponse> => {
  const path = getWorkstationInfoPath(sessionId);
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'GET',
    url: `http://${BOX_OFFICE_IO_API}${path}`,
  };
  return axios(requestOptions);
};

export const sendToPrinter = (
  sessionId: string,
  orderId: string,
  printItems: PrintItems[],
  printingSpec: IPrintingSpec,
  seatIdsToPrint?: number[],
): Promise<AxiosResponse> => {
  const path = getPrinterPath(sessionId);
  const { printer, stockId: printerStockId, typeId: printerTypeId } = printingSpec;

  const requestOptions: AxiosRequestConfig = {
    data: {
      orderId,
      printer,
      printerStockId,
      printerTypeId,
      printing: printItems,
      seatIdsToPrint: seatIdsToPrint || null,
    },
    headers: { ...commonHeaders },
    method: 'POST',
    url: `http://${BOX_OFFICE_IO_API}${path}`,
  };
  return axios(requestOptions);
};

// tslint:disable-next-line: max-line-length
export const sendSessionCardToPrinter = (contextId: string, sessionId: string, userId: string, outletLabel: string, printer: string,
                                         startDateTime: string, endDateTime: string): Promise<AxiosResponse> => {
  const path = getPrintSessionPath(sessionId);
  const requestOptions: AxiosRequestConfig = {
    data: {
      contextId,
      endDateTime,
      outletLabel,
      printer,
      startDateTime,
      userId,
    },
    headers: { ...commonHeaders },
    method: 'POST',
    url: `http://${BOX_OFFICE_IO_API}${path}`,
  };
  return axios(requestOptions);
};

export const fetchSkin = (skinName: string, schemaName: string): Promise<AxiosResponse> => {
  const path = getSkinPath(skinName, schemaName);
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'GET',
    url: UAPI_ACCOUNT_HOST + path,
  };
  return axios(requestOptions);
};

export const fetchCmsEvent = (contextId: number, eventId: number): Promise<AxiosResponse> => {
  const path = getCmsEventsPath(contextId, eventId);
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'GET',
    url: PROTOCOL + UAPI_COMMERCE_HOST + path,
    withCredentials: true,
  };
  return axios(requestOptions);
};

// tslint:disable-next-line: max-line-length
export const fetchEventOffers = (contextId: number, authToken: string, eventId: number, inventory: boolean, outletId?: number) => {
  const path = getEventOffersPath(contextId, eventId, inventory, outletId);
  const requestOptions: AxiosRequestConfig = {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${authToken}`,
    },
    method: 'GET',
    timeout: 60000,
    url: getApiHost() + path,
  };
  return axios(requestOptions);
};

export const fetchEventsByName = (contextId: number, authToken: string, eventName: string) => {
  const path = getEventsByNamePath(contextId, eventName);
  const requestOptions: AxiosRequestConfig = {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${authToken}`,
    },
    method: 'GET',
    url: getApiHost() + path,
  };
  return axios(requestOptions);
};

export const fetchEventById = (contextId: number, authToken: string, eventId: string | number) => {
  const path = getEventById(contextId, eventId.toString());
  const requestOptions: AxiosRequestConfig = {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${authToken}`,
    },
    method: 'GET',
    url: getApiHost() + path,
  };
  return axios(requestOptions);
};

// Temporary Implementation until we get the events form the Global Search (That will be multi context)
export const fetchUpcomingEvents = (contextId: number, authToken: string, userId: string, outletId?: number,
                                    limit?: number) => {
  const path = getUpcomingEventsPath(contextId, userId, outletId, limit);
  const requestOptions: AxiosRequestConfig = {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${authToken}`,
    },
    method: 'GET',
    timeout: 20000,
    url: getApiHost() + path,
  };
  return axios(requestOptions);
};

export const fetchOutlets = (contextId: number, authToken: string) => {
  const path = getOutletsPath(contextId);
  const requestOptions: AxiosRequestConfig = {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${authToken}`,
    },
    method: 'GET',
    url: getApiHost() + path,
  };
  return axios(requestOptions);
};

export const fetchOfferPricing = (contextId: number, offerId: number, getSections: boolean, includeResale: boolean) => {
  const path = getOfferPricingPath(contextId, offerId, getSections, includeResale);
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'GET',
    url: PROTOCOL + UAPI_COMMERCE_HOST + path,
    withCredentials: true,
  };
  return axios(requestOptions);
};

export const fetchOfferInventory = (
  contextId: number, offerId: number, eventId: number, includeResale: boolean = true) => {
  const path = getInventoryPath(includeResale);

  const requestOptions: AxiosRequestConfig = {
    data: {
      contextId,
      eventId,
      offerId,
      sectionLabel: null,
    },
    headers: { ...commonHeaders },
    method: 'POST',
    url: PROTOCOL + UAPI_COMMERCE_HOST + path,
    withCredentials: true,
  };
  return axios(requestOptions);
};

export const fetchOffer = (contextId: number, offerId: number) => {
  const path = getOfferPath(contextId, offerId);
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'GET',
    url: PROTOCOL + UAPI_COMMERCE_HOST + path,
    withCredentials: true,
  };
  return axios(requestOptions);
};

// tslint:disable-next-line: max-line-length
export const createSession = (boxOfficeAuthToken: string, contextId?: string, outletId?: string, userCartTTL?: number, extendSession: boolean = false) => {
  const path = '/veritix/v3/session';
  const requestOptions: AxiosRequestConfig = {
    data: { boxOfficeAuthToken, contextId, outletId, userCartTTL, extendSession },
    headers: { ...commonHeaders },
    method: 'POST',
    url: PROTOCOL + UAPI_COMMERCE_HOST + path,
    withCredentials: true,
  };
  return axios(requestOptions);
};
/**
 * Uses global search API to fetch orders
 * @param {number} contextId - context ID
 * @param {string} authToken - auth token
 * @param {string} text - search query
 * @return {Promise} - Search results of orders
 */
export const globalSearchOrders = (contextId: number, authToken: string, text: string,
                                   page: number = 1, results: number = 10) => {
  const path = GLOBAL_SEARCH_API + GLOBAL_SEARCH_ORDERS;
  const requestOptions: AxiosRequestConfig = {
    data: { searchValues: text, page, results },
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${authToken}`,
      [xAxsContextId]: contextId,
    },
    method : 'POST',
    timeout: 5000,
    url: path,
  };
  return axios(requestOptions);
};

export const globalSearchCustomers = (contextId: number, authToken: string, searchQuery: string) => {
  const path = GLOBAL_SEARCH_API + GLOBAL_SEARCH_CUSTOMERS;
  const requestOptions: AxiosRequestConfig = {
    data : { searchValues: searchQuery, page: '1', results: '10' },
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${authToken}`,
      [xAxsContextId]: contextId,
    },
    method: 'POST',
    timeout: 5000,
    url: path,
  };
  return axios(requestOptions);
};

export const globalSearchTickets = (contextId: number, authToken: string, searchQuery: string) => {
  const path = GLOBAL_SEARCH_API + GLOBAL_SEARCH_TICKETS;
  const requestOptions: AxiosRequestConfig = {
    data : { searchValues: searchQuery, page: '1', results: '10' },
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${authToken}`,
      [xAxsContextId]: contextId,
    },
    method: 'POST',
    timeout: 5000,
    url: path,
  };
  return axios(requestOptions);
};

// tslint:disable-next-line: max-line-length
export const getSessionInfo = (contextId: number, userId: string, startDateTime: string, endDateTime: string, sessionId: string) => {
  const path = getSessionPath(contextId, userId, startDateTime, endDateTime, sessionId);
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method : 'GET',
    url: PROTOCOL + UAPI_COMMERCE_HOST + path,
    withCredentials: true,
  };
  return axios(requestOptions);
};

export const fetchOrderDetails = (orderId: number) => {
  const path = `/v1/order/${orderId}`;
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'GET',
    url: PROTOCOL + UAPI_COMMERCE_HOST + path,
    withCredentials: true,
  };
  return axios(requestOptions);
};

export const fetchAvailableDeliveryMethods = (contextId: number, offerId: number) => {
  const path = getDeliveryMethodsPath(contextId, offerId);
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'GET',
    url: PROTOCOL + UAPI_COMMERCE_HOST + path,
    withCredentials: true,
  };
  return axios(requestOptions);
};
