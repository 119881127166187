import { IFeaturesState, FeaturesActions, FeaturesActionTypes } from '../types/featuresTypes';
import { UserStateTypes } from '../types/userTypes';

export const initialState: IFeaturesState = {
  client: null,
};

const ACTION_HANDLERS = {
  [FeaturesActionTypes.SET_FEATURES]: (state: IFeaturesState, { features }: FeaturesActions) => {
    return Object.assign({}, state, { features });
  },
  [FeaturesActionTypes.SET_CLIENT]: (state: IFeaturesState, { client }: FeaturesActions) => {
    return Object.assign({}, state, { client });
  },
  [UserStateTypes.LOGOUT]: (state: IFeaturesState) => {
    const newState = { ...state };
    newState.features = undefined;
    return Object.assign({}, newState);
  },
};

export default function reducer(state: IFeaturesState = initialState, action: FeaturesActions) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
