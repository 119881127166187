import React from 'react';
import { AXSBlock } from '@axs/axs-ui';

/**
 * @param align
 * @param offerName
 * @param fontSize
 * @param fontWeight
 * @param color
 * @param margin
 * @constructor
 */
export const EventDetailOffer = ({ align, offerName, fontSize, fontWeight, color, margin }: any) =>
  (
    <AXSBlock margin={ margin } textAlign={ align } color={ color } fontWeight={ fontWeight } fontSize={ fontSize }>
      { offerName }
    </AXSBlock>
  );
