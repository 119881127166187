import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { USER_REDUCER_KEY } from '../../store/store.types';
import { SessionCardButton } from './styles';
import SessionCardModal from './SessionModal';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

interface ISessionCard {
  clearSessionInfo: () => void;
  expanderClickHandler: () => void;
  toggleOverlay: () => void;
}

/**
 * Session Card button inside of preferences panel, triggers Session Card Modal=
 */
const SessionCard = ({ clearSessionInfo, expanderClickHandler, toggleOverlay }: ISessionCard) => {
  const [t] = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const userId = useSelector((state: any) => state[USER_REDUCER_KEY].userId);
  const printer = useSelector((state: any) => get(state[USER_REDUCER_KEY], 'settings.printer.value'));

  return (
    <div>
      <SessionCardButton
        border-radius="4px"
        color="axsui.text.secondary"
        icon="arrow-forward"
        iconPosition="after"
        iconSize="16px"
        onClick={ () => {
          expanderClickHandler();
          setModalOpen(!modalOpen);
        } }
        secondary
        size="large"
      >{ t('preferences.sessionCard') }
      </SessionCardButton>
      <SessionCardModal
        active={ modalOpen }
        handleClose={ () => {
          setModalOpen(false);
          clearSessionInfo();
        } }
        userId={ userId }
        printer={ printer }
      />
    </div>
  );
};

export default SessionCard;
