import moment from 'moment';
import {
  IN_ACCOUNT_STATUS,
  ORDER_NUMBER_FLASH,
  TICKET_STATES_IDS,
  TICKET_STATUSES,
} from '../constants/ticketStatus';
import {
  IFlashTickets,
  IPrimaryOrderSeat,
  IPrimaryOrderProduct,
} from '../api/types/fanAccount/responseTypes';

// common
const isCancelled = (ticket: IPrimaryOrderSeat) => {
  return ticket.cancelled;
};

// flash statuses
const isFlashSeatsStaging = ({ inAccountStatus }: IFlashTickets) =>
  inAccountStatus === IN_ACCOUNT_STATUS.STAGING;

const isFlashSeatsInAccount = ({ inAccountStatus, ticketState }: IFlashTickets) =>
  ticketState === TICKET_STATES_IDS.TRADABLE &&
  inAccountStatus === IN_ACCOUNT_STATUS.IN_ACCOUNT;

const isFlashSeatsListed = ({ ticketState }: IFlashTickets) =>
  ticketState === TICKET_STATES_IDS.LISTED;

const isFlashSeatTransferred = ({ inAccountStatus, ticketState }: IFlashTickets) =>
  ticketState !== TICKET_STATES_IDS.REDEEMED &&
  inAccountStatus === IN_ACCOUNT_STATUS.TRANSFERRED;

const isFlashSeatsTransferWaiting = ({ ticketState }: IFlashTickets) =>
  ticketState === TICKET_STATES_IDS.TRANSFER_WAITING;

const isFlashSeatsTransferredRedeemed = ({ ticketState }: IFlashTickets) =>
  ticketState === TICKET_STATES_IDS.REDEEMED;

const isFlashSeatsSold = ({ inAccountStatus, ticketState }: IFlashTickets) =>
  ticketState !== TICKET_STATES_IDS.REDEEMED &&
  inAccountStatus === IN_ACCOUNT_STATUS.SOLD;

const isFlashSeatsSoldRedeemed = ({ inAccountStatus, ticketState }: IFlashTickets) =>
  ticketState === TICKET_STATES_IDS.REDEEMED &&
  inAccountStatus === IN_ACCOUNT_STATUS.SOLD;

const isFlashSeatsNotRedeemed = ({ ticketState }: IFlashTickets) =>
  ticketState === TICKET_STATES_IDS.UNUSED;

// E-Ticket statuses

const isVeritixShipped = (ticket: IPrimaryOrderSeat) => ticket.shipDate;

const isVeritixPrinted = (ticket: IPrimaryOrderSeat) => ticket.barcodeIsValid;

const isVeritixRedeemed = (ticket: IPrimaryOrderSeat) => ticket.lastScannedDate;

const isVeritixNotRedeemed = (offer: IPrimaryOrderProduct, ticket: IPrimaryOrderSeat) => {
  return ticket.shipDate
    && !ticket.lastScannedDate
    && moment(offer.endDate).isBefore(moment.now());
};

const isFlashOnlyTicket = ({ parent: { orderNumber } }: IPrimaryOrderProduct) => {
  return orderNumber === ORDER_NUMBER_FLASH;
};

export const getTicketStatus = (
  offer: IPrimaryOrderProduct,
  orderedTicket: IPrimaryOrderSeat,
  flashTicket: IFlashTickets | null,
) => {
  const { isFlashSeat } = orderedTicket.fulfillment;

  if (isCancelled(orderedTicket)) return TICKET_STATUSES.CANCELLED;

  if (isFlashSeat && flashTicket) {
    if (isFlashSeatsStaging(flashTicket)) return TICKET_STATUSES.STAGING;
    if (isFlashSeatsInAccount(flashTicket)) {
      if (isFlashOnlyTicket(offer)) return TICKET_STATUSES.RECEIVED;
      return TICKET_STATUSES.IN_ACCOUNT;
    }
    if (isFlashSeatsListed(flashTicket)) return TICKET_STATUSES.LISTED;
    if (isFlashSeatTransferred(flashTicket)) return TICKET_STATUSES.TRANSFERRED;
    if (isFlashSeatsTransferWaiting(flashTicket)) return TICKET_STATUSES.TRANSFER_WAITING;
    if (isFlashSeatsSold(flashTicket)) return TICKET_STATUSES.SOLD;
    if (isFlashSeatsSoldRedeemed(flashTicket)) return TICKET_STATUSES.SOLD_REDEEMED;
    if (isFlashSeatsNotRedeemed(flashTicket)) return TICKET_STATUSES.NOT_REDEEMED;
    if (isFlashSeatsTransferredRedeemed(flashTicket)) return TICKET_STATUSES.TRANSFERRED_REDEEMED;
  }

  if (isFlashSeat && !flashTicket) return TICKET_STATUSES.STAGING;

  if (!isFlashSeat) {
    if (isVeritixNotRedeemed(offer, orderedTicket)) return TICKET_STATUSES.NOT_REDEEMED;
    if (isVeritixRedeemed(orderedTicket)) return TICKET_STATUSES.REDEEMED;
    if (isVeritixShipped(orderedTicket)) return TICKET_STATUSES.SHIPPED;
    if (isVeritixPrinted(orderedTicket)) return TICKET_STATUSES.PRINTED;
  }

  return TICKET_STATUSES.AWAITING_DELIVERY;
};
