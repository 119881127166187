import React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import { matchPath } from 'react-router';
import { get } from 'lodash';

import PageLayout from '../layouts/PageLayout';
import { pages } from '../constants/routes';

// Main Container/jsx Displayed on the page
const TicketsContainer = Loadable({
  loader: () => import('../containers/tickets'),
  loading: () => null,
});

export default function (store: any, props: any) {
    // call Register Reducer here
  const match = props.match;
  const ticketPageParameters: any = matchPath(match.url, {
    exact: true,
    path: pages.ticket(':email', ':orderId', ':eventId', ':eventZoneId', ':seatGroupId', ':seatId'),
    strict: false,
  });

  const renderFunction = (props: any) => {
    const more = {
      ...props,
      customerEmail: get(ticketPageParameters, 'params.email'),
      eventId: get(ticketPageParameters, 'params.eventId'),
      eventZoneId: get(ticketPageParameters, 'params.eventZoneId'),
      orderId: get(ticketPageParameters, 'params.orderId'),
      seatGroupId: get(ticketPageParameters, 'params.seatGroupId'),
      seatId: get(ticketPageParameters, 'params.seatId'),
    };
    return <TicketsContainer {...more} />;
  };

  return (
    <Switch>
      <PageLayout>
          <Route
            path={ match.url }
            render={ renderFunction }
          />
      </PageLayout>
    </Switch>
  );
}
