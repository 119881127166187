import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from './home';
import Checkout from './checkout';
import Confirmation from './confirmation';
import Customers from './customers';
import Events from './events';
import Offer from './offer';
import Order from './order';
import LegacyOrder from './legacyOrderDetail';
import Login from './login';
import SearchResultsContainer from './search';
import PreferencesContainer from './preferences';
import TicketsContainer from './tickets';
import TransferPageContainer from './transfer';
import ChangeModPageContainer from './changeMod';
import { pages } from '../constants/routes';


export const createRoutes = (store: any) => {
  const getComponentRenderer = (component: any) => (props: any) => component(store, props);
  const customersRenderer = getComponentRenderer(Customers);
  const eventsRenderer = getComponentRenderer(Events);
  const homeRenderer = getComponentRenderer(Home);
  const loginRenderer = getComponentRenderer(Login);
  const offerRenderer = getComponentRenderer(Offer);
  const preferencesRenderer = getComponentRenderer(PreferencesContainer);
  const searchRenderer = getComponentRenderer(SearchResultsContainer);
  const ticketsRenderer = getComponentRenderer(TicketsContainer);
  const transferRenderer = getComponentRenderer(TransferPageContainer);
  const changeModRenderer = getComponentRenderer(ChangeModPageContainer);

  const renderOrder = (props: any) => Order(store, props);
  const renderLegacyOrderDetail = (props: any) => LegacyOrder(store, props);

  const ORDER_ID = ':orderId';
  const EVENT_ID = ':eventId';
  const EVENT_ZONE_ID = ':eventZoneId';
  const SEAT_GROUP_ID = ':seatGroupId';
  const SEAT_ID = ':seatId';
  const EMAIL = ':email';
  const TAB = ':tab';
  const OFFER_ID = ':offerId';

  return (
    <Switch>
      <Route
        store={ store }
        path={ pages.preferences() }
        render={ preferencesRenderer }
      />
      <Route
        store={ store }
        path={ pages.home }
        render={ homeRenderer }
      />
      <Route
        store={ store }
        path={ pages.offer(EVENT_ID, OFFER_ID) }
        render={ offerRenderer }
      />
      { /* ^^^ should be nested route within route below given same root path */ }
      <Route
        store={ store }
        path={ pages.event(EVENT_ID) }
        render={ eventsRenderer }
      />
      <Route
        path={ pages.login }
        render={ loginRenderer }
      />
      <Route
        path={ pages.reset }
        render={ loginRenderer }
      />
      <Route
        store={ store }
        path={ pages.search }
        render={ searchRenderer }
      />
      <Route
        store={ store }
        path={ pages.checkout }
        render={ (props: any) => Checkout(store, props) }
      />
      <Route
        store={ store }
        path={ pages.confirmation }
        render={ (props: any) => Confirmation(store, props) }
      />
      <Route
        path={ pages.changeModTicket(EMAIL, ORDER_ID, EVENT_ID, EVENT_ZONE_ID, SEAT_GROUP_ID, SEAT_ID) }
        render={ changeModRenderer }
        store={ store }
      />
      <Route
        path={ pages.changeModOrder(EMAIL, ORDER_ID, EVENT_ID) }
        render={ changeModRenderer }
        store={ store }
      />
      <Route
        path={ pages.transferTicket(EMAIL, ORDER_ID, EVENT_ID, EVENT_ZONE_ID, SEAT_GROUP_ID, SEAT_ID) }
        render={ transferRenderer }
        store={ store }
      />
      <Route
        path={ pages.ticket(EMAIL, ORDER_ID, EVENT_ID, EVENT_ZONE_ID, SEAT_GROUP_ID, SEAT_ID) }
        render={ ticketsRenderer }
        store={ store }
      />
      <Route
        path={ pages.transferOrder(EMAIL, ORDER_ID, EVENT_ID) }
        render={ transferRenderer }
        store={ store }
      />
      <Route
        path={ pages.orderTab(EMAIL, ORDER_ID, TAB) }
        render={ renderOrder }
        store={ store }
      />
      <Route
        store={ store }
        path={ pages.order(EMAIL, ORDER_ID) }
        render={ renderOrder }
      />
      <Route
        store={ store }
        path={ pages.legacyOrder(ORDER_ID) }
        render={ renderLegacyOrderDetail }
      />
      <Route
        store={ store }
        path={ pages.customer(EMAIL, TAB) }
        render={ customersRenderer }
      />
      <Redirect
        from="/"
        to={ pages.login }
      />
    </Switch>
  );
};

export default createRoutes;
