import { AnyAction } from 'redux';
import OfferPriceChart from '../common/PriceChart/OfferPriceChart';
import Seat from '../common/Seat/Seat';
import Section from '../common/Section/Section';
import OfferInventory from '../common/Offer/OfferInventory';

export interface IInventoryState {
  fetchingInventory: boolean;
  fetchingOfferPricing: boolean;
  offerInventories: OfferInventory[];
  offerPrices: OfferPriceChart[];
  seatInventory: Seat[];
  sectionInventory: Section[];
}


export interface IPriceRange {
  max?: number;
  min?: number;
}

export enum InventoryStateTypes {
  ADD_OFFER_INVENTORY_SEATS = 'ADD_OFFER_INVENTORY_SEATS',
  GET_OFFER_INVENTORY_BEGIN = 'GET_OFFER_INVENTORY_BEGIN',
  GET_OFFER_INVENTORY_SUCCESS = 'GET_OFFER_INVENTORY_SUCCESS',
  GET_OFFER_INVENTORY_FAILURE = 'GET_OFFER_INVENTORY_FAILURE',
  GET_OFFER_PRICING_BEGIN = 'GET_OFFER_PRICING_BEGIN',
  GET_OFFER_PRICING_SUCCESS = 'GET_OFFER_PRICING_SUCCESS',
  GET_OFFER_PRICING_FAILURE = 'GET_OFFER_PRICING_FAILURE',
}

export interface IAddOfferInventorySeats extends AnyAction {
  type: InventoryStateTypes.ADD_OFFER_INVENTORY_SEATS;
  seats: Seat[];
}

export interface IGetOfferInventoryBegin extends AnyAction {
  type: InventoryStateTypes.GET_OFFER_INVENTORY_BEGIN;
}

export interface IGetOfferInventorySuccess extends AnyAction {
  type: InventoryStateTypes.GET_OFFER_INVENTORY_SUCCESS;
}

export interface IGetOfferInventoryFailure extends AnyAction {
  type: InventoryStateTypes.GET_OFFER_INVENTORY_FAILURE;
}

export interface IGetOfferPricingBegin extends AnyAction {
  type: InventoryStateTypes.GET_OFFER_PRICING_BEGIN;
}

export interface IGetOfferPricingSuccess extends AnyAction {
  type: InventoryStateTypes.GET_OFFER_PRICING_SUCCESS; data: any;
}

export interface IGetOfferPricingFailure extends AnyAction {
  type: InventoryStateTypes.GET_OFFER_PRICING_FAILURE;
}

export type InventoryStateActions =
  IAddOfferInventorySeats |
  IGetOfferPricingBegin |
  IGetOfferPricingSuccess |
  IGetOfferPricingFailure |
  IGetOfferInventoryBegin |
  IGetOfferInventorySuccess |
  IGetOfferInventoryFailure;
