import { get } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { IProductIdentity, ISearchCriteria, ISeatSelection } from '../types/commonTypes';
import {
  CartingActions,
  CartingActionTypes,
  ICartingBegin,
  ICartingEnd,
  IClearCartingResult,
  IClearOrderingResult,
  ICloseCartDrawer,
  ICompleteCheckout,
  IOpenCartDrawer,
  IPlaceOrderBegin,
  IPlaceOrderEnd,
  IResetCartAndOrder, ISetAddress1,
  ISetCustomerCreditCard,
  ISetCustomerEmail,
  ISetCustomerFirstName,
  ISetCustomerLastName, ISetPhoneNumber,
  ITenderCash,
  IToggleCartDrawer,
  ISetAddress2,
  ISetCountry,
  ISetCity,
  ISetState,
  ISetPostalCode,
  IValidateCustomerAddress,
  ISetOrderAutoPrinted,
  IProcessOrderStatus,
  IRestoreCarting,
} from '../types/cartingTypes';
import {
  ICart,
  ICartingResponse,
  IOrderingResult,
  OrderStatus,
} from '../types/apiResponseTypes';
import cartingApi from '../api/ApiManager';
import CartActionRequest from '../classes/CartActionRequest';
import CartAddSelection from '../classes/CartAddSelection';
import CartRemoveSelection from '../classes/CartRemoveSelection';
import EmptyRequest from '../classes/EmptyRequest';
import CreateOrderRequest from '../classes/CreateOrderRequest';
import { ICustomer, ISeatSelectionForAdd } from '../types/apiRequestTypes';
import { ICreditCard } from '../../../../types/creditCard';
import CartUpdateSelection from '../classes/CartUpdateSelection';
import { setSession } from '../../../../actions/userActions';
import { getExpiration, isExpired } from '../../../../utils/common';
import config from '../../../../config';

const cartingBegin = (): ICartingBegin => {
  return { type: CartingActionTypes.CARTING_BEGIN };
};

const cartingEnd = (payload: ICartingResponse,
                    status?: number): ICartingEnd => {
  return { payload, status, type: CartingActionTypes.CARTING_END };
};

export const setCustomerCreditCard = (creditCard: ICreditCard | null): ISetCustomerCreditCard => {
  return { creditCard, type: CartingActionTypes.SET_CUSTOMER_CREDIT_CARD };
};

export const setCustomerEmail = (email: string): ISetCustomerEmail => {
  return { email, type: CartingActionTypes.SET_CUSTOMER_EMAIL };
};

export const setCustomerFirstName = (firstName: string): ISetCustomerFirstName => {
  return { firstName, type: CartingActionTypes.SET_CUSTOMER_FIRST_NAME };
};

export const validateCustomerAddress = (): IValidateCustomerAddress => {
  return {  type: CartingActionTypes.VALIDATE_CUSTOMER_ADDRESS };
};
export const setCustomerLastName = (lastName: string): ISetCustomerLastName => {
  return { lastName, type: CartingActionTypes.SET_CUSTOMER_LAST_NAME };
};
export const setPhoneNumber = (phoneNumber: string): ISetPhoneNumber => {
  return { phoneNumber, type: CartingActionTypes.SET_PHONE_NUMBER };
};
export const setAddress1 = (address1: string): ISetAddress1 => {
  return { address1, type: CartingActionTypes.SET_ADDRESS_1 };
};
export const setAddress2 = (address2: string): ISetAddress2 => {
  return { address2, type: CartingActionTypes.SET_ADDRESS_2 };
};
export const setCountry = (country: string, name: string): ISetCountry => {
  return { country, name, type: CartingActionTypes.SET_COUNTRY };
};
export const setCity = (city: string): ISetCity => {
  return { city, type: CartingActionTypes.SET_CITY };
};
export const setState = (state: string): ISetState => {
  return { state, type: CartingActionTypes.SET_STATE };
};
export const setPostalCode = (postalCode: string): ISetPostalCode => {
  return { postalCode, type: CartingActionTypes.SET_POSTAL_CODE };
};


const placeOrderBegin = (): IPlaceOrderBegin => {
  return { type: CartingActionTypes.PLACE_ORDER_BEGIN };
};

export const openCartDrawer = (): IOpenCartDrawer => {
  return { type: CartingActionTypes.OPEN_CART_DRAWER };
};

export const closeCartDrawer = (): ICloseCartDrawer => {
  return { type: CartingActionTypes.CLOSE_CART_DRAWER };
};

export const toggleCartDrawer = (): IToggleCartDrawer => {
  return { type: CartingActionTypes.TOGGLE_CART_DRAWER };
};

export const clearCartingResult = (): IClearCartingResult => {
  return { type: CartingActionTypes.CLEAR_CARTING_RESULT };
};

export const clearOrderingResult = (): IClearOrderingResult => {
  return { type: CartingActionTypes.CLEAR_ORDERING_RESULT };
};

export const resetCartAndOrder = (clearCustomer: boolean = false): IResetCartAndOrder => {
  return { clearCustomer, type: CartingActionTypes.RESET_CART_AND_ORDER };
};

export const completeCheckout = (): ICompleteCheckout => {
  return { type: CartingActionTypes.COMPLETE_CHECKOUT };
};

export const tenderCash = (amount?: number): ITenderCash => {
  return { amount, type: CartingActionTypes.TENDER_CASH };
};

export const setOrderAutoPrinted = (): ISetOrderAutoPrinted => {
  return { type: CartingActionTypes.SET_ORDER_AUTO_PRINTED };
};

export const restoreCarting = (cashTendered: number | null, customer: ICustomer | null,
                               lastOrderingResult: IOrderingResult | null, order: ICart | null,
                               orderAutoPrinted: boolean, orderCheckedOut: boolean): IRestoreCarting => {
  return {
    cashTendered,
    customer,
    lastOrderingResult,
    order,
    orderAutoPrinted,
    orderCheckedOut,
    type: CartingActionTypes.RESTORE_CARTING,
  };
};

const dispatchResponse = (dispatch: any,
                          endAction: (data: ICartingResponse, status?: number, expiration?: number)
                          => ICartingEnd | ThunkAction<void, any, null, CartingActions>,
                          data: ICartingResponse,
                          status?: number,
                          pendingExpiration?: number): void => {
  if (data) {
    dispatch(endAction(data, status, pendingExpiration));
  } else {
    dispatch(endAction({ message: 'carting.unableToCart' }, status, pendingExpiration));
  }
};

async function handleApiCall<RequestType>(api: (request: RequestType) => any,
                                          request: RequestType, dispatch: any,
                                          endAction: (data: ICartingResponse, status?: number, expiration?: number)
                                          => ICartingEnd | ThunkAction<void, any, null, CartingActions>,
                                          pendingExpiration?: number,
                                          ignoreError: boolean = false) {
  try {
    const response = await api(request);
    const data = get(response, 'data');
    const status = get(response, 'status');
    dispatchResponse(dispatch, endAction, data, status, pendingExpiration);
  } catch (error) {
    let data: any;
    let status: number | undefined;
    handleSessionExpired(error, dispatch);
    if (!ignoreError) {
      data = get(error, 'response.data');
      status = get(error, 'response.status');
    } else {
      data = {};
    }
    dispatchResponse(dispatch, endAction, data, status, pendingExpiration);
  }
}

const handleSessionExpired = (error: any, dispatch: any): void => {
  const status = get(error, 'response.status');
  const message = get(error, 'response.data.message');
  if (status === 412 && message.match(/session.+expired/gi)) {
    dispatch(setSession(null));
  }
};

export const getCart = (): ThunkAction<void, any, null, CartingActions> => async (dispatch: any) => {
  dispatch(cartingBegin());
  await handleApiCall<EmptyRequest>(cartingApi.getCart, new EmptyRequest(), dispatch, cartingEnd, undefined, true);
};

// tslint:disable-next-line: max-line-length
export const searchTickets = (product: IProductIdentity, searchCriterias: ISearchCriteria[], overwrite: boolean): ThunkAction<void, any, null, CartingActions> => async (dispatch: any) => {
  dispatch(cartingBegin());
  const selection: CartAddSelection = new CartAddSelection(product, undefined, false, overwrite);
  searchCriterias.forEach((searchCriteria) => {
    selection.addSearch(searchCriteria);
  });

  const request: CartActionRequest<CartAddSelection> = new CartActionRequest<CartAddSelection>(selection);
  await handleApiCall<CartActionRequest<CartAddSelection>>(cartingApi.addToCart, request, dispatch, cartingEnd);
};

// tslint:disable-next-line: max-line-length
export const addTickets = (product: IProductIdentity, items: ISeatSelectionForAdd[], overwrite: boolean): ThunkAction<void, any, null, CartingActions> => async (dispatch: any) => {
  dispatch(cartingBegin());
  const selection: CartAddSelection = new CartAddSelection(product, undefined, true, overwrite);
  if (items && items.length) {
    selection.pickSeats(items);
  }
  const request: CartActionRequest<CartAddSelection> = new CartActionRequest<CartAddSelection>(selection);
  await handleApiCall<CartActionRequest<CartAddSelection>>(cartingApi.addToCart, request, dispatch, cartingEnd);
};

// tslint:disable-next-line: max-line-length
export const removeTickets = (product: IProductIdentity, items?: ISeatSelection[], quantity?: number, priceLevelID?: string, priceTypeID?: string): ThunkAction<void, any, null, CartingActions> => async (dispatch: any) => {
  dispatch(cartingBegin());
  const selection: CartRemoveSelection = new CartRemoveSelection(product, quantity, priceTypeID, priceLevelID);
  if (items && items.length) {
    selection.addItems(items);
  }
  const request: CartActionRequest<CartRemoveSelection> = new CartActionRequest<CartRemoveSelection>(selection);
  await handleApiCall<CartActionRequest<CartRemoveSelection>>(cartingApi.removeFromCart, request, dispatch, cartingEnd);
};

// tslint:disable-next-line: max-line-length
export const setDeliveryMethod = (product: IProductIdentity, deliveryMethodID: string): ThunkAction<void, any, null, CartingActions> => async (dispatch: any) => {
  dispatch(cartingBegin());
  const selection: CartAddSelection = new CartAddSelection(product, deliveryMethodID);
  const request: CartActionRequest<CartAddSelection> = new CartActionRequest<CartAddSelection>(selection);
  await handleApiCall<CartActionRequest<CartAddSelection>>(cartingApi.addToCart, request, dispatch, cartingEnd);
};

// tslint:disable-next-line: max-line-length
export const setPriceCode = (product: IProductIdentity, item: ISeatSelectionForAdd): ThunkAction<void, any, null, CartingActions> => async (dispatch: any) => {
  dispatch(cartingBegin());
  const selection: CartUpdateSelection = new CartUpdateSelection(product);
  selection.updateSeats(item);
  const request: CartActionRequest<CartUpdateSelection> = new CartActionRequest<CartUpdateSelection>(selection);
  await handleApiCall<CartActionRequest<CartUpdateSelection>>(cartingApi.updateCart, request, dispatch, cartingEnd);
};

export const extendCartExpiration = (): ThunkAction<void, any, null, CartingActions> => async (dispatch: any) => {
  dispatch(cartingBegin());
  await handleApiCall<EmptyRequest>(cartingApi.updateTimer, new EmptyRequest(), dispatch, cartingEnd);
};

// tslint:disable-next-line: max-line-length
export const placeOrder = (customer: ICustomer | null, guestCheckoutOrder: boolean, machineName?: string, cashPaymentTypeID?: string, cashTendered?: number): ThunkAction<void, any, null, CartingActions> => async (dispatch: any) => {
  dispatch(placeOrderBegin());
  const request: CreateOrderRequest =
    new CreateOrderRequest(customer, guestCheckoutOrder, machineName, cashPaymentTypeID, cashTendered);
  await handleApiCall<CreateOrderRequest>(cartingApi.createOrder, request, dispatch, placeOrderEnd);
};

export const deleteCart = (): ThunkAction<void, any, null, CartingActions> => async (dispatch: any) => {
  dispatch(cartingBegin());
  await handleApiCall<EmptyRequest>(cartingApi.deleteCart, new EmptyRequest(), dispatch, cartingEnd);
};

// tslint:disable-next-line: max-line-length
export const checkOrderStatus = (pendingExpiration: number): ThunkAction<void, any, null, CartingActions> => async (dispatch: any) => {
  if (isExpired(pendingExpiration)) {
    dispatch(processOrderStatus({ status: OrderStatus.UNAVAILABLE, success: false }, undefined, pendingExpiration));
    return;
  }
  await handleApiCall<EmptyRequest>(cartingApi.checkOrderStatus, new EmptyRequest(), dispatch, processOrderStatus,
                                    pendingExpiration);
};

// tslint:disable-next-line: max-line-length
const placeOrderEnd = (payload: ICartingResponse, status?: number): ThunkAction<void, any, null, CartingActions> => async (dispatch: any) => {
  dispatch({ payload, status, type: CartingActionTypes.PLACE_ORDER_END } as IPlaceOrderEnd);
  if (status === 200) {
    const pendingExpiration = getExpiration('orderPendingTimeout');
    dispatch(checkOrderStatus(pendingExpiration));
  }
};

// tslint:disable-next-line: max-line-length
export const processOrderStatus = (payload: ICartingResponse, status?: number, expiration?: number): ThunkAction<void, any, null, CartingActions> => (dispatch: any) => {
  dispatch({ payload, status, type: CartingActionTypes.PROCESS_ORDER_STATUS } as IProcessOrderStatus);
  const orderStatus = get(payload, 'status', OrderStatus.UNAVAILABLE) as OrderStatus;
  if (expiration && !isExpired(expiration)) {
    if (orderStatus === OrderStatus.PENDING) {
      setTimeout(() => dispatch(checkOrderStatus(expiration)), config.orderStatusCheckInterval * 1000);
    }
  } else {
    dispatch({
      payload: { status: OrderStatus.UNAVAILABLE, success: false },
      type: CartingActionTypes.PROCESS_ORDER_STATUS,
    } as IProcessOrderStatus);
  }
};
