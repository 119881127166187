import config from '../config';
import { get } from 'lodash';
import Moment from 'moment';
import 'moment-timezone';
import { IExpiration } from '../types/common';

const getExpiration = (type: string): number => {
  const timestamp = Date.now();
  const shelfLife: number = config.shelfLife[type];
  if (shelfLife) { return timestamp + shelfLife * 1000; }
  return timestamp;
};

const isExpired = (object: IExpiration<any> | number | null): boolean => {
  const timestamp = Date.now();
  if (typeof object === 'number') {
    return timestamp >= object;
  }
  const expireAt = get(object, 'expireAt', 0);
  return timestamp >= expireAt;
};

const getStartOfTodayLocalDateAndTime = () => {
  return Moment().startOf('day').format();
};

const getEndOfTodayLocalDateAndTime = () => {
  return Moment().endOf('day').format();
};

const getStartOfTodayUTC = () => {
  return Moment().startOf('day').utc().format();
};

const getEndOfTodayUTC = () => {
  return Moment().endOf('day').utc().format();
};

const utcToLocalFormat = (dateTime: string, format: string) => {
  const timeZone = Moment.tz.guess();
  const localDateTime = Moment.utc(dateTime).tz(timeZone);
  return localDateTime.format(format);
};

export {
  getStartOfTodayLocalDateAndTime,
  getEndOfTodayLocalDateAndTime,
  getStartOfTodayUTC,
  getEndOfTodayUTC,
  getExpiration,
  isExpired,
  utcToLocalFormat,
};
