import React from 'react';
import ReactDOM from 'react-dom';
import sentry from './sentry';
import { Index } from './containers/app';
import createStore from './store/createStore';
import './custom.scss';
import './i18n';
import * as serviceWorker from './registerServiceWorker';
import { createBrowserHistory } from 'history';

sentry.activate();

const customHistory = createBrowserHistory();
const initialState =  {};
const store = createStore(customHistory, initialState);
const mountNode = document.getElementById('root');

const render = () => {
  const routes = require('./routes/index').default(store);

  ReactDOM.render(
    <Index store={ store } routes={ routes } history={ customHistory }/>,
    mountNode,
  );
};
serviceWorker.unregister();

render();
