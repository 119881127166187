import { all } from 'redux-saga/effects';
import {
  fetchDefaultEventOffersWatcher,
  fetchMissingOffersWatcher,
  fetchOfferPricingWatcher,
  fetchOffersWatcher,
} from './offers';
import { getCmsEventsWatcher } from './cmsEvents';
import { fetchCustomersWatcher } from './customers';
import { fetchTicketsWatcher } from './tickets';
import { fetchDefaultEventsWatcher, fetchEventsWatcher, fetchMissingEventsWatcher } from './events';
import { fetchSessionDataWatcher, extendSessionWatcher } from './session';

export function* rootSaga() {
  yield all([
    fetchDefaultEventsWatcher(),
    fetchDefaultEventOffersWatcher(),
    fetchEventsWatcher(),
    fetchMissingEventsWatcher(),
    fetchMissingOffersWatcher(),
    fetchOffersWatcher(),
    fetchCustomersWatcher(),
    fetchTicketsWatcher(),
    fetchSessionDataWatcher(),
    fetchOfferPricingWatcher(),
    extendSessionWatcher(),
    getCmsEventsWatcher(),
  ]);
}
