import { ThunkAction } from 'redux-thunk';
import { get } from 'lodash';
import { AxiosError, AxiosResponse } from 'axios';
import { IFullStorageShape } from '../store/store.types';
import { fetchUserProfile, fetchSeatHistory } from '../api/FanAccountApiManager';
import {
  IGetSeatHistoryBegin,
  IGetSeatHistoryEnd,
  SeatStateTypes,
  SeatStateActions,
  IGetSeatCustomerProfileBegin,
  IGetSeatCustomerProfileEnd,
} from '../types/seatTypes';
import { IPrimarySeatHistory, IPrimaryUser } from '../api/types/fanAccount/responseTypes';
import { setNotification } from './appActions';

export const getSeatCustomerProfile = (
  contextId: number,
  oboAccessToken: string,
  oboUserEmail: string,
  customerEmail: string,
): ThunkAction<void, IFullStorageShape, null, SeatStateActions> => (dispatch: any) => {
  dispatch(getSeatCustomerProfileBegin());
  return fetchUserProfile(contextId, oboAccessToken, oboUserEmail, customerEmail)
    .then((response: AxiosResponse<IPrimaryUser>) => {
      const userProfile: IPrimaryUser = get(response, 'data');
      dispatch(getSeatCustomerProfileEnd(userProfile));
    })
    .catch((error: AxiosError) => {
      dispatch(setNotification('danger', 'fanAccount.errors.accountProfile', true));
    });
};

export const getSeatHistory = (
  contextId: number,
  accessToken: string,
  orderId: string,
  seatVid: {
    eventId: string,
    eventZoneId: string,
    seatGroupId: string,
    seatId: string,
  },
  ticketId?: string,
): ThunkAction<void, IFullStorageShape, null, SeatStateActions> => (dispatch: any) => {
  dispatch(getSeatHistoryBegin());
  return fetchSeatHistory(contextId, accessToken, orderId, seatVid, ticketId)
    .then((response: AxiosResponse<IPrimarySeatHistory[]>) => {
      if (!response.data || !response.data.length) {
        dispatch(setNotification('danger', 'fanAccount.errors.seatHistory', true));
      }
      dispatch(getSeatHistoryEnd(response.data, seatVid));
    })
    .catch((error: AxiosError) => {
      dispatch(setNotification('danger', 'fanAccount.errors.seatHistory', true));
    });
};

export const getSeatHistoryBegin = (): IGetSeatHistoryBegin =>
  ({ type: SeatStateTypes.GET_SEAT_HISTORY_BEGIN });

export const getSeatHistoryEnd = (
  seatHistoryItems: IPrimarySeatHistory[],
  seatVid: {
    eventId: string,
    eventZoneId: string,
    seatGroupId: string,
    seatId: string,
  },
): IGetSeatHistoryEnd =>
  ({ type: SeatStateTypes.GET_SEAT_HISTORY_END, seatHistoryItems, seatVid });

export const getSeatCustomerProfileBegin = (): IGetSeatCustomerProfileBegin =>
  ({ type: SeatStateTypes.GET_SEAT_CUSTOMER_PROFILE_BEGIN });

export const getSeatCustomerProfileEnd = (userProfile: IPrimaryUser): IGetSeatCustomerProfileEnd =>
  ({ type: SeatStateTypes.GET_SEAT_CUSTOMER_PROFILE_END, userProfile });

export const resetSeatState = () =>
  ({ type: SeatStateTypes.RESET_SEAT_STATE });
