import PriceChart from '../PriceChart/PriceChart';
import { Currency } from '../Price/Currency';
import { find, get } from 'lodash';
import Seat from '../Seat/Seat';
import { OfferType } from '../Offer/OfferType';
import Price from '../Price';
import { SeatType } from '../Seat/SeatType';
import PriceCode from '../PriceCode';

class OfferPriceChart {

  constructor(
    public offerId: number,
    public offerGroupId: number,
    public priceCharts: PriceChart[],
    public currency: Currency,
  ) {
  }

  /**
   * Return the possible prices of a specific seat
   *
   * @param {OfferType} offerType The Offer Type we are getting the price from.
   * @param {Seat} seat The Seat we are getting the price from
   * @param {number} priceCodeId the price Code Id of the desired price
   *
   * @returns {number | undefined}
   */
  public getSeatPrices(seat: Seat, offerType: OfferType, priceCodeId?: number): number[] {
    if (seat.seatType === SeatType.FLASHSEATS) {
      const price: number | undefined = this.getFirstPriceChart().getSeatResalePrice(seat.sectionLabel, seat.offerId);
      if (price) return [price];
      return [];
    }
    if (offerType === OfferType.SINGLE) {
      return this.getFirstPriceChart().getSeatPrices(seat, priceCodeId);
    }
    if (offerType === OfferType.BUNDLE) {
      let prices: number[] = [];
      this.priceCharts.forEach((priceChart: PriceChart) => {
        const seatPrices = priceChart.getSeatPrices(seat, priceCodeId, false);
        if (prices.length === 0) {
          prices = seatPrices;
        } else {
          seatPrices.forEach((price, i) => {
            if (prices[i] !== undefined) {
              prices[i] = prices[i] + price;
            }
          });
        }
      });
      return prices;
    }
    return [];
  }

  /**
   * Return the minimum price of a priceLevel
   *
   * @param {number} priceLevelId The PRice Level ID
   * @param {number} priceCodeId the price Code Id of the desired price
   * @param {OfferType} offerType The Offer Type we are getting the price from.
   *
   * @returns {number | undefined}
   */
  public getMinPrice(priceLevelId: number, priceCodeIds: number[], offerType: OfferType): number | undefined {
    if (offerType === OfferType.SINGLE) {
      return this.getFirstPriceChart().getMinPrice(priceLevelId, priceCodeIds);
    }
    if (offerType === OfferType.BUNDLE) {
      let minPrice: number| undefined;
      this.priceCharts.forEach((priceChart: PriceChart) => {
        const pcMinPrice = priceChart.getMinPrice(priceLevelId, priceCodeIds);
        minPrice = this.addRange(minPrice, pcMinPrice);
      });
      return minPrice;
    }
    return undefined;
  }

  /**
   * Get the Large price Range of the entire Offer Prices
   *
   * @param {OfferType} offerType The Offer Type. If Single, we get the first price Chart
   *
   * @returns {number} the list of prices
   */
  public getPriceRange(offerType: OfferType): number[] {
    if (offerType === OfferType.SINGLE) {
      const priceChart = this.getFirstPriceChart();
      return [get(priceChart, 'priceRange.min'), get(priceChart, 'priceRange.max')];
    }
    if (offerType === OfferType.BUNDLE) {
      let minPrice: number| undefined;
      let maxPrice: number| undefined;
      this.priceCharts.forEach((priceChart: PriceChart) => {
        const pcMinPrice = get(priceChart, 'priceRange.min');
        const pcMaxPrice = get(priceChart, 'priceRange.max');
        minPrice = this.addRange(minPrice, pcMinPrice);
        maxPrice = this.addRange(maxPrice, pcMaxPrice);
      });
      if (minPrice !== undefined && maxPrice !== undefined) {
        return [minPrice, maxPrice];
      }
    }
    return [0, 0];
  }

  /**
   * Get The price Codes for a specific product
   *
   * @param {number} productId The targetted productId
   *
   * @returns {PriceCode[]} The list of PriceCode Object
   */
  public getPriceCodes(productId: number): PriceCode [] {
    const priceChart = find(this.priceCharts, { productId });
    if (priceChart) {
      return priceChart.getPriceCodes();
    }
    return [];
  }

  /**
   * Get The First Price Chart, mostly used for Single Offers
   *
   * @returns {PriceChart} The PriceChart Object
   */
  public getFirstPriceChart(): PriceChart {
    return this.priceCharts[0];
  }

  /**
   * Determine if the priceLevel is in the price range
   *
   * @param {number} priceLevelId The price Level ID
   * @param {number[]} priceRange The selected price Range, 0 is min, 1 is max
   * @param {number[]} priceCodeIds The selected price Code Ids
   * @param {OfferType} offerType The Offer Type.
   *
   * @returns {boolean} Wether the priceLevel is in the range or not
   */
  public isPriceLevelInRange(priceLevelId: number, priceRange: number[],
                             priceCodeIds: number[], offerType: OfferType): boolean {
    // Get The priceLevel prices
    let prices: number[] = [];
    if (offerType === OfferType.SINGLE) {
      const priceObjs: Price[] = this.getFirstPriceChart().getPriceLevelPrices(priceLevelId, priceCodeIds);
      prices = priceObjs.map(p => p.value);
    } else if (offerType === OfferType.BUNDLE) {
      prices = this.getBundledPrices(priceLevelId, priceCodeIds);
    }
    if (prices) {
      const min = priceRange[0];
      const max = priceRange[1];
      let onePriceInRange = false;
      prices.forEach((price: number) => {
        const priceInUnit = price / 100;
        if (priceInUnit >= min && priceInUnit <= max) {
          onePriceInRange = true;
        }
      });
      return onePriceInRange;
    }
    return false;
  }

  private addRange(value: number | undefined, newValue: number | undefined): number | undefined {
    let result = value;
    if (newValue !== undefined) {
      if (result === undefined) {
        result = newValue;
      } else {
        result += newValue;
      }
    }
    return result;
  }

  private getBundledPrices (priceLevelId: number, priceCodeIds: number[]): number[] {
    const prices: number[] = [];
    const priceMap: object  = {};
    this.priceCharts.forEach((priceChart: PriceChart) => {
      const priceObjs: Price[] = priceChart.getPriceLevelPrices(priceLevelId, priceCodeIds);
      priceObjs.forEach((obj: Price) => {
        priceMap[obj.priceCodeId] = priceMap[obj.priceCodeId] === undefined ?
          obj.value : priceMap[obj.priceCodeId] + obj.value;
      });
    });
    Object.keys(priceMap).forEach((pC: any) => {
      prices.push(priceMap[pC]);
    });
    return prices;
  }

}

export default OfferPriceChart;
