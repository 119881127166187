class Section {

  constructor(
    public id: number,
    public description: string,
  ) {
  }

  public toString() {
    return `Section: ${this.id} - ${this.description}`;
  }

}

export default Section;
