import { AnyAction } from 'redux';
import { IPrimarySeatHistory, IPrimaryUser } from '../api/types/fanAccount/responseTypes';

export interface ISeatState {
  customerEmail: string | null;
  customerName: string | null;
  seatHistoryItems: IPrimarySeatHistory[] | null;
  seatHistoryVid: null | {
    eventId: string,
    eventZoneId: string,
    seatGroupId: string,
    seatId: string,
  };
}

export enum SeatStateTypes {
  GET_SEAT_HISTORY_BEGIN = 'GET_SEAT_HISTORY_BEGIN',
  GET_SEAT_HISTORY_END = 'GET_SEAT_HISTORY_END',
  GET_SEAT_CUSTOMER_PROFILE_BEGIN = 'GET_SEAT_CUSTOMER_PROFILE_BEGIN',
  GET_SEAT_CUSTOMER_PROFILE_END = 'GET_SEAT_CUSTOMER_PROFILE_END',
  RESET_SEAT_STATE = 'RESET_SEAT_STATE',
}

export interface IGetSeatHistoryBegin extends AnyAction {
  type: SeatStateTypes.GET_SEAT_HISTORY_BEGIN;
}

export interface IGetSeatHistoryEnd extends AnyAction {
  seatHistoryItems: IPrimarySeatHistory[];
  seatVid: {
    eventId: string,
    eventZoneId: string,
    seatGroupId: string,
    seatId: string,
  };
  type: SeatStateTypes.GET_SEAT_HISTORY_END;
}

export interface IGetSeatCustomerProfileBegin extends AnyAction {
  type: SeatStateTypes.GET_SEAT_CUSTOMER_PROFILE_BEGIN;
}

export interface IGetSeatCustomerProfileEnd extends AnyAction {
  type: SeatStateTypes.GET_SEAT_CUSTOMER_PROFILE_END;
  userProfile: IPrimaryUser;
}

export type SeatStateActions =
  IGetSeatHistoryBegin |
  IGetSeatHistoryEnd |
  IGetSeatCustomerProfileBegin |
  IGetSeatCustomerProfileEnd;
