import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { find } from 'lodash';
import styled, { ThemeProvider } from 'styled-components';

import { getThemes, setThemeMode } from '../actions/appActions';
import { APP_REDUCER_KEY, USER_REDUCER_KEY } from '../store/store.types';
import { skinSchemas } from '../constants';
import { push } from 'connected-react-router';
import { SessionStorage } from '../components/SessionStorage';
import { LocalStorage } from '../components/LocalStorage';
import { pages } from '../constants/routes';
import { themeTypes } from '../theme';

export interface IProps {
  contextId: number | null;
  children: any;
  getThemes: any;
  pushLocation: (location: string) => void;
  restoredFromLocalStorage: boolean;
  restoredFromSessionStorage: boolean;
  setThemeMode?: any;
  themeMode?: string;
  themes?: any;
  userId: string | null;
}

const Viewport = styled.div<any>`
  background-color: ${({ themeMode }: any) =>
    themeMode === themeTypes.dark ? '#040619' : '#f6f6f6' }; // @todo don't hardcode.
  min-height: calc(100vh);
`;

export class LoginLayout extends React.Component<IProps> {

  public componentDidMount() {
    this.redirectHome();
    if (this.props.themes.length < 1) {
      this.props.getThemes('axs', skinSchemas.THEME_SCHEMA_AXS_UI);
      this.props.getThemes('box-office-axs-dark', skinSchemas.THEME_SCHEMA_AXS_UI);
    }
  }

  public componentDidUpdate() {
    this.redirectHome();
  }

  public render() {
    const { children, themes, themeMode } = this.props;
    const selectedTheme = themeMode === themeTypes.dark ? find(themes, { skin: themeTypes.dark }) : find(themes, { skin: 'AXS' });

    return(
      <ThemeProvider theme={ selectedTheme || {} }>
        <Viewport themeMode={ themeMode } className={ classnames(`${ themes.skin }`) }>
          <LocalStorage />
          <SessionStorage />
          <div className="container">
            <div className="row">
              { children }
            </div>
          </div>
        </Viewport>
      </ThemeProvider>
    );
  }

  private redirectHome() {
    const { contextId, pushLocation, restoredFromLocalStorage, restoredFromSessionStorage, userId } = this.props;
    if (restoredFromLocalStorage && restoredFromSessionStorage && userId && contextId) {
      pushLocation(pages.home);
    }
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getThemes: (skinName: string, schemaName: string) => dispatch(getThemes(skinName, schemaName)),
    pushLocation: (location: string) => dispatch(push(location)),
    setThemeMode: (mode: string) => dispatch(setThemeMode(mode)),
  };
};

const mapStateToProps =  (state: any) => {
  return {
    contextId: state[USER_REDUCER_KEY].contextId,
    restoredFromLocalStorage: state[APP_REDUCER_KEY].restoredFromLocalStorage,
    restoredFromSessionStorage: state[APP_REDUCER_KEY].restoredFromSessionStorage,
    themeMode: state[APP_REDUCER_KEY].themeMode,
    themes: state[APP_REDUCER_KEY].themes,
    userId: state[USER_REDUCER_KEY].userId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginLayout);
