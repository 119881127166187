import { AnyAction } from 'redux';

export interface INotification {
  autoDismiss: boolean;
  message: string | string[];
  notificationType: INotificationType;
  translate?: boolean;
}

export interface IApplicationState {
  notification?: INotification;
  overlayEnabled: boolean;
  restoredFromLocalStorage: boolean;
  restoredFromSessionStorage: boolean;
  themeMode: string;
  themes: object[];
}

export enum ApplicationStateTypes {
  CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION',
  FETCH_THEME_BEGIN = 'FETCH_THEME_BEGIN',
  FETCH_THEME_FAILURE = 'FETCH_THEME_FAILURE',
  FETCH_THEME_SUCCESS = 'FETCH_THEME_SUCCESS',
  GET_THEMES = 'GET_THEMES',
  RESTORE_LOCAL_STORAGE = 'RESTORE_LOCAL_STORAGE',
  RESTORE_SESSION_STORAGE = 'RESTORE_SESSION_STORAGE',
  SET_NOTIFICATION = 'SET_NOTIFICATION',
  SET_THEME_MODE = 'SET_THEME_MODE',
  TOGGLE_OVERLAY = 'TOGGLE_OVERLAY',
}

export interface ISetNotification extends AnyAction {
  notification: INotification;
  type: ApplicationStateTypes.SET_NOTIFICATION;
}

export interface IClearNotification extends AnyAction {
  type: ApplicationStateTypes.CLEAR_NOTIFICATION;
}

export interface ISetThemeMode extends AnyAction {
  mode: string;
  type: ApplicationStateTypes.SET_THEME_MODE;
}

export interface IGetTheme extends AnyAction {
  type: ApplicationStateTypes.GET_THEMES;
}

export interface IFetchThemeBegin extends AnyAction {
  type: ApplicationStateTypes.FETCH_THEME_BEGIN;
}

export interface IFetchThemeSuccess extends AnyAction {
  type: ApplicationStateTypes.FETCH_THEME_SUCCESS;
}

export interface IFetchThemeFailure extends AnyAction {
  type: ApplicationStateTypes.FETCH_THEME_FAILURE;
}

export interface IRestoreFromLocalStorage extends AnyAction {
  type: ApplicationStateTypes.RESTORE_LOCAL_STORAGE;
}

export interface IRestoreFromSessionStorage extends AnyAction {
  type: ApplicationStateTypes.RESTORE_SESSION_STORAGE;
}

export interface IToggleOverlay extends AnyAction {
  type: ApplicationStateTypes.TOGGLE_OVERLAY;
}

export type ApplicationStateActions =
  IFetchThemeBegin |
  IFetchThemeFailure |
  IFetchThemeSuccess |
  IGetTheme |
  ISetThemeMode |
  IToggleOverlay;

interface IColorDef {
  name: string;
  key: string;
  value: string;
}

interface IButton {
  background: string;
  color: string;
  borderRadius: string;
  borderColor: string;
  borderColorHover: string;
  backgroundHover: string;
  colorHover: string;
  disabled: string;
  disabledBackground: string;
  disabledBorder: string;
  disabledHover: string;
  disabledBackgroundHover: string;
  disabledBorderHover: string;
}

interface IBrand {
  default: string;
  focus: string;
  active: string;
  hover: string;
  selected: string;
}

interface IStatus {
  default: string;
  focus: string;
  active: string;
  hover: string;
  selected: string;
}

interface IHeaderElement {
  color: string;
  fontSize: string;
  fontWeight: string;
}

export interface ISkin {
  skin: string;
  description: string;
  axsui: {
    color_defs: IColorDef[],
    components: {
      buttons: {
        primary: IButton,
        secondary: IButton,
      },
      tag: {
        borderRadius: string,
        iconColor: string,
        color: string,
        colorHover: string,
        colorDisabled: string,
        backgroundColor: string,
        backgroundColorDisabled: string,
        borderColor: string,
      },
      table: {
        headerColor: string,
        headerBackgroundColor: string,
        headerBorderColor: string,
        color: string,
        rowBackgroundColor: string,
        rowBorderColor: string,
      },
      pageTitle: {
        titleColor: string,
        subTitleColor: string,
      },
      card: {
        borderRadius: string,
        color: string,
        colorHover: string,
        secondaryColor: string,
        secondaryColorHover: string,
        borderColor: string,
        borderColorHover: string,
        borderColorActive: string,
        borderColorDisabled: string,
        backgroundColor: string,
        backgroundColorActive: string,
        backgroundColorHover: string,
        backgroundColorDisabled: string,
      },
      menu: {
        color: string,
        colorActive: string,
        borderColor: string,
        borderColorActive: string,
        backgroundColor: string,
        backgroundColorActive: string,
      },
      label: {
        color: string,
        backgroundColor: string,
        borderColor: string,
        disabledBackgroundColor: string,
        disabledBorderColor: string,
        disabledColor: string,
      },
      seatLocator: {
        color: string,
        labelColor: string,
      },
      date: {
        color: string,
        dayColor: string,
      },
      counter: {
        activeBorderColor: string,
        borderColor: string,
        counterColor: string,
        counterActiveColor: string,
        counterBorderColor: string,
        counterSize: string,
        upDownColor: string,
        upDownActiveColor: string,
        upDownSize: string,
        labelColor: string,
        labelSize: string,
      },
    },
    brand: {
      primary: IBrand,
      secondary: IBrand,
    },
    status: {
      success: IStatus,
      warning: IStatus,
      danger: IStatus,
      info: IStatus,
      info2: IStatus,
      disabled: IStatus,
    },
    forms: {
      borderRadius: string,
      borderColor: string,
      borderColorHover: string,
      borderColorActive: string,
      borderColorError: string,
      borderColorDisabled: string,
      iconColor: string,
      color: string,
      colorHover: string,
      colorActive: string,
      colorDisabled: string,
      colorError: string,
      placeholder: string,
      backgroundColor: string,
      backgroundColorActive: string,
      backgroundColorHover: string,
      backgroundColorDisabled: string,
      labelColor: string,
      labelColorDisabled: string,
      selectOptionsWrapperBackgroundColor: string,
      selectOptionsBackgroundColor: string,
      selectOptionsBackgroundColorHover: string,
      selectOptionsSelectedBackgroundColor: string,
      selectOptionsColor: string,
      selectOptionsColorHover: string,
    },
    ui: {
      white: string,
      black: string,
      background: string,
    },
    text: {
      primary: string,
      secondary: string,
      tertiary: string,
      link: string,
      linkHover: string,
      linkActive: string,
      linkVisited: string,
      linkDisabled: string,
      elements: {
        h1: IHeaderElement,
        h2: IHeaderElement,
        h3: IHeaderElement,
        h4: IHeaderElement,
        h5: IHeaderElement,
        h6: IHeaderElement,
      },
    },
  };
}

export interface IPrintingSpec {
  printer?: string;
  stockId?: string;
  typeId?: string;
}

export enum PrintItems {
  TICKETS = 'tickets',
  TOPCARD = 'topCard',
  RECEIPT = 'receipt',
}

export type INotificationType = 'danger' | 'info' | 'warning' | 'success';


