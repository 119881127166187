import { AXSBlock, AXSButton, AXSDateDisplay, AXSFlex, AXSIcon } from '@axs/axs-ui';
import { EventDetailTitle } from '../EventDetailTitle/EventDetailTitle';
import { EventDetailSupportingTitle } from '../EventDetailSupportingTitle/EventDetailSupportingTitle';
import { EventDetailVenue } from '../EventDetailVenue/EventDetailVenue';
import { EventDetailOffer } from '../EventDetailOffer/EventDetailOffer';
import EventDetailModal from '../EventDetailModal';
import * as React from 'react';
import VTEvent from '../../common/Event/VTEvent';
import get from 'lodash/get';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { eventHeaderHeight } from '../../theme';
import { TAGS, removeHtmlTag } from '../../common/utils/index';
import { Link } from 'react-router-dom';
import { pages } from '../../constants/routes';
import { OFFERS_REDUCER_KEY, USER_REDUCER_KEY } from '../../store/store.types';
import { connect } from 'react-redux';
import { IAvailableOffers } from '../../types/offerTypes';

export const headerVariants = {
  cartDrawer: 'cart-drawer',
  checkout: 'checkout',
  confirmation: 'confirmation',
  edp: 'edp',
  header: 'header',
};

interface IEventDetail {
  availableOffers: IAvailableOffers;
  event: VTEvent;
  hasOtherDates?: boolean;
  selectedOfferId?: number;
  toggleModal?: any;
  variant?: string;
}

const OtherDates = styled(AXSBlock)`
  cursor: pointer;
`;

const ShortDate = styled(AXSDateDisplay)`
  font-weight: bold;
  text-align: center;
`;

const EventInfoContent = styled(AXSBlock)`
  max-height: 200px;
  overflow: auto;
`;

const EDPLink = styled(Link)`
  &:hover { text-decoration: none; }
`;

interface IDateBlock {
  date: string;
}

const DateBlock = ({ date }: IDateBlock) => {
  return (
    <AXSBlock width="82px" marginRight="24px">
      <ShortDate
        fontSize="24px"
        date={ date }
      />
      <AXSBlock color="axsui.components.date.color" marginTop="-6px" fontSize="12px">
        { moment(date).format('ddd h:mm A') }
      </AXSBlock>
  </AXSBlock>
  );
};

const displayEventDescription = (content: string) => {
  return { __html: content };
};

export const EventDetail = ({ event, variant, toggleModal, hasOtherDates, selectedOfferId, availableOffers }:
  IEventDetail) => {
  const [t] = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const eventDescription = get(event, 'description', '');
  const cleanHtml = removeHtmlTag(eventDescription, TAGS.Script);
  let availableOffer = null;
  if (selectedOfferId) {
    availableOffer = get(availableOffers, selectedOfferId.toString());
  }
  const offer = availableOffer && availableOffer.data;
  let offerName = null;
  if (offer) {
    offerName = offer.name;
  }
  if (!event) {
    return (
      <AXSBlock height={ eventHeaderHeight } />
    );
  }
  return (
    <AXSBlock marginRight={ variant === headerVariants.edp ? '160px' : '0' }>
      <EventDetailModal
        active={ showModal }
        handleClose={ () => setShowModal(false) }
        eventDate={ event.startDate }
        eventTitle={ event.name }
        venue={ get(event, 'venue.name') }
      >
        <EventInfoContent dangerouslySetInnerHTML={ displayEventDescription(cleanHtml) } />
      </EventDetailModal>
      <AXSFlex flexWrap="wrap">
        { (variant === headerVariants.header || variant === headerVariants.confirmation) && (
        <AXSBlock>
          <AXSDateDisplay
            fontSize="14px"
            date={ event.startDate }
            displayFormat="full"
            displayYear
            displayWeekday
          />
        </AXSBlock>
        ) }
        { variant === headerVariants.cartDrawer &&
          <DateBlock date={ event.startDate } />
        }
        <AXSBlock width={ variant !== headerVariants.cartDrawer ? '100%' : 'auto' }>
          <EventDetailTitle variant={ variant }>
              { variant === headerVariants.edp ?  event.name
                  :  <EDPLink to={ pages.event(event.id) }>{ event.name }</EDPLink>
              }
          </EventDetailTitle>
      { event.supportingAct && variant !== headerVariants.confirmation && (
        <EventDetailSupportingTitle
          color="axsui.text.secondary"
          fontSize={ variant === headerVariants.header ? '16px' :
            variant === headerVariants.cartDrawer ? '16px'
              : variant === headerVariants.confirmation ? '13px' : '28px'
          }
          margin={ variant === headerVariants.header ? '-2px 0 6px 0' : '0 0 0 0' }
          width="100%"
        >{ event.supportingAct }
        </EventDetailSupportingTitle>
      ) }
      { (
        <EventDetailOffer
          color="axsui.text.secondary"
          fontSize="16px"
          fontWeight="bold"
          margin="0 0 10px 0"
          width="100%"
          offerName={ variant === headerVariants.header ? offerName : null }
        />
      ) }
      { event.venue && event.venue.name ? (
        <EventDetailVenue
          color="axsui.text.elements.h2.color"
          width="100%"
        >
          <AXSBlock
            fontSize={
              variant === headerVariants.header ? '14px'
                : variant === headerVariants.checkout ? '12px'
                : '16px'
            }
            inline
            marginBottom={ variant === headerVariants.header || variant === headerVariants.confirmation ? '0' : '40px' }
            paddingRight="10px"
          >
            <span>{ get(event, 'venue.name') }</span>
            <span>, </span>
            <span>{ get(event, 'venue.address.city') }</span>
            <span>{ ' ' }</span>
            <span>{ get(event, 'venue.address.state') }</span>
          </AXSBlock>
          { variant !== headerVariants.confirmation ? (
            <AXSButton
              borderless
              color="axsui.status.info.default"
              onClick={ () => setShowModal(true) }
            >{ t('offers.eventInfo') }
            </AXSButton>
          ) : null }
        </EventDetailVenue>
      ) : <AXSBlock marginBottom="16px" /> }
        </AXSBlock>
      </AXSFlex>

      { variant !== 'header' && variant !== headerVariants.cartDrawer && variant !== 'confirmation' && (
      <AXSFlex alignItems="center" marginTop="40px">
        <AXSDateDisplay
          date={ event.startDate }
          displayFormat="full"
          fontSize="24px"
          displayYear
          displayWeekday
        />
        { hasOtherDates && (
        <OtherDates
          color="axsui.text.link"
          onClick={ () => toggleModal() }
          fontSize="16px"
          marginLeft="12px"
        >
          <AXSIcon name="date-range" /> Other Dates
        </OtherDates>
        ) }
      </AXSFlex>
      ) }
    </AXSBlock>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    availableOffers: state[OFFERS_REDUCER_KEY].availableOffers,
    selectedOfferId: state[USER_REDUCER_KEY].selectedOfferId,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(EventDetail);
