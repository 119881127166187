import styled from 'styled-components';
import { AXSBlock, AXSIcon } from '@axs/axs-ui';

export const Wrapper = styled(AXSBlock)<any>`
  box-shadow: ${({ active, boxShadow }) => !active && boxShadow };
  height: calc(100vh - 50px);
  padding: 10px 20px 20px 20px;
  position: fixed;
  top: calc(100vh - 60px);
  transition: .2s;
  width: 288px;

  &.active {
    transform: translate3d(0, calc(110px - 100vh), 0);
  }
`;

export const Expander = styled(AXSIcon)`
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 16px;
  z-index: 2;
`;

export const BottomWrapper = styled(AXSBlock)<any>`
  bottom: 0;
  position: absolute;
  text-align: center;
  width: 244px;
`;

export const WrapperContent = styled(AXSBlock)<any>`
  display: inline-block;
`;

export const SettingsWrapper = styled(AXSBlock)<any>`
  height: calc(100vh - 380px);
`;
