import React from 'react';
import { AXSFlex, AXSBlock, AXSModal, AXSFlexItem, AXSLoader } from '@axs/axs-ui';
import { PageTitle, SectionHeader, SessionCardSubTitles, CloseButton, PrintSessionCardButton } from './styles';
import LineItem from './LineItem';
import { useTranslation } from 'react-i18next';
import { ISessionItem, ISessionInfo } from '../../types/userTypes';
import styled from 'styled-components';
import { formatPrice } from '../../common/utils/formatPrice';
import { useSelector, useDispatch } from 'react-redux';
import { USER_REDUCER_KEY } from '../../store/store.types';
import { getSessionInfoBegin } from '../../actions/userActions';
import { printSessionCard } from '../../actions/appActions';
import { getStartOfTodayLocalDateAndTime, getEndOfTodayLocalDateAndTime } from '../../utils/common';

const Wrapper = styled(AXSBlock)`
  position: absolute;
  top: 0;
  left: 0;

  .total-divider {
    border-top: solid 1px #1d3570;
    margin-bottom: 30px;
    margin-top: 20px;
  }
`;

const InnerModal = styled.div`
  height: 345px;
  overflow: auto;
`;

const SessionCardContainer = styled.div`
  width: 100%;
  text-align: center;
`;

interface ISessionCardModalProps {
  active: boolean;
  handleClose: (active: boolean) => void;
  userId: string;
  printer: string;
}



const SessionCardModal = ({ active, handleClose, userId, printer }: ISessionCardModalProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const sessionInfo: ISessionInfo | null | undefined = useSelector((state: any) => state[USER_REDUCER_KEY].sessionInfo);
  const sessionId = useSelector((state: any) => state[USER_REDUCER_KEY].sessionId);
  const startDateTime = getStartOfTodayLocalDateAndTime();
  const endDateTime = getEndOfTodayLocalDateAndTime();

  const cardItems: any[] = [];
  let cardTotal: number = 0;
  let cashTotal: number = 0;
  let grandTotal: number = 0;
  let content: JSX.Element = (
    <AXSFlex width="100%" marginTop="50px" justifyContent="center">
      <AXSBlock width="100%" textAlign="center">
        <AXSLoader center width="75px" height="75px" active inline />
      </AXSBlock>
    </AXSFlex>
  );

  const handlePrintSessionCard = (sessionId: string, userId: string, printer: string,
                                  startDateTime: string, endDateTime: string) => {
    if (sessionId) {
      dispatch(printSessionCard(sessionId, userId, printer, startDateTime, endDateTime));
    }
  };

  const closeButton: JSX.Element | null = sessionInfo ? (
    <CloseButton
      borderless
      onClick={ () => handleClose(!active) }
    >
      { t('preferences.cancel') }
    </CloseButton>
  ) : null;

  React.useEffect(() => {
    if (active && sessionInfo === undefined) {
      dispatch(getSessionInfoBegin());
    }
  },              [active, dispatch, sessionInfo]);

  if (sessionInfo) {
    sessionInfo.items.forEach((item: ISessionItem) => {
      if (item.mopLabel === 'Cash') {
        cashTotal += item.amount;
      } else {
        cardTotal += item.amount;
        cardItems.push(item);
      }
    });
    grandTotal = sessionInfo.grandTotal;
    content = (
      <div>
        <AXSFlex width="100%" justifyContent="center" marginTop="40px" marginBottom="40px">
          <SessionCardSubTitles fontSize="36px">{ t('preferences.sessionOverview') }</SessionCardSubTitles>
        </AXSFlex>
        <AXSBlock>
          <AXSFlex alignItems={ 'stretch' } justifyContent="space-between">
            <AXSFlexItem>
              { t('preferences.cardTenderedTotal') }
            </AXSFlexItem>
            <AXSFlexItem>
              { formatPrice(cardTotal * 100) }
            </AXSFlexItem>
          </AXSFlex>
        </AXSBlock>
        <AXSBlock>
          { cardItems.map((item: ISessionItem) => {
            return (
              <LineItem key={ item.amount } mopLabel={ item.mopLabel } amount={ item.amount } />
            );
          })}
        </AXSBlock>
        <AXSBlock>
          <AXSFlex alignItems="stretch" justifyContent="space-between">
            <AXSFlexItem marginTop="30px">
              { t('preferences.cashTenderedTotal') }
            </AXSFlexItem>
            <AXSFlexItem marginTop="30px">
              { formatPrice(cashTotal * 100) }
            </AXSFlexItem>
          </AXSFlex>
        </AXSBlock>
        <hr className="total-divider" />
        <AXSBlock marginBottom="40px">
          <AXSFlex alignItems="stretch" justifyContent="space-between">
            <AXSFlexItem>
              { t('preferences.sessionTotal') }
            </AXSFlexItem>
            <AXSFlexItem fontSize="20px" fontWeight="bold">
              { formatPrice(grandTotal * 100) }
            </AXSFlexItem>
          </AXSFlex>
        </AXSBlock>
      </div>
    );
  } else if (sessionInfo === null) {
    content = (
      <div>
        <AXSFlex width="100%" justifyContent="center" marginTop="60px" marginBottom="40px">
          <AXSBlock width="100%" textAlign="center">
            <SessionCardSubTitles fontSize="36px">{ t('preferences.sessionCardNotLoaded') }</SessionCardSubTitles>
          </AXSBlock>
        </AXSFlex>
      </div>
    );
  }

  return (
    <Wrapper>
      <AXSModal
        active={ active }
        handleClose={ () => handleClose(!active) }
        height="650px"  // TODO adjust height once time stamps are added
        showClose
        width="584px"
      >
        <PageTitle textAlign="center" marginBottom="30px" marginTop="20px">
          { t('preferences.sessionCard') }
        </PageTitle>
        <SectionHeader textAlign="center">{ userId }</SectionHeader>
        <InnerModal className="row justify-content-center">
          <div className="col-8">
            { content }
          </div>
        </InnerModal>
        <SessionCardContainer>
          <PrintSessionCardButton
            onClick={ () => handlePrintSessionCard(sessionId, userId, printer, startDateTime, endDateTime) }
          >
            { t('preferences.printCard') }
          </PrintSessionCardButton>
        </SessionCardContainer>
        { closeButton }
      </AXSModal>
    </Wrapper>
  );
};

export default SessionCardModal;
