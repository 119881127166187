class Product {

  constructor(
    public id: number,
    public description: string,
    public eventId: number,
    public required: boolean,
    public zoneDescription: string,
  ) {
  }

  public toString() {
    return `Product: ${this.id} - ${this.description}`;
  }

}

export default Product;
