import { AnyAction } from 'redux';
import Seat from '../../../../common/Seat/Seat';
import Section from '../../../../common/Section/Section';

export enum MapActionTypes {
  MAP_SELECT_SECTION = 'MAP_SELECT_SECTION',
  MAP_SELECT_SEAT = 'MAP_SELECT_SEAT',
  MAP_UPDATE_INVENTORY= 'MAP_UPDATE_INVENTORY',
}

export interface IMapState {
  maps: any[];
  mapInventory: any[];
  previewMapError: boolean;
}

export interface IMapUpdateInventory extends AnyAction {
  type: MapActionTypes.MAP_UPDATE_INVENTORY;
  mapId: string;
  seats: Seat[];
  sections: Section[];
}


export interface IMapSelectSection extends AnyAction {
  type: MapActionTypes.MAP_SELECT_SECTION;
  sectionLabel: string;
}

export type MapActions =
  IMapSelectSection |
  IMapUpdateInventory;
