import CartingRequest from './CartingRequest';
import { ICustomer, IAddress } from '../types/apiRequestTypes';
import { ICreditCard } from '../../../../types/creditCard';
import { formatResaleAddress, isValidEmailAddress } from '../helpers';
import { NAMES_VALIDATOR } from '../../../../constants/index';
import { guestCheckoutModeCustomer } from '../../../../constants/guestCheckoutMode';

export const validateNamesAndEmail = (firstName: string, lastName: string, email: string): boolean => {
  const validFirst = NAMES_VALIDATOR.FIRST_NAME_VALIDATOR.test(firstName);
  const validLast = NAMES_VALIDATOR.LAST_NAME_VALIDATOR.test(lastName);
  const validNames = validFirst && validLast;
  return isValidEmailAddress(email) && firstName.length > 0 && lastName.length > 0 && validNames;
};

export default class Customer extends CartingRequest {
  public email: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public creditCard?: ICreditCard;
  public resaleAddress?: any;
  private address?: IAddress;

  constructor(customer?: ICustomer) {
    super();
    if (customer) {
      this.setFromJSON(customer);
    }
  }

  public hasAllRequiredDetails(): boolean {
    return validateNamesAndEmail(this.firstName, this.lastName, this.email);
  }

  public setAddress(address1: string, address2: string, city: string, postalCode: string, regionCode: string,
                    countryCode: string, primaryPhone: string): void {
    this.address = {
      address1,
      address2,
      city,
      countryCode,
      postalCode,
      primaryPhone,
      regionCode,
    };
  }

  public setFromJSON(customer: ICustomer) {

    this.email = customer.shipping.email;
    this.firstName = customer.shipping.firstName;
    this.lastName = customer.shipping.lastName;
    this.resaleAddress = customer.resaleAddress;
    if (customer.cards && customer.cards.length) {
      this.creditCard = customer.cards[0];
    }
    if (customer.shipping.address) {
      const {
        address1,
        address2,
        city,
        postalCode,
        regionCode,
        countryCode,
        primaryPhone,
      } = customer.shipping.address;
      this.setAddress(address1, address2, city, postalCode, regionCode, countryCode, primaryPhone);
    }
  }

  public getName() {
    return `${this.firstName} ${this.lastName}`;
  }

  public setGuestCheckoutCustomer(): void {
    const { email, firstName, lastName } = guestCheckoutModeCustomer.shipping;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
  }

  public toJSON(): ICustomer {
    const json: ICustomer = {
      shipping: {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
      },
    };
    if (this.creditCard !== undefined) {
      const resaleAddress = this.resaleAddress;
      json.cards = [this.creditCard];
      if (resaleAddress) {
        json.cards[0].address = formatResaleAddress(resaleAddress);
      }
    }
    if (this.address !== undefined) {
      json.shipping.address = this.address;
    }
    return json;
  }
}
