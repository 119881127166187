import filter from 'lodash/filter';
import Seat from '../../../../common/Seat/Seat';
import Section from '../../../../common/Section/Section';
import Offer from '../../../../common/Offer/Offer';
import { SeatType } from '../../../../common/Seat/SeatType';
import { SeatTag } from '../constants';

export const seatToMmcId = (seatDescription: string, rowLabel: string, sectionLabel: string): string => {
  return `S_${sectionLabel.replace(/\W+/g, '')}-${rowLabel}-${seatDescription}`;
};

export const mmcIdToSeat = (mmcSeatId: string): { number: string, rowLabel: string, sectionLabel: string } => {
  const trimmedMmcSeatId = mmcSeatId.replace('S_', '');
  const seatStructure = trimmedMmcSeatId.split('-');
  return {
    number: seatStructure[2],
    rowLabel: seatStructure[1],
    sectionLabel: seatStructure[0],
  };
};

export const mmcIdToSectionLabel = (mmcId: string): string => {
  return mmcId.substring(2);
};

export const sectionLabelToMmcId = (sectionLabel: string): string => {
  return `S_${sectionLabel.replace(/\W+/g, '')}`;
};

export const sectionLabelsToMmcIds = (sectionLabels: string[]): string[] => {
  return sectionLabels.map((sectionLabel) => {
    return sectionLabelToMmcId(sectionLabel);
  });
};

/**
 * Some inventories have section labels with spaces. This
 * Iterates through all seats and returns the section labels
 * in "MmcId" format (e.g., no spaces or special characters).
 *
 * @param   {[array]}  seats  seats array *
 * @return  {[array]}         transformed seats array
 */
export const seatsWithMmcIds = (seats: Seat[]) => seats.map((seat: Seat) => {
  return {
    ...seat,
    sectionLabel: seat.sectionLabel.replace(/\W+/g, ''),
  };
});

export const loadScript = async (filepath: string, callback: () => void = () => null, toBottom: boolean = false) => {
  try {
    const fileref: any = document.createElement('script');
    let done = false;
    const head = document.getElementsByTagName('head')[0];

    fileref.onload = fileref.onreadystatechange = () => {
      if (!done && (!fileref.readyState || fileref.readyState === 'loaded' || fileref.readyState === 'complete')) {
        done = true;
        if (callback !== undefined) {
          callback();
        }
        // Handle memory leak in IE
        fileref.onload = fileref.onreadystatechange = null;
        if (!toBottom && head && fileref.parentNode) {
          head.removeChild(fileref);
        }
      }
    };
    fileref.setAttribute('type', 'text/javascript');
    fileref.setAttribute('src', filepath);
    if (!toBottom) {
      head.appendChild(fileref);
    } else if (document.body !== null) {
      document.body.appendChild(fileref);
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const getSectionsWithAvailableSeats = (seatInventory: Seat[], sectionInventory: Section[]) => {
  const sections: Section[] = [];
  sectionInventory.forEach((section: Section) => {
    const filteredSections = filter(seatInventory, (seat: Seat) => (Number(seat.sectionId) === section.id));
    if (filteredSections.length > 0) {
      sections.push(section);
    }
  });
  return sections;
};


export const has2DMap = (offer: Offer) => {
  return offer.mmcMapId && !(offer.isMmc3D || offer.isMMCEnabled);
};

export const getSeatTag = (seat: Seat) => {
  switch (seat.seatType) {
    case SeatType.FLASHSEATS:
      return SeatTag.flashseats;
    case SeatType.ACCESSIBLE:
      return SeatTag.accessible;
    default:
      return SeatTag.standard;
  }
};

export const checkSeatZoomLevel = (seatLevelZoom: number, sectionDimensions: any, maxSectionHeight: number,
                                   minSeatLevelZoom: number): any => {
  let zoomShrinkPercent;
  let newSeatLevelZoom;
  if (sectionDimensions.height > maxSectionHeight) {
    zoomShrinkPercent = (sectionDimensions.height - maxSectionHeight) * .01;
    newSeatLevelZoom = seatLevelZoom - (seatLevelZoom * zoomShrinkPercent);
    // Use minSeatLevelZoom if seatLevelZoom shrinks lower than it
    newSeatLevelZoom = (minSeatLevelZoom > newSeatLevelZoom ? minSeatLevelZoom : newSeatLevelZoom);
  } else {
    newSeatLevelZoom = seatLevelZoom;
  }
  return newSeatLevelZoom;
};

