class Category {

  constructor(
    public id: any,
    public name: string,
    public typeId: number,
  ) {
  }
}

export default Category;
