import styled from 'styled-components';
import { AXSPageTitle, AXSBlock } from '@axs/axs-ui';
import { zIndexes } from '../../../../../theme';
import { CartTable } from '../common/style';

export const CartDrawerWrapper = styled(AXSBlock)`
  position: absolute;
  top: 111px;
  left: 0;
  right: 0;
  box-shadow: 0 5px 5px rgba(0, 0, 0, .2);
  z-index: ${ zIndexes.CART_DRAWER };

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

export const CartDrawerTable = styled(CartTable)`
  margin-bottom: 20px;
`;

export const CartItemsPane = styled(AXSBlock)`
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 20px 0 0;
`;

export const Title = styled(AXSPageTitle)`
  float: left;
  margin-right: 10px;
  margin-bottom: 50px;
`;

export const Total = styled(AXSBlock)`
  display: inline;
  float: right;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-right: 20px;
`;

export const OfferCell = styled.td`
  width: 100%;
`;

